import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { RingLoader } from 'react-spinners'

interface IProps {
    dependances: boolean[],
}

// https://www.davidhu.io/react-spinners/
export const FloatingSpinner: FC<IProps> = (props) => {
    // const color = "#4c1a57"
    const color = "#FFFFFF"
    const display = props.dependances.includes(true)
    return (
        // <Box
        //     className="FloatingSpinner"
        //     sx={{
        //         mt: "80px",
        //         position: "fixed",
        //         top: 0,
        //         width: "100%",
        //         right: 0,
        //         zIndex: 1301,
        //         display: props.dependances.includes(true) ? "flex" : "none",
        //         justifyContent: "center",
        //     }}
        // >
        //     <RingLoader color={color} size={30} />
        // </Box>
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1400,
                display: display ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <RingLoader color={color} size={30} />
            <Typography sx={{ mt: 4, color: color }}>
                Traitement en cours...
            </Typography>
        </Box>
    )
}
