import {
    Button, FormControl, Input,
    InputAdornment,
    InputLabel, OutlinedInput, TextField, Typography, useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMessageType } from '../../enums';
import { IBankAccountMinimal } from '../../interfaces';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_CREATE_QUICK_ACCOUNT_OPERATION } from '../../graphql/Mutations';
import { Flexbox } from '../utils/Flexbox';

interface IProps {
    bankAccount: IBankAccountMinimal,
}

export const QuickAccountOperation: FC<IProps> = (props) => {
    const [title, setTitle] = useState<string>("")
    const [amount, setAmount] = useState<string>("")
    const [createQuickAccountOperation, { data: createQuickAccountOperationData, loading: createQuickAccountOperationLoading, error: createQuickAccountOperationError }] = useMutation(GQL_MUTATION_CREATE_QUICK_ACCOUNT_OPERATION)

    const theme = useTheme()


    useEffect(() => {
        if (createQuickAccountOperationError) {
            addMessage({
                location: "QuickAccountOperation",
                type: EnumMessageType.Error,
                message: createQuickAccountOperationError.message,
            })
        } else if (createQuickAccountOperationData) {
            if (createQuickAccountOperationData.createQuickAccountOperation.statusCode === 200) {
                cleanDatas()
                setAllRefetchNeeded("QuickAccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opération ajoutée.",
                })
            } else {
                createQuickAccountOperationData.createQuickAccountOperation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createQuickAccountOperationData, createQuickAccountOperationError])

    const cleanDatas = () => {
        setTitle("")
        setAmount("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createQuickAccountOperation({
            variables: {
                bankAccountId: props.bankAccount.id,
                title: title,
                amount: parseFloat(amount),
            }
        })
    }

    return (
        <>
            <Box
                noValidate
                component="form"
                onSubmit={handleConfirm}
            >

                <Flexbox direction='column' sx={{
                    // bgcolor: "yellow",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}>
                    <Typography sx={{
                        mt: 2,
                        fontSize: 18,
                    }}>
                        {props.bankAccount.title} :
                    </Typography>
                    <TextField
                        margin="dense"
                        label="Nom de l'opération"
                        // fullWidth
                        variant="standard"
                        onChange={(event) => { setTitle(event.target.value) }}
                        value={title}
                    // required
                    // error={errorOnTitle}
                    // helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                    />
                    <FormControl variant="outlined" sx={{
                        mt: 1,
                        width: '100%',
                    }}>
                        <InputLabel htmlFor="outlined-adornment-amount" >Montant</InputLabel>
                        {/* <Input
                            type='text'
                            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                            endAdornment={
                                <InputAdornment position="end">
                                    €
                                </InputAdornment>
                            }
                            color="primary"
                            onChange={(event) => { setAmount(event.currentTarget.value) }}
                            value={amount}
                            autoComplete='off'
                        /> */}
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            type='number'
                            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' }}
                            endAdornment={
                                <InputAdornment position="end">
                                    €
                                </InputAdornment>
                            }
                            label="Montant"
                            color="primary"
                            onChange={(event) => { setAmount(event.currentTarget.value) }}
                            value={amount}
                            autoComplete='off'
                        />
                    </FormControl>
                    <Flexbox sx={{
                        // bgcolor: "red",
                        mt: 1,
                        width: "100%",
                        justifyContent: "flex-end",
                    }}>
                        <Button type='submit' variant="text" color="primary">
                            Ajouter
                        </Button>

                    </Flexbox>
                </Flexbox>
            </Box>
        </ >
    )
}
