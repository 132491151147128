import { Container, Divider, IconButton, Tooltip, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { expandSelectedBankAccountsAction, selectAllBankAccountAction, unExpandSelectedBankAccountsAction, unSelectAllBankAccountAction } from '../../redux/features/cashflow/bankAccountsSlice';
import ClearIcon from '@mui/icons-material/Clear';
import { ModalNewBankAccount } from './modals/ModalNewBankAccount';
import { useDispatch } from 'react-redux';
import { ExpandMore } from '../utils/ExpandMore';
import { IBankAccount } from '../../interfaces';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    selectedBankAccountIds: string[],
    setFilter: (filter: string) => void;
    handleDeleteSelection: () => void;
    filteredBankAccounts: IBankAccount[],
}

export const BankAccountsFilter: FC<IProps> = (props) => {
    const [showModalNewBankAccount, setShowModalNewBankAccount] = useState(false)
    const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [selectAllBankAccounts, setSelectAllBankAccounts] = useState(true)
    const [expandSelectedBankAccounts, setExpandSelectedBankAccounts] = useState(true)

    const dispatch = useDispatch()

    const hasSelectedBankAccount = () => {
        return props.selectedBankAccountIds.length > 0
    }

    const closeModalNewBankAccount = () => {
        setShowModalNewBankAccount(false)
    }

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            props.setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);

    const handleSelectAll = () => {
        selectAllBankAccounts ? dispatch(selectAllBankAccountAction()) : dispatch(unSelectAllBankAccountAction())
        setSelectAllBankAccounts(!selectAllBankAccounts)
    }

    const handleExpandSelectedBankAccounts = () => {
        if (getSelectedBankAccountIds().length > 0) {
            expandSelectedBankAccounts ? dispatch(expandSelectedBankAccountsAction()) : dispatch(unExpandSelectedBankAccountsAction())
            setExpandSelectedBankAccounts(!expandSelectedBankAccounts)
        }
    }

    const getSelectedBankAccountIds = () => {
        if (props.filteredBankAccounts === undefined) {
            return []
        } else {
            return props.filteredBankAccounts.filter(filteredBankAccount => filteredBankAccount.selected).map(filteredBankAccount => filteredBankAccount.id)
        }
    }

    return (
        <Container className="ListBankAccounts-actions"
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: "12px",
            }}
        >
            <FormControl sx={{
                pb: 2,
                my: 0,
                mr: 1,
                width: "200px",
            }} variant="standard">
                <InputLabel htmlFor="standard-adornment-bankAccount">Filtrer...</InputLabel>
                <Input
                    type='text'
                    onChange={(event) => { handleFilterInput(event.target.value) }}
                    value={filterInput}
                    endAdornment={
                        filterInput.length > 0 && <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleFilterInput("") }}
                            >
                                <ClearIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Divider orientation="vertical" flexItem variant="middle" sx={{
                mx: 1,
            }} />
            {
                hasSelectedBankAccount() && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les comptes sélectionnés">
                    <IconButton onClick={props.handleDeleteSelection} color="error">
                        <DeleteOutline />
                    </IconButton>
                </Tooltip>
            }
            {
                hasSelectedBankAccount() &&
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={expandSelectedBankAccounts ? "Déplier les comptes sélectionnnés" : "Plier les comptes sélectionnnés"}>
                    <ExpandMore expand={!expandSelectedBankAccounts} onClick={handleExpandSelectedBankAccounts} aria-expanded={!expandSelectedBankAccounts} aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Tooltip>
            }
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllBankAccounts ? "Sélectionner tout" : "Désélectionner tout"}>
                <IconButton onClick={handleSelectAll} color={selectAllBankAccounts ? "secondary" : "cancel"}>
                    <CropFreeOutlined />
                </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem variant="middle" sx={{
                mx: 1,
            }} />
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un compte">
                <IconButton color="primary" onClick={() => setShowModalNewBankAccount(true)}><Add fontSize="medium" /></IconButton>
            </Tooltip>
            <ModalNewBankAccount
                open={showModalNewBankAccount}
                handleClose={closeModalNewBankAccount}
            />
        </Container>
    )
}
