import { Link, Typography } from '@mui/material';
import { FC } from 'react';

interface IProps {
    sx?: object,
}

export const Copyright: FC<IProps> = (props) => {

    return (
        <>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href={`${window.location.origin}`}>
                    Metadoc
                </Link>{' 2014-'}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </>
    )
}
