import { createSlice } from "@reduxjs/toolkit";
import { IAccountOperationStatesFilter, IBankAccount, IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_BANK_ACCOUNT, GQL_USER } from "../../../graphql/Queries";
import { EnumAccountOperationImputableVisibility, EnumAccountOperationTypeVisibility, EnumDayjsFormat } from "../../../enums";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';


interface IUserSliceState {
    user: undefined | IUser,
    refetchNeeded: boolean,
}

const initialState: IUserSliceState = {
    user: undefined,
    refetchNeeded: false,
}

export const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        setUserAction: (state, action) => {
            state.user = action.payload
        },
        setUserRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
    }
})

export const {
    setUserAction,
    setUserRefetchNeeded,
} = userSlice.actions


export const useGetUser = (userId: string) => {
    const user: (undefined | IUser) = useSelector((state: IRootState) => state.userReducer.user)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.userReducer.refetchNeeded)
    if (refetchNeeded || user === undefined || user.id !== userId) {
        apolloClient.query({
            query: GQL_USER,
            fetchPolicy: 'network-only',
            variables: {
                userId,
            }
        }
        ).then(response => {
            store.dispatch(setUserAction(response.data.user))
            store.dispatch(setUserRefetchNeeded(false))
            return response.data.user
        })
    } else {
        return user
    }
}

export default userSlice.reducer