import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IContact } from "../../interfaces";
import {
    Add, MoreHoriz
} from '@mui/icons-material';

import { EnumCustomContactMode, EnumSize } from '../../enums';
import { IRootState } from '../../redux/store';
import 'dayjs/locale/fr';
import Grid from '@mui/material/Grid2';
import { useDispatch, useSelector } from 'react-redux';
import { clearOpenedModal, setOpenedModal } from '../../redux/features/global/modalsSlice';
import { CustomContactBadge } from './CustomContactBadge';
import { ModalNewCustomContactRelationship } from './modals/ModalNewCustomContactRelationship';
import { Flexbox } from '../utils/Flexbox';


interface IProps {
    contact: IContact,
}

export const CustomContactRelationships: FC<IProps> = (props) => {
    const ModalNewCustomContactRelationshipId = `ModalNewCustomContactRelationship-contact-${props.contact.id}`
    const openedModal: string = useSelector((state: IRootState) => state.modalsReducer.openedModal)
    const dispatch = useDispatch()
    const [displayAll, setDisplayAll] = useState(false)
    const minDisplay = 2

    const theme = useTheme()



    const handleOpenModalNewCustomContactRelationship = () => {
        dispatch(setOpenedModal(ModalNewCustomContactRelationshipId))
    }

    const closeModalNewCustomContactRelationship = () => {
        dispatch(clearOpenedModal())
    }


    return (
        <>
            <Grid container sx={{ width: "100%", alignItems: "center" }}>
                <Grid size={{ xs: 8 }} sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // overflow: "hidden",
                    flexWrap: "wrap",
                }}>
                    {
                        props.contact.customContacts && props.contact.customContacts.length > 0
                            ?
                            props.contact.customContacts.map((customContact, customContactIndex) => {
                                return (customContactIndex < minDisplay || displayAll)
                                    ?
                                    <Flexbox key={`grid-custom-contact-relationship-${customContact.id}`} sx={{
                                        mr: 1,
                                    }}>
                                        <CustomContactBadge showContactAvatar={false} mode={EnumCustomContactMode.standard} customContact={customContact} size={EnumSize.tiny} displayTooltip={true} />
                                    </Flexbox>

                                    :
                                    null
                            })
                            :
                            <Grid
                                size={{ xs: 10 }}
                            >
                                <Typography variant="body1" sx={{ fontSize: "14px", color: theme.palette.text.secondary }}>
                                    Aucune relation de payeur / bénéficiaire..
                                </Typography>
                            </Grid>

                    }
                </Grid>
                <Grid size={{ xs: 2 }}>
                    {
                        !displayAll && props.contact.customContacts && props.contact.customContacts.length > minDisplay &&
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Afficher tout">
                            <IconButton color="primary" onClick={() => setDisplayAll(true)}>
                                <MoreHoriz fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
                <Grid size={{ xs: 2 }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter une relation">
                        <IconButton color="primary" onClick={handleOpenModalNewCustomContactRelationship}><Add fontSize="medium" /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <ModalNewCustomContactRelationship
                open={openedModal === ModalNewCustomContactRelationshipId}
                handleClose={closeModalNewCustomContactRelationship}
                contact={props.contact}
            />
        </>
    )
}
