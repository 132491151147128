import "../../css/components/directories/Directory.css";
import { Box, Button, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IBreadcrumb, IDirectory, IDirectorySearchResult } from "../../interfaces";
import { EnumBasePathFor, EnumBreadcrumbMode, EnumMessageType, EnumParentType, EnumSvgVariant } from "../../enums";
import { truncateString } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { constructPath, getBasePath, getModePath } from "../../utils/Navigation";
import { DirectoryIcon } from "../svg_icons/DirectoryIcon";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { Breadcrumbs } from "../Breadcrumbs";

import {
  setPocketRefetchNeeded,
  useGetDirectories as useGetPocketDirectories,
} from "../../redux/features/pocket/pocketSlice";
import { PocketIcon } from "../svg_icons/PocketIcon";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET } from "../../graphql/Mutations";
import { addMessage } from "../messages/Message";
import { useDispatch } from "react-redux";

interface IProps {
  directory: IDirectorySearchResult,
}

export const DirectorySearchResult: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  const pocketDirectories = useGetPocketDirectories()
  const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
  const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

  const theme = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const directoryPath = constructPath(getBasePath(EnumBasePathFor.directory, props.directory.mode), [
    { key: "directoryId", value: props.directory.id.toString() }
  ])

  const getAncestorsBreadcrumbs = () => {
    if (props.directory === undefined) {
      return [{ title: "..." }]
    }
    let res = []
    // Le locker d'abord:
    res.push(
      {
        title: props.directory.locker.title,
        path: constructPath(getBasePath(EnumBasePathFor.locker, props.directory.mode), [
          { key: "lockerId", value: props.directory.locker.id.toString() }
        ])
      }
    )
    // Puis les ancestors:
    res.push(
      ...props.directory.ancestors.map(ancestor => {
        return {
          title: ancestor.name,
          path: constructPath(getBasePath(EnumBasePathFor.directory, props.directory.mode), [
            { key: "directoryId", value: ancestor.id.toString() }
          ])
        }
      })
    )
    return res
  }

  const isInPocket = () => {
    if (pocketDirectories) {
      return pocketDirectories.filter(directory => directory.id === props.directory.id).length > 0
    }
    return false
  }

  const handlePutInPocket = () => {
    addInPocket({
      variables: {
        directoryIds: [props.directory.id],
      }
    })
  }

  const handleRemoveFromPocket = () => {
    removeFromPocket({
      variables: {
        directoryIds: [props.directory.id],
      }
    })
  }

  useEffect(() => {
    setBreadcrumbs([
      getModePath(props.directory.mode),
      ...getAncestorsBreadcrumbs(),
      {
        title: props.directory.name || "...",
      },
    ])
  }, [props.directory])

  useEffect(() => {
    if (addInPocketError) {
      addMessage({
        location: "DirectorySearchResult",
        type: EnumMessageType.Error,
        message: addInPocketError.message,
      })
    } else if (addInPocketData) {
      if (addInPocketData.addInPocket.statusCode === 200) {
        // dispatch(addDirectoriesInPocketAction([props.directory]))
        dispatch(setPocketRefetchNeeded(true))
        addMessage({
          type: EnumMessageType.Success,
          message: "C'est dans la pocket.",
        })
      } else {
        addInPocketData.addInPocket.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [addInPocketData, addInPocketError])

  useEffect(() => {
    if (removeFromPocketError) {
      addMessage({
        location: "DirectorySearchResult",
        type: EnumMessageType.Error,
        message: removeFromPocketError.message,
      })
    } else if (removeFromPocketData) {
      if (removeFromPocketData.removeFromPocket.statusCode === 200) {
        // dispatch(removeDirectoriesFromPocketAction([props.directory.id]))
        dispatch(setPocketRefetchNeeded(true))
        addMessage({
          type: EnumMessageType.Success,
          message: "Retiré de la pocket.",
        })
      } else {
        removeFromPocketData.removeFromPocket.errors.map((error: string) => {
          addMessage({
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [removeFromPocketData, removeFromPocketError])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", mx: 2, width: "100%" }}>
      <Paper className={`Directory ${props.directory.selected ? "selected" : ""} `}>
        <Box className="Directory-container">
          <Box className="Directory-main-section">
            <Box className="Directory-icon-section">
              <DirectoryIcon color={props.directory.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
            </Box>
            <Box className="Directory-content-section">
              <Box className="Directory-title-section">
                <Typography
                  onClick={() => navigate(directoryPath)}
                  sx={{
                    color: theme.palette.text.primary,
                    fontSize: 24,
                    ":hover": {
                      cursor: "pointer",
                      textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                    }
                  }}>
                  {props.directory.name}
                </Typography>
              </Box>
              <Typography >
                {truncateString(props.directory.description, 80)}
              </Typography>
              <Box className="Directory-infos-section">
                <Box className="Directory-infos-directories" sx={{ mt: 1 }}>
                  <SubDirectoriesInfos mode={props.directory.mode} detailsMode={false} parentType={EnumParentType.directory} directory={props.directory} />
                </Box>
              </Box>
            </Box>

          </Box>
          <Box className="Directory-footer-section">
            {
              isInPocket() ?
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                  <IconButton onClick={handleRemoveFromPocket} color="secondary">
                    <PocketIcon variant={EnumSvgVariant.secondary} />
                  </IconButton>
                </Tooltip> :
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                  <IconButton onClick={handlePutInPocket} color="primary">
                    <PocketIcon variant={EnumSvgVariant.primary} />
                  </IconButton>
                </Tooltip>
            }
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
              <Link href={directoryPath} underline="none" target="_blank">
                <IconButton color="primary">
                  <OpenInNewOutlinedIcon />
                </IconButton>
              </Link>
            </Tooltip>
            {/* <Button size="small" onClick={() => navigate(directoryPath)}>Ouvrir</Button> */}
          </Box>
        </Box>
      </Paper>
      <Box sx={{ ml: 2 }}>
        <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ mt: -1, mb: 2 }} />
      </Box>
    </Box>
  )
}
