
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


interface EyeClosedIconProps extends SvgIconProps {
  mainColor: string;
}

export const EyeClosedIcon = (
  { mainColor, ...props }: EyeClosedIconProps,
) => {
  return (
    // <SvgIcon viewBox='0 0 24 24' {...props}>
    //   <path d="M21.0006 12.0007C19.2536 15.5766 15.8779 18 12 18M12 18C8.12204 18 4.7463 15.5766 2.99977 12.0002M12 18L12 21M19.4218 14.4218L21.4999 16.5M16.2304 16.9687L17.5 19.5M4.57812 14.4218L2.5 16.5M7.76953 16.9687L6.5 19.5" stroke={mainColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    // </SvgIcon>

    <SvgIcon viewBox='0 0 25 25' {...props}>
      <path d="M7 15.5L5.5 17.5M20.5 12.5C19.8612 13.5647 19.041 14.6294 18.0008 15.501M18.0008 15.501C16.5985 16.676 14.7965 17.5 12.5 17.5M18.0008 15.501L18 15.5M18.0008 15.501L19.5 17.5M12.5 17.5C8.5 17.5 6 15 4.5 12.5M12.5 17.5V20M15.5 17L16.5 19.5M9.5 17L8.5 19.5" stroke={mainColor} strokeWidth="1.2" />
    </SvgIcon>

  )
}


