import { Box, TextField, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../utils/Flexbox';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_CREATE_TODOSTEP } from '../../graphql/Mutations';
import { ITodolist } from '../../interfaces';
import { Todolist } from './Todolist';


interface IProps {
    todolist: ITodolist,
}

export const TodostepPrompt: FC<IProps> = (props) => {
    const [newTodostep, setNewTodostep] = useState("")
    const titleRef = useRef<HTMLInputElement>(null);
    const [createTodostep, { data: createTodostepData, loading: createTodostepLoading, error: createTodostepError }] = useMutation(GQL_MUTATION_CREATE_TODOSTEP)

    const theme = useTheme()

    useEffect(() => {
        setTimeout(() => {
            if (titleRef.current) {
                titleRef.current.focus();
            }
        }, 0);
    }, []);

    useEffect(() => {
        if (createTodostepError) {
            addMessage({
                location: "TodostepPrompt",
                type: EnumMessageType.Error,
                message: createTodostepError.message,
            })
        } else if (createTodostepData) {
            if (createTodostepData.createTodostep.statusCode === 200) {
                setAllRefetchNeeded("TodostepPrompt")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Etape ajoutée.",
                })
                setNewTodostep("")
            } else {
                createTodostepData.createTodostep.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createTodostepData, createTodostepError])

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createTodostep({
            variables: {
                todolistId: props.todolist.id,
                title: newTodostep,
            }
        })
    }


    return (
        <Flexbox sx={{
            justifyContent: "flex-start",
            mb: 1,
            pl: 6,
            width: "100%",
            // bgcolor: "yellow",
        }}>
            <Box
                noValidate
                component="form"
                onSubmit={handleConfirm}
                autoComplete="off"
                sx={{
                    width: "100%",
                    maxWidth: { xs: "200px", md: "400px" },
                }}
            >
                <TextField
                    inputRef={titleRef}
                    name="new-todostep"
                    margin="dense"
                    label="Ajouter une étape.."
                    fullWidth
                    variant="standard"
                    onChange={(event) => { setNewTodostep(event.target.value) }}
                    value={newTodostep}
                    autoComplete="off"
                    color="primary"
                />
            </Box>
        </Flexbox>
    )
}
