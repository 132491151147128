// import * as React from 'react';
import { FC, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router"
import { NAVIGATE_LOGIN, NAVIGATE_ROOT } from "../utils/Navigation"
import { useDispatch, useSelector } from "react-redux";
import { setJwt } from "../redux/features/global/apolloSlice";
import { IRootState } from "../redux/store"
import { useMutation } from "@apollo/client"
import { GQL_MUTATION_LOGIN, GQL_MUTATION_RESET_PASSWORD, GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL } from "../graphql/Mutations";
import { Messages } from "../components/messages/Messages";
import { addMessage } from "../components/messages/Message";
import { EnumMessageType, EnumMetadocLogoMode } from "../enums";
import { MetadocLogo } from '../components/MetadocLogo';
import { useParams } from "react-router-dom";
import { Copyright } from '../components/utils/Copyright';



// function Copyright(props: any) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href={process.env.REACT_APP_METADOC_WEB_URI}>
//                 Metadoc
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }


export default function PasswordReset() {
    const [email, setEmail] = useState("")
    const [errorOnEmail, setErrorOnEmail] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [errorOnNewPassword, setErrorOnNewPassword] = useState(false)
    const [errorOnNewPasswordConfirmation, setErrorOnNewPasswordConfirmation] = useState(false)
    const [resetPassword, { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError }] = useMutation(GQL_MUTATION_RESET_PASSWORD)
    const navigate = useNavigate()
    const params = useParams()
    const passwordResetToken = params.passwordResetToken
    // console.log("params: ", params);
    // console.log("passwordResetToken: ", passwordResetToken);


    useEffect(() => {
        if (resetPasswordError) {
            addMessage({
                location: "PasswordReset",
                type: EnumMessageType.Error,
                message: resetPasswordError.message,
            })
        } else if (resetPasswordData) {
            if (resetPasswordData.resetPassword.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Mot de passe réinitialisé."
                })
                navigate(NAVIGATE_LOGIN)
            } else {
                resetPasswordData.resetPassword.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [resetPasswordData, resetPasswordError])

    const checkErrors = () => {
        let res = true
        if (email === undefined || email === "") {
            setErrorOnEmail(true)
            res = false
        } else {
            setErrorOnEmail(false)
        }
        if (newPassword === undefined || newPassword === "") {
            setErrorOnNewPassword(true)
            res = false
        } else {
            setErrorOnNewPassword(false)
        }
        if (newPasswordConfirmation === undefined || newPasswordConfirmation === "") {
            setErrorOnNewPasswordConfirmation(true)
            res = false
        } else {
            setErrorOnNewPasswordConfirmation(false)
        }
        if (newPassword !== newPasswordConfirmation) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Le nouveau mot de passe et sa confirmation sont différents."
            })
            res = false
        }
        return res
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            resetPassword({
                variables: {
                    email: email,
                    passwordResetToken: passwordResetToken,
                    newPassword: newPassword,
                }
            })
        }
    };

    return (
        <div className="PasswordReset">
            <Messages />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        paddingTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <MetadocLogo mode={EnumMetadocLogoMode.accueil} link={true} />
                    <Typography component="h1" variant="h5" color="primary">
                        Changement de mot de passe
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="dense"
                            label="Email"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setEmail(event.target.value) }}
                            value={email}
                            required
                            error={errorOnEmail}
                            helperText={errorOnEmail ? "Ce champ est obligatoire." : ""}
                            autoFocus
                            name="email"
                        />
                        <TextField
                            type='password'
                            margin="dense"
                            label="Nouveau mot de passe"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNewPassword(event.target.value) }}
                            value={newPassword}
                            required
                            error={errorOnNewPassword}
                            helperText={errorOnNewPassword ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            type='password'
                            margin="dense"
                            label="Confirmez le mot de passe"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNewPasswordConfirmation(event.target.value) }}
                            value={newPasswordConfirmation}
                            required
                            error={errorOnNewPasswordConfirmation}
                            helperText={errorOnNewPasswordConfirmation ? "Ce champ est obligatoire." : ""}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Enregistrer
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href='#' onClick={() => navigate(NAVIGATE_LOGIN)} variant="body2">
                                    Se connecter
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
    );
}