
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { forwardRef } from 'react';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

// export const ExpandMore = styled((props: ExpandMoreProps) => {
//     const { expand, ...other } = props;
//     return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//     transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//     }),
// }));

export const ExpandMore = styled(
    forwardRef((props: ExpandMoreProps, ref: React.Ref<any>) => {
        const { expand, ...other } = props;
        return <IconButton ref={ref} {...other} />;
    })
)(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
