import { useMutation } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { EnumContactType, EnumDayjsFormat, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_CONTACT } from '../../../graphql/Mutations';
import { IContact } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { Flexbox } from '../../utils/Flexbox';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { ModalDatePicker } from '../../modals/ModalDatePicker';
import { ContactIcon } from '../../svg_icons/ContactIcon';
import { DateSelector } from '../../utils/DateSelector';

interface IProps {
    open: boolean;
    handleClose: () => void;
    contact: IContact

}

export const ModalEditContact: FC<IProps> = (props) => {

    const [nickname, setNickname] = useState(props.contact.nickname)
    const nicknameRef = useRef<HTMLInputElement>(null);
    const [firstname, setFirstname] = useState(props.contact.firstname)
    const [lastname, setLastname] = useState(props.contact.lastname)
    const [enterpriseName, setEnterpriseName] = useState(props.contact.enterpriseName)
    const [contactType, setContactType] = useState(props.contact.contactType)
    const [phone, setPhone] = useState(props.contact.phone)
    const [email, setEmail] = useState(props.contact.email)

    // const [birthAt, setBirthAt] = useState(props.contact.birthAt || "")
    const [birthAt, setBirthAt] = useState<Dayjs | undefined>(props.contact.birthAt ? dayjs(props.contact.birthAt) : undefined)
    // const [showModalDatePickerBirthAt, setShowModalDatePickerBirthAt] = useState(false)

    const [adresse, setAdresse] = useState(props.contact.adresse)
    const [description, setDescription] = useState(props.contact.description)

    const theme = useTheme()
    const [updateContact, { data: updateContactData, loading: updateContactLoading, error: updateContactError }] = useMutation(GQL_MUTATION_UPDATE_CONTACT)


    useEffect(() => {
        setNickname(props.contact.nickname)
        setFirstname(props.contact.firstname)
        setLastname(props.contact.lastname)
        setEnterpriseName(props.contact.enterpriseName)
        setContactType(props.contact.contactType)
        setPhone(props.contact.phone)
        setEmail(props.contact.email)
        setBirthAt(props.contact.birthAt ? dayjs(props.contact.birthAt) : undefined)
        // setBirthAt(props.contact.birthAt || "")
        setAdresse(props.contact.adresse)
        setDescription(props.contact.description)
    }, [props.contact])

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (nicknameRef.current) {
                    nicknameRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateContactError) {
            addMessage({
                location: "ModalEditContact",
                type: EnumMessageType.Error,
                message: updateContactError.message,
            })
        } else if (updateContactData) {
            if (updateContactData.updateContact.statusCode === 200) {
                setAllRefetchNeeded("ModalEditContact")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Contact modifié.",
                })
                handleClose()
            } else {
                updateContactData.updateContact.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateContactData, updateContactError])

    // const closeModalDatePickerBirthAt = () => {
    //     setShowModalDatePickerBirthAt(false)
    // }

    const confirmModalDatePickerBirthAt = (pDate: Dayjs) => {
        // setBirthAt(pDate.format("DD/MM/YYYY"))
        setBirthAt(pDate)
    }

    const resetModalDatePickerBirthAt = () => {
        // setBirthAt("")
        // setBirthAt(props.contact.birthAt || "")
        setBirthAt(undefined)
    }


    const checkErrors = () => {
        let res = true
        if ((firstname === undefined || firstname === "") &&
            (lastname === undefined || lastname === "") &&
            (enterpriseName === undefined || enterpriseName === "")
        ) {
            addMessage({
                type: EnumMessageType.Error,
                message: "L'un des champs nom, prénom ou entreprise doit être renseigné.",
            })
            res = false
        }
        if (email && email.length > 0 && !email.includes('@')) {
            addMessage({
                type: EnumMessageType.Error,
                message: "L'email est incorrect.",
            })
            res = false
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateContact({
                variables: {
                    contactId: props.contact.id,
                    nickname,
                    firstname,
                    lastname,
                    enterpriseName,
                    contactType,
                    phone,
                    email,
                    birthAt: birthAt?.format(EnumDayjsFormat.dateForBackend),
                    adresse,
                    description,
                }
            })
        }
    }

    const cleanDatas = () => {
        setNickname(props.contact.nickname)
        setFirstname(props.contact.firstname)
        setLastname(props.contact.lastname)
        setEnterpriseName(props.contact.enterpriseName)
        setContactType(props.contact.contactType)
        setPhone(props.contact.phone)
        setEmail(props.contact.email)
        setBirthAt(props.contact.birthAt ? dayjs(props.contact.birthAt) : undefined)
        setAdresse(props.contact.adresse)
        setDescription(props.contact.description)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    // const handleBirthAt = () => {
    //     setShowModalDatePickerBirthAt(true)
    // }

    return (
        <div className="ModalEditContact">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <ContactIcon sx={{ fontSize: 24, mr: 3, color: theme.palette.desktop.main }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier le contact
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={nicknameRef}
                            autoFocus
                            margin="dense"
                            label="Surnom"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNickname(event.target.value) }}
                            value={nickname}
                        />
                        <TextField
                            margin="dense"
                            label="Prénom"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setFirstname(event.target.value) }}
                            value={firstname}
                        />
                        <TextField
                            margin="dense"
                            label="Nom"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setLastname(event.target.value) }}
                            value={lastname}
                        />
                        <TextField
                            margin="dense"
                            label="Entreprise"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setEnterpriseName(event.target.value) }}
                            value={enterpriseName}
                        />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Devient accessible dans Cashflow.">
                            <FormControlLabel control={
                                <Checkbox
                                    checked={contactType === EnumContactType.business}
                                    value={contactType === EnumContactType.business}
                                    onChange={(event) => setContactType(event.target.checked ? EnumContactType.business : EnumContactType.standard)}
                                />
                            } label="Contact de type business." />
                        </Tooltip>
                        <TextField
                            margin="dense"
                            label="Téléphone"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setPhone(event.target.value) }}
                            value={phone}
                        />
                        <TextField
                            margin="dense"
                            label="Email"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setEmail(event.target.value) }}
                            value={email}
                            type='email'
                        />
                        <Flexbox sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Date de naissance
                            </Typography>
                            {/* <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Date de naissance">
                                <IconButton onClick={handleBirthAt} color="primary">
                                    <EventOutlinedIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                            </Tooltip>
                            <Flexbox sx={{
                                // borderBottom: birthAt === "" ? `1px solid ${theme.palette.text.secondary}` : "none",
                                // width: "100%"
                                borderBottom: (birthAt === undefined || birthAt === null) ? `1px solid ${theme.palette.text.secondary}` : "none",
                            }}>
                                {
                                    // birthAt !== ""
                                    (birthAt !== undefined && birthAt !== null)
                                        ?
                                        <Typography variant="body2" sx={{ color: theme.palette.text.primary }}  >
                                            {/* {dayjs(props.contact.birthAt).locale('fr').format(EnumDayjsFormat.date)}
                                            {dayjs(birthAt).locale('fr').format(EnumDayjsFormat.date)}
                                        </Typography>
                                        :
                                        <Typography variant="body2"  >
                                            &nbsp;
                                        </Typography>
                                }
                            </Flexbox> */}
                            <DateSelector
                                date={birthAt}
                                title="Date de naissance"
                                disableFuture={true}
                                handleDate={confirmModalDatePickerBirthAt}
                                resetDate={resetModalDatePickerBirthAt}
                                showArrows={false}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Adresse"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setAdresse(event.target.value) }}
                            value={adresse}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            {/* <ModalDatePicker
                title="Date de naissance"
                disableFuture={true}
                open={showModalDatePickerBirthAt}
                handleClose={closeModalDatePickerBirthAt}
                handleConfirm={confirmModalDatePickerBirthAt}
                handleReset={resetModalDatePickerBirthAt}
                defaultDate={birthAt}
            /> */}
        </div >
    )
}
