import { useMutation } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Radio, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumBankAccountType, EnumCbColor, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_BANK_ACCOUNT } from '../../../graphql/Mutations';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { BankAccountIcon } from '../../svg_icons/BankAccountIcon';
import { Flexbox } from '../../utils/Flexbox';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalNewBankAccount: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [description, setDescription] = useState("")
    const titleRef = useRef<HTMLInputElement>(null);
    const [cbColor, setCbColor] = useState(EnumCbColor.cbBlue)
    const [bankAccountType, setBankAccountType] = useState(EnumBankAccountType.standard)
    const [taxRate, setTaxRate] = useState<string>("0")
    const [bankName, setBankName] = useState("")
    const [createBankAccount, { data: createBankAccountData, loading: createBankAccountLoading, error: createBankAccountError }] = useMutation(GQL_MUTATION_CREATE_BANK_ACCOUNT)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createBankAccountError) {
            addMessage({
                location: "ModalNewBankAccount",
                type: EnumMessageType.Error,
                message: createBankAccountError.message,
            })
        } else if (createBankAccountData) {
            if (createBankAccountData.createBankAccount.statusCode === 200) {
                // const createdBankAccount: IBankAccount = createBankAccountData.createBankAccount.bankAccount
                // dispatch(addBankAccountAction(createdBankAccount))
                setAllRefetchNeeded("ModalNewBankAccount")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Compte ajouté.",
                })
                handleClose()
            } else {
                createBankAccountData.createBankAccount.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createBankAccountData, createBankAccountError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setCbColor(EnumCbColor.cbBlue)
        setDescription("")
        setBankName("")
        setBankAccountType(EnumBankAccountType.standard)
        setTaxRate("0")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createBankAccount({
                variables: {
                    title,
                    cbColor,
                    description,
                    bankName,
                    bankAccountType,
                    taxRate: parseFloat(taxRate),
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalNewBankAccount">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <BankAccountIcon color={cbColor} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouveau compte
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Nom du compte"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <Flexbox sx={{
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                            gap: 1,
                        }}>
                            {Object.values(EnumCbColor).map((color) => (
                                <Flexbox key={color}
                                >
                                    <Radio
                                        checked={cbColor === color}
                                        onChange={() => { setCbColor(color) }}
                                        value={color}
                                    />
                                    <BankAccountIcon color={`${color}`} sx={{ fontSize: 24, mr: "12px" }} />
                                </Flexbox>
                            ))}
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                        <TextField
                            margin="dense"
                            label="Banque"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setBankName(event.target.value) }}
                            value={bankName}
                        />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Certaines spécificités sur les opérations.">
                            <FormControlLabel sx={{
                                mt: 2,
                            }}
                                control={
                                    <Checkbox
                                        checked={bankAccountType === EnumBankAccountType.business}
                                        value={bankAccountType === EnumBankAccountType.business}
                                        onChange={(event) => setBankAccountType(event.target.checked ? EnumBankAccountType.business : EnumBankAccountType.standard)}
                                    />
                                } label="Compte professionel" />
                        </Tooltip>
                        {
                            bankAccountType === EnumBankAccountType.business && <>
                                <FormControl variant="outlined" sx={{
                                    mt: 1,
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-tax-rate">Taux d'imposition</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-tax-rate"
                                        type='number'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        }
                                        label="Taux d'imposition"
                                        color="primary"
                                        onChange={(event) => { setTaxRate(event.currentTarget.value) }}
                                        value={taxRate}
                                        autoComplete='off'
                                        sx={{
                                            width: "200px",
                                        }} />
                                    <FormHelperText>
                                        Appliqué au montant brut pour le pré-calcul de l'imposition.
                                    </FormHelperText>
                                </FormControl>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
