import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { addMessage } from '../messages/Message';
import { EnumDatePeriodMode, EnumMessageType } from '../../enums';
import { Flexbox } from './Flexbox';
import { DateSelector } from './DateSelector';

interface IProps {
    mode: EnumDatePeriodMode,
    dateFrom: Dayjs,
    dateTo: Dayjs,
    open: boolean,
    handleClose: () => void,
    handleConfirm: (pDateFrom: Dayjs, pDateTo: Dayjs) => void,
}

export const ModalSelectDatePeriod: FC<IProps> = (props) => {
    const [newDateFrom, setNewDateFrom] = useState<Dayjs>(props.dateFrom)
    const [newDateTo, setNewDateTo] = useState<Dayjs>(props.dateTo)

    const theme = useTheme()

    const cleanDatas = () => {
        setNewDateFrom(props.dateFrom)
        setNewDateTo(props.dateTo)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const checkErrors = (pDateFrom: Dayjs, pDateTo: Dayjs) => {
        let res = true
        if (pDateFrom.isAfter(pDateTo)) {
            addMessage({
                type: EnumMessageType.Error,
                message: "La date de début ne peut pas être postérieure à la date de fin.",
            });
            res = false;
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors(newDateFrom, newDateTo)) {
            props.handleConfirm(newDateFrom, newDateTo)
        }
    }

    const handleNewDateFrom = (pDate: Dayjs) => {
        setNewDateFrom(pDate)
    }

    const handleNewDateTo = (pDate: Dayjs) => {
        setNewDateTo(pDate)
    }

    const quickSelection = (pDateFrom: Dayjs, pDateTo: Dayjs) => {
        // Attention, il n'a pas le temps de changer les dates avant de les envoyer
        // setNewDateFrom(pDateFrom)
        // setNewDateTo(pDateTo)
        // if (checkErrors()) {
        //     props.handleConfirm(newDateFrom, newDateTo)
        // }
        if (checkErrors(pDateFrom, pDateTo)) {
            props.handleConfirm(pDateFrom, pDateTo)
        }
    }


    return (
        <div className="ModalSelectDatePeriod">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <EventOutlinedIcon sx={{
                                fontSize: 24,
                                mr: "12px",
                            }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Période
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Du
                            </Typography>
                            <DateSelector
                                date={newDateFrom}
                                title="Date de début"
                                disableFuture={false}
                                handleDate={handleNewDateFrom}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Au
                            </Typography>
                            <DateSelector
                                date={newDateTo}
                                title="Date de fin"
                                disableFuture={false}
                                handleDate={handleNewDateTo}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        <Divider flexItem sx={{
                            mb: 1,
                            mt: 2,
                        }}>
                            Sélection rapide
                        </Divider>
                        <Flexbox sx={{
                            flexWrap: "wrap",
                        }}>
                            {
                                props.mode === EnumDatePeriodMode.byMonth && <>
                                    <Button
                                        onClick={() => {
                                            quickSelection(dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month'))
                                        }}
                                    >
                                        Le mois dernier
                                    </Button>
                                </>
                            }
                            {
                                props.mode === EnumDatePeriodMode.byMonth && <>
                                    <Button
                                        onClick={() => {
                                            quickSelection(dayjs().startOf('month'), dayjs().endOf('month'))
                                        }}
                                    >
                                        Ce mois-ci
                                    </Button>
                                </>
                            }
                            {
                                props.mode === EnumDatePeriodMode.byMonth && <>
                                    <Button
                                        onClick={() => {
                                            quickSelection(dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month'))
                                        }}
                                    >
                                        Le mois prochain
                                    </Button>
                                </>
                            }
                            <Button
                                onClick={() => {
                                    quickSelection(dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year'))
                                }}
                            >
                                L'année dernière
                            </Button>
                            <Button
                                onClick={() => {
                                    quickSelection(dayjs().startOf('year'), dayjs().endOf('year'))
                                }}
                            >
                                Cette année
                            </Button>
                            <Button
                                onClick={() => {
                                    quickSelection(dayjs().add(1, 'year').startOf('year'), dayjs().add(1, 'year').endOf('year'));
                                }}
                            >
                                L'année prochaine
                            </Button>
                            <Button
                                onClick={() => {
                                    quickSelection(dayjs().subtract(5, 'year').startOf('year'), dayjs().endOf('year'))
                                }}
                            >
                                Les 5 dernières années
                            </Button>
                            <Button
                                onClick={() => {
                                    quickSelection(dayjs().startOf('year').subtract(100, 'year'), dayjs().year(2077).endOf('year'))
                                }}
                            >
                                Tout l'historique
                            </Button>
                        </Flexbox>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
