
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const VaultIcon = (props: SvgIconProps) => {
  return (
    // <SvgIcon viewBox='0 0 1706.66 1706.66' {...props}>
    //   <path d="M1535.48 1557.77l-1364.16 0c-94.44,0 -171.25,-76.81 -171.25,-171.25l0 -1206.52c0,-94.44 76.81,-171.25 171.25,-171.25l1364.02 -0c94.44,0 171.25,76.81 171.25,171.25l0 1206.52c0.14,94.44 -76.67,171.25 -171.11,171.25zm-1364.16 -1509.02c-72.36,0 -131.25,58.89 -131.25,131.25l0 1206.52c0,72.36 58.89,131.25 131.25,131.25l1364.02 0c72.36,0 131.25,-58.89 131.25,-131.25l0 -1206.52c0,-72.36 -58.89,-131.25 -131.25,-131.25l-1364.02 -0z" />
    //   <path d="M1501.59 1400.13l-1296.38 0c-11.11,0 -20,-9.03 -20,-20l0 -1193.88c0,-11.11 8.89,-20 20,-20l1296.24 0c10.97,0 20,8.89 20,20l0 170.42c0,26.94 -36.82,20 -68.47,20 -19.31,0 -35,15.69 -35,35l0 63.33c0,43.26 50.93,35 83.47,35 10.97,0 20,8.89 20,20l0 512.22c0,26.94 -37.05,20 -68.47,20 -19.31,0 -35,15.69 -35,35l0 63.33c0,43.26 50.93,35 83.47,35 10.97,0 20,9.03 20,20l0 164.58c0.14,11.11 -8.89,20 -19.86,20zm-1276.38 -40l1256.24 0 0 -124.44 -28.47 0c-41.39,0 -75,-33.61 -75,-75l0 -63.33c0,-41.39 33.61,-75 75,-75l28.47 0 0 -472.22 -28.47 0c-41.39,0 -75,-33.61 -75,-75l0 -63.47c0,-41.39 33.61,-75 75,-75l28.47 0 0 -130.42 -1256.24 0 0 1153.88z" />
    //   <path d="M1549.78 550l-96.81 0c-41.39,0 -75,-33.61 -75,-75l0 -63.33c0,-41.39 33.61,-75 75,-75l96.81 0c41.39,0 75,33.61 75,75l0 63.33c0,41.39 -33.61,75 -75,75zm-96.81 -173.33c-19.31,0 -35,15.69 -35,35 0,112.34 -9.67,98.33 131.81,98.33 19.31,0 35,-15.69 35,-35 0,-112.33 9.68,-98.33 -131.81,-98.33z" />
    //   <path d="M1549.78 1235.69l-96.81 0c-41.39,0 -75,-33.61 -75,-75l0 -63.33c0,-41.39 33.61,-75 75,-75l96.81 0c41.39,0 75,33.61 75,75l0 63.33c0,41.25 -33.61,75 -75,75zm-96.81 -173.47c-19.31,0 -35,15.69 -35,35 0,112.34 -9.67,98.33 131.81,98.33 19.31,0 35,-15.69 35,-35 0,-112.33 9.68,-98.33 -131.81,-98.33z" />
    //   <path d="M853.39 962.77c-100,0 -181.24,-81.39 -181.24,-181.24 0,-100 81.39,-181.25 181.24,-181.25 100,0 181.25,81.25 181.25,181.25 0,99.99 -81.25,181.24 -181.25,181.24zm0 -322.5c-77.91,0 -141.24,63.33 -141.24,141.25 0,77.91 63.33,141.25 141.24,141.25 77.92,0 141.25,-63.33 141.25,-141.24 0,-77.92 -63.33,-141.25 -141.25,-141.25z" />
    //   <path d="M853.39 1048.6c-11.11,0 -20,-9.03 -20,-20l0 -85.83c0,-26.33 40,-26.33 40,0l0 85.83c0,11.11 -8.89,20 -20,20zm0 -408.33c-11.11,0 -20,-8.89 -20,-20l0 -85.83c0,-26.36 40,-26.36 40,0l0 85.83c0,10.97 -8.89,20 -20,20z" />
    //   <path d="M1100.48 801.52l-85.83 0c-26.33,0 -26.33,-40 0,-40l85.83 0c26.33,0 26.33,40 0,40zm-408.33 0l-85.83 0c-26.36,0 -26.36,-40 0,-40l85.83 0c26.43,0 26.26,40 0,40z" />
    //   <path d="M853.39 878.74c-53.61,0 -97.22,-43.61 -97.22,-97.22 0,-53.61 43.61,-97.22 97.22,-97.22 53.61,0 97.22,43.61 97.22,97.22 0,53.61 -43.61,97.22 -97.22,97.22zm0 -154.44c-75.3,0 -75.3,114.44 0,114.44 75.3,0 75.3,-114.44 0,-114.44z" />
    //   <path d="M458.54 1022.07l-127.08 0c-11.11,0 -20,-9.03 -20,-20l0 -437.63c0,-11.11 8.89,-20 20,-20l127.08 0c26.36,0 26.36,40 0,40l-107.08 0 0 397.63 107.08 0c26.33,0 26.33,40 0,40z" />
    //   <path d="M1496.17 1697.91l-229.3 0c-7.92,0 -15.14,-4.72 -18.33,-11.94l-61.39 -140.14c-5.7,-13.26 3.88,-28.06 18.33,-28.06l290.69 0c10.97,0 20,9.03 20,20l0 140.14c0,11.11 -9.03,20 -20,20zm-216.25 -40l196.25 0 0 -100.14 -240 0 43.75 100.14z" />
    //   <path d="M439.93 1697.91l-229.3 0c-11.11,0 -20,-9.03 -20,-20l0 -140.14c0,-10.97 8.89,-20 20,-20l290.69 0c14.48,0 24.18,14.82 18.33,28.06l-61.39 140.14c-3.2,7.22 -10.42,11.94 -18.33,11.94zm-209.3 -40l196.25 0 43.89 -100.14 -240.14 0 0 100.14z" />

    // </SvgIcon>

    <SvgIcon viewBox='0 0 1706.66 1706.66' {...props}>
      {/* <path fill="#727D6F" d="M1527.5 1660.27l-199.86 0c-9.31,0 -17.36,-6.53 -19.44,-15.56l-50 -220.14c-2.86,-12.43 6.76,-24.44 19.44,-24.44l249.86 0c10.97,0 20,9.03 20,20l0 220.14c0.14,10.97 -8.89,20 -20,20z" />
      <path fill="#727D6F" d="M378.89 1659.99l-199.72 0c-11.11,0 -20,-9.03 -20,-20l0 -220.14c0,-10.97 8.89,-20 20,-20l249.86 0c12.76,0 22.47,11.89 19.58,24.44l-50 220.14c-2.22,9.03 -10.28,15.56 -19.72,15.56z" /> */}
      <path fill="#8D9A89" d="M1530.69 1440.13l-1354.72 0c-96.94,0 -175.97,-78.89 -175.97,-175.97l0 -1041.8c0,-96.95 78.89,-175.98 175.98,-175.98l1354.71 0c97.08,0 175.97,78.89 175.97,175.98l0 1041.94c-0,96.94 -78.89,175.83 -175.97,175.83z" />
      <path fill="#E9E3D5" d="M1548.33 1228.32l-1390 0c-11.11,0 -20,-9.02 -20,-20l0 -929.99c0,-11.11 8.89,-20 20,-20l1390.13 0.01c10.97,0 20,8.89 20,20l0 930c-0.14,10.96 -9.03,19.99 -20.14,19.99z" />
      <polygon fill="#BDB8AD" points="797.5,1228.32 757.5,1228.32 757.5,258.33 797.5,258.33 " />
      <path fill="#E9E3D5" d="M1411.39 626.25l-501.8 0c-10.97,0 -20,-8.89 -20,-20l0.01 -167.64c0,-11.11 9.02,-20 20,-20l501.66 0c10.97,0 20,8.89 20,20l0 167.64c0.13,10.98 -8.89,20 -19.86,20z" />
      <path fill="#727D6F" d="M1030.83 844.02l-121.11 0c-10.97,0 -20,-8.89 -20,-20l0 -128.47c0,-11.11 9.03,-20 20,-20l121.11 0c10.97,0 20,8.89 20,20l0 128.47c0,11.11 -9.02,20 -20,20z" />
      <path fill="#727D6F" d="M1218.61 844.02l-121.11 0c-10.97,0 -20,-8.89 -20,-20l0 -128.47c0,-11.11 9.03,-20 20,-20l121.11 0c10.97,0 20,8.89 20,20l0 128.47c0,11.11 -9.03,20 -20,20z" />
      <path fill="#727D6F" d="M1406.39 844.02l-121.11 0c-10.97,0 -20,-8.89 -20,-20l0 -128.47c0,-11.11 9.03,-20 20,-20l121.11 0c10.97,0 20,8.89 20,20l0 128.47c0,11.11 -8.89,20 -20,20z" />
      <path fill="#727D6F" d="M1030.83 1069.85l-121.11 0c-10.97,0 -20,-9.03 -20,-20l0 -128.47c0,-10.97 9.03,-20 20,-20l121.11 0c10.97,0 20,9.02 20,20l0 128.47c0,10.97 -9.02,20 -20,20z" />
      <path fill="#727D6F" d="M1218.61 1069.85l-121.11 0c-10.97,0 -20,-9.03 -20,-20l0 -128.47c0,-10.97 9.03,-20 20,-20l121.11 0c10.97,0 20,9.02 20,20l0 128.47c0,10.97 -9.03,20 -20,20z" />
      <path fill="#727D6F" d="M1406.39 1069.85l-121.11 0c-10.97,0 -20,-9.03 -20,-20l0 -128.47c0,-10.97 9.03,-20 20,-20l121.11 0c10.97,0 20,9.02 20,20l0 128.47c0,10.97 -8.89,20 -20,20z" />
      <path fill="#727D6F" d="M454.58 926.66c-101.11,0 -183.47,-82.22 -183.47,-183.47 0,-101.25 82.22,-183.47 183.47,-183.47 101.25,0 183.47,82.36 183.47,183.61 0,101.25 -82.36,183.33 -183.47,183.33zm0 -326.81c-79.03,0 -143.47,64.31 -143.47,143.48 0,79.17 64.44,143.33 143.47,143.33 79.03,0 143.48,-64.3 143.48,-143.47 0,-79.17 -64.45,-143.33 -143.48,-143.33z" />
      <path fill="#727D6F" d="M454.58 599.85c-11.11,0 -20,-8.89 -20,-20l0 -82.5c0,-26.37 40,-26.37 40,0l0 82.5c0,11.11 -9.03,20 -20,20z" />
      <path fill="#727D6F" d="M536.26 621.8c-15.54,0 -24.9,-16.79 -17.37,-30l41.25 -71.53c13.17,-22.78 47.82,-2.94 34.72,20l-41.25 71.53c-3.75,6.39 -10.42,10 -17.36,10z" />
      <path fill="#727D6F" d="M596.11 681.52c-20.26,0 -27.81,-27.06 -10,-37.36l71.53 -41.25c22.77,-13.17 42.91,21.48 20,34.72 -13.08,7.56 -70.74,43.89 -81.53,43.89z" />
      <path fill="#727D6F" d="M700.56 763.33l-82.5 0c-26.36,0 -26.37,-40 0,-40l82.5 0c26.43,0 26.27,40 0,40z" />
      <path fill="#727D6F" d="M667.5 886.24c-10.76,0 -68.48,-36.35 -81.53,-43.89 -22.8,-13.19 -2.86,-47.94 20,-34.72l71.53 41.25c17.5,10.12 10.56,37.36 -9.99,37.36z" />
      <path fill="#727D6F" d="M577.64 976.24c-6.94,0 -13.61,-3.61 -17.36,-10l-41.25 -71.53c-13.17,-22.77 21.48,-42.91 34.72,-20l41.25 71.53c7.72,13.34 -1.98,30 -17.36,30z" />
      <path fill="#727D6F" d="M454.58 1009.3c-11.11,0 -20,-9.03 -20,-20l0 -82.5c0,-26.33 40,-26.34 40,0l0 82.5c0,10.97 -9.03,20 -20,20z" />
      <path fill="#727D6F" d="M331.53 976.24c-15.54,0 -24.9,-16.79 -17.37,-30l41.26 -71.53c13.24,-22.9 47.76,-2.84 34.72,20l-41.25 71.53c-3.61,6.39 -10.41,10 -17.36,10z" />
      <path fill="#727D6F" d="M241.53 886.24c-20.26,0 -27.81,-27.07 -10,-37.36l71.53 -41.25c22.77,-13.17 42.91,21.48 20,34.72 -13.12,7.58 -70.69,43.89 -81.53,43.89z" />
      <path fill="#727D6F" d="M291.11 763.33l-82.5 0c-26.36,0 -26.37,-40 0,-40l82.5 0c26.36,0 26.36,40 0,40z" />
      <path fill="#727D6F" d="M313.06 681.52c-10.76,0 -68.48,-36.34 -81.53,-43.89 -22.78,-13.17 -2.94,-47.82 20,-34.72l71.53 41.25c17.48,10.11 10.58,37.36 -10,37.36z" />
      <path fill="#727D6F" d="M372.92 621.8c-6.94,0 -13.61,-3.61 -17.36,-10l-41.25 -71.53c-13.17,-22.77 21.48,-42.91 34.72,-20l41.25 71.53c7.84,13.56 -2.29,30 -17.37,30z" />
      <path fill="#727D6F" d="M454.58 832.22c-117,0 -117,-177.78 0,-177.78 117,0 117,177.78 0,177.78z" />
      <polygon fill="#FF0000" points="1300,400 1400,400 1400,500 1300,500 " />
    </SvgIcon>
  )
}

