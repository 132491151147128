import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC } from 'react';
import { UserInfos } from '../../components/profile/UserInfos';
import { Layout } from "../Layout";
import { Partners } from '../../components/profile/Partners';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import { UserPackInfos } from '../../components/profile/UserPackInfos';
import { EnumUserInfosMode } from '../../enums';
import { useParams } from 'react-router-dom';
import { useGetUser } from '../../redux/features/admin/userSlice';

interface IProps {
}

export const AdminUserPage: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()
  const params = useParams()
  const userId = params.userId || ""
  const user = useGetUser(userId)

  return (
    <div className="AdminUserPage">
      <Layout>
        <div className="page-body">
          <Box sx={{ mt: 4, px: { xs: 0, md: 8 } }}>
            {
              currentUser && !currentUser.admin && <>
                <Box sx={{ mt: 4, px: { xs: 0, md: 8 } }}>
                  <Typography color="error" sx={{ fontSize: 24 }}>
                    Section réservée aux administrateurs
                  </Typography>
                </Box>
              </>
            }
            {
              currentUser && currentUser.admin && user && <>
                <Grid container spacing={0}>
                  <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", px: 2, py: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
                      <UserInfos user={user} mode={EnumUserInfosMode.standard} />
                      <UserPackInfos user={user} />
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", px: 2, py: 2 }}>
                    <Partners user={user} />
                  </Grid>
                </Grid>
              </>
            }
          </Box>
        </div>
      </Layout>
    </div >
  )
}
