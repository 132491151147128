import { createSlice } from "@reduxjs/toolkit";


interface IFilterSliceState {
    filterQuery: undefined | string,
}

const initialState: IFilterSliceState = {
    filterQuery: undefined,
}

export const filterSlice = createSlice({
    name: "filterSlice",
    initialState,
    reducers: {
        setFilterQueryAction: (state, action) => {
            state.filterQuery = action.payload
        },
    }
})

export const { setFilterQueryAction,
} = filterSlice.actions

export default filterSlice.reducer