
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const ContactIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      {/* <path d="M21,7H19V9h2Zm0,6H19v2h2ZM11,8a2,2,0,1,0,2,2A2,2,0,0,0,11,8Zm3,7a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3v1h6Z" fill='none' stroke='rgb(44, 169, 188)' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}> */}
      <path d="M21,7H19V9h2Zm0,6H19v2h2ZM11,8a2,2,0,1,0,2,2A2,2,0,0,0,11,8Zm3,7a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3v1h6Z" fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}>
      </path>
      <rect x="3" y="3" width="16" height="18" rx="1" fill='none' stroke='rgb(0, 0, 0)' strokeLinecap='round' strokeLinejoin='round' strokeWidth={2}></rect>
    </SvgIcon>

  )
}

