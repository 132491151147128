import { IconButton, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Flexbox } from './Flexbox';

interface IProps {
    defaultFilterValue: string;
    setFilter: (filter: string) => void;
}

export const BasicFilter: FC<IProps> = (props) => {
    // const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [filterInput, setFilterInput] = useState(props.defaultFilterValue) // Pour temporiser la saisie..

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            props.setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);

    return (
        <Flexbox>
            <FormControl sx={{
                pb: 2,
                my: 0,
                mr: 1,
                width: "200px",
            }} variant="standard">
                <InputLabel htmlFor="standard-adornment">Filtrer...</InputLabel>
                <Input
                    type='text'
                    onChange={(event) => { handleFilterInput(event.target.value) }}
                    value={filterInput}
                    endAdornment={
                        filterInput.length > 0 && <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleFilterInput("") }}
                            >
                                <ClearIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Flexbox>
    )
}
