
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { EnumSvgVariant } from '../../enums';

interface IPocketIconProps extends SvgIconProps {
  variant?: EnumSvgVariant;
}


export const PocketIcon = (props: IPocketIconProps) => {
  const { variant = EnumSvgVariant.primary, ...otherProps } = props;
  return (
    // <SvgIcon viewBox="0 0 20 20" {...otherProps}>
    <SvgIcon viewBox="0 0 64 64" {...otherProps}>
      {/* {
        variant == EnumSvgVariant.default &&
        <g transform="translate(-450 -2000)">
          <g>
            <rect x="452" y="2001" width="16" height="3" />
            <polygon points="468,2014 460,2019 452,2014 452,2005 468,2005" />
          </g>
        </g>
      } */}

      {/* 
      {
        variant == EnumSvgVariant.primary &&
        <g>
          <rect x="2" y="1" width="16" height="2" />
          <polygon points="17,14 10,19 3,14 3,5 17,5" fill='none' stroke="currentColor" strokeWidth="2" />
        </g>
      }
      {
        variant == EnumSvgVariant.secondary &&
        <g>
          <rect x="2" y="1" width="16" height="2" />
          <polygon points="17,14 10,19 3,14 3,5 17,5" stroke="currentColor" strokeWidth="2" />
        </g>
      } */}


      {/* <polygon fill="#693B00" points="6 33 5 56 59 56 58 33 6 33" />
      <polygon fill="#884D00" points="10 33 9 52 27 52 26 33 10 33" />
      <polygon fill="#884D00" points="38 33 37 52 55 52 54 33 38 33" />
      <path fill="#ca7300" d="M13,33H8a4,4,0,0,1-4-4L3,16H61L60,29c0,2.21-.79,4-3,4H51" />
      <path fill="#693B00" d="M40,16H38V10a1,1,0,0,0-1-1H27a1,1,0,0,0-1,1v6H24V10a3,3,0,0,1,3-3H37a3,3,0,0,1,3,3Z" />
      <polygon fill="#693B00" points="44 32 44 46 46 48 48 46 48 32 44 32" />
      <polyline fill="#693B00" points="44 33 44 28 48 28 48 33" />
      <path fill="#D4C8B8" d="M50,32H42a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V33A1,1,0,0,0,50,32Zm-1,8H43V34h2v4h2V34h2Z" />
      <polygon fill="#693B00" points="16 32 16 46 18 48 20 46 20 32 16 32" />
      <polyline fill="#693B00" points="16 33 16 28 20 28 20 33" />
      <path fill="#D4C8B8" d="M22,32H14a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V33A1,1,0,0,0,22,32Zm-1,8H15V34h2v4h2V34h2Z" />
      <rect fill="#EBE5DD" x="16" y="24" width="4" height="4" />
      <rect fill="#EBE5DD" x="44" y="24" width="4" height="4" />
      <rect fill="#693B00" x="23" y="28" width="2" height="2" />
      <rect fill="#693B00" x="27" y="28" width="2" height="2" />
      <rect fill="#693B00" x="31" y="28" width="2" height="2" />
      <rect fill="#693B00" x="35" y="28" width="2" height="2" />
      <rect fill="#693B00" x="39" y="28" width="2" height="2" />
      <rect fill="#693B00" x="7" y="19" width="2" height="2" />
      <rect fill="#693B00" x="7" y="23" width="2" height="2" />
      <rect fill="#693B00" x="7" y="27" width="2" height="2" />
      <rect fill="#693B00" x="55" y="19" width="2" height="2" />
      <rect fill="#693B00" x="55" y="23" width="2" height="2" />
      <rect fill="#693B00" x="55" y="27" width="2" height="2" />
      <rect fill="#693B00" x="11" y="28" width="2" height="2" />
      <rect fill="#693B00" x="51" y="28" width="2" height="2" />
      <rect fill="#FFF" x="10" y="19" width="2" height="2" />
      <path fill="#FFF" d="M14,28H11a1,1,0,0,1-1-1V24h2v2h2Z" />
      <path fill="#FFF" d="M15,50H12a1,1,0,0,1-1-1V42h2v6h2Z" />
      <rect fill="#FFF" x="11" y="38" width="2" height="2" />
      <path fill="#FFF" d="M44,50H40a1,1,0,0,1-1-1V43h2v5h3Z" />
      <rect fill="#FFF" x="39" y="39" width="2" height="2" />
      <path d="M61,29.08l1-13A1,1,0,0,0,61,15H40V10a3,3,0,0,0-3-3H27a3,3,0,0,0-3,3v5H3a1,1,0,0,0-1,1.08L3,29a5,5,0,0,0,2,4L4,56a1,1,0,0,0,.28.73A1,1,0,0,0,5,57H59a1,1,0,0,0,.72-.31A1,1,0,0,0,60,56l-1-22.5C60.28,32.7,61,31.18,61,29.08ZM26,10a1,1,0,0,1,1-1H37a1,1,0,0,1,1,1v5H26ZM6,55,7,33.89A5.1,5.1,0,0,0,8,34H9L8,52a1.06,1.06,0,0,0,.27.74A1,1,0,0,0,9,53H27a1,1,0,0,0,.73-.31A1.06,1.06,0,0,0,28,52l-.95-18H37L36,52a1.06,1.06,0,0,0,.27.74A1,1,0,0,0,37,53H55a1,1,0,0,0,.73-.31A1.06,1.06,0,0,0,56,52l-1-18h2L58,55Zm8-13h1v4a1,1,0,0,0,.29.71l2,2a1,1,0,0,0,1.42,0l2-2A1,1,0,0,0,21,46V42h1a1,1,0,0,0,1-1V34h2.05L26,51H10.05L11,34H13v7A1,1,0,0,0,14,42Zm5-4V34h2v6H15V34h2v4Zm0-9v3H17V29Zm-2-2V25h2v2Zm2,15v3.59l-1,1-1-1V42Zm23,0h1v4a1,1,0,0,0,.29.71l2,2a1,1,0,0,0,1.42,0l2-2A1,1,0,0,0,49,46V42h1a1,1,0,0,0,1-1V34h2L54,51H38.05L39,34h2v7A1,1,0,0,0,42,42Zm5-4V34h2v6H43V34h2v4Zm0-9v3H45V29Zm-2-2V25h2v2Zm2,15v3.59l-1,1-1-1V42ZM59,29c0,3-1.5,3-2,3H49V24a1,1,0,0,0-1-1H44a1,1,0,0,0-1,1v8H21V24a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v8H8a3,3,0,0,1-3-3.08L4.08,17H59.92Z" />
      <rect x="23" y="28" width="2" height="2" />
      <rect x="27" y="28" width="2" height="2" />
      <rect x="31" y="28" width="2" height="2" />
      <rect x="35" y="28" width="2" height="2" />
      <rect x="39" y="28" width="2" height="2" />
      <rect x="7" y="19" width="2" height="2" />
      <rect x="7" y="23" width="2" height="2" />
      <rect x="7" y="27" width="2" height="2" />
      <rect x="55" y="19" width="2" height="2" />
      <rect x="55" y="23" width="2" height="2" />
      <rect x="55" y="27" width="2" height="2" />
      <rect x="11" y="28" width="2" height="2" />
      <rect x="51" y="28" width="2" height="2" /> */}

      {
        variant == EnumSvgVariant.primary &&
        <path d="M61.25 18C61.2474 16.8736 60.7987 15.7941 60.0023 14.9977C59.2058 14.2012 58.1264 13.7526 57 13.75H42.17C41.8586 11.2723 40.6535 8.99359 38.7809 7.34162C36.9083 5.68965 34.4971 4.77808 32 4.77808C29.5029 4.77808 27.0917 5.68965 25.2191 7.34162C23.3465 8.99359 22.1414 11.2723 21.83 13.75H7C5.87364 13.7526 4.79417 14.2012 3.99771 14.9977C3.20126 15.7941 2.75264 16.8736 2.75 18V20.29C2.75337 22.9023 3.44306 25.468 4.75 27.73V55C4.75264 56.1263 5.20126 57.2058 5.99771 58.0022C6.79417 58.7987 7.87364 59.2473 9 59.25H55C56.1264 59.2473 57.2058 58.7987 58.0023 58.0022C58.7987 57.2058 59.2474 56.1263 59.25 55V27.73C60.5569 25.468 61.2466 22.9023 61.25 20.29V18ZM32 7.24995C33.8373 7.25197 35.6141 7.90666 37.0134 9.09721C38.4128 10.2878 39.3437 11.9367 39.64 13.75H24.36C24.6563 11.9367 25.5872 10.2878 26.9866 9.09721C28.3859 7.90666 30.1627 7.25197 32 7.24995V7.24995ZM5.25 18C5.25263 17.5366 5.43784 17.093 5.76546 16.7654C6.09309 16.4378 6.53668 16.2526 7 16.25H57C57.4633 16.2526 57.9069 16.4378 58.2345 16.7654C58.5622 17.093 58.7474 17.5366 58.75 18V20.29C58.7474 23.5937 57.4338 26.7614 55.0976 29.0976C52.7615 31.4337 49.5938 32.7473 46.29 32.75H17.71C14.4062 32.7473 11.2385 31.4337 8.90237 29.0976C6.56624 26.7614 5.25265 23.5937 5.25 20.29V18ZM29.25 35.25H34.75V40C34.75 40.7293 34.4603 41.4288 33.9445 41.9445C33.4288 42.4602 32.7293 42.75 32 42.75C31.2707 42.75 30.5712 42.4602 30.0555 41.9445C29.5397 41.4288 29.25 40.7293 29.25 40V35.25ZM55 56.75H9C8.53668 56.7473 8.09309 56.5621 7.76546 56.2345C7.43784 55.9069 7.25263 55.4633 7.25 55V31C10.0371 33.7495 13.7949 35.2907 17.71 35.29H26.71V40C26.71 41.3923 27.2631 42.7277 28.2477 43.7123C29.2323 44.6968 30.5676 45.25 31.96 45.25C33.3524 45.25 34.6877 44.6968 35.6723 43.7123C36.6569 42.7277 37.21 41.3923 37.21 40V35.25H46.21C50.147 35.2825 53.9367 33.7544 56.75 31V55C56.7474 55.4633 56.5622 55.9069 56.2345 56.2345C55.9069 56.5621 55.4633 56.7473 55 56.75Z" fill="currentColor" />
      }
      {
        variant == EnumSvgVariant.secondary &&
        <path d="M61.25 18C61.2474 16.8736 60.7987 15.7941 60.0023 14.9977C59.2058 14.2012 58.1264 13.7526 57 13.75H42.17C41.8586 11.2723 40.6535 8.99359 38.7809 7.34162C36.9083 5.68965 34.4971 4.77808 32 4.77808C29.5029 4.77808 27.0917 5.68965 25.2191 7.34162C23.3465 8.99359 22.1414 11.2723 21.83 13.75H7C5.87364 13.7526 4.79417 14.2012 3.99771 14.9977C3.20126 15.7941 2.75264 16.8736 2.75 18V20.29C2.75337 22.9023 3.44306 25.468 4.75 27.73V55C4.75264 56.1263 5.20126 57.2058 5.99771 58.0022C6.79417 58.7987 7.87364 59.2473 9 59.25H55C56.1264 59.2473 57.2058 58.7987 58.0023 58.0022C58.7987 57.2058 59.2474 56.1263 59.25 55V27.73C60.5569 25.468 61.2466 22.9023 61.25 20.29V18ZM32 7.24995C33.8373 7.25197 35.6141 7.90666 37.0134 9.09721C38.4128 10.2878 39.3437 11.9367 39.64 13.75H24.36C24.6563 11.9367 25.5872 10.2878 26.9866 9.09721C28.3859 7.90666 30.1627 7.25197 32 7.24995V7.24995ZM5.25 18C5.25263 17.5366 5.43784 17.093 5.76546 16.7654C6.09309 16.4378 6.53668 16.2526 7 16.25H57C57.4633 16.2526 57.9069 16.4378 58.2345 16.7654C58.5622 17.093 58.7474 17.5366 58.75 18V20.29C58.7474 23.5937 57.4338 26.7614 55.0976 29.0976C52.7615 31.4337 49.5938 32.7473 46.29 32.75H17.71C14.4062 32.7473 11.2385 31.4337 8.90237 29.0976C6.56624 26.7614 5.25265 23.5937 5.25 20.29V18ZM29.25 35.25H34.75V40C34.75 40.7293 34.4603 41.4288 33.9445 41.9445C33.4288 42.4602 32.7293 42.75 32 42.75C31.2707 42.75 30.5712 42.4602 30.0555 41.9445C29.5397 41.4288 29.25 40.7293 29.25 40V35.25ZM55 56.75H9C8.53668 56.7473 8.09309 56.5621 7.76546 56.2345C7.43784 55.9069 7.25263 55.4633 7.25 55V31C10.0371 33.7495 13.7949 35.2907 17.71 35.29H26.71V40C26.71 41.3923 27.2631 42.7277 28.2477 43.7123C29.2323 44.6968 30.5676 45.25 31.96 45.25C33.3524 45.25 34.6877 44.6968 35.6723 43.7123C36.6569 42.7277 37.21 41.3923 37.21 40V35.25H46.21C50.147 35.2825 53.9367 33.7544 56.75 31V55C56.7474 55.4633 56.5622 55.9069 56.2345 56.2345C55.9069 56.5621 55.4633 56.7473 55 56.75Z"
          fill="currentColor"
          stroke='currentColor'
          strokeWidth="6"
        />
      }
    </SvgIcon>
  )
}

