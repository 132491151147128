import {
    Button,
    FormControl, IconButton, Input, InputAdornment, InputLabel, Tooltip, useTheme
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { EnumCustomContactMode, EnumCustomContactSelectionMode, EnumMessageType, EnumSize } from '../../enums';
import { ICustomContact } from '../../interfaces';
import { filterMatch } from '../../utils/Utils';
import { Flexbox } from '../utils/Flexbox';
import { Spinner } from '../spinner/Spinner';
import { addCustomContactAction, useGetCustomContacts } from '../../redux/features/customContacts/customContactsSlice';
import { CustomContactBadge } from './CustomContactBadge';
import { Add } from '@mui/icons-material';
import { title } from 'process';
import { useMutation } from '@apollo/client';
import { GQL_MUTATION_CREATE_CUSTOM_CONTACT } from '../../graphql/Mutations';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';

interface IProps {
    handleSelect: (selectedCustomContact: ICustomContact) => void;
    heightSize?: EnumSize,
}

export const CustomContactSelection: FC<IProps> = (props) => {
    const customContacts = useGetCustomContacts()
    const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [filter, setFilter] = useState("")
    const [filteredCustomContacts, setFilteredCustomContacts] = useState<ICustomContact[] | undefined>([])
    const [customContactSelectionMode, setCustomContactSelectionMode] = useState<EnumCustomContactSelectionMode>(EnumCustomContactSelectionMode.standard)

    const [createCustomContact, { data: createCustomContactData, loading: createCustomContactLoading, error: createCustomContactError }] = useMutation(GQL_MUTATION_CREATE_CUSTOM_CONTACT)

    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        if (customContacts) {
            // Attention, on doit passer par une variable temporaire, sinon les filtered restent vides
            let tempFilteredCustomContacts = [...customContacts]

            if (filter.length !== 0) {
                tempFilteredCustomContacts = tempFilteredCustomContacts.filter(tempFilteredContact => (
                    filterMatch(tempFilteredContact.title, filter)
                ))
            }
            setFilteredCustomContacts([...tempFilteredCustomContacts])
        }
    }, [customContacts, filter])

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);

    useEffect(() => {
        if (createCustomContactError) {
            addMessage({
                location: "CustomContactSelection",
                type: EnumMessageType.Error,
                message: createCustomContactError.message,
            })
        } else if (createCustomContactData) {
            if (createCustomContactData.createCustomContact.statusCode === 200) {
                // setAllRefetchNeeded("CustomContactSelection")
                // Cas spécial, on ne rajoute que le nouveau customContact
                dispatch(addCustomContactAction(createCustomContactData.createCustomContact.customContact))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Payeur / Bénéficiaire ajouté.",
                })
            } else {
                createCustomContactData.createCustomContact.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createCustomContactData, createCustomContactError])

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    const getHeightSize = () => {
        const defaultHeight = '300px'
        if (props.heightSize) {
            switch (props.heightSize) {
                case EnumSize.tiny:
                    return '100px'
                case EnumSize.small:
                    return '200px'
                case EnumSize.medium:
                    return defaultHeight
                case EnumSize.large:
                    return '600px'
                case EnumSize.xlarge:
                    return '900px'
                default:
                    return defaultHeight
            }
        } else {
            return defaultHeight
        }
    }

    const handleNewCustomContact = () => {
        if (filter.length > 0) {
            createCustomContact({
                variables: {
                    title: filter,
                }
            })
        }
    }

    const handleSettings = () => {
        setCustomContactSelectionMode(customContactSelectionMode === EnumCustomContactSelectionMode.settings ? EnumCustomContactSelectionMode.standard : EnumCustomContactSelectionMode.settings)
    }

    return (
        <Flexbox direction='column' sx={{
            // bgcolor: "green",
            width: "100%",
        }}>
            <Flexbox sx={{
                // bgcolor: "yellow",
                width: "100%",
                justifyContent: "space-between",
            }}>
                <Flexbox sx={{
                    // width: "100%",
                    // bgcolor: "green",
                    justifyContent: "flex-start",
                    pl: 1,
                }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Gérer">
                        <IconButton onClick={handleSettings} >
                            <SettingsIcon sx={{
                                fontSize: 18,
                                color: customContactSelectionMode === EnumCustomContactSelectionMode.settings ? theme.palette.secondary.main : theme.palette.text.secondary,
                            }} />
                        </IconButton>
                    </Tooltip>
                </Flexbox>
                <FormControl sx={{
                    pb: 2,
                    my: 0,
                    mr: 1,
                    width: { xs: "120px", md: "200px" },
                    // width: "100%",
                    // bgcolor: "red",
                }} variant="standard">
                    <InputLabel >Filtrer...</InputLabel>
                    <Input
                        type='text'
                        onChange={(event) => { handleFilterInput(event.target.value) }}
                        value={filterInput}
                        endAdornment={
                            filterInput.length > 0 && <InputAdornment position="end">
                                <IconButton
                                    onClick={() => { handleFilterInput("") }}
                                >
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Flexbox>
            {
                customContacts === undefined
                    ?
                    (
                        <Spinner classes="big-spinner" />
                    )
                    :
                    (
                        <Flexbox direction='column' sx={{
                            // height: '300px', // Forcer la hauteur du contenant
                            height: getHeightSize(), // Forcer la hauteur du contenant
                            overflowY: 'auto', // Ajouter l'ascenseur vertical si le contenu dépasse
                            width: "100%",
                        }}>
                            <Grid container sx={{
                                width: "100%",
                                // bgcolor: "red",
                            }}>
                                {

                                    filteredCustomContacts?.map((filteredCustomContact, filteredCustomContactIndex) => {
                                        return (
                                            <Grid
                                                key={`filteredCustomContactRelation-${filteredCustomContact.id}`}
                                                sx={{

                                                    borderLeftStyle: {
                                                        xs: "none",
                                                        md: filteredCustomContactIndex % 2 === 0 ? "inherit" : "solid",
                                                    },
                                                    borderLeftWidth: 1,
                                                    borderLeftColor: theme.palette.cancel.main,
                                                    pl: 2,
                                                }}
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <Flexbox sx={{
                                                    justifyContent: "space-between",
                                                    py: 1,
                                                }}>
                                                    {
                                                        customContactSelectionMode === EnumCustomContactSelectionMode.settings && <>
                                                            <CustomContactBadge showContactAvatar={true} mode={EnumCustomContactMode.settings} customContact={filteredCustomContact} size={EnumSize.small} />
                                                        </>
                                                    }
                                                    {
                                                        customContactSelectionMode === EnumCustomContactSelectionMode.standard && <>
                                                            <CustomContactBadge showContactAvatar={true} mode={EnumCustomContactMode.selection} onClick={props.handleSelect} customContact={filteredCustomContact} size={EnumSize.small} />
                                                        </>
                                                    }
                                                </Flexbox>
                                            </Grid>
                                        )
                                    })
                                }

                            </Grid>
                            {
                                filter.length > 0 && filteredCustomContacts?.filter(filteredCustomContact => filteredCustomContact.title.toLowerCase() === filter.toLowerCase()).length === 0 && <>
                                    <Flexbox
                                        sx={{
                                            pl: 2,
                                        }}
                                    >
                                        <Button startIcon={<Add />} onClick={handleNewCustomContact} variant="outlined" color="secondary">Créer {filter}</Button>
                                    </Flexbox>
                                </>
                            }

                        </Flexbox>
                    )
            }
        </Flexbox>
    )
}
