import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import { EnumMessageType, EnumSize } from '../../../enums';
import { IUser } from '../../../interfaces';
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { GQL_MUTATION_CREATE_PARTNERSHIP } from '../../../graphql/Mutations';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { UserBadge } from '../../UserBadge';

interface IProps {
    open: boolean;
    handleClose: () => void;
    user: IUser;
}

export const ModalNewPartnership: FC<IProps> = (props) => {
    // const currentUser = useGetCurrentUser()
    const [createPartnership, { data: createPartnershipData, loading: createPartnershipLoading, error: createPartnershipError }] = useMutation(GQL_MUTATION_CREATE_PARTNERSHIP)

    useEffect(() => {
        if (createPartnershipError) {
            addMessage({
                location: "ModalNewPartnership",
                type: EnumMessageType.Error,
                message: createPartnershipError.message,
            })
        } else if (createPartnershipData) {
            if (createPartnershipData.createPartnership.statusCode === 200) {
                // dispatch(setCurrentUserRefetchNeeded(true))
                setAllRefetchNeeded("ModalNewPartnership")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Demande envoyée.",
                })
                handleClose()
            } else {
                createPartnershipData.createPartnership.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createPartnershipData, createPartnershipError])

    const handleClose = () => {
        props.handleClose()
    }

    const handleCreatePartnership = (notPartner: IUser) => {
        if (props.user) {
            createPartnership({
                variables: {
                    requesterId: props.user.id,
                    responderId: notPartner.id,
                }
            })
        }
    }

    return (
        <div className="ModalNewPartnership">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <HandshakeOutlinedIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: { xs: 18, md: 24 } }}>
                            Demande de partenariat
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        {
                            props.user && props.user.notPartners?.map(notPartner => {
                                return (
                                    <Box key={notPartner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <UserBadge user={notPartner} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Button size='small' color='primary' onClick={() => handleCreatePartnership(notPartner)}>Demander</Button>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
