// Tuto : https://www.dtreelabs.com/blog/s3-direct-file-upload-using-presigned-url-from-react-and-rails

import axios from "axios";

const useFileUpload = () => {
  const handleUpload = (files, handleCallback, handleUploadDone) => {
    for (let i = 0 ; i < files.length ; i++) {
      const uploadAccessUrl =
          // "http://192.168.0.12:3000/s3/params" + `?filename=${files[i].name}&type=${files[i].type}`
          process.env.REACT_APP_S3_PARAMS + `?filename=${files[i].name}&type=${files[i].type}`
      axios.get(
        uploadAccessUrl
      ).then((uploadParams) => {
        if (uploadParams) {
          uploadToS3(uploadParams, files[i], i, handleCallback, handleUploadDone);
        }
      });
    }

  }
  return { handleUpload };
};

export default useFileUpload;

const uploadToS3 = (uploadParams, file, fileIndex, handleCallback, handleUploadDone) => {
  const { url, fields } = uploadParams.data;
  const formData = new FormData();
  // Append S3 upload params to the form object.
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append('file', file);
  // for (const pair of formData.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  axios.post(
    url,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        const percentage = (progressEvent.loaded / progressEvent.total) * 100
        handleCallback(fileIndex, percentage)
      },
    },
    
  ).then((response) => {
    // Inform the backend !
    const fileMetaData = {
      id: fields.key.match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        filename: file.name,
          size: file.size,
          mime_type: fields['Content-Type'],
      },
    }
    handleUploadDone(fileIndex, fileMetaData)
  }).catch(function (error) {
    console.log(error);
  });
};