import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IAudio } from "../../interfaces";
import {
    DeleteOutline
} from '@mui/icons-material';

import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_AUDIO, GQL_MUTATION_DELETE_VIDEO } from '../../graphql/Mutations';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { MusicIcon } from '../svg_icons/MusicIcon';

interface IProps {
    audio: IAudio,
}

export const Audio: FC<IProps> = (props) => {
    const [showDeleteAudioDialog, setShowDeleteAudioDialog] = useState(false)
    const [deleteAudio, { data: deleteAudioData, loading: deleteAudioLoading, error: deleteAudioError }] = useMutation(GQL_MUTATION_DELETE_AUDIO)

    const theme = useTheme()

    useEffect(() => {
        if (deleteAudioError) {
            addMessage({
                location: "Audio",
                type: EnumMessageType.Error,
                message: deleteAudioError.message,
            })
        } else if (deleteAudioData) {
            if (deleteAudioData.deleteAudio.statusCode === 200) {
                setAllRefetchNeeded("Audio")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Musique supprimée.",
                })
            } else {
                deleteAudioData.deleteAudio.errors.map((error: string) => {
                    addMessage({
                        location: "Audio",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAudioData, deleteAudioError])

    const handleDelete = () => {
        setShowDeleteAudioDialog(true)
    }

    const closeDeleteAudioDialog = () => {
        setShowDeleteAudioDialog(false)
    }

    const confirmedDeleteAudio = () => {
        deleteAudio({
            variables: {
                audioId: props.audio.id
            }
        })
        setShowDeleteAudioDialog(false)
    }

    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    width: "320px",
                }}>
                <Box>
                    {
                        props.audio.audioFileUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                // cursor: "pointer",
                                // ":hover": {
                                //     color: theme.palette.secondary.main,
                                // }
                            }}
                            // onClick={() => props.onClick(props.index)}
                            >
                                Audio en construction
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>
                                    (Patientez puis recharchez la page..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.audio.audioFileUrl && <>
                            <Flexbox direction='column' sx={{
                                px: 1,
                                py: 4,
                                backgroundColor: theme.palette.fotoBackground.main,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <MusicIcon sx={{
                                    fontSize: 24,
                                    mr: 1,
                                    color: theme.palette.grey[200],
                                    my: 2,
                                }} />
                                <Typography sx={{
                                    // bgcolor: "red",
                                    color: theme.palette.grey[200],
                                    mb: 2,
                                }}>
                                    {props.audio.title}
                                </Typography>
                                <audio controls>
                                    <source src={props.audio.audioFileUrl} type="audio/mpeg" />
                                    Votre navigateur ne supporte pas la lecture audio.
                                </audio>
                            </Flexbox>
                        </>
                    }


                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.audio.audioFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalDeleteConfirm
                open={showDeleteAudioDialog}
                title="Suppression de la musique"
                content={`Confirmez-vous la suppression définitive de la musique ?`}
                handleClose={closeDeleteAudioDialog}
                handleConfirm={confirmedDeleteAudio}
            />
        </>
    )
}
