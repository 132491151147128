
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const DirectoryIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
      <path fill="#EFCE4A" d="M46.324,52.5H1.565c-1.03,0-1.779-0.978-1.51-1.973l10.166-27.871
	c0.184-0.682,0.803-1.156,1.51-1.156H56.49c1.03,0,1.51,0.984,1.51,1.973L47.834,51.344C47.65,52.026,47.031,52.5,46.324,52.5z"/>
      <path fill="currentColor" d="M50.268,12.5H25l-5-7H1.732C0.776,5.5,0,6.275,0,7.232V49.96c0.069,0.002,0.138,0.006,0.205,0.01
		l10.015-27.314c0.184-0.683,0.803-1.156,1.51-1.156H52v-7.268C52,13.275,51.224,12.5,50.268,12.5z"/>
    </SvgIcon>
  )
}

