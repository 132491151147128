import "../css/global.css"
import { Container } from '@mui/material'
import { FC, ReactElement } from 'react'
import { Footer } from '../components/Footer'
import ResponsiveAppBar from '../components/ResponsiveAppBar'
import { Messages } from "../components/messages/Messages"
import { Pocket } from "../components/Pocket"
import { EnumListLockersMode, EnumPocketTargetType } from "../enums"
import { useGetCurrentUser } from "../redux/features/users/currentUserSlice"
// import { Messages } from '../components/messages/Messages'


interface IProps {
  children: ReactElement | ReactElement[];
  pocketTargetType?: EnumPocketTargetType
  pocketTargetId?: string
  pocketTargetMode?: EnumListLockersMode
}

export const Layout: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()
  return (
    <div className="Layout">
      <ResponsiveAppBar />
      <Messages />
      <Container maxWidth="xl" sx={{
        minHeight: "500px",
        mt: 1.5,
        px: { xs: 1, md: 2 },
      }}>
        {props.children}
      </Container>
      <Footer />
      {
        currentUser &&
        <Pocket targetType={props.pocketTargetType} targetId={props.pocketTargetId} targetMode={props.pocketTargetMode} />
      }
    </div>
  )
}
