import { FC, useState } from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { IDirectory, ILocker } from "../interfaces";
import { DirectoryIcon } from "./svg_icons/DirectoryIcon";
import { EnumListLockersMode, EnumParentType } from "../enums";
import { Spinner } from './spinner/Spinner';
import { apolloClient } from '../ApolloClient';
import { updateInfosLockerAction as updateInfosLockerOfLockersAction } from '../redux/features/lockers/lockersSlice';
import { useDispatch } from 'react-redux';
import { GQL_QUERY_DIRECTORY_DIRECTORIES_INFOS, GQL_QUERY_GET_SIZE_INFOS, GQL_QUERY_LOCKER_DIRECTORIES_INFOS } from '../graphql/Queries';
import { DocumentIcon } from './svg_icons/DocumentIcon';
import { updateInfosLockerAction, updateInfosDirectoryAction as updateInfosDirectoryOfLockerAction, setSizeInfosLockerAction, expandAllDirectoriesAction } from '../redux/features/lockers/lockerSlice';
import { pluralize } from '../utils/Utils';
import { DatabaseIcon } from './svg_icons/DatabaseIcon';
import { expandAllChildrenAction, setSizeInfosDirectoryAction, updateInfosDirectoryAction } from '../redux/features/directories/directorySlice';
import { Flexbox } from './utils/Flexbox';
import { NoteIcon } from './svg_icons/NoteIcon';
import { ShareOutlined } from '@mui/icons-material';
import { ModalDirectoryPublik } from './directories/modals/ModalDirectoryPublik';


interface IProps {
    mode: EnumListLockersMode,
    parentType: EnumParentType,
    locker?: ILocker,
    directory?: IDirectory,
    detailsMode: boolean,
}

export const SubDirectoriesInfos: FC<IProps> = (props) => {
    const theme = useTheme()
    const [showModalDirectoryPublik, setShowModalDirectoryPublik] = useState(false)

    const dispatch = useDispatch()
    if (props.parentType === EnumParentType.locker && props.locker && props.locker.directoriesCount === undefined) {
        apolloClient.query({
            query: GQL_QUERY_LOCKER_DIRECTORIES_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                lockerId: props.locker.id,
            }
        }
        ).then(response => {
            const payloadObject = {
                lockerId: props.locker?.id,
                newInfos: {
                    directoriesCount: response.data.locker.directoriesCount,
                    documentsCount: response.data.locker.documentsCount,
                    notesCount: response.data.locker.notesCount,
                }
            }
            dispatch(updateInfosLockerOfLockersAction(payloadObject))
            dispatch(updateInfosLockerAction(payloadObject))
        })
    } else if (props.parentType === EnumParentType.directory && props.directory && props.directory.directoriesCount === undefined) {
        apolloClient.query({
            query: GQL_QUERY_DIRECTORY_DIRECTORIES_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                directoryId: props.directory.id,
            }
        }
        ).then(response => {
            const payloadObject = {
                directoryId: props.directory?.id,
                newInfos: {
                    directoriesCount: response.data.directory.directoriesCount,
                    documentsCount: response.data.directory.documentsCount,
                    notesCount: response.data.directory.notesCount,
                }
            }
            dispatch(updateInfosDirectoryOfLockerAction(payloadObject))
            dispatch(updateInfosDirectoryAction(payloadObject))
        })

    }

    const determinePublikColor = () => {
        let res = props.mode
        if (
            (props.parentType === EnumParentType.locker && props.locker && props.locker.publik) ||
            (props.parentType === EnumParentType.directory && props.directory && props.directory.publik)
        ) {
            res = EnumListLockersMode.shared
        }
        return res
    }

    const handleGetSizeInfos = () => {
        if (props.locker) {
            var directoryIds: string[] = []
            if (props.locker.directories) {
                directoryIds = props.locker.directories.map((directory) => { return directory.id })
            }
            apolloClient.query({
                query: GQL_QUERY_GET_SIZE_INFOS,
                fetchPolicy: 'network-only',
                variables: {
                    lockerIds: [props.locker.id],
                    directoryIds: directoryIds,
                }
            }
            ).then(response => {
                const payloadObject = response.data.getSizeInfos
                dispatch(setSizeInfosLockerAction(payloadObject))
                dispatch(expandAllDirectoriesAction())
            })
        } else if (props.directory) {
            var directoryIds: string[] = [props.directory.id]
            if (props.directory.children) {
                directoryIds = [...directoryIds, ...props.directory.children.map((child) => { return child.id })]
            }

            var documentIds: string[] = []
            if (props.directory.documents) {
                documentIds = props.directory.documents.map((document) => { return document.id })
            }
            apolloClient.query({
                query: GQL_QUERY_GET_SIZE_INFOS,
                fetchPolicy: 'network-only',
                variables: {
                    directoryIds: directoryIds,
                    documentIds: documentIds,
                }
            }
            ).then(response => {
                const payloadObject = response.data.getSizeInfos
                dispatch(setSizeInfosDirectoryAction(payloadObject))
                dispatch(expandAllChildrenAction())
            })
        }
    }

    const handleDirectoryPublik = () => {
        setShowModalDirectoryPublik(true)
    }

    const closeModalDirectoryPublik = () => {
        setShowModalDirectoryPublik(false)
    }

    return (
        <Flexbox sx={{ justifyContent: "flex-start" }}>
            {
                !props.detailsMode &&
                <>
                    {
                        props.parentType === EnumParentType.locker &&
                        props.locker &&
                        (
                            props.locker.directoriesCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.locker?.directoriesCount > 0 &&
                                <>
                                    <DirectoryIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.locker.directoriesCount}
                                    </Typography>
                                </>
                        )
                    }
                    {
                        props.parentType === EnumParentType.directory &&
                        props.directory &&
                        (
                            props.directory.directoriesCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.directory.directoriesCount > 0 &&
                                <>
                                    <DirectoryIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.directory.directoriesCount}
                                    </Typography>
                                </>
                        )
                    }
                    {
                        props.parentType === EnumParentType.locker &&
                        props.locker &&
                        (
                            props.locker.documentsCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.locker.documentsCount > 0 &&
                                <>
                                    <DocumentIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.locker.documentsCount}
                                    </Typography>
                                </>
                        )
                    }
                    {
                        props.parentType === EnumParentType.directory &&
                        props.directory &&
                        (
                            props.directory.documentsCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.directory.documentsCount > 0 &&
                                <>
                                    <DocumentIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.directory.documentsCount}
                                    </Typography>
                                </>
                        )
                    }
                    {
                        props.parentType === EnumParentType.locker &&
                        props.locker &&
                        (
                            props.locker.notesCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.locker.notesCount > 0 &&
                                <>
                                    <NoteIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.locker.notesCount}
                                    </Typography>
                                </>
                        )
                    }
                    {
                        props.parentType === EnumParentType.directory &&
                        props.directory &&
                        (
                            props.directory?.notesCount === undefined
                                ?
                                <Spinner size={14} />
                                :
                                props.directory?.notesCount > 0 &&
                                <>
                                    <NoteIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                    <Typography sx={{ mr: 1 }}>
                                        {props.directory.notesCount}
                                    </Typography>
                                </>
                        )
                    }
                </>
            }

            {
                props.detailsMode &&
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", mb: 1 }} >
                        <DirectoryIcon color={determinePublikColor()} sx={{ fontSize: 24, mr: .5 }} />
                        {
                            props.parentType === EnumParentType.locker &&
                            (
                                props.locker &&
                                    props.locker?.directoriesCount !== undefined
                                    ?
                                    `${props.locker.directoriesCount} ${pluralize("dossier", props.locker.directoriesCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                        {
                            props.parentType === EnumParentType.directory &&
                            (
                                props.directory &&
                                    props.directory?.directoriesCount !== undefined
                                    ?
                                    `${props.directory.directoriesCount} ${pluralize("dossier", props.directory.directoriesCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", mb: 1 }} >
                        <DocumentIcon color={determinePublikColor()} sx={{ fontSize: 24, mr: .5 }} />
                        {
                            props.parentType === EnumParentType.locker &&
                            (
                                props.locker &&
                                    props.locker?.documentsCount !== undefined
                                    ?
                                    `${props.locker.documentsCount} ${pluralize("document", props.locker.documentsCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                        {
                            props.parentType === EnumParentType.directory &&
                            (
                                props.directory &&
                                    props.directory?.documentsCount !== undefined
                                    ?
                                    `${props.directory.documentsCount} ${pluralize("document", props.directory.documentsCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", mb: 1 }} >
                        <NoteIcon color={determinePublikColor()} sx={{ fontSize: 24, mr: .5 }} />
                        {
                            props.parentType === EnumParentType.locker &&
                            (
                                props.locker &&
                                    props.locker?.notesCount !== undefined
                                    ?
                                    `${props.locker.notesCount} ${pluralize("note", props.locker.notesCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                        {
                            props.parentType === EnumParentType.directory &&
                            (
                                props.directory &&
                                    props.directory?.notesCount !== undefined
                                    ?
                                    `${props.directory.notesCount} ${pluralize("note", props.directory.notesCount)}`
                                    :
                                    <Spinner size={14} />
                            )
                        }
                    </Box>
                    <Flexbox sx={{ justifyContent: "flex-start", ml: -1 }} >
                        <IconButton color={props.mode} onClick={handleGetSizeInfos}>
                            <DatabaseIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                        {
                            props.locker &&
                            <>
                                {
                                    props.locker.sizeInfos === undefined
                                        ?
                                        <Typography>
                                            Taille non calculée.
                                        </Typography>
                                        :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.locker.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.locker.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                }
                            </>
                        }
                        {
                            props.directory &&
                            <>
                                {
                                    props.directory.sizeInfos === undefined
                                        ?
                                        <Typography>
                                            Taille non calculée.
                                        </Typography>
                                        :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.directory.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.directory.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                }
                            </>
                        }
                    </Flexbox>
                    {
                        props.directory &&
                        <Flexbox sx={{ justifyContent: "flex-start", ml: -1 }} >
                            {

                                props.directory.publik &&
                                <>
                                    <IconButton onClick={handleDirectoryPublik} color="shared">
                                        <ShareOutlined />
                                    </IconButton>
                                    <Typography>
                                        Lien public
                                    </Typography>
                                </>
                            }
                            <ModalDirectoryPublik
                                open={showModalDirectoryPublik}
                                handleClose={closeModalDirectoryPublik}
                                directory={props.directory}
                            />
                        </Flexbox>
                    }
                </Flexbox>
            }
        </Flexbox>
    )
}
