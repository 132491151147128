import { Avatar, Box, Button, Link, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IUser } from "../../interfaces";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { ModalAvatar } from './modals/ModalAvatar';
import { ModalEditPassword } from './modals/ModalEditPassword';
import { EnumPackLabel, EnumUserInfosMode } from '../../enums';
import { LinearProgressWithLabel } from '../spinner/LinearProgressWithLabel';
import { ModalChangePack } from '../admin/ModalChangePack';
import { Flexbox } from '../utils/Flexbox';
import { dateFormat, isDateOld } from '../../utils/Utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from "react-router-dom";
import { constructPath, NAVIGATE_ADMIN_USER } from '../../utils/Navigation';
dayjs.extend(utc);


interface IProps {
    user: IUser,
    mode: EnumUserInfosMode,
    // mode: EnumListLockersMode,
}

export const UserInfos: FC<IProps> = (props) => {
    const [showModalAvatar, setShowModalAvatar] = useState(false)
    const [showModalEditPassword, setShowModalEditPassword] = useState(false)
    const [packPictoUrl, setPackPictoUrl] = useState("")
    const [packLabel, setPackLabel] = useState("")
    const [packLimit, setPackLimit] = useState("")
    const [packUsagePercent, setPackUsagePercent] = useState(0)
    const [showModalChangePack, setShowModalChangePack] = useState(false)
    const [avatarLoaded, setAvatarLoaded] = useState(false)
    const navigate = useNavigate()

    const userPath = constructPath(NAVIGATE_ADMIN_USER, [
        { key: "userId", value: props.user.id }
    ])
    const theme = useTheme()

    useEffect(() => {
        if (props.user.packInfos) {
            setPackUsagePercent(props.user.packInfos.usagePercent)
        }
        if (props.user.packInfos?.label === EnumPackLabel.starter) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/starter.png`)
            setPackLabel("Starter")
            setPackLimit("20 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.basic) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/basic.png`)
            setPackLabel("Basic")
            setPackLimit("50 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.bronze) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/bronze.png`)
            setPackLabel("Bronze")
            setPackLimit("100 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.silver) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/silver.png`)
            setPackLabel("Silver")
            setPackLimit("200 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.gold) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/gold.png`)
            setPackLabel("Gold")
            setPackLimit("500 MB")
        } else if (props.user.packInfos?.label === EnumPackLabel.amethyst) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/amethyst.png`)
            setPackLabel("Amethyst")
            setPackLimit("1 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.ruby) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/ruby.png`)
            setPackLabel("Ruby")
            setPackLimit("2 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.diamond) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/diamond.png`)
            setPackLabel("Diamond")
            setPackLimit("5 GB")
        } else if (props.user.packInfos?.label === EnumPackLabel.blackDiamond) {
            setPackPictoUrl(`${process.env.PUBLIC_URL}/images/packs/black_diamond.png`)
            setPackLabel("Black diamond")
            setPackLimit("10 GB")
        }
    }, [])

    const handleEditAvatar = () => {
        setShowModalAvatar(true)
    }

    const closeModalAvatar = () => {
        setShowModalAvatar(false)
    }

    const handleEditPassword = () => {
        setShowModalEditPassword(true)
    }

    const closeModalEditPassword = () => {
        setShowModalEditPassword(false)
    }

    const handleChangePack = () => {
        setShowModalChangePack(true)
    }

    const closeModalChangePack = () => {
        setShowModalChangePack(false)
    }

    const handleAvatarLoaded = () => {
        setAvatarLoaded(true)
    }


    return (
        <>
            <Paper className="UserInfos" sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-start", alignItems: { xs: "center", md: "flex-start" }, py: 2, px: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", mr: { xs: 0, md: 4 } }}>
                        {
                            !avatarLoaded && props.user.userAvatarPreviewUrl && <Skeleton variant='circular' animation="wave" sx={{ width: 72, height: 72 }} />
                        }
                        <Avatar alt={`${props.user.firstname} ${props.user.lastname}`}
                            onLoad={handleAvatarLoaded}
                            src={`${props.user.userAvatarPreviewUrl}`}
                            sx={{
                                width: 72,
                                height: 72,
                                display: (avatarLoaded || !props.user.userAvatarPreviewUrl) ? "flex" : "none",
                            }} />
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: { xs: "center", md: "flex-start" } }}>
                        {
                            props.mode === EnumUserInfosMode.standard && <>
                                <Typography variant="h5" color="primary">
                                    {props.user.firstname} {props.user.lastname}
                                </Typography>
                            </>
                        }
                        {
                            props.mode === EnumUserInfosMode.admin && <>
                                <Link href={userPath} underline="none">
                                    <Typography
                                        sx={{
                                            color: theme.palette.text.primary,
                                            fontSize: 24,
                                            ":hover": {
                                                cursor: "pointer",
                                                textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                            }
                                        }}>
                                        {props.user.firstname} {props.user.lastname}
                                    </Typography>
                                </Link>
                            </>
                        }
                        {
                            props.user.email &&
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <MailOutlinedIcon color='primary' sx={{ fontSize: "18px", color: theme.palette.text.secondary, mr: 1 }} />
                                <Typography variant="body1" sx={{ fontSize: "14px", color: theme.palette.text.secondary, wordBreak: "break-all" }}>
                                    {props.user.email}
                                </Typography>
                            </Box>
                        }
                        {
                            props.mode === EnumUserInfosMode.standard &&
                            <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-end", alignItems: "center", pt: 2 }}>
                                <Button size='medium' color='secondary' onClick={handleEditAvatar}>Modifier l'avatar</Button>
                                <Button size='medium' color='secondary' onClick={handleEditPassword}>Changer le mot de passe</Button>
                            </Box>
                        }
                        {
                            props.mode === EnumUserInfosMode.admin &&
                            <Box sx={{ display: "flex", width: "100%", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", pt: 2 }}>
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <Box
                                            component="img"
                                            src={packPictoUrl}
                                            sx={{ width: "20px" }}
                                        />
                                        <Typography color="primary" sx={{ fontSize: "14px" }}>
                                            {packLabel}
                                        </Typography>
                                    </Box>
                                    <Typography color="primary" sx={{ fontSize: "14px" }}>
                                        {packLimit}
                                    </Typography>
                                </Box>
                                <Box sx={{ width: '100%', px: 1 }}>
                                    <LinearProgressWithLabel variant="determinate"
                                        color={packUsagePercent > 80 ? "error" : packUsagePercent > 60 ? "warning" : 'success'}
                                        value={packUsagePercent} />
                                </Box>

                                {
                                    props.user.lastConnection && <Flexbox sx={{ width: "100%", justifyContent: "space-between" }}>
                                        <Typography sx={{
                                            fontSize: 14,
                                            color: isDateOld(props.user.lastConnection) ? theme.palette.error.main : theme.palette.text.secondary
                                        }}>
                                            Dernière connection:
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: 14,
                                            color: isDateOld(props.user.lastConnection) ? theme.palette.error.main : theme.palette.text.secondary
                                        }}>
                                            {`${dateFormat(props.user.lastConnection)}`}
                                        </Typography>
                                    </Flexbox>
                                }
                                <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", md: "row" }, justifyContent: "flex-end", alignItems: "center", pt: 2 }}>
                                    <Button size='medium' color='secondary' onClick={handleEditAvatar}>Modifier l'avatar</Button>
                                    <Button size='medium' color='secondary' onClick={handleChangePack}>Changer le pack</Button>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Paper>
            <ModalAvatar
                open={showModalAvatar}
                handleClose={closeModalAvatar}
                user={props.user}
            />
            <ModalEditPassword
                open={showModalEditPassword}
                handleClose={closeModalEditPassword}
                user={props.user}
            />
            <ModalChangePack
                open={showModalChangePack}
                handleClose={closeModalChangePack}
                user={props.user}
            />
        </>
    )
}
