import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import "yet-another-react-lightbox/styles.css";
import { IAccountOperation } from '../../../interfaces';
import { Flexbox } from '../../utils/Flexbox';
import { AccountOperationIcon } from '../../svg_icons/AccountOperationIcon';
import 'dayjs/locale/fr';


interface IProps {
    accountOperation: IAccountOperation
}

export const AccountOperationSpan: FC<IProps> = (props) => {

    return (
        <>
            <Typography component="span" sx={{
                color: "text.primary",
                fontSize: 18,
                // mx: 1,
            }}>
                <AccountOperationIcon color={props.accountOperation.accountOperationType} sx={{ fontSize: 18, mr: .5, mb: -0.5 }} />
                {props.accountOperation.title}
            </Typography>
        </>
    )
}
