import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Radio, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { IUser } from '../../interfaces';
import { addMessage } from '../messages/Message';
import { EnumMessageType, EnumPackHumanLabel, EnumPackLabel } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setAdminRefetchNeeded } from '../../redux/features/admin/adminSlice';
import { GQL_MUTATION_CHANGE_PACK } from '../../graphql/Mutations';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { getPacks } from '../../utils/Utils';

interface IProps {
    open: boolean;
    handleClose: () => void;
    user: IUser;
}

export const ModalChangePack: FC<IProps> = (props) => {
    const [packLabel, setPackLabel] = useState("")
    const [changePack, { data: changePackData, loading: changePackLoading, error: changePackError }] = useMutation(GQL_MUTATION_CHANGE_PACK)
    const dispatch = useDispatch()



    useEffect(() => {
        if (props.user.packInfos) {
            setPackLabel(props.user.packInfos.label)
        }
    }, [])

    useEffect(() => {
        if (changePackError) {
            addMessage({
                location: "ModalChangePack",
                type: EnumMessageType.Error,
                message: changePackError.message,
            })
        } else if (changePackData) {
            if (changePackData.changePack.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Pack de l'utilisateur modifié.",
                })
                dispatch(setAdminRefetchNeeded(true))
                handleClose()
            } else {
                changePackData.changePack.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [changePackData, changePackError])


    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (packLabel !== "") {
            changePack({
                variables: {
                    userId: props.user.id,
                    packLabel: packLabel,
                }
            })
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleChange = (newPackLabel: string) => {
        setPackLabel(newPackLabel)
    }

    return (
        <div className="ModalChangePack">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <SettingsOutlinedIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Changer le pack
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {
                            getPacks().map((pack, index) => {
                                return <Box key={index} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 1 }}>
                                    <Radio
                                        checked={packLabel === pack.label}
                                        onChange={(event) => { handleChange(event.target.value) }}
                                        value={pack.label}
                                    />
                                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                        <Box
                                            component="img"
                                            src={pack.pictoUrl}
                                            sx={{ width: "20px" }}
                                        />
                                        <Typography color="primary" sx={{ fontSize: "14px" }}>
                                            {pack.humanLabel}
                                        </Typography>
                                    </Box>
                                    <Typography color="primary" sx={{ fontSize: "14px" }}>
                                        {pack.limit}
                                    </Typography>
                                    <Typography color="primary" sx={{ fontSize: "14px" }}>
                                        {` - ${pack.price}`}
                                    </Typography>
                                    {
                                        props.user.packInfos?.label === pack.label &&
                                        <Typography color="primary" sx={{ fontSize: "14px" }}>
                                            ( pack actuel )
                                        </Typography>
                                    }
                                </Box>
                            })
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
