import { Box, IconButton, Tooltip } from "@mui/material";
import { FC, useState } from "react";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { ModalFilter } from "./modals/ModalFilter";
import { useSelector } from "react-redux";
import { IRootState } from "../redux/store";

interface IProps {
}

export const Filter: FC<IProps> = (props) => {
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const [showModalFilter, setShowModalFilter] = useState(false)

    const closeModalFilter = () => {
        setShowModalFilter(false)
    }

    return (
        <Box className="Filter">
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={filterQuery === undefined ? "Activer le filtre" : "Un filtre est activé !"}>
                <IconButton color={filterQuery === undefined ? "primary" : "secondary"} onClick={() => setShowModalFilter(true)}><FilterAltOutlinedIcon fontSize="medium" /></IconButton>
            </Tooltip>
            <ModalFilter
                open={showModalFilter}
                handleClose={closeModalFilter}
            />
        </Box>
    )
}





