import { FC } from 'react'
import { Layout } from "./Layout"
import { ListAlbums } from '../components/albums/ListAlbums'

interface IProps {
}

export const AlbumsPage: FC<IProps> = (props) => {

  return (
    <div className="Albums">
      <Layout>
        <div className="page-body">
          <ListAlbums />
        </div>
      </Layout>
    </div >
  )
}
