import "../../css/components/lockers/LockerDetails.css";
import { Box, Collapse, Paper, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IDocument } from "../../interfaces";
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EnumListLockersMode } from "../../enums";
import { DocumentIcon } from "../svg_icons/DocumentIcon";

interface IProps {
    document: IDocument,
}

export const PublikDocumentDetails: FC<IProps> = (props) => {
    const [expanded, setExpanded] = useState(true)
    // const handleExpand = () => {
    //     setExpanded(!expanded)
    // }
    const theme = useTheme()

    return (
        <>
            {/* <Paper className="LockerDetails">
                <Box className="LockerDetails-container">
                    <Box className="LockerDetails-main-section">
                        <Box className="LockerDetails-icon-section">
                            <DocumentIcon color={EnumListLockersMode.shared} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Box className="LockerDetails-content-section">
                            <Box className="LockerDetails-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.document.title}
                                </Typography>
                                <Box className="LockerDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="LockerDetails-expanded-section">
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {
                                props.document.description && <>
                                    <Box className="LockerDetails-description-section">
                                        <Typography sx={{ fontSize: "16px", my: 3 }}>
                                            {props.document.description}
                                        </Typography>
                                    </Box>
                                </>
                            }
                        </Collapse>
                    </Box>
                </Box>
            </Paper> */}
            <Typography sx={{
                // bgcolor: "red",
                textAlign: "center",
                my: 4,
                fontSize: 48,
                color: theme.palette.shared.main,
            }}>
                Accès public au document
            </Typography>
        </>
    )
}
