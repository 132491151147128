import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumParentType, EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_DELETE_DIRECTORIES, GQL_MUTATION_REMOVE_FROM_POCKET } from "../../graphql/Mutations";
import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import {
    expandSelectedDirectoriesAction,
    selectAllDirectoriesAction,
    unExpandSelectedDirectoriesAction,
    unSelectAllDirectoriesAction,
    useGetLocker,
} from "../../redux/features/lockers/lockerSlice";
import { Directory } from "./Directory";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { ModalNewDirectory } from "./modals/ModalNewDirectory";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addDirectoriesAction as addDirectoriesInPocketAction,
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
} from "../../redux/features/pocket/pocketSlice";
import { IRootState, setAllRefetchNeeded } from "../../redux/store";
import { pluralize } from "../../utils/Utils";
import { Filter } from "../Filter";
import { FloatingSpinner } from "../spinner/FloatingSpinner";

interface IProps {
    mode: EnumListLockersMode,
}

export const ListDirectories: FC<IProps> = (props) => {
    const params = useParams()
    const lockerId = params.lockerId || ""
    const locker = useGetLocker(lockerId)
    const [showModalNewDirectory, setShowModalNewDirectory] = useState(false)
    const [selectAllDirectories, setSelectAllDirectories] = useState(true)
    const [hasSelectedDirectory, setHasSelectedDirectory] = useState(false)
    const [showDeleteDirectoriesDialog, setShowDeleteDirectoriesDialog] = useState(false)
    const [expandSelectedDirectories, setExpandSelectedDirectories] = useState(true)
    const [putInPocketSelectedDirectories, setPutInPocketSelectedDirectories] = useState(true)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const [deleteDirectories, { data: deleteDirectoriesData, loading: deleteDirectoriesLoading, error: deleteDirectoriesError }] = useMutation(GQL_MUTATION_DELETE_DIRECTORIES)
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const dispatch = useDispatch()

    const theme = useTheme()

    const closeModalNewDirectory = () => {
        setShowModalNewDirectory(false)
    }

    const handleSelectAll = () => {
        selectAllDirectories ? dispatch(selectAllDirectoriesAction()) : dispatch(unSelectAllDirectoriesAction())
        setSelectAllDirectories(!selectAllDirectories)
    }

    const handleDeleteSelection = () => {
        if (getSelectedDirectoryIds().length > 0) {
            setShowDeleteDirectoriesDialog(true)
        }
    }

    const closeDeleteDirectoriesDialog = () => {
        setShowDeleteDirectoriesDialog(false)
    }

    const confirmedDeleteDirectories = () => {
        deleteDirectories({
            variables: {
                directoryIds: getSelectedDirectoryIds()
            }
        })
        setShowDeleteDirectoriesDialog(false)
    }


    const getSelectedDirectoryIds = () => {
        if (locker && locker.directories) {
            return locker.directories?.filter(directory => directory.selected).map(directory => directory.id)
        } else {
            return []
        }
    }

    const getSelectedDirectories = () => {
        if (locker && locker.directories) {
            return locker.directories?.filter(directory => directory.selected)
        } else {
            return []
        }
    }

    const handleExpandSelectedDirectories = () => {
        if (getSelectedDirectoryIds().length > 0) {
            expandSelectedDirectories ? dispatch(expandSelectedDirectoriesAction()) : dispatch(unExpandSelectedDirectoriesAction())
            setExpandSelectedDirectories(!expandSelectedDirectories)
        }
    }

    const handlePutInPocket = () => {
        if (getSelectedDirectoryIds().length > 0) {
            addInPocket({
                variables: {
                    directoryIds: getSelectedDirectoryIds(),
                }
            })

        }
    }

    const handleRemoveFromPocket = () => {
        if (getSelectedDirectoryIds().length > 0) {
            removeFromPocket({
                variables: {
                    directoryIds: getSelectedDirectoryIds(),
                }
            })

        }
    }

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "ListDirectories",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addDirectoriesInPocketAction(getSelectedDirectories()))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
                // Il peut y avoir certains qui n'ont pas fonctionner:
                const errors: string[] = addInPocketData.addInPocket.errors
                errors.map(error => addMessage({
                    type: EnumMessageType.Warning,
                    message: error,
                }))
                setPutInPocketSelectedDirectories(false)

            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "ListDirectories",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDirectoriesFromPocketAction(getSelectedDirectoryIds()))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
                setPutInPocketSelectedDirectories(true)
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])


    useEffect(() => {
        if (deleteDirectoriesError) {
            addMessage({
                location: "ListDirectories",
                type: EnumMessageType.Error,
                message: deleteDirectoriesError.message,
            })
        } else if (deleteDirectoriesData) {
            if (deleteDirectoriesData.deleteDirectories.statusCode === 200) {
                const selectedDirectoryIds = getSelectedDirectoryIds()
                setAllRefetchNeeded("ListDirectories")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${selectedDirectoryIds.length} ${pluralize("dossier supprimé", selectedDirectoryIds.length, "dossiers supprimés")}.`,
                })
            } else {
                deleteDirectoriesData.deleteDirectories.errors.map((error: string) => {
                    addMessage({
                        location: "ListDirectories",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDirectoriesData, deleteDirectoriesError])

    useEffect(() => {
        if (getSelectedDirectoryIds().length > 0) {
            setHasSelectedDirectory(true)
        } else {
            setHasSelectedDirectory(false)
        }
    }, [locker?.directories])


    return (
        <div className='ListDirectories'>
            <FloatingSpinner dependances={[deleteDirectoriesLoading]} />
            <Container sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
            }}>
                <Filter />
                <Divider orientation="vertical" flexItem variant="middle" />
                {
                    hasSelectedDirectory && props.mode !== EnumListLockersMode.shared && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les dossiers sélectionnés">
                        <IconButton onClick={handleDeleteSelection} color="error">
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedDirectory && putInPocketSelectedDirectories &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer les dossiers sélectionnés dans la pocket">
                        <IconButton onClick={handlePutInPocket} color="primary">
                            <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedDirectory && !putInPocketSelectedDirectories &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer les dossiers sélectionnés de la pocket">
                        <IconButton onClick={handleRemoveFromPocket} color="secondary">
                            <PocketIcon variant={EnumSvgVariant.secondary} sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedDirectory &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={expandSelectedDirectories ? "Déplier les dossiers sélectionnnés" : "Plier les dossiers sélectionnnés"}>
                        <ExpandMore expand={!expandSelectedDirectories} onClick={handleExpandSelectedDirectories} aria-expanded={!expandSelectedDirectories} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Tooltip>
                }
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllDirectories ? "Sélectionner tout" : "Désélectionner tout"}>
                    <IconButton onClick={handleSelectAll} color={selectAllDirectories ? "secondary" : "cancel"}>
                        <CropFreeOutlined />
                    </IconButton>
                </Tooltip>
                {
                    props.mode !== EnumListLockersMode.shared && locker &&
                    <>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un dossier">
                            <IconButton color="primary" onClick={() => setShowModalNewDirectory(true)}><Add fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewDirectory
                            open={showModalNewDirectory}
                            handleClose={closeModalNewDirectory}
                            locker={locker}
                            mode={props.mode}
                        />
                    </>
                }

            </Container>
            <Container >
                <Grid container spacing={0} className="ListDirectories-grid-container">
                    {
                        locker === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            locker.directories?.map((directory) => {
                                return (
                                    <Grid key={`${directory.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Directory directory={directory} directoryParentType={EnumParentType.locker} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        locker !== undefined && locker.directories?.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun dossier.
                            </Typography>
                            {
                                filterQuery &&
                                <Typography sx={{ fontSize: 24, color: theme.palette.warning.main }}>
                                    Un filtre est actif.
                                </Typography>
                            }
                        </Box>
                    }
                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteDirectoriesDialog}
                title="Suppression des dossiers"
                content={`Confirmez-vous la suppression définitive de ${pluralize("ce dossier", getSelectedDirectoryIds().length, `ces ${getSelectedDirectoryIds().length} dossiers`)} ?`}
                handleClose={closeDeleteDirectoriesDialog}
                handleConfirm={confirmedDeleteDirectories}
                danger={true}
            />
        </div>
    )
}
