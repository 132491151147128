import { useMutation } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Radio, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumAccountOperationType, EnumBankAccountType, EnumCbColor, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_SYNC_BANK_ACCOUNT_WITH_BANK, GQL_MUTATION_UPDATE_BANK_ACCOUNT } from '../../../graphql/Mutations';
import { IBankAccount } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { BankAccountIcon } from '../../svg_icons/BankAccountIcon';
import { setAllRefetchNeeded } from '../../../redux/store';
import { Flexbox } from '../../utils/Flexbox';
import SyncIcon from '@mui/icons-material/Sync';
import { formatFancyCurrency } from '../../../utils/Utils';

interface IProps {
    open: boolean;
    handleClose: () => void;
    bankAccount: IBankAccount

}

export const ModalSyncBankAccountWithBank: FC<IProps> = (props) => {
    const [amount, setAmount] = useState<string>("")
    const amountRef = useRef<HTMLInputElement>(null);
    const [errorOnAmount, setErrorOnAmount] = useState(false)
    const [syncBankAccountWithBank, { data: syncBankAccountWithBankData, loading: syncBankAccountWithBankLoading, error: syncBankAccountWithBankError }] = useMutation(GQL_MUTATION_SYNC_BANK_ACCOUNT_WITH_BANK)

    const theme = useTheme()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (amountRef.current) {
                    amountRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (syncBankAccountWithBankError) {
            addMessage({
                location: "ModalSyncBankAccountWithBank",
                type: EnumMessageType.Error,
                message: syncBankAccountWithBankError.message,
            })
        } else if (syncBankAccountWithBankData) {
            if (syncBankAccountWithBankData.syncBankAccountWithBank.statusCode === 200) {
                setAllRefetchNeeded("ModalSyncBankAccountWithBank")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opération de régularisation créée.",
                })
                handleClose()
            } else {
                syncBankAccountWithBankData.syncBankAccountWithBank.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [syncBankAccountWithBankData, syncBankAccountWithBankError])

    const checkErrors = () => {
        let res = true
        if (amount === undefined || amount === "") {
            setErrorOnAmount(true)
            addMessage({
                type: EnumMessageType.Error,
                message: "Le solde indiqué par la banque est obligatoire.",
            })
            res = false
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            syncBankAccountWithBank({
                variables: {
                    bankAccountId: props.bankAccount.id,
                    bankBalance: parseFloat(amount),
                }
            })
        }
    }

    const cleanDatas = () => {
        setAmount("")
        setErrorOnAmount(false)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalSyncBankAccountWithBank">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <SyncIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Synchronisation bancaire
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox direction='column' sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            // bgcolor: "yellow",
                            width: "100%",
                        }}>
                            <Typography sx={{
                                width: "100%",
                                textAlign: "center",
                            }}>
                                Votre solde bancaire est actuellement de:
                            </Typography>
                            <Typography sx={{
                                my: 1,
                                width: "100%",
                                textAlign: "center",
                                fontSize: 24,
                                color: parseFloat(props.bankAccount.bankBalance) >= 0 ? theme.palette.credit.main : theme.palette.debit.main,
                            }}>
                                {formatFancyCurrency(parseFloat(props.bankAccount.bankBalance), parseFloat(props.bankAccount.bankBalance) >= 0 ? EnumAccountOperationType.credit : EnumAccountOperationType.debit)}
                            </Typography>
                            <Typography sx={{
                                color: "text.secondary",
                            }}>
                                Si votre banque indique un autre solde, vous pouvez le renseigner ci-dessous.
                                <br />
                                Metadoc va créer automatiquement l'opération de régularisation pour synchroniser les soldes.
                            </Typography>
                        </Flexbox>
                        <FormControl variant="outlined" sx={{
                            mt: 4,
                        }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Solde indiqué par la banque</InputLabel>
                            <OutlinedInput
                                inputRef={amountRef}
                                autoFocus
                                id="outlined-adornment-amount"
                                type='number'
                                endAdornment={
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                }
                                label="Solde indiqué par la banque"
                                color="primary"
                                onChange={(event) => { setAmount(event.currentTarget.value) }}
                                value={amount}
                                autoComplete='off'
                                error={errorOnAmount}
                                sx={{
                                    width: "264px",
                                }} />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Synchroniser
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
