import { createSlice } from "@reduxjs/toolkit";
import { IDirectory, ILocker } from "../../../interfaces";
import { sortByName } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_LOCKER } from "../../../graphql/Queries";


interface ILockerSliceState {
    locker: undefined | ILocker;
    refetchNeeded: boolean
}

const initialState: ILockerSliceState = {
    locker: undefined,
    refetchNeeded: false,
}

export const lockerSlice = createSlice({
    name: "lockerSlice",
    initialState,
    reducers: {
        setLockerAction: (state, action) => {
            state.locker = { ...action.payload, directories: sortByName(action.payload.directories) }
        },
        updateLockerAction: (state, action) => {
            state.locker = { ...state.locker, ...action.payload }
        },
        addDirectoryAction: (state, action) => {
            const newDirectory: IDirectory = action.payload
            if (state.locker) {
                if (state.locker.directories) {
                    state.locker.directories = sortByName([...state.locker.directories, newDirectory])
                } else {
                    state.locker.directories = [newDirectory]
                }
            }
        },
        updateDirectoryAction: (state, action) => {
            const updatedDirectory: IDirectory = action.payload
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        return directory.id === updatedDirectory.id ? updatedDirectory : directory
                    }
                )
            }
        },
        removeDirectoryAction: (state, action) => {
            const directoryId = action.payload
            if (state.locker) {
                state.locker.directories = state.locker.directories?.filter(directory => directory.id !== directoryId)
            }
        },
        toggleSelectDirectoryAction: (state, action) => {
            const directoryId = action.payload
            // console.log("in toggleSelectDirectoryAction");

            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        // console.log("comparing ", directory.id, "with ", directoryId, ": ", directory.id === directoryId);
                        return directory.id === directoryId ? { ...directory, selected: !directory.selected } : directory
                    }
                )
            }
        },
        selectAllDirectoriesAction: (state) => {
            if (state.locker) {
                state.locker.directories = state.locker?.directories?.map(
                    directory => {
                        return { ...directory, selected: true }
                    }
                )
            }
        },
        unSelectAllDirectoriesAction: (state) => {
            if (state.locker) {
                state.locker.directories = state.locker?.directories?.map(
                    directory => {
                        return { ...directory, selected: false }
                    }
                )
            }
        },
        toggleExpandDirectoryAction: (state, action) => {
            const directoryId = action.payload
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        return directory.id === directoryId ? { ...directory, expanded: !directory.expanded } : directory
                    }
                )

            }
        },
        expandSelectedDirectoriesAction: (state) => {
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        return directory.selected ? { ...directory, expanded: true } : directory
                    }
                )
            }
        },
        unExpandSelectedDirectoriesAction: (state) => {
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        return directory.selected ? { ...directory, expanded: false } : directory
                    }
                )
            }
        },
        expandAllDirectoriesAction: (state) => {
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(
                    directory => {
                        return { ...directory, expanded: true }
                    }
                )
            }
        },
        removeDirectoriesAction: (state, action) => {
            const directoryIds = action.payload
            if (state.locker) {
                state.locker.directories = state.locker.directories?.filter(directory => !directoryIds.includes(directory.id))
            }
        },
        removeLockerAction: (state) => {
            state.locker = undefined
        },
        putLockerInVaultAction: (state) => {
            if (state.locker) {
                state.locker = { ...state.locker, vault: true }
            }
        },
        removeLockerFromVaultAction: (state) => {
            if (state.locker) {
                state.locker = { ...state.locker, vault: false }
            }
        },
        setLockerRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        updateInfosLockerAction: (state, action) => {
            const lockerId = action.payload.lockerId
            if (state.locker && lockerId === state.locker.id) {
                const newInfos = action.payload.newInfos
                state.locker = { ...state.locker, ...newInfos }
            }
        },
        updateInfosDirectoryAction: (state, action) => {
            const directoryId = action.payload.directoryId
            const newInfos = action.payload.newInfos
            if (state.locker) {
                state.locker.directories = state.locker.directories?.map(directory => {
                    return directory.id === directoryId ? { ...directory, ...newInfos } : directory
                })
            }
        },
        setSizeInfosLockerAction: (state, action) => {
            const payloadLockers = action.payload.lockers
            payloadLockers.forEach((payloadLocker: ILocker) => {
                if (state.locker && state.locker.id === payloadLocker.id) {
                    state.locker = { ...state.locker, sizeInfos: payloadLocker.sizeInfos }
                }
            })
            const payloadDirectories = action.payload.directories
            if (payloadDirectories) {
                payloadDirectories.forEach((payloadDirectory: IDirectory) => {
                    if (state.locker && state.locker.directories) {
                        state.locker.directories = state.locker.directories.map(directory => {
                            return directory.id === payloadDirectory.id ? { ...directory, sizeInfos: payloadDirectory.sizeInfos } : directory
                        })
                    }
                })
            }
        },
    }
})

export const { setLockerRefetchNeeded, setLockerAction, updateLockerAction, toggleSelectDirectoryAction, selectAllDirectoriesAction, unSelectAllDirectoriesAction,
    toggleExpandDirectoryAction, expandSelectedDirectoriesAction, unExpandSelectedDirectoriesAction,
    addDirectoryAction, updateDirectoryAction, removeDirectoryAction, removeDirectoriesAction,
    removeLockerAction, putLockerInVaultAction, removeLockerFromVaultAction, updateInfosLockerAction, updateInfosDirectoryAction,
    setSizeInfosLockerAction, expandAllDirectoriesAction,
} = lockerSlice.actions


export const useGetLocker = (lockerId: string) => {
    const locker: (undefined | ILocker) = useSelector((state: IRootState) => state.lockerReducer.locker)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.lockerReducer.refetchNeeded)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    if (refetchNeeded || locker === undefined || locker.id !== lockerId) {
        apolloClient.query({
            query: GQL_LOCKER,
            fetchPolicy: 'network-only',
            variables: {
                lockerId,
                filter: filterQuery,
            }
        }
        ).then(response => {
            store.dispatch(setLockerAction(response.data.locker))
            store.dispatch(setLockerRefetchNeeded(false))
            // console.log("return locker by fetching..")
            return response.data.locker
        })
    } else {
        // console.log("return locker by useSeletor..")
        return locker
    }
}

export default lockerSlice.reducer