import { Avatar, Box, Button, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IBadge, IContact, IContactMinimal } from "../../interfaces";
import {
    Add,
    DeleteOutline, EditOutlined,
    Key,
    MoreHoriz
} from '@mui/icons-material';

import { getDayjsAge, truncateString } from "../../utils/Utils";
import { KeysIcon } from "../svg_icons/KeysIcon";
import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumContactType, EnumDayjsFormat, EnumMessageType, EnumSize } from '../../enums';
import { IRootState, setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_CONTACT } from '../../graphql/Mutations';
import { ModalEditContact } from './modals/ModalEditContact';
import BusinessIcon from '@mui/icons-material/Business';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { ContactIcon } from '../svg_icons/ContactIcon';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { ModalContactAvatar } from './modals/ModalContactAvatar';
import Grid from '@mui/material/Grid2';
import { ModalNewContactRelationship } from './modals/ModalNewContactRelationship';
import { useDispatch, useSelector } from 'react-redux';
import { clearOpenedModal, setOpenedModal } from '../../redux/features/global/modalsSlice';
import { ContactBadge } from './ContactBadge';


interface IProps {
    contact: IContact,
}

export const ContactRelationships: FC<IProps> = (props) => {
    // const [showModalNewContactRelationship, setShowModalNewContactRelationship] = useState(false)
    const ModalNewContactRelationshipId = `ModalNewContactRelationship-contact-${props.contact.id}`
    const openedModal: string = useSelector((state: IRootState) => state.modalsReducer.openedModal)
    const dispatch = useDispatch()
    const [displayAll, setDisplayAll] = useState(false)
    const minDisplay = 8

    const theme = useTheme()



    const handleOpenModalNewContactRelationship = () => {
        dispatch(setOpenedModal(ModalNewContactRelationshipId))
    }

    const closeModalNewContactRelationship = () => {
        dispatch(clearOpenedModal())
    }


    return (
        <>
            <Grid container sx={{ width: "100%", alignItems: "center" }}>
                <Grid
                    size={{ xs: 8 }} sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                    }}
                >
                    {
                        props.contact.contactRelations && props.contact.contactRelations.length > 0
                            ?
                            props.contact.contactRelations.map((contactRelation, contactRelationIndex) => {
                                return (contactRelationIndex < minDisplay || displayAll)
                                    ?
                                    <ContactBadge key={`contact-badge-${contactRelationIndex}`} contact={contactRelation} size={EnumSize.medium} displayContactTitle={false} displayTooltip={true} />
                                    :
                                    null
                            })
                            :
                            <Grid
                                size={{ xs: 10 }}
                            >
                                <Typography variant="body1" sx={{ fontSize: "14px", color: theme.palette.text.secondary }}>
                                    Aucune connaissance..
                                </Typography>
                            </Grid>

                    }

                </Grid>
                <Grid size={{ xs: 2 }}>
                    {
                        !displayAll && props.contact.contactRelations && props.contact.contactRelations.length > minDisplay &&
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Afficher tout">
                            <IconButton color="primary" onClick={() => setDisplayAll(true)}>
                                <MoreHoriz fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
                <Grid size={{ xs: 2 }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter une relation">
                        <IconButton color="primary" onClick={handleOpenModalNewContactRelationship}><Add fontSize="medium" /></IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <ModalNewContactRelationship
                open={openedModal === ModalNewContactRelationshipId}
                handleClose={closeModalNewContactRelationship}
                contact={props.contact}
            />
        </>
    )
}
