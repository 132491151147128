import { createSlice } from "@reduxjs/toolkit";
import { ICustomContact } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_CUSTOM_CONTACTS } from "../../../graphql/Queries";


interface ICustomContactsSliceState {
    customContacts: undefined | ICustomContact[];
    refetchNeeded: boolean
}

const initialState: ICustomContactsSliceState = {
    customContacts: undefined,
    refetchNeeded: false,
}

export const customContactsSlice = createSlice({
    name: "customContactsSlice",
    initialState,
    reducers: {
        setCustomContactsAction: (state, action) => {
            const newCustomContacts = [...action.payload]
            state.customContacts = newCustomContacts as ICustomContact[]
        },
        unsetCustomContactsAction: (state) => {
            state.customContacts = initialState.customContacts
        },
        addCustomContactAction: (state, action) => {
            // state.customContacts = state.customContacts?.concat(action.payload)
            state.customContacts = state.customContacts ? [...state.customContacts, action.payload] : [action.payload]
        },
        setCustomContactsRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        // selectAllCusContactAction: (state) => {
        //     state.contacts = state.contacts?.map(
        //         contact => {
        //             return { ...contact, selected: true }
        //         }
        //     )
        // },
        // unSelectAllContactAction: (state) => {
        //     state.contacts = state.contacts?.map(
        //         contact => {
        //             return { ...contact, selected: false }
        //         }
        //     )
        // },
        // toggleSelectContactAction: (state, action) => {
        //     const contactId = action.payload
        //     state.contacts = state.contacts?.map(
        //         contact => {
        //             return contact.id === contactId ? { ...contact, selected: !contact.selected } : contact
        //         }
        //     )
        // },
        updateCustomContactAction: (state, action) => {
            // state.customContacts = state.customContacts?.concat(action.payload)
            state.customContacts = state.customContacts?.map(
                contact => {
                    return contact.id === action.payload.id ? action.payload : contact
                }
            )
        },
        deleteCustomContactAction: (state, action) => {
            state.customContacts = state.customContacts?.filter(
                contact => contact.id !== action.payload
            )
        }
    }
})

export const { setCustomContactsAction, unsetCustomContactsAction, setCustomContactsRefetchNeeded,
    // selectAllContactAction,
    // unSelectAllContactAction,
    // toggleSelectContactAction,
    addCustomContactAction,
    updateCustomContactAction,
    deleteCustomContactAction,
} = customContactsSlice.actions


export const useGetCustomContacts = () => {
    const customContacts: (undefined | ICustomContact[]) = useSelector((state: IRootState) => state.customContactsReducer.customContacts)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.customContactsReducer.refetchNeeded)
    if (customContacts === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_CUSTOM_CONTACTS,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            store.dispatch(setCustomContactsAction(response.data.customContacts))
            store.dispatch(setCustomContactsRefetchNeeded(false))
            return response.data.customContacts
        })
    } else {
        return customContacts
    }
}

export default customContactsSlice.reducer