import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_DOCUMENT, GQL_MUTATION_UPDATE_NOTE } from '../../../graphql/Mutations';
import { IDocument, INote } from '../../../interfaces';
import { updateNoteAction } from '../../../redux/features/directories/directorySlice';
import { updateChildAction, updateDirectoryAction as updateMainDirectoryAction } from '../../../redux/features/directories/directorySlice';
import { addMessage } from '../../messages/Message';
import { DocumentIcon } from '../../svg_icons/DocumentIcon';
import { NoteIcon } from '../../svg_icons/NoteIcon';
import { ModalInsertionMarkdown } from './ModalInsertionMarkdown';
import { setAllRefetchNeeded } from '../../../redux/store';

interface IProps {
    open: boolean;
    handleClose: () => void;
    note: INote

}

export const ModalEditNote: FC<IProps> = (props) => {
    const [title, setTitle] = useState(props.note.title)
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [content, setContent] = useState(props.note.content || "")
    const titleRef = useRef<HTMLInputElement>(null);
    const [showModalInsertionMarkdown, setShowModalInsertionMarkdown] = useState(false)
    const [updateNote, { data: updateNoteData, loading: updateNoteLoading, error: updateNoteError }] = useMutation(GQL_MUTATION_UPDATE_NOTE)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateNoteError) {
            addMessage({
                location: "ModalEditNote",
                type: EnumMessageType.Error,
                message: updateNoteError.message,
            })
        } else if (updateNoteData) {
            if (updateNoteData.updateNote.statusCode === 200) {
                const updatedNote: INote = updateNoteData.updateNote.note
                // dispatch(updateNoteAction(updatedNote))
                setAllRefetchNeeded("ModalEditNote")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Note modifiée.",
                })
                handleClose()
            } else {
                updateNoteData.updateNote.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateNoteData, updateNoteError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateNote({
                variables: {
                    noteId: props.note.id,
                    title,
                    content,
                }
            })
        }
    }

    const cleanDatas = () => {
        setTitle(props.note.title)
        setContent(props.note.content)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const closeModalInsertionMarkdown = () => {
        setShowModalInsertionMarkdown(false)
    }

    const confirmModalInsertionMarkdown = (markdownToAdd: String) => {
        setContent(content + markdownToAdd)
        setShowModalInsertionMarkdown(false)
    }


    return (
        <div className="ModalEditNote">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <NoteIcon color={props.note.mode} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier la note
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Titre de la note"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                            sx={{mb: 2}}
                        />
                        <Box sx={{ display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                            <Button color='secondary' onClick={() => {setShowModalInsertionMarkdown(true)}}>Insertion</Button>
                        </Box>
                        <TextField
                            margin="normal"
                            label="Contenu"
                            fullWidth
                            multiline
                            rows={12}
                            onChange={(event) => { setContent(event.target.value) }}
                            value={content}
                            sx={{mt:0}}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
            <ModalInsertionMarkdown
                open={showModalInsertionMarkdown}
                handleClose={closeModalInsertionMarkdown}
                handleConfirm={confirmModalInsertionMarkdown}
            />
        </div >
    )
}
