
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const NoteIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 24 24' {...props}>
      <path fill="currentColor" d="M22.9482327,0.2794006c-0.090332-0.1826089-0.2758789-0.2983263-0.4838867-0.2768429
		c-0.2871094,0.0205069-7.1147461,0.5903051-13.890625,8.2603664c-5.5491943,6.2819405-5.1386719,9.8119297-4.7131348,11.0309906
		c-0.829895,1.1815262-1.729126,2.4618034-2.767334,3.9155941c-0.1606445,0.2245998-0.1083984,0.5370846,0.1162109,0.6972332
		C1.2973539,23.9697285,1.3989164,24,1.4995023,24c0.15625,0,0.3095703-0.0727501,0.4072266-0.2094631
		c1.004944-1.4064903,1.8781129-2.6492329,2.6859131-3.7991428c0.2780762-0.0464458,5.1893921-0.9050503,8.5645752-3.8811722
		c0.1123047-0.0986271,0.1743164-0.2426643,0.1689453-0.3915834c-0.0048828-0.1494064-0.0761719-0.2885609-0.1948242-0.3798647
		c-0.0634766-0.0488262-0.1303711-0.1039991-0.1953125-0.1640558c0.355957,0.0185547,0.7119141-0.0029287,1.0595703-0.0585909
		c0.1181641-0.019042,0.2260742-0.079586,0.3032227-0.1713791c1.1254883-1.3339233,2.6142578-5.093029,2.6835938-5.2697792
		c0.8574219-2.4329944,2.784668-5.3254409,5.8920898-8.8428593C23.0092678,0.679773,23.0380764,0.4615212,22.9482327,0.2794006z
		 M10.1655006,8.010498l0.7333984,1.8331299c-0.4373169,0.534729-0.848877,1.046814-1.2425537,1.5443726L8.9724464,9.3366089
		c0.1186523-0.1383667,0.2253418-0.270752,0.3503418-0.4122925C9.60355,8.6065063,9.8846169,8.3034668,10.1655006,8.010498z
		 M11.6543312,10.5h3.9009399c-0.1928101,0.4455566-0.428894,0.9724731-0.6830444,1.5h-4.4245605
		C10.8303566,11.5164185,11.23032,11.0186768,11.6543312,10.5z M8.2201881,10.2410278l0.6970215,2.0910034
		c-1.5322881,1.9795532-2.8145757,3.7748413-4.2646489,5.835144C4.5542941,16.8546753,4.9945407,14.2768555,8.2201881,10.2410278z
		 M13.650425,14.1538086c-0.4858398,0.0556641-0.9902344,0.0229492-1.4956055-0.0961914
		c-0.1552734-0.0371094-0.3149414,0.0024414-0.4360352,0.1035156c-0.1206055,0.1010742-0.1865234,0.253418-0.1777344,0.4106445
		c0.0219727,0.4013672,0.1987305,0.7851562,0.5268555,1.1464844c-2.2479248,1.7857666-5.1192021,2.6836548-6.6231084,3.06073
		C6.8827243,16.7357788,8.1534157,14.9561157,9.6654396,13h4.6975708
		C14.1203957,13.4483643,13.8765602,13.8552856,13.650425,14.1538086z M16.0454445,9.324707
		C16.0436134,9.3293457,16.0183449,9.3930664,15.9750099,9.5h-3.4953003
		c0.7441406-0.8931274,1.5588989-1.8499146,2.4714966-2.8994141c0.1811523-0.2084961,0.1591797-0.5244141-0.0493164-0.7055664
		c-0.2084961-0.1801758-0.5229492-0.159668-0.7055664,0.0493164c-0.9532471,1.0963745-1.8006592,2.0924683-2.5721436,3.0200806
		l-0.6893921-1.7235718c4.2017212-4.0687866,8.239563-5.5060425,10.2605591-5.9962158
		C18.5405617,4.3852539,16.850132,7.0415039,16.0454445,9.324707z"/>
	<circle fill="currentColor" cx="16.0000343" cy="5" r="0.5"/>
	<circle fill="currentColor" cx="17.5000343" cy="4" r="0.5"/>
    </SvgIcon>
  )
}

