import "../css/global.css"
import { Container } from '@mui/material'
import { FC, ReactElement } from 'react'
import { Footer } from '../components/Footer'
import { Messages } from "../components/messages/Messages"
import PublikResponsiveAppBar from "../components/PublikResponsiveAppBar"
// import { Messages } from '../components/messages/Messages'


interface IProps {
  children: ReactElement | ReactElement[],
}

export const PublikLayout: FC<IProps> = (props) => {
  return (
    <div className="Layout">
      <PublikResponsiveAppBar />
      <Messages />
      <Container maxWidth="xl" sx={{
        minHeight: "500px",
        mt: 1.5,
      }}>
        {props.children}
      </Container>
      <Footer />
    </div>
  )
}
