import { Box, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { EnumLockerIconMode, EnumNewsInfoIcon } from '../enums';
import { Flexbox } from './utils/Flexbox';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { LockerIcon } from './svg_icons/LockerIcon';
import { DocumentIcon } from './svg_icons/DocumentIcon';
import CakeIcon from '@mui/icons-material/Cake';

interface IProps {
    icon: EnumNewsInfoIcon,
    title: string,
    handleAction?: () => void;
    children?: ReactNode,
}

export const NewsInfo: FC<IProps> = (props) => {
    const theme = useTheme()
    const handleSee = () => {
        if (props.handleAction) {
            props.handleAction()
        }
    }
    return (
        <Paper sx={{ pt: 2, pb: 1, px: 2, mx: 1, my: 1, width: "330px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                <Flexbox sx={{
                    // bgcolor: "yellow",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                }}>
                    {
                        props.icon === EnumNewsInfoIcon.partnership &&
                        <HandshakeOutlinedIcon sx={{ mr: 1, fontSize: 22, color: theme.palette.primary.main }} />
                    }
                    {
                        props.icon === EnumNewsInfoIcon.delivery &&
                        <LockerIcon mode={EnumLockerIconMode.delivery} sx={{ mr: 1, fontSize: 22, color: theme.palette.primary.main }} />
                    }
                    {
                        props.icon === EnumNewsInfoIcon.document &&
                        <DocumentIcon sx={{ mr: 1, fontSize: 22, color: theme.palette.desktop.main }} />
                    }
                    {
                        props.icon === EnumNewsInfoIcon.birthday &&
                        <CakeIcon sx={{ mr: 1, fontSize: 22, color: theme.palette.desktop.main }} />
                    }
                    <Typography color="primary">
                        {props.title}
                    </Typography>
                </Flexbox>
                {
                    props.children !== undefined && <Flexbox sx={{
                        mt: 1,
                        ml: 4,
                    }}>
                        {props.children}
                    </Flexbox>
                }
                {
                    props.handleAction &&
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Voir">
                            <IconButton onClick={handleSee} color="secondary" size="small"
                                sx={{ ml: 2 }}
                            >
                                <VisibilityOutlinedIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
        </Paper>
    )
}
