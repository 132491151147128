import { configureStore } from "@reduxjs/toolkit"
import messagesReducer from "./features/global/messagesSlice"
import apolloReducer from "./features/global/apolloSlice"
import currentUserReducer, { setCurrentUserRefetchNeeded } from "./features/users/currentUserSlice"
import lockersReducer, { setLockersRefetchNeeded } from "./features/lockers/lockersSlice";
import lockerReducer, { setLockerRefetchNeeded } from "./features/lockers/lockerSlice";
import pocketReducer, { setPocketRefetchNeeded } from "./features/pocket/pocketSlice";
import directoryReducer, { setDirectoryRefetchNeeded } from "./features/directories/directorySlice";
import filterReducer from "./features/filter/filterSlice";
import searchReducer from "./features/search/searchSlice";
import adminReducer, { setAdminRefetchNeeded } from "./features/admin/adminSlice";
import { EnumSliceReference } from "../enums";
import passwordsReducer, { setPasswordsRefetchNeeded } from "./features/passwords/passwordsSlice";
import albumsReducer, { setAlbumsRefetchNeeded } from "./features/albums/albumsSlice";
import albumReducer, { setAlbumRefetchNeeded } from "./features/albums/albumSlice";
import contactsReducer, { setContactsRefetchNeeded } from "./features/contacts/contactsSlice";
import modalsReducer from "./features/global/modalsSlice"
import bankAccountsReducer, { setBankAccountsRefetchNeeded } from "./features/cashflow/bankAccountsSlice";
import bankAccountReducer, { setBankAccountRefetchNeeded } from "./features/cashflow/bankAccountSlice";
import customContactsReducer, { setCustomContactsRefetchNeeded } from "./features/customContacts/customContactsSlice";
import userReducer, { setUserRefetchNeeded } from "./features/admin/userSlice";



export const store = configureStore({
    reducer: {
        messagesReducer,
        apolloReducer,
        currentUserReducer,
        lockersReducer,
        lockerReducer,
        pocketReducer,
        directoryReducer,
        filterReducer,
        searchReducer,
        adminReducer,
        passwordsReducer,
        albumsReducer,
        albumReducer,
        contactsReducer,
        modalsReducer,
        bankAccountsReducer,
        bankAccountReducer,
        customContactsReducer,
        userReducer,
    }
})

export type IRootState = ReturnType<typeof store.getState>

export const setAllRefetchNeeded = (from: string, except: EnumSliceReference[] = []) => {
    if (!except.includes(EnumSliceReference.lockers)) {
        store.dispatch(setLockersRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.locker)) {
        store.dispatch(setLockerRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.directory)) {
        store.dispatch(setDirectoryRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.pocket)) {
        store.dispatch(setPocketRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.currentUser)) {
        store.dispatch(setCurrentUserRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.admin)) {
        store.dispatch(setAdminRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.passwords)) {
        store.dispatch(setPasswordsRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.contacts)) {
        store.dispatch(setContactsRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.albums)) {
        store.dispatch(setAlbumsRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.album)) {
        store.dispatch(setAlbumRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.bankAccounts)) {
        store.dispatch(setBankAccountsRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.bankAccount)) {
        store.dispatch(setBankAccountRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.customContacts)) {
        store.dispatch(setCustomContactsRefetchNeeded(true))
    }
    if (!except.includes(EnumSliceReference.user)) {
        store.dispatch(setUserRefetchNeeded(true))
    }
}
