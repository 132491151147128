import { FC } from 'react';
import { Layout } from "./Layout";
import { ListContacts } from '../components/contacts/ListContacts';

interface IProps {
}

export const ContactsPage: FC<IProps> = (props) => {
  return (
    <div className="ContactsPage">
      <Layout>
        <div className="page-body">
          <ListContacts />
        </div>
      </Layout>
    </div >
  )
}
