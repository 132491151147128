import { FC, useState } from 'react';
import { Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { EnumDayjsFormat, EnumListLockersMode } from "../../enums";
import { Spinner } from '../spinner/Spinner';
import { apolloClient } from '../../ApolloClient';
import { useDispatch } from 'react-redux';
import { pluralize } from '../../utils/Utils';
import { DatabaseIcon } from '../svg_icons/DatabaseIcon';
import { Flexbox } from '../utils/Flexbox';
import { IAlbum } from '../../interfaces';
import { CameraIcon } from '../svg_icons/CameraIcon';
import { GQL_QUERY_GET_SIZE_INFOS } from '../../graphql/Queries';
import { setSizeInfosAlbumAction } from '../../redux/features/albums/albumSlice';
import { ShareOutlined } from '@mui/icons-material';
import { ModalAlbumPublik } from './modals/ModalAlbumPublik';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';


interface IProps {
    album: IAlbum,
    detailsMode: boolean,
}

export const SubAlbumInfos: FC<IProps> = (props) => {
    const [showModalAlbumPublik, setShowModalAlbumPublik] = useState(false)

    const theme = useTheme()
    const dispatch = useDispatch()

    const determinePublikColor = () => {
        return props.album.publik ? EnumListLockersMode.shared : EnumListLockersMode.desktop
    }

    const handleGetSizeInfos = () => {
        var directoryIds: string[] = []
        apolloClient.query({
            query: GQL_QUERY_GET_SIZE_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                albumIds: [props.album.id],
            }
        }
        ).then(response => {
            const payloadObject = response.data.getSizeInfos
            dispatch(setSizeInfosAlbumAction(payloadObject))
            // dispatch(expandAllAlbumsAction())
        })
    }

    const handleAlbumPublik = () => {
        setShowModalAlbumPublik(true)
    }

    const closeModalAlbumPublik = () => {
        setShowModalAlbumPublik(false)
    }

    return (
        <Flexbox sx={{ justifyContent: "flex-start" }}>
            {
                !props.detailsMode &&
                <>
                    {
                        props.album.albumItemsCount === undefined
                            ?
                            <Spinner size={14} />
                            :
                            props.album.albumItemsCount > 0 &&
                            <>
                                <CameraIcon color={determinePublikColor()} sx={{ fontSize: 18, mr: .5 }} />
                                <Typography sx={{ mr: 1 }}>
                                    {props.album.albumItemsCount}
                                </Typography>
                            </>
                    }
                </>
            }

            {
                props.detailsMode &&
                <Flexbox direction='column' sx={{ alignItems: "flex-start" }}>
                    <Flexbox sx={{
                        mb: 1,
                    }}>
                        <EventOutlinedIcon color={EnumListLockersMode.desktop} sx={{ fontSize: 24, mr: 1 }} />
                        <Typography>
                            {dayjs(props.album.dateAlbum).locale('fr').format(EnumDayjsFormat.date)}
                        </Typography>
                    </Flexbox>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", mb: 1 }} >
                        <CameraIcon color={determinePublikColor()} sx={{ fontSize: 24, mr: 1 }} />
                        {

                            props.album.albumItemsCount !== undefined
                                ?
                                <Typography>
                                    {`${props.album.albumItemsCount} ${pluralize("photo", props.album.albumItemsCount)}`}
                                </Typography>
                                :
                                <Spinner size={14} />
                        }
                    </Box>
                    <Flexbox sx={{ justifyContent: "flex-start", ml: -1 }} >
                        <IconButton color={EnumListLockersMode.desktop} onClick={handleGetSizeInfos}>
                            <DatabaseIcon sx={{ fontSize: 24 }} />
                        </IconButton>
                        {
                            props.album.sizeInfos === undefined
                                ?
                                <Typography>
                                    Taille non calculée.
                                </Typography>
                                :
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.album.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                    <Typography>
                                        {props.album.sizeInfos.humanSize}
                                    </Typography>
                                </Tooltip>
                        }
                    </Flexbox>
                    {
                        props.album &&
                        <Flexbox sx={{ justifyContent: "flex-start", ml: -1 }} >
                            {

                                props.album.publik &&
                                <>
                                    <IconButton onClick={handleAlbumPublik} color="shared">
                                        <ShareOutlined />
                                    </IconButton>
                                    <Typography>
                                        Lien public
                                    </Typography>
                                </>
                            }
                            <ModalAlbumPublik
                                open={showModalAlbumPublik}
                                handleClose={closeModalAlbumPublik}
                                album={props.album}
                            />

                        </Flexbox>
                    }
                </Flexbox>
            }
        </Flexbox>
    )
}
