import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { IAlbum, IDirectory, IFotoToUpload } from '../../../interfaces';
import { Add } from '@mui/icons-material';
import useFileUpload from "../../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { pluralize } from '../../../utils/Utils';
import { GQL_MUTATION_CREATE_DOCUMENTS, GQL_MUTATION_CREATE_FOTOS } from '../../../graphql/Mutations';
import { LinearProgressWithLabel } from '../../spinner/LinearProgressWithLabel';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';
import { CameraIcon } from '../../svg_icons/CameraIcon';
import { Flexbox } from '../../utils/Flexbox';

interface IProps {
    album: IAlbum,
    open: boolean;
    handleClose: () => void;
}

export const ModalNewFotos: FC<IProps> = (props) => {
    const [fotosToUpload, setFotosToUpload] = useState<IFotoToUpload[]>([])
    const [createFotos, { data: createFotosData, loading: createFotosLoading, error: createFotosError }] = useMutation(GQL_MUTATION_CREATE_FOTOS)

    useEffect(() => {
        let trigger_mutation = fotosToUpload.length === 0 ? false : true
        let fotoFiles: string[] = []
        fotosToUpload.forEach(fotoToUpload => {
            if (!fotoToUpload.fotoFile) {
                trigger_mutation = false
            } else {
                fotoFiles.push(fotoToUpload.fotoFile)
            }
        });
        if (trigger_mutation) {
            createFotos({
                variables: {
                    albumId: props.album.id,
                    fotoFiles: fotoFiles,
                }
            })
        }
    }, [fotosToUpload])

    useEffect(() => {
        if (createFotosError) {
            addMessage({
                location: "ModalNewFotos",
                type: EnumMessageType.Error,
                message: createFotosError.message,
            })
        } else if (createFotosData) {
            if (createFotosData.createFotos.statusCode === 200) {
                cleanDatas()
                setAllRefetchNeeded("ModalNewFotos")
                addMessage({
                    type: EnumMessageType.Success,
                    message: pluralize("Item créé.", createFotosData.createFotos.createdCount, `${createFotosData.createFotos.createdCount} items créés.`),
                })
                handleClose()
            } else {
                createFotosData.createFotos.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createFotosData, createFotosError])

    const cleanDatas = () => {
        setFotosToUpload([])
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const { handleUpload } = useFileUpload();
    const handlePercentage = (fileIndex: number, paramPercentage: number) => {
        setFotosToUpload((currentFotosToUpload) => {
            return currentFotosToUpload.map(currentFotoToUpload => {
                return currentFotoToUpload.index === fileIndex ?
                    { ...currentFotoToUpload, percentage: paramPercentage } :
                    currentFotoToUpload
            }
            )
        })
    }

    const handleFileChange = (fotos: FileList | null, handleCallback: Function) => {
        if (fotos && fotos.length > 0) {
            let newFotosToUpload: IFotoToUpload[] = []
            for (let i = 0; i < fotos.length; i++) {
                // console.log("file: ", fotos[i]);

                newFotosToUpload.push({
                    index: i,
                    percentage: 0,
                    filename: fotos[i].name,
                })
            }
            setFotosToUpload(newFotosToUpload)
            handleUpload(fotos, handlePercentage, handleUploadDone);
        }
    }

    const handleUploadDone = (fileIndex: number, fileMetaData: any) => {
        setFotosToUpload(currentFotosToUpload => currentFotosToUpload.map(currentFotoToUpload => {
            return currentFotoToUpload.index === fileIndex ?
                { ...currentFotoToUpload, fotoFile: JSON.stringify(fileMetaData) } :
                currentFotoToUpload
        }))
    }

    return (
        <div className="ModalNewFotos">
            <FloatingSpinner dependances={[createFotosLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <CameraIcon color="info" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Nouvelles photos, videos et musiques
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Flexbox>
                            <Typography sx={{
                                mb: 2,
                            }}>
                                Ajouter des photos, videos et musiques (au format mp3)
                            </Typography>
                        </Flexbox>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                Vous pouvez sélectionner plusieurs fichiers
                            </Typography>
                            <IconButton color="primary" component="label">
                                <input hidden type="file" multiple onChange={(event) => handleFileChange(event.target.files, handlePercentage)} />
                                <Add fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider flexItem></Divider>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column ", justifyContent: "flex-start", alignItems: "flex-start", my: 2 }}>
                            {
                                fotosToUpload.length > 0
                                &&
                                fotosToUpload.map(fotoToUpload => (
                                    <Box key={fotoToUpload.index} sx={{ width: "100%" }}>
                                        <Typography>
                                            {fotoToUpload.filename}
                                        </Typography>
                                        <LinearProgressWithLabel value={fotoToUpload.percentage} color="success" />
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
