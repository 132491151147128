import {
    FormControl, IconButton, Input, InputAdornment, InputLabel, Switch, Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { EnumContactType, EnumMandatoryContactConditions, EnumMandatoryContactFields, EnumSize } from '../../enums';
import { useGetContacts } from '../../redux/features/contacts/contactsSlice';
import { IContact } from '../../interfaces';
import { filterMatch } from '../../utils/Utils';
import { Flexbox } from '../utils/Flexbox';
import { Spinner } from '../spinner/Spinner';
import { ContactBadge } from './ContactBadge';

interface IProps {
    handleSelect: (selectedContact: IContact) => void;
    mandatoryContactFields?: EnumMandatoryContactFields[],
    mandatoryContactConditions?: EnumMandatoryContactConditions[],
    heightSize?: EnumSize,
}

export const ContactSelection: FC<IProps> = (props) => {
    const contacts = useGetContacts()
    const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [filter, setFilter] = useState("")
    const [filteredContacts, setFilteredContacts] = useState<IContact[] | undefined>([])

    const theme = useTheme()

    useEffect(() => {
        if (contacts) {
            // Attention, on doit passer par une variable temporaire, sinon les filtered restent vides
            let tempFilteredContacts = [...contacts]
            if (props.mandatoryContactFields && props.mandatoryContactFields.includes(EnumMandatoryContactFields.email)) {
                tempFilteredContacts = tempFilteredContacts.filter(tempFilteredContact => (tempFilteredContact.email !== null && tempFilteredContact.email !== undefined && tempFilteredContact.email !== ""))
            }

            if (props.mandatoryContactConditions && props.mandatoryContactConditions.includes(EnumMandatoryContactConditions.isBusiness)) {
                tempFilteredContacts = tempFilteredContacts.filter(tempFilteredContact => (tempFilteredContact.contactType === EnumContactType.business))
            }

            if (filter.length !== 0) {
                tempFilteredContacts = tempFilteredContacts.filter(tempFilteredContact => (
                    filterMatch(tempFilteredContact.firstname, filter) ||
                    filterMatch(tempFilteredContact.lastname, filter) ||
                    filterMatch(tempFilteredContact.enterpriseName, filter)
                ))
            }
            setFilteredContacts([...tempFilteredContacts])
        }
    }, [contacts, filter])

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    const getHeightSize = () => {
        const defaultHeight = '300px'
        if (props.heightSize) {
            switch (props.heightSize) {
                case EnumSize.tiny:
                    return '100px'
                case EnumSize.small:
                    return '200px'
                case EnumSize.medium:
                    return defaultHeight
                case EnumSize.large:
                    return '600px'
                case EnumSize.xlarge:
                    return '900px'
                default:
                    return defaultHeight
            }
        } else {
            return defaultHeight
        }
    }

    return (
        <Flexbox direction='column' sx={{
            // bgcolor: "green",
            width: "100%",
        }}>
            <Flexbox sx={{
                // bgcolor: "yellow",
                width: "100%",
                justifyContent: "flex-end",
            }}>
                <FormControl sx={{
                    pb: 2,
                    my: 0,
                    mr: 1,
                    width: "200px",
                }} variant="standard">
                    <InputLabel >Filtrer...</InputLabel>
                    <Input
                        type='text'
                        onChange={(event) => { handleFilterInput(event.target.value) }}
                        value={filterInput}
                        endAdornment={
                            filterInput.length > 0 && <InputAdornment position="end">
                                <IconButton
                                    onClick={() => { handleFilterInput("") }}
                                >
                                    <ClearIcon fontSize='small' />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Flexbox>
            {
                contacts === undefined
                    ?
                    (
                        <Spinner classes="big-spinner" />
                    )
                    :
                    (
                        <Flexbox direction='column' sx={{
                            // height: '300px', // Forcer la hauteur du contenant
                            height: getHeightSize(), // Forcer la hauteur du contenant
                            overflowY: 'auto', // Ajouter l'ascenseur vertical si le contenu dépasse
                            width: "100%",
                        }}>
                            <Grid container sx={{
                                width: "100%",
                                // bgcolor: "red",
                            }}>
                                {

                                    filteredContacts?.map((filteredContact, filteredContactIndex) => {
                                        return (
                                            <Grid
                                                key={`filteredContactRelation-${filteredContact.id}`}
                                                sx={{

                                                    borderLeftStyle: {
                                                        xs: "none",
                                                        md: filteredContactIndex % 2 === 0 ? "inherit" : "solid",
                                                    },
                                                    borderLeftWidth: 1,
                                                    borderLeftColor: theme.palette.cancel.main,
                                                    pl: 2,
                                                }}
                                                size={{ xs: 12, md: 6 }}
                                            >
                                                <Flexbox sx={{
                                                    justifyContent: "space-between",
                                                    py: 1,
                                                }}>

                                                    <ContactBadge onClick={props.handleSelect} contact={filteredContact} size={EnumSize.small} displayContactTitle={true} />
                                                    {/* <Switch
                                                        checked={isContactRelation(filteredContact)}
                                                        onChange={() => handleSetContactRelation(filteredContact)}
                                                        color="desktop"
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    /> */}
                                                </Flexbox>
                                            </Grid>
                                        )
                                    })
                                }

                            </Grid>

                        </Flexbox>
                    )
            }
        </Flexbox>
    )
}
