import "../../css/components/lockers/ListLockers.css"
import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { Locker } from './Locker';
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { ModalNewLocker } from "./modals/ModalNewLocker";
import { expandAllLockersAction, expandSelectedLockersAction, removeLockersAction, selectAllLockerAction, setSizeInfosAction, unExpandSelectedLockersAction, unSelectAllLockerAction, useGetLockers } from "../../redux/features/lockers/lockersSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { addMessage } from "../messages/Message";
import { EnumListLockersMode, EnumMessageType } from "../../enums";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_DELETE_LOCKERS } from "../../graphql/Mutations";
import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IRootState, setAllRefetchNeeded } from "../../redux/store";
import { pluralize } from "../../utils/Utils";
import { Filter } from "../Filter";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { apolloClient } from "../../ApolloClient";
import { GQL_QUERY_GET_SIZE_INFOS } from "../../graphql/Queries";
import { FloatingSpinner } from "../spinner/FloatingSpinner";


interface IProps {
    mode: EnumListLockersMode,
}

export const ListLockers: FC<IProps> = (props) => {
    const lockers = useGetLockers(props.mode)
    const [showModalNewLocker, setShowModalNewLocker] = useState(false)
    const [selectAllLockers, setSelectAllLockers] = useState(true)
    const [hasSelectedLocker, setHasSelectedLocker] = useState(false)
    const [showDeleteLockersDialog, setShowDeleteLockersDialog] = useState(false)
    const [expandSelectedLockers, setExpandSelectedLockers] = useState(true)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const [deleteLockers, { data: deleteLockersData, loading: deleteLockersLoading, error: deleteLockersError }] = useMutation(GQL_MUTATION_DELETE_LOCKERS)
    const dispatch = useDispatch()

    const theme = useTheme()

    const closeModalNewLocker = () => {
        setShowModalNewLocker(false)
    }

    const handleSelectAll = () => {
        selectAllLockers ? dispatch(selectAllLockerAction()) : dispatch(unSelectAllLockerAction())
        setSelectAllLockers(!selectAllLockers)
    }

    const handleDeleteSelection = () => {
        if (getSelectedLockerIds().length > 0) {
            setShowDeleteLockersDialog(true)
        }
    }

    const closeDeleteLockersDialog = () => {
        setShowDeleteLockersDialog(false)
    }

    const confirmedDeleteLockers = () => {
        deleteLockers({
            variables: {
                lockerIds: getSelectedLockerIds()
            }
        })
        setShowDeleteLockersDialog(false)
    }


    const getSelectedLockerIds = () => {
        if (lockers === undefined) {
            return []
        } else {
            return lockers.filter(locker => locker.selected).map(locker => locker.id)
        }
    }

    const handleExpandSelectedLockers = () => {
        if (getSelectedLockerIds().length > 0) {
            expandSelectedLockers ? dispatch(expandSelectedLockersAction()) : dispatch(unExpandSelectedLockersAction())
            setExpandSelectedLockers(!expandSelectedLockers)
        }
    }

    useEffect(() => {
        if (deleteLockersError) {
            addMessage({
                location: "ListLockers",
                type: EnumMessageType.Error,
                message: deleteLockersError.message,
            })
        } else if (deleteLockersData) {
            if (deleteLockersData.deleteLockers.statusCode === 200) {
                const selectedLockerIds = getSelectedLockerIds()
                // dispatch(removeLockersAction(selectedLockerIds))
                setAllRefetchNeeded("ListLockers")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${selectedLockerIds.length} ${pluralize("casier supprimé", selectedLockerIds.length, "casiers supprimés")}.`,
                })
            } else {
                deleteLockersData.deleteLockers.errors.map((error: string) => {
                    addMessage({
                        location: "ListLockers",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteLockersData, deleteLockersError])

    useEffect(() => {
        // console.log("detecting hasSelectedLocker..");
        if (getSelectedLockerIds().length > 0) {
            setHasSelectedLocker(true)
        } else {
            setHasSelectedLocker(false)
        }
    }, [lockers])

    const handleGetSizeInfosSelection = () => {
        apolloClient.query({
            query: GQL_QUERY_GET_SIZE_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                lockerIds: getSelectedLockerIds(),
            }
        }
        ).then(response => {
            const payloadObject = response.data.getSizeInfos.lockers
            dispatch(setSizeInfosAction(payloadObject))
            dispatch(expandAllLockersAction())
            setExpandSelectedLockers(false)
        })
    }


    return (
        <div className='ListLockers'>
            <FloatingSpinner dependances={[deleteLockersLoading]} />
            <Container className="ListLockers-actions">
                <Filter />
                <Divider orientation="vertical" flexItem variant="middle" />
                {
                    hasSelectedLocker && props.mode !== EnumListLockersMode.shared && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les casiers sélectionnés">
                        <IconButton onClick={handleDeleteSelection} color="error">
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedLocker &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Calculer les tailles sélectionnnés">
                        <IconButton onClick={handleGetSizeInfosSelection} color="primary">
                            <DatabaseIcon />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedLocker &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={expandSelectedLockers ? "Déplier les casiers sélectionnnés" : "Plier les casiers sélectionnnés"}>
                        <ExpandMore expand={!expandSelectedLockers} onClick={handleExpandSelectedLockers} aria-expanded={!expandSelectedLockers} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Tooltip>
                }
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllLockers ? "Sélectionner tout" : "Désélectionner tout"}>
                    <IconButton onClick={handleSelectAll} color={selectAllLockers ? "secondary" : "cancel"}>
                        <CropFreeOutlined />
                    </IconButton>
                </Tooltip>
                {
                    props.mode !== EnumListLockersMode.shared &&
                    <>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un casier">
                            <IconButton color="primary" onClick={() => setShowModalNewLocker(true)}><Add fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewLocker
                            open={showModalNewLocker}
                            handleClose={closeModalNewLocker}
                            vault={props.mode === EnumListLockersMode.vault}
                        />
                    </>
                }

            </Container>
            <Container className="ListLockers-container">
                <Grid container spacing={0} className="ListLockers-grid-container">
                    {
                        lockers === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            lockers.map((locker) => {
                                return (
                                    <Grid key={`${locker.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Locker locker={locker} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        lockers !== undefined && lockers.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun casier.
                            </Typography>
                            {
                                filterQuery &&
                                <Typography sx={{ fontSize: 24, color: theme.palette.warning.main }}>
                                    Un filtre est actif.
                                </Typography>
                            }
                        </Box>
                    }
                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteLockersDialog}
                title="Suppression des casiers"
                content={`Confirmez-vous la suppression définitive de ${pluralize("ce casier", getSelectedLockerIds().length, `ces ${getSelectedLockerIds().length} casiers`)} ?`}
                handleClose={closeDeleteLockersDialog}
                handleConfirm={confirmedDeleteLockers}
                danger={true}
            />
        </div>
    )
}
