import { createSlice } from "@reduxjs/toolkit";
import { ICurrentUser, IUser } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_WHO_AM_I } from "../../../graphql/Queries";




interface ICurrentUserSliceState {
    user?: ICurrentUser
    refetchNeeded: boolean
}

const initialState: ICurrentUserSliceState = {
    refetchNeeded: false
}

export const currentUserSlice = createSlice({
    name: "currentUserSlice",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.user = action.payload
        },
        unsetCurrentUser: (state) => {
            state = initialState
        },
        setCurrentUserRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        setCurrentUserAvatarUrl: (state, action) => {
            if (state.user) {
                state.user.userAvatarUrl = action.payload
            }
        },
        setCurrentUserAvatarPreviewUrl: (state, action) => {
            if (state.user) {
                state.user.userAvatarPreviewUrl = action.payload
            }
        },
    }
})

export const { setCurrentUser, unsetCurrentUser, setCurrentUserRefetchNeeded,
    setCurrentUserAvatarUrl, setCurrentUserAvatarPreviewUrl,
} = currentUserSlice.actions

export const useGetCurrentUser = () => {
    const currentUser: (undefined | ICurrentUser) = useSelector((state: IRootState) => state.currentUserReducer.user)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.currentUserReducer.refetchNeeded)
    if (currentUser === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_WHO_AM_I,
            fetchPolicy: 'network-only',
        }
        ).then(response => {
            store.dispatch(setCurrentUser(response.data.whoami))
            store.dispatch(setCurrentUserRefetchNeeded(false))
            return response.data.whoami
        })
    } else {
        return currentUser
    }
}

export const useGetPartners = () => {
    let res: IUser[] = []
    const currentUser = useGetCurrentUser()
    if (currentUser?.partnerships && currentUser?.partnerships?.length > 0) {
        currentUser.partnerships.forEach(partnership => {
            if (partnership.confirmed) {
                res.push(partnership.partner)
            }
        })
    }
    return res
}


export default currentUserSlice.reducer