import { createSlice } from "@reduxjs/toolkit";
import { IDirectory, IDocument, INote, ISheet } from "../../../interfaces";
import { sortByName, sortByTitle } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_DIRECTORY } from "../../../graphql/Queries";


interface IDirectorySliceState {
    directory: undefined | IDirectory;
    refetchNeeded: boolean
}

const initialState: IDirectorySliceState = {
    directory: undefined,
    refetchNeeded: false,
}

export const directorySlice = createSlice({
    name: "directorySlice",
    initialState,
    reducers: {
        setDirectoryAction: (state, action) => {
            state.directory = { ...action.payload, children: sortByName(action.payload.children), documents: sortByTitle(action.payload.documents) }
        },
        updateDirectoryAction: (state, action) => {
            // on vérifie les ID car la modif peut venir d'une page locker et modifier le mainDirectory alors que celui-ci pointe sur un autre.
            if (state.directory && state.directory.id === action.payload.id) {
                state.directory = { ...state.directory, ...action.payload }
            }
        },
        addChildAction: (state, action) => {
            const newChild: IDirectory = action.payload
            if (state.directory) {
                if (state.directory.children) {
                    state.directory.children = sortByName([...state.directory.children, newChild])
                } else {
                    state.directory.children = [newChild]
                }
            }
        },
        addDocumentAction: (state, action) => {
            const newDocument: IDocument = action.payload
            if (state.directory) {
                if (state.directory.documents) {
                    state.directory.documents = sortByTitle([...state.directory.documents, newDocument])
                } else {
                    state.directory.documents = [newDocument]
                }
            }
        },
        updateChildAction: (state, action) => {
            const updatedChild: IDirectory = action.payload
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return directory.id === updatedChild.id ? updatedChild : directory
                    }
                )
            }
        },
        updateDocumentAction: (state, action) => {
            const updatedDocument: IDocument = action.payload
            if (state.directory) {
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return document.id === updatedDocument.id ? updatedDocument : document
                    }
                )
            }
        },
        removeChildAction: (state, action) => {
            const subDirectoryId = action.payload
            if (state.directory) {
                state.directory.children = state.directory.children?.filter(directory => directory.id !== subDirectoryId)
            }
        },
        removeDocumentAction: (state, action) => {
            const documentId = action.payload
            if (state.directory) {
                state.directory.documents = state.directory.documents?.filter(document => document.id !== documentId)
            }
        },
        toggleSelectChildAction: (state, action) => {
            const subDirectoryId = action.payload
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return directory.id === subDirectoryId ? { ...directory, selected: !directory.selected } : directory
                    }
                )
            }
        },
        toggleSelectDocumentAction: (state, action) => {
            const documentId = action.payload
            if (state.directory) {
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return document.id === documentId ? { ...document, selected: !document.selected } : document
                    }
                )
            }
        },
        selectAllChildrenAction: (state) => {
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return { ...directory, selected: true }
                    }
                )
                // On sélectionne aussi les documents:
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return { ...document, selected: true }
                    }
                )
                // On sélectionne aussi les notes:
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return { ...note, selected: true }
                    }
                )
            }
        },
        unSelectAllChildrenAction: (state) => {
            if (state.directory) {
                state.directory.children = state.directory?.children?.map(
                    directory => {
                        return { ...directory, selected: false }
                    }
                )
                // On désélectionne aussi les documents:
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return { ...document, selected: false }
                    }
                )
                // On désélectionne aussi les notes:
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return { ...note, selected: false }
                    }
                )
            }
        },
        toggleExpandChildAction: (state, action) => {
            const subdirectoryId = action.payload
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return directory.id === subdirectoryId ? { ...directory, expanded: !directory.expanded } : directory
                    }
                )

            }
        },
        toggleExpandDocumentAction: (state, action) => {
            const documentId = action.payload
            if (state.directory) {
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return document.id === documentId ? { ...document, expanded: !document.expanded } : document
                    }
                )

            }
        },
        expandSelectedChildrenAction: (state) => {
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return directory.selected ? { ...directory, expanded: true } : directory
                    }
                )
                // Pareil pour les documents : 
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return document.selected ? { ...document, expanded: true } : document
                    }
                )
                // Pareil pour les notes : 
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return note.selected ? { ...note, expanded: true } : note
                    }
                )
            }
        },
        unExpandSelectedChildrenAction: (state) => {
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return directory.selected ? { ...directory, expanded: false } : directory
                    }
                )
                // Pareil pour les documents : 
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return document.selected ? { ...document, expanded: false } : document
                    }
                )
                // Pareil pour les notes : 
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return note.selected ? { ...note, expanded: false } : note
                    }
                )
            }
        },
        expandAllChildrenAction: (state) => {
            if (state.directory) {
                state.directory.children = state.directory.children?.map(
                    directory => {
                        return { ...directory, expanded: true }
                    }
                )
                // Pareil pour les documents : 
                state.directory.documents = state.directory.documents?.map(
                    document => {
                        return { ...document, expanded: true }
                    }
                )
                // Pareil pour les notes : 
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return { ...note, expanded: true }
                    }
                )
            }
        },
        removeChildrenAction: (state, action) => {
            const subDirectoryIds = action.payload
            if (state.directory) {
                state.directory.children = state.directory.children?.filter(directory => !subDirectoryIds.includes(directory.id))
            }
        },
        removeDocumentsAction: (state, action) => {
            const documentIds = action.payload
            if (state.directory) {
                state.directory.documents = state.directory.documents?.filter(document => !documentIds.includes(document.id))
            }
        },
        removeDirectoryAction: (state) => {
            state.directory = undefined
        },
        setDirectoryRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        updateInfosDirectoryAction: (state, action) => {
            const directoryId = action.payload.directoryId
            const newInfos = action.payload.newInfos
            if (state.directory) {
                if (directoryId === state.directory.id) {
                    state.directory = { ...state.directory, ...newInfos }
                } else {
                    // C'est peut-être un child:
                    state.directory.children = state.directory.children?.map(directory => {
                        return directory.id === directoryId ? { ...directory, ...newInfos } : directory
                    })
                }
            }
        },
        addSheetAction: (state, action) => {
            const documentId: string = action.payload.documentId
            const newSheet: ISheet = action.payload.newSheet
            if (state.directory) {
                if (state.directory.documents) {
                    state.directory.documents = state.directory.documents.map(document => {
                        return document.id === documentId
                            ?
                            {
                                ...document,
                                sheets: [
                                    newSheet, ...document.sheets?.map(sheet => {
                                        return { ...sheet, applicable: false }
                                    }) as ISheet[]
                                ]
                            }
                            :
                            document
                    })
                }
            }
        },
        setSheetApplicableAction: (state, action) => {
            const documentId: string = action.payload.documentId
            const sheetId: string = action.payload.sheetId
            if (state.directory) {
                if (state.directory.documents) {
                    state.directory.documents = state.directory.documents.map(document => {
                        return document.id === documentId
                            ?
                            {
                                ...document,
                                sheets: [
                                    ...document.sheets?.map(sheet => {
                                        return sheet.id === sheetId ? { ...sheet, applicable: true } : { ...sheet, applicable: false }
                                    }) as ISheet[]
                                ]
                            }
                            :
                            document
                    })
                }
            }
        },
        removeSheetAction: (state, action) => {
            const documentId: string = action.payload.documentId
            const sheetId: string = action.payload.sheetId
            if (state.directory) {
                if (state.directory.documents) {
                    state.directory.documents = state.directory.documents.map(document => {
                        return document.id === documentId
                            ?
                            {
                                ...document,
                                sheets: document.sheets?.filter(sheet => sheet.id !== sheetId)
                            }
                            :
                            document
                    })
                }
            }
        },
        toggleSelectNoteAction: (state, action) => {
            const noteId = action.payload
            if (state.directory) {
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return note.id === noteId ? { ...note, selected: !note.selected } : note
                    }
                )
            }
        },
        toggleExpandNoteAction: (state, action) => {
            const noteId = action.payload
            if (state.directory) {
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return note.id === noteId ? { ...note, expanded: !note.expanded } : note
                    }
                )

            }
        },
        updateNoteAction: (state, action) => {
            const updatedNote: INote = action.payload
            if (state.directory) {
                state.directory.notes = state.directory.notes?.map(
                    note => {
                        return note.id === updatedNote.id ? updatedNote : note
                    }
                )
            }
        },
        setSizeInfosDirectoryAction: (state, action) => {
            const payloadDirectories = action.payload.directories
            payloadDirectories.forEach((payloadDirectory: IDirectory) => {
                // if (state.directory && state.directory.id === payloadDirectory.id) {
                //     state.directory = { ...state.directory, sizeInfos: payloadDirectory.sizeInfos }
                // }
                if (state.directory) {
                    if (state.directory.id === payloadDirectory.id) {
                        state.directory = { ...state.directory, sizeInfos: payloadDirectory.sizeInfos }
                    } else if (state.directory.children) {
                        state.directory.children = state.directory.children.map(child => {
                            return child.id === payloadDirectory.id ? { ...child, sizeInfos: payloadDirectory.sizeInfos } : child
                        })
                    }
                }
            })
            const payloadDocuments = action.payload.documents
            if (payloadDocuments) {
                payloadDocuments.forEach((payloadDocument: IDocument) => {
                    if (state.directory && state.directory.documents) {
                        state.directory.documents = state.directory.documents.map(document => {
                            return document.id === payloadDocument.id ? { ...document, sizeInfos: payloadDocument.sizeInfos } : document
                        })
                    }
                })
            }
        },
    }
})

export const { setDirectoryRefetchNeeded, setDirectoryAction, updateDirectoryAction, toggleSelectChildAction, selectAllChildrenAction, unSelectAllChildrenAction,
    toggleExpandChildAction, expandSelectedChildrenAction, unExpandSelectedChildrenAction,
    addChildAction, updateChildAction, removeChildAction, removeChildrenAction,
    removeDirectoryAction,
    addDocumentAction, updateDocumentAction, removeDocumentAction, toggleSelectDocumentAction, toggleExpandDocumentAction, removeDocumentsAction, updateInfosDirectoryAction,
    addSheetAction, setSheetApplicableAction, removeSheetAction, toggleExpandNoteAction, updateNoteAction,
    toggleSelectNoteAction, setSizeInfosDirectoryAction, expandAllChildrenAction,
} = directorySlice.actions


export const useGetDirectory = (directoryId: string) => {
    const directory: (undefined | IDirectory) = useSelector((state: IRootState) => state.directoryReducer.directory)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.directoryReducer.refetchNeeded)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    if (refetchNeeded || directory === undefined || directory.id !== directoryId) {
        apolloClient.query({
            query: GQL_DIRECTORY,
            fetchPolicy: 'network-only',
            variables: {
                directoryId,
                filter: filterQuery,
            }
        }
        ).then(response => {
            store.dispatch(setDirectoryAction(response.data.directory))
            store.dispatch(setDirectoryRefetchNeeded(false))
            // console.log("return directory by fetching..")
            return response.data.directory
        })
    } else {
        // console.log("return directory by useSeletor..")
        return directory
    }
}

export default directorySlice.reducer