import { Box, Typography, Button } from '@mui/material'
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react'
import { UserInfos } from '../../components/profile/UserInfos'
import { Layout } from "../Layout"
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice'
import { useGetUsers } from '../../redux/features/admin/adminSlice'
import { Spinner } from '../../components/spinner/Spinner'
import { EnumMessageType, EnumUserInfosMode } from '../../enums'
import { FloatingSpinner } from '../../components/spinner/FloatingSpinner'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_RECALCULATE_DISK_USAGE } from '../../graphql/Mutations'
import { addMessage } from '../../components/messages/Message'
import { setAllRefetchNeeded } from '../../redux/store'
import { ModalNewUser } from '../../components/admin/ModalNewUser'

interface IProps {
}

export const AdminPage: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()
  const users = useGetUsers()
  const [recalculateDiskUsage, { data: recalculateDiskUsageData, loading: recalculateDiskUsageLoading, error: recalculateDiskUsageError }] = useMutation(GQL_MUTATION_RECALCULATE_DISK_USAGE)
  const [showModalNewUser, setShowModalNewUser] = useState(false)


  useEffect(() => {
    if (recalculateDiskUsageError) {
      addMessage({
        location: "AdminPage",
        type: EnumMessageType.Error,
        message: recalculateDiskUsageError.message,
      })
    } else if (recalculateDiskUsageData) {
      if (recalculateDiskUsageData.recalculateDiskUsage.statusCode === 200) {
        setAllRefetchNeeded("AdminPage")
        addMessage({
          type: EnumMessageType.Success,
          message: "Recalcul des espaces disque effectué.",
        })
      } else {
        recalculateDiskUsageData.recalculateDiskUsage.errors.map((error: string) => {
          addMessage({
            location: "AdminPage",
            type: EnumMessageType.Error,
            message: error,
          })
        })
      }
    }
  }, [recalculateDiskUsageData, recalculateDiskUsageError])

  const handleRecalculate = () => {
    if (users !== undefined && users.length > 0) {
      recalculateDiskUsage({
        variables: {
          userIds: users.map(user => user.id)
        }
      })
    }
  }

  const handleInviteUser = () => {
    setShowModalNewUser(true)
  }

  const closeModalNewUser = () => {
    setShowModalNewUser(false)
  }

  return (
    <div className="AdminPage">
      <Layout>
        <div className="page-body">
          <FloatingSpinner dependances={[recalculateDiskUsageLoading]} />
          {
            currentUser === undefined &&
            <Spinner classes="big-spinner" />
          }
          {
            currentUser && (
              (currentUser !== undefined && !currentUser.admin)
                ?
                <Box sx={{ mt: 4, px: { xs: 0, md: 8 } }}>
                  <Typography color="error" sx={{ fontSize: 24 }}>
                    Section réservée aux administrateurs
                  </Typography>
                </Box>
                :
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", mt: 4, px: { xs: 0, md: 8 } }}>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", mt: 4, mb: 2, px: { xs: 0, md: 8 }, gap: 2 }}>
                    <Button onClick={handleRecalculate} variant="text" color="primary">Recalculer les espaces disques</Button>
                    <Button onClick={handleInviteUser} variant="text" color="primary">Inviter un nouvel utilisateur</Button>
                  </Box>
                  <Grid container spacing={2}>
                    {
                      users === undefined ? (
                        <Spinner classes="big-spinner" />
                      ) : (
                        users.map((user) => {
                          return (
                            <Grid key={`${user.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                              {/* <Box key={`${user.id}`} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}> */}
                              <UserInfos user={user} mode={EnumUserInfosMode.admin} />
                              {/* </Box> */}
                            </Grid>
                          )
                        })
                      )
                    }
                  </Grid>
                </Box>
            )
          }
        </div>
        <ModalNewUser
          open={showModalNewUser}
          handleClose={closeModalNewUser}
        />
      </Layout>
    </div >
  )
}
