// import "../../css/components/lockers/LockerDetails.css"
import { Box, Button, Collapse, Divider, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { IAlbum } from "../../interfaces";
import { DeleteOutline, EditOutlined, ShareOutlined } from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumListAlbumsMode, EnumMessageType, EnumSize, EnumSliceReference } from "../../enums";
import {
    GQL_MUTATION_DELETE_ALBUM,
    GQL_MUTATION_SET_ALBUM_PUBLIK,
    GQL_MUTATION_UNSET_ALBUM_PUBLIK
} from "../../graphql/Mutations";
import { ModalEditAlbum } from "./modals/ModalEditAlbum";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_ALBUMS } from "../../utils/Navigation";
import { UserBadge } from "../UserBadge";
import { setAllRefetchNeeded } from "../../redux/store";
import { Flexbox } from "../utils/Flexbox";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { CameraIcon } from '../svg_icons/CameraIcon';
import { SubAlbumInfos } from './SubAlbumInfos';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { ModalShareAlbum } from './modals/ModalShareAlbum';

interface IProps {
    album: IAlbum,
}

export const AlbumDetails: FC<IProps> = (props) => {
    const [showDeleteAlbumDialog, setShowDeleteAlbumDialog] = useState(false)
    const [showModalEditAlbum, setShowModalEditAlbum] = useState(false)
    const [showModalShareAlbum, setShowModalShareAlbum] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const closeModalEditAlbum = () => {
        setShowModalEditAlbum(false)
    }
    const [deleteAlbum, { data: deleteAlbumData, loading: deleteAlbumLoading, error: deleteAlbumError }] = useMutation(GQL_MUTATION_DELETE_ALBUM)
    const [setAlbumPublik, { data: setAlbumPublikData, loading: setAlbumPublikLoading, error: setAlbumPublikError }] = useMutation(GQL_MUTATION_SET_ALBUM_PUBLIK)
    const [unsetAlbumPublik, { data: unsetAlbumPublikData, loading: unsetAlbumPublikLoading, error: unsetAlbumPublikError }] = useMutation(GQL_MUTATION_UNSET_ALBUM_PUBLIK)


    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        if (deleteAlbumError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: deleteAlbumError.message,
            })
        } else if (deleteAlbumData) {
            if (deleteAlbumData.deleteAlbum.statusCode === 200) {
                setAllRefetchNeeded("AlbumDetails", [EnumSliceReference.album])
                navigate(NAVIGATE_ALBUMS)
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album supprimé.",
                })
            } else {
                deleteAlbumData.deleteAlbum.errors.map((error: string) => {
                    addMessage({
                        location: "AlbumDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAlbumData, deleteAlbumError])

    useEffect(() => {
        if (setAlbumPublikError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: setAlbumPublikError.message,
            })
        } else if (setAlbumPublikData) {
            if (setAlbumPublikData.setAlbumPublik.statusCode === 200) {
                setAllRefetchNeeded("Album")
                // dispatch(setPublikAlbumAction(props.album.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album rendu public.",
                })
            } else {
                setAlbumPublikData.setAlbumPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setAlbumPublikData, setAlbumPublikError])

    useEffect(() => {
        if (unsetAlbumPublikError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: unsetAlbumPublikError.message,
            })
        } else if (unsetAlbumPublikData) {
            if (unsetAlbumPublikData.unsetAlbumPublik.statusCode === 200) {
                setAllRefetchNeeded("Album")
                // dispatch(unsetPublikAlbumAction(props.album.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album rendu privé.",
                })
            } else {
                unsetAlbumPublikData.unsetAlbumPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [unsetAlbumPublikData, unsetAlbumPublikError])


    const handleEdit = () => {
        setShowModalEditAlbum(true)
    }

    const handleDelete = () => {
        setShowDeleteAlbumDialog(true)
    }

    const closeDeleteAlbumDialog = () => {
        setShowDeleteAlbumDialog(false)
    }

    const confirmedDeleteAlbum = () => {
        deleteAlbum({
            variables: {
                albumId: props.album.id
            }
        })
        setShowDeleteAlbumDialog(false)
    }

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    const handleShareAlbum = () => {
        setShowModalShareAlbum(true)
    }
    const closeModalShareAlbum = () => {
        setShowModalShareAlbum(false)
    }

    const handleSetPublik = () => {
        setAlbumPublik({
            variables: {
                albumId: props.album.id
            }
        })
    }

    const handleUnsetPublik = () => {
        unsetAlbumPublik({
            variables: {
                albumId: props.album.id
            }
        })
    }




    return (
        <>
            <FloatingSpinner dependances={[deleteAlbumLoading]} />
            <Paper className={`LockerDetails ${props.album.selected ? "selected" : ""} `}>
                <Box className="LockerDetails-container">
                    <Box className="LockerDetails-main-section">
                        <Box className="LockerDetails-icon-section">
                            <CameraIcon color={props.album.mode === EnumListAlbumsMode.owned ? "desktop" : "shared"} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Box className="LockerDetails-content-section">
                            <Box className="LockerDetails-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.album.title}
                                </Typography>
                                <Box className="LockerDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="LockerDetails-expanded-section">
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {
                                props.album.description && <>
                                    {/* <Divider /> */}
                                    <Box className="LockerDetails-description-section">
                                        <Typography sx={{ fontSize: "16px", my: 3 }}>
                                            {props.album.description}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            <Grid container className="LockerDetails-infos-section">
                                <Grid size={{ xs: 12, md: 3 }} className="LockerDetails-infos-content" sx={{ mb: 2 }}>
                                    <Divider flexItem sx={{ mb: 1 }}>Informations</Divider>
                                    <SubAlbumInfos detailsMode={true} album={props.album} />
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-infos-users" sx={{ mb: 2 }}>
                                    <Box className="LockerDetails-infos-owners">
                                        <Divider flexItem>Propriétaires</Divider>
                                        {
                                            props.album.owners.map((owner) => {
                                                return (
                                                    <UserBadge key={owner.id} user={owner} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                )
                                            })
                                        }
                                    </Box>
                                    {
                                        props.album.viewers.length > 0 && <>
                                            <Box className="LockerDetails-infos-viewers">
                                                <Divider flexItem>Partagés</Divider>
                                                {
                                                    props.album.viewers.map((viewer) => {
                                                        return (
                                                            <UserBadge key={viewer.id} user={viewer} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    }
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-actions"
                                    sx={{ mb: 2 }}
                                >
                                    <Divider flexItem sx={{ mb: 1 }}>Actions</Divider>
                                    <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }} >
                                            {
                                                props.album.mode === EnumListAlbumsMode.owned &&
                                                <Button startIcon={<EditOutlined />} color="primary" size="small" onClick={handleEdit}>Modifier</Button>
                                            }
                                            {
                                                props.album.mode === EnumListAlbumsMode.owned &&
                                                <>
                                                    <Button startIcon={<HandshakeOutlinedIcon />} color="primary" size="small" onClick={handleShareAlbum}>Partager</Button>
                                                    <ModalShareAlbum
                                                        open={showModalShareAlbum}
                                                        handleClose={closeModalShareAlbum}
                                                        album={props.album}
                                                    />
                                                </>
                                            }
                                            {
                                                props.album.publik
                                                    ?
                                                    <Button startIcon={<ShareOutlined />} color="shared" size="small" onClick={handleUnsetPublik}>Rendre privé</Button>
                                                    :
                                                    <Button startIcon={<ShareOutlined />} color="primary" size="small" onClick={handleSetPublik}>Rendre public</Button>
                                            }
                                        </Flexbox>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }}>
                                            {
                                                props.album.mode === EnumListAlbumsMode.owned &&
                                                <Button startIcon={<DeleteOutline />} color="error" size="small" onClick={handleDelete}>Supprimer</Button>
                                            }
                                        </Flexbox >
                                    </Flexbox>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditAlbum
                open={showModalEditAlbum}
                handleClose={closeModalEditAlbum}
                album={props.album}
            />
            <ModalDeleteConfirm
                open={showDeleteAlbumDialog}
                title="Suppression de l'album"
                content={`Confirmez-vous la suppression définitive de l'album ${props.album.title} ?`}
                handleClose={closeDeleteAlbumDialog}
                handleConfirm={confirmedDeleteAlbum}
            />
        </>
    )
}
