import { FC, useState } from 'react';
import { IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Flexbox } from './Flexbox';
import { ModalDatePicker } from './private/ModalDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { EnumDayjsFormat } from '../../enums';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface IProps {
    title: string,
    disableFuture: boolean,
    date?: Dayjs,
    handleDate: (pDate: Dayjs) => void;
    resetDate?: () => void;
    showArrows: boolean;
    displayDateOnLeft: boolean
}

export const DateSelector: FC<IProps> = (props) => {
    const [showModalDatePicker, setShowModalDatePicker] = useState(false)
    const theme = useTheme()

    const handleDateSelected = () => {
        setShowModalDatePicker(true)
    }

    const closeModalDatePicker = () => {
        setShowModalDatePicker(false)
    }

    const handleDatePrevious = () => {
        props.handleDate(dayjs(props.date).subtract(1, 'day'))
    }

    const handleDateNext = () => {
        if (!props.disableFuture || dayjs(props.date).add(1, 'day').isBefore(dayjs(), 'day')) {
            props.handleDate(dayjs(props.date).add(1, 'day'));
        }
    }

    const getDate = () => {
        return <>
            <Flexbox sx={{
                borderBottom: props.date ? "none" : `1px solid ${theme.palette.text.secondary}`,
            }}>
                {
                    props.date
                        ?
                        <Typography variant="body2" sx={{ color: theme.palette.text.primary }}  >
                            {dayjs(props.date).locale('fr').format(EnumDayjsFormat.date)}
                        </Typography>
                        :
                        <Typography variant="body2"  >
                            &nbsp;
                        </Typography>
                }
            </Flexbox>
        </>
    }

    return (
        <>
            <Flexbox>
                {
                    props.displayDateOnLeft && getDate()
                }
                {
                    props.showArrows &&
                    <>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Jour précédent">
                            <IconButton onClick={handleDatePrevious} color="primary">
                                <KeyboardArrowLeftIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                    </>
                }
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.title}>
                    <IconButton onClick={handleDateSelected} color="primary">
                        <EventOutlinedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                </Tooltip>
                {
                    props.showArrows &&
                    <>
                        {
                            props.disableFuture && !dayjs(props.date).add(1, 'day').isBefore(dayjs(), 'day')
                                ?
                                <IconButton
                                    color="primary"
                                    disabled={true}
                                >
                                    <KeyboardArrowRightIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                                :
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Jour suivant">
                                    <IconButton
                                        onClick={handleDateNext}
                                        color="primary"
                                    >
                                        <KeyboardArrowRightIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                        }
                    </>
                }
                {
                    !props.displayDateOnLeft && getDate()
                }
            </Flexbox>
            <ModalDatePicker
                defaultDate={props.date || dayjs()}
                title={props.title}
                disableFuture={props.disableFuture}
                open={showModalDatePicker}
                handleClose={closeModalDatePicker}
                handleConfirm={props.handleDate}
                handleReset={props.resetDate}
            />
        </>
    )
}


