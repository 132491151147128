import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumLockerIconMode, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_LOCKER } from '../../../graphql/Mutations';
import { ILocker } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { LockerIcon } from '../../svg_icons/LockerIcon';
import { setAllRefetchNeeded } from '../../../redux/store';

interface IProps {
    open: boolean;
    handleClose: () => void;
    locker: ILocker

}

export const ModalEditLocker: FC<IProps> = (props) => {
    const [title, setTitle] = useState(props.locker.title)
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [description, setDescription] = useState(props.locker.description)
    const titleRef = useRef<HTMLInputElement>(null);
    const [updateLocker, { data: updateLockerData, loading: updateLockerLoading, error: updateLockerError }] = useMutation(GQL_MUTATION_UPDATE_LOCKER)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateLockerError) {
            addMessage({
                location: "ModalEditLocker",
                type: EnumMessageType.Error,
                message: updateLockerError.message,
            })
        } else if (updateLockerData) {
            if (updateLockerData.updateLocker.statusCode === 200) {
                // const updatedLocker: ILocker = updateLockerData.updateLocker.locker
                // dispatch(updateLockerAction(updatedLocker))
                // dispatch(updateLockerDetailsAction(updatedLocker))
                setAllRefetchNeeded("ModalEditLocker")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier modifié.",
                })
                handleClose()
            } else {
                updateLockerData.updateLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateLockerData, updateLockerError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    // const cleanDatas = () => {
    //     setTitle("")
    //     setErrorOnTitle(false)
    //     setDescription("")
    // }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateLocker({
                variables: {
                    lockerId: props.locker.id,
                    title,
                    description,
                }
            })
        }
    }

    const handleClose = () => {
        // cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalEditLocker">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <LockerIcon mode={EnumLockerIconMode.standard} color="desktop" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier le casier
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Nom du casier"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
