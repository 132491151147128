import { FC } from 'react';
import { Flexbox } from '../../utils/Flexbox';
import { BasicFilter } from '../../utils/BasicFilter';
import { Divider, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { IAccountOperationStatesFilter } from '../../../interfaces';
import { EnumAccountOperationImputableVisibility, EnumAccountOperationState, EnumAccountOperationTypeVisibility, EnumDevice } from '../../../enums';
import theme from '../../../theme';
import { EyeOpenIcon } from '../../svg_icons/EyeOpenIcon';
import { EyeClosedIcon } from '../../svg_icons/EyeClosedIcon';
import { AccountOperationIcon } from '../../svg_icons/AccountOperationIcon';

interface IProps {
    mode: EnumDevice,
    defaulFiltertValue: string;
    setFilter: (filter: string) => void;
    statesFilter: IAccountOperationStatesFilter,
    setStatesFilter: (statesFilter: IAccountOperationStatesFilter) => void;
    imputableVisibility: EnumAccountOperationImputableVisibility,
    toggleImputableVisibility: () => void;
    accountOperationTypeVisibility: EnumAccountOperationTypeVisibility,
    toggleAccountOperationTypeVisibility: () => void;
}

export const AccountOperationsFilter: FC<IProps> = (props) => {

    const stateMr = 1

    const handleSetStatesFilter = (accountOperationState: EnumAccountOperationState) => {
        const newStatesFilter = { ...props.statesFilter }
        newStatesFilter[accountOperationState] = !newStatesFilter[accountOperationState]
        props.setStatesFilter(newStatesFilter)
    }

    const getAccountOperationTypeTooltipTitle = () => {
        if (props.accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.both) {
            return "Crédits et débits visibles."
        }
        if (props.accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.credit) {
            return "Crédits visibles uniquement."
        }
        return "Débits visibles uniquement."
    }

    const getAccountOperationTypeFilterColor = () => {
        if (props.accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.both) {
            return theme.palette.primary.main
        }
        if (props.accountOperationTypeVisibility === EnumAccountOperationTypeVisibility.credit) {
            return theme.palette.credit.main
        }
        return theme.palette.debit.main
    }

    return (
        <Flexbox direction={props.mode === EnumDevice.desktop ? 'row' : 'column'} sx={{
            // width: "100%",
            // bgcolor: "yellow",
        }}>
            <Flexbox>
                <Flexbox sx={{ mx: 1 }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={getAccountOperationTypeTooltipTitle()}>
                        <IconButton onClick={() => props.toggleAccountOperationTypeVisibility()}>
                            {/* <EyeOpenIcon mainColor={getAccountOperationTypeFilterColor()} /> */}
                            <AccountOperationIcon sx={{
                                fontSize: 18,
                                // mr: 1,
                                color: getAccountOperationTypeFilterColor(),
                            }} />
                        </IconButton>
                    </Tooltip>
                    {
                        (props.imputableVisibility === EnumAccountOperationImputableVisibility.both ||
                            props.imputableVisibility === EnumAccountOperationImputableVisibility.imputable
                        ) && <>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.imputableVisibility === EnumAccountOperationImputableVisibility.both ? "Opérations imputables et non-imputables visibles." : "Opérations imputables visibles uniquement."}>
                                <IconButton onClick={() => props.toggleImputableVisibility()}>
                                    <EyeOpenIcon mainColor={props.imputableVisibility === EnumAccountOperationImputableVisibility.both ? theme.palette.secondary.main : theme.palette.primary.main} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    {
                        props.imputableVisibility === EnumAccountOperationImputableVisibility.notImputable && <>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Opérations non-imputables visibles uniquement.">
                                <IconButton onClick={() => props.toggleImputableVisibility()}>
                                    <EyeClosedIcon mainColor={theme.palette.cbBlack.main} />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </Flexbox>
                <Flexbox sx={{
                    mr: stateMr,
                }}>
                    {
                        props.mode === EnumDevice.desktop &&
                        <Typography sx={{
                            color: theme.palette.cbBlack.main,
                            fontSize: 16,
                        }}>
                            Préparé
                        </Typography>
                    }
                    <Switch
                        checked={props.statesFilter.prepared}
                        onChange={() => handleSetStatesFilter(EnumAccountOperationState.prepared)}
                        color="cbBlack"
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                </Flexbox>
                <Flexbox sx={{
                    mr: stateMr,
                }}>
                    {
                        props.mode === EnumDevice.desktop &&
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            fontSize: 16,
                        }}>
                            Enregistré
                        </Typography>
                    }
                    <Switch
                        checked={props.statesFilter.created}
                        onChange={() => handleSetStatesFilter(EnumAccountOperationState.created)}
                        color="primary"
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                </Flexbox>
                <Flexbox sx={{
                    mr: stateMr,
                }}>
                    {props.mode === EnumDevice.desktop && <Typography sx={{
                        color: theme.palette.credit.main,
                        fontSize: 16,
                    }}>
                        Payé
                    </Typography>}
                    <Switch
                        checked={props.statesFilter.paid}
                        onChange={() => handleSetStatesFilter(EnumAccountOperationState.paid)}
                        color="credit"
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                </Flexbox>
                <Flexbox sx={{
                    mr: stateMr,
                }}>
                    {props.mode === EnumDevice.desktop && <Typography sx={{
                        color: theme.palette.desktop.main,
                        fontSize: 16,
                    }}>
                        Consolidé
                    </Typography>}
                    <Switch
                        checked={props.statesFilter.consolidated}
                        onChange={() => handleSetStatesFilter(EnumAccountOperationState.consolidated)}
                        color="desktop"
                        inputProps={{ 'aria-label': 'controlled' }}
                        size='small'
                    />
                </Flexbox>
            </Flexbox>
            {/* <Divider orientation="vertical" flexItem variant="middle" sx={{
                mx: 1,
            }} /> */}
            <BasicFilter setFilter={props.setFilter} defaultFilterValue={props.defaulFiltertValue} />
        </Flexbox>
    )
}
