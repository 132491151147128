// import "../../css/components/documents/Document.css"
import { Box, Divider, IconButton, Link, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IDocument, ISheet } from "../../interfaces";
import { DeleteOutline } from '@mui/icons-material';
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumListLockersMode, EnumMessageType } from "../../enums";
import { GQL_MUTATION_SET_SHEET_APPLICABLE, GQL_MUTATION_DELETE_SHEET } from "../../graphql/Mutations";
import { dateFormat } from "../../utils/Utils";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { green } from '@mui/material/colors';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { setSheetApplicableAction, removeSheetAction } from '../../redux/features/directories/directorySlice';
import { FloatingSpinner } from '../spinner/FloatingSpinner';

interface IProps {
    document: IDocument,
    sheet: ISheet,
    provoqueRefetch: () => void,
}

export const Sheet: FC<IProps> = (props) => {
    const [showDeleteSheetDialog, setShowDeleteSheetDialog] = useState(false)
    const [deleteSheet, { data: deleteSheetData, loading: deleteSheetLoading, error: deleteSheetError }] = useMutation(GQL_MUTATION_DELETE_SHEET)
    const [setSheetApplicable, { data: setSheetApplicableData, loading: setSheetApplicableLoading, error: setSheetApplicableError }] = useMutation(GQL_MUTATION_SET_SHEET_APPLICABLE)
    const dispatch = useDispatch()
    const theme = useTheme()

    useEffect(() => {
        if (setSheetApplicableError) {
            addMessage({
                location: "Sheet",
                type: EnumMessageType.Error,
                message: setSheetApplicableError.message,
            })
        } else if (setSheetApplicableData) {
            if (setSheetApplicableData.setSheetApplicable.statusCode === 200) {
                const payload = {
                    documentId: props.document.id,
                    sheetId: props.sheet.id,
                }
                dispatch(setSheetApplicableAction(payload))
                props.provoqueRefetch()
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Version applicable paramétrée.",
                })
            } else {
                setSheetApplicableData.setSheetApplicable.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setSheetApplicableData, setSheetApplicableError])

    useEffect(() => {
        if (deleteSheetError) {
            addMessage({
                location: "Sheet",
                type: EnumMessageType.Error,
                message: deleteSheetError.message,
            })
        } else if (deleteSheetData) {
            if (deleteSheetData.deleteSheet.statusCode === 200) {
                const payload = {
                    documentId: props.document.id,
                    sheetId: props.sheet.id,
                }
                dispatch(removeSheetAction(payload))
                props.provoqueRefetch()
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Version supprimée.",
                })
            } else if (deleteSheetData.deleteSheet.statusCode === 406) {
                deleteSheetData.deleteSheet.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Warning,
                        message: error,
                    })
                })
            } else {
                deleteSheetData.deleteSheet.errors.map((error: string) => {
                    addMessage({
                        location: "Sheet",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteSheetData, deleteSheetError])

    const handleDelete = () => {
        setShowDeleteSheetDialog(true)
    }

    const closeDeleteSheetDialog = () => {
        setShowDeleteSheetDialog(false)
    }

    const confirmedDeleteSheet = () => {
        deleteSheet({
            variables: {
                sheetId: props.sheet.id
            }
        })
        setShowDeleteSheetDialog(false)
    }

    const handleApplicable = () => {
        setSheetApplicable({
            variables: {
                sheetId: props.sheet.id,
            }
        })
    }

    return (
        <Box className="Sheet" sx={{ backgroundColor: props.sheet.applicable ? green[50] : "inherit" }}>
            <FloatingSpinner dependances={[deleteSheetLoading]} />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", px: 1 }}>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        {
                            props.sheet.applicable &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Version applicable">
                                <TaskAltOutlinedIcon sx={{ fontSize: 18, color: theme.palette.success.main, mx: 1 }} />
                            </Tooltip>
                        }
                        <Typography sx={{ fontSize: 18, }}>
                            {props.sheet.filename}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Date">
                            <EventOutlinedIcon color={props.document.mode} sx={{ fontSize: 18, mr: .5 }} />
                        </Tooltip>
                        <Typography color="primary" sx={{ fontSize: 14 }}>
                            {`${dateFormat(props.sheet.updatedAt)}`}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    {
                        props.document.mode !== EnumListLockersMode.shared &&
                        <>
                            {
                                !props.sheet.applicable &&
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                    <IconButton onClick={handleDelete} color="error">
                                        <DeleteOutline sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                props.sheet.sheetFileUrl &&
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                    <Link href={props.sheet.sheetFileUrl} underline="none" target="_blank">
                                        <IconButton color="primary">
                                            <OpenInNewOutlinedIcon sx={{ fontSize: 18 }} />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            }
                            {
                                props.sheet.sheetFileUrlForDownload &&
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                    <IconButton href={props.sheet.sheetFileUrlForDownload} color="primary">
                                        <CloudDownloadOutlinedIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                            {
                                !props.sheet.applicable &&
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rendre applicable">
                                    <IconButton onClick={handleApplicable} color="primary">
                                        <TaskAltOutlinedIcon sx={{ fontSize: 18 }} />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                </Box>
            </Box>
            <Divider flexItem variant='fullWidth' sx={{ mb: 2, mt: 1 }} />
            <ModalDeleteConfirm
                open={showDeleteSheetDialog}
                title="Suppression de la version"
                content={`Confirmez-vous la suppression définitive de cette version ?`}
                handleClose={closeDeleteSheetDialog}
                handleConfirm={confirmedDeleteSheet}
            />
        </Box>
    )
}
