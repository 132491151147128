import { EnumDateFormat, EnumPackHumanLabel, EnumPackLabel, EnumAccountOperationType } from "../enums";
import { IContactMinimal } from "../interfaces";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

export const sortByTitle = (p_array: Array<any>) => {
    const res = [...p_array]
    // const res = p_array
    res.sort((item1, item2) => {
        if (item1.title.toUpperCase() < item2.title.toUpperCase()) {
            return -1
        }
        if (item1.title.toUpperCase() > item2.title.toUpperCase()) {
            return 1
        }
        return 0
    })
    return res
}

export const sortByName = (p_array: Array<any>) => {
    const res = [...p_array]
    res.sort((item1, item2) => {
        if (item1.name.toUpperCase() < item2.name.toUpperCase()) {
            return -1
        }
        if (item1.name.toUpperCase() > item2.name.toUpperCase()) {
            return 1
        }
        return 0
    })
    return res
}

export const truncateString = (p_string: string | undefined, size: number) => {
    if (p_string === undefined || p_string === null) {
        return ""
    }
    return p_string.length > size ? `${p_string.slice(0, size)}..` : p_string
}

export const pluralize = (p_string: string, nb: number | undefined, plural_version?: string) => {
    if (nb !== undefined && nb > 1) {
        return plural_version !== undefined ? plural_version : `${p_string}s`
    }
    return p_string
}

export const dateFormat = (date: string, fromFormat: EnumDateFormat = EnumDateFormat.sql, toFormat: EnumDateFormat = EnumDateFormat.human) => {
    if (date === null || date === undefined || date === "") {
        return date
    }
    let day
    let month
    let year
    if (fromFormat === EnumDateFormat.sql) {
        // 2023-03-30 17:40:55 UTC
        day = date.substring(8, 10)
        month = date.substring(5, 7)
        year = date.substring(0, 4)
    }
    if (fromFormat === EnumDateFormat.human) {
        // 28/06/1981
        day = date.substring(0, 2)
        month = date.substring(3, 5)
        year = date.substring(6, 10)
    }

    if (toFormat === EnumDateFormat.sql) {
        // 2023-03-30 17:40:55 UTC
        return `${year}-${month}-${day}`
    }
    if (toFormat === EnumDateFormat.human) {
        // 28/06/1981
        return `${day}/${month}/${year}`
    }
    return date
}

export const getPacks = () => {
    const packs = [
        {
            label: EnumPackLabel.starter,
            humanLabel: EnumPackHumanLabel.starter,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/starter.png`,
            limit: "20 MB",
            price: "3 €/mois",
        },
        {
            label: EnumPackLabel.basic,
            humanLabel: EnumPackHumanLabel.basic,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/basic.png`,
            limit: "50 MB",
            price: "5 €/mois",
        },
        {
            label: EnumPackLabel.bronze,
            humanLabel: EnumPackHumanLabel.bronze,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/bronze.png`,
            limit: "100 MB",
            price: "9 €/mois",
        },
        {
            label: EnumPackLabel.silver,
            humanLabel: EnumPackHumanLabel.silver,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/silver.png`,
            limit: "200 MB",
            price: "16 €/mois",
        },
        {
            label: EnumPackLabel.gold,
            humanLabel: EnumPackHumanLabel.gold,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/gold.png`,
            limit: "500 MB",
            price: "35 €/mois",
        },
        {
            label: EnumPackLabel.amethyst,
            humanLabel: EnumPackHumanLabel.amethyst,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/amethyst.png`,
            limit: "1 GB",
            price: "60 €/mois",
        },
        {
            label: EnumPackLabel.ruby,
            humanLabel: EnumPackHumanLabel.ruby,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/ruby.png`,
            limit: "2 GB",
            price: "100 €/mois",
        },
        {
            label: EnumPackLabel.diamond,
            humanLabel: EnumPackHumanLabel.diamond,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/diamond.png`,
            limit: "5 GB",
            price: "200 €/mois",
        },
        {
            label: EnumPackLabel.blackDiamond,
            humanLabel: EnumPackHumanLabel.blackDiamond,
            pictoUrl: `${process.env.PUBLIC_URL}/images/packs/black_diamond.png`,
            limit: "10 GB",
            price: "300 €/mois",
        },
    ]
    return packs
}

export const isDateOld = (date: string) => {
    return dayjs.utc(date).isBefore(dayjs().subtract(1, "day"))
}

export const getDayjsDuration = (startAt: Dayjs, endAt: Dayjs) => {
    // console.log("MED: ==> startAt:", startAt, " endAt:", endAt)
    return endAt.diff(startAt, 'minute')
}

export const getDayjsAge = (birthAt: Dayjs) => {
    return dayjs().diff(birthAt, 'year')
}

// export const filterMatch = (stringToCompare: string, searchString: string) => {
//     return stringToCompare.toLowerCase().includes(searchString.toLowerCase())
// }

// export const filterMatch = (pStringToCompare: string | undefined, pSearchString: string | undefined) => {
//     const stringToCompare = pStringToCompare || ""
//     const searchString = pSearchString || ""
//     return stringToCompare.toLowerCase().includes(searchString.toLowerCase())
// }
// Chatgpt pour gérer les accents (è) :
export const filterMatch = (pStringToCompare: string | undefined, pSearchString: string | undefined) => {
    const normalizeString = (str: string) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Normalise et retire les accents

    const stringToCompare = normalizeString(pStringToCompare || "").toLowerCase();
    const searchString = normalizeString(pSearchString || "").toLowerCase();

    return stringToCompare.includes(searchString);
}


// export const displayContactTitle = (contact: IContactMinimal, fallbackToSubtitle = false) => {
//     if (
//         (contact.nickname && contact.nickname.length > 0)
//     ) {
//         return `${contact.nickname}`
//     }
//     if (
//         (contact.firstname && contact.firstname.length > 0) ||
//         (contact.lastname && contact.lastname.length > 0)
//     ) {
//         return `${contact.firstname || ""} ${contact.lastname || ""}`

//     } else if (fallbackToSubtitle) {
//         return displayContactSubtitle(contact)
//     } else {
//         return ""
//     }
// }

export const displayContactSubtitle = (contact: IContactMinimal) => {
    if (
        contact.enterpriseName && contact.enterpriseName.length > 0

    ) {
        return contact.enterpriseName
    }
    return ""
}

// export const displayContactTitleOrSubtitle = (contact: IContactMinimal) => {
//     let res = ""
//     res = displayContactTitle(contact)
//     if (res.length < 1) {
//         res = displayContactSubtitle(contact)
//     }
//     return res
// }


export const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

export const formatCurrency = (amount: number): string => {
    return `${amount.toFixed(2)} €`;
}

export const formatFancyCurrency = (amount: number, enumAccountOperationType: EnumAccountOperationType, showCreditSign: boolean = true): string => {
    if (enumAccountOperationType === EnumAccountOperationType.credit) {
        return showCreditSign ? `+ ${formatCurrency(amount)}` : `${formatCurrency(amount)}`;
    }
    if (enumAccountOperationType === EnumAccountOperationType.debit) {
        const res = `${formatCurrency(amount)}`
        if (res.startsWith('-')) {
            return `- ${res.slice(1)}`;
        }
        return res.startsWith('-') ? res : `- ${res}`;
    }
    return `${formatCurrency(amount)}`;
}

export const dateIsInPeriod = (date: Dayjs, dateFrom: Dayjs, dateTo: Dayjs) => {
    return date.isSameOrAfter(dateFrom) && date.isSameOrBefore(dateTo)
}