import { Button, useTheme } from '@mui/material';
import { FC } from 'react';
import "yet-another-react-lightbox/styles.css";
import { IAccountOperation } from '../../../interfaces';
import { Flexbox } from '../../utils/Flexbox';
import { EnumAccountOperationState, EnumAccountOperationType } from '../../../enums';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ModalHandleStateAccountOperation } from './modals/ModalHandleStateAccountOperation';
import { useDispatch, useSelector } from 'react-redux';
import { clearOpenedModal, setOpenedModal } from '../../../redux/features/global/modalsSlice';
import { IRootState } from '../../../redux/store';
import { HourglassIcon } from '../../svg_icons/HourglassIcon';
import { CashBagIcon } from '../../svg_icons/CashBagIcon';
import DoneIcon from '@mui/icons-material/Done';

interface IProps {
    accountOperation: IAccountOperation
}

export const AccountOperationState: FC<IProps> = (props) => {
    // const [showModalHandleStateAccountOperation, setShowModalHandleStateAccountOperation] = useState(false)
    const ModalHandleStateAccountOperationId = `ModalHandleStateAccountOperation-${props.accountOperation.id}`
    const openedModal: string = useSelector((state: IRootState) => state.modalsReducer.openedModal)

    const theme = useTheme()
    const dispatch = useDispatch()

    const handleState = () => {
        // setShowModalHandleStateAccountOperation(true)
        dispatch(setOpenedModal(ModalHandleStateAccountOperationId))
    }

    const closeModalHandleStateAccountOperation = () => {
        // setShowModalHandleStateAccountOperation(false)
        dispatch(clearOpenedModal())
    }

    const getCashBagIcon = () => {
        if (props.accountOperation.accountOperationType === EnumAccountOperationType.credit) {
            return <CashBagIcon mainColor={theme.palette.cbGreen.main} secondaryColor={theme.palette.cbGreen.light} thirdColor={theme.palette.grey[50]} />
        }
        else {
            return <CashBagIcon mainColor={theme.palette.cbRed.main} secondaryColor={theme.palette.cbRed.light} thirdColor={theme.palette.grey[50]} />
        }
    }

    return (
        <>
            <Flexbox sx={{
                justifyContent: "flex-end",
                alignItems: "flex-start",
                // pr: 1,
                // bgcolor: "yellow",
                width: "100%",
            }}>
                {
                    props.accountOperation.state === EnumAccountOperationState.prepared &&
                    <Button startIcon={<AutorenewIcon />} size="small" onClick={handleState} sx={{
                        color: theme.palette.cancel.main,
                    }}>Préparé</Button>
                }
                {
                    props.accountOperation.state === EnumAccountOperationState.created &&
                    <Button startIcon={<HourglassIcon />} size="small" onClick={handleState} sx={{
                        color: theme.palette.primary.main,
                    }}>Enregistré</Button>
                }
                {
                    props.accountOperation.state === EnumAccountOperationState.paid &&
                    <Button startIcon={getCashBagIcon()} size="small" onClick={handleState} sx={{
                        color: props.accountOperation.accountOperationType === EnumAccountOperationType.credit ? theme.palette.credit.main : theme.palette.debit.main,
                    }}>Payé</Button>
                }
                {
                    props.accountOperation.state === EnumAccountOperationState.consolidated &&
                    <Button startIcon={<DoneIcon />} size="small" onClick={handleState} sx={{
                        color: theme.palette.desktop.main,
                    }}>Consolidé</Button>
                }
            </Flexbox>
            <ModalHandleStateAccountOperation
                open={openedModal === ModalHandleStateAccountOperationId}
                handleClose={closeModalHandleStateAccountOperation}
                accountOperation={props.accountOperation}
            />
        </>
    )
}
