import "../../css/components/documents/Document.css";
import { Box, Collapse, Divider, IconButton, Link, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IDocument } from "../../interfaces";
import { DeleteOutline, EditOutlined, ShareOutlined } from '@mui/icons-material';
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_DELETE_DOCUMENT } from "../../graphql/Mutations";
import { dateFormat, truncateString } from "../../utils/Utils";
import { removeDocumentAction, toggleExpandDocumentAction, toggleSelectDocumentAction } from "../../redux/features/directories/directorySlice";
import { ModalEditDocument } from "./modals/ModalEditDocument";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addDocumentsAction as addDocumentsInPocketAction,
    removeDocumentsAction as removeDocumentsFromPocketAction,
    useGetDocuments as useGetPocketDocuments,
} from "../../redux/features/pocket/pocketSlice";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { ModalSheets } from "./modals/ModalSheets";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { Flexbox } from "../utils/Flexbox";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { ModalDocumentPublik } from "./modals/ModalDocumentPublik";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { ModalSendByMailDocument } from "./modals/ModalSendByMailDocument";

interface IProps {
    document: IDocument,
}

export const Document: FC<IProps> = (props) => {
    const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] = useState(false)
    const [showModalEditDocument, setShowModalEditDocument] = useState(false)
    const [showModalSheets, setShowModalSheets] = useState(false)
    const pocketDocuments = useGetPocketDocuments()
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [showModalSendByMailDocument, setShowModalSendByMailDocument] = useState(false)

    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const [deleteDocument, { data: deleteDocumentData, loading: deleteDocumentLoading, error: deleteDocumentError }] = useMutation(GQL_MUTATION_DELETE_DOCUMENT)
    const [showModalDocumentPublik, setShowModalDocumentPublik] = useState(false)
    const dispatch = useDispatch()

    const theme = useTheme()

    const closeModalEditDocument = () => {
        setShowModalEditDocument(false)
    }

    const closeModalSheets = () => {
        setShowModalSheets(false)
    }

    useEffect(() => {
        if (deleteDocumentError) {
            addMessage({
                location: "Document",
                type: EnumMessageType.Error,
                message: deleteDocumentError.message,
            })
        } else if (deleteDocumentData) {
            if (deleteDocumentData.deleteDocument.statusCode === 200) {
                // dispatch(removeDocumentAction(props.document.id))
                setAllRefetchNeeded("Document")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Document supprimé.",
                })
            } else {
                deleteDocumentData.deleteDocument.errors.map((error: string) => {
                    addMessage({
                        location: "Document",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDocumentData, deleteDocumentError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Document",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addDocumentsInPocketAction([props.document]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Document",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDocumentsFromPocketAction([props.document.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    const handleEdit = () => {
        setShowModalEditDocument(true)
    }

    const handleSheets = () => {
        setShowModalSheets(true)
    }

    const handleDelete = () => {
        setShowDeleteDocumentDialog(true)
    }

    const closeDeleteDocumentDialog = () => {
        setShowDeleteDocumentDialog(false)
    }

    const confirmedDeleteDocument = () => {
        deleteDocument({
            variables: {
                documentId: props.document.id
            }
        })
        setShowDeleteDocumentDialog(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectDocumentAction(props.document.id))
    }

    const handleExpand = () => {
        dispatch(toggleExpandDocumentAction(props.document.id))
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                documentIds: [props.document.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                documentIds: [props.document.id],
            }
        })
    }

    const isInPocket = () => {
        if (pocketDocuments) {
            return pocketDocuments.filter(document => document.id === props.document.id).length > 0
        }
        return false
    }

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    const handleDocumentPublik = () => {
        setShowModalDocumentPublik(true)
    }

    const closeModalDocumentPublik = () => {
        setShowModalDocumentPublik(false)
    }

    const handleSendByMail = () => {
        setShowModalSendByMailDocument(true)
    }

    const closeModalSendByMailDocument = () => {
        setShowModalSendByMailDocument(false)
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteDocumentLoading]} />
            <Paper className={`Document ${props.document.selected ? "selected" : ""} `}>
                <Box className="Document-container">
                    <Box className="Document-main-section">
                        <Box className="Document-icon-section">
                            <DocumentIcon onClick={handleSelect} color={props.document.publik ? EnumListLockersMode.shared : props.document.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6, ":hover": { cursor: "pointer" } }} />
                        </Box>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: "100%",
                            // bgcolor: "red",
                        }}>
                            {
                                props.document.sheetPreviewUrl && <Flexbox sx={{
                                    px: 1,
                                    py: 1,
                                }}>
                                    {!previewLoaded && <Skeleton variant="rectangular" width={100} height={100} sx={{ borderRadius: 1 }} />}
                                    <Box component="img"
                                        src={props.document.sheetPreviewUrl}
                                        onLoad={handlePreviewLoaded}
                                        sx={{
                                            width: "100px",
                                            borderRadius: 1,
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderColor: theme.palette.grey[200],
                                            display: previewLoaded ? 'block' : 'none',
                                        }}
                                    />
                                </Flexbox>
                            }
                            <Flexbox direction="column" sx={{
                                pl: "4px",
                                pb: "4px",
                                alignItems: "flex-start",
                            }}>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Typography color="text.primary" sx={{ fontSize: 18, overflowWrap: "anywhere" }}>
                                        {props.document.title}
                                    </Typography>
                                </Flexbox>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Typography sx={{ fontSize: 14 }} >
                                        {truncateString(props.document.description, 80)}
                                    </Typography>
                                </Flexbox>

                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Flexbox sx={{ justifyContent: "flex-start", mt: 1 }}>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Dernière modification">
                                            <EventOutlinedIcon color={props.document.publik ? EnumListLockersMode.shared : props.document.mode} sx={{ fontSize: 18, mr: .5 }} />
                                        </Tooltip>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                                            {`${dateFormat(props.document.updatedAt)}`}
                                        </Typography>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>

                        </Flexbox>

                    </Box>
                    <Box className="Document-footer-section">
                        <ExpandMore expand={props.document.expanded == undefined ? false : props.document.expanded}
                            onClick={handleExpand} aria-expanded={props.document.expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        {
                            props.document.sheetFileUrlForDownload &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.document.sheetFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                    <Box className="Document-expanded-section">
                        <Collapse in={props.document.expanded} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box className="Locker-expanded-section-actions" sx={{ display: "flex", justifyContent: "space-between" }}>
                                {
                                    isInPocket() ?
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                            <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                                <PocketIcon variant={EnumSvgVariant.secondary} />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                            <IconButton onClick={handlePutInPocket} color="primary">
                                                <PocketIcon variant={EnumSvgVariant.primary} />
                                            </IconButton>
                                        </Tooltip>
                                }
                                {
                                    props.document.mode !== EnumListLockersMode.shared &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                            <IconButton onClick={handleEdit} color="primary">
                                                <EditOutlined />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Versions">
                                            <IconButton onClick={handleSheets} color="primary">
                                                <TextSnippetOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                            <IconButton onClick={handleDelete} color="error">
                                                <DeleteOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                {
                                    props.document.publik &&
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Lien public">
                                        <IconButton onClick={handleDocumentPublik} color="shared">
                                            <ShareOutlined />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    props.document.sheetFileUrl &&
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Envoyer par mail">
                                        <IconButton onClick={handleSendByMail} color="desktop">
                                            <ForwardToInboxIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                {
                                    props.document.sheetFileUrl &&
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                        <Link href={props.document.sheetFileUrl} underline="none" target="_blank">
                                            <IconButton color="primary">
                                                <OpenInNewOutlinedIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                }
                            </Box>
                            {
                                props.document.sizeInfos &&
                                <>
                                    <Divider />
                                    <Flexbox sx={{ justifyContent: "flex-start", mt: 1, mx: 1 }} >
                                        <DatabaseIcon color={props.document.mode} />
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.document.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.document.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                    </Flexbox>
                                </>
                            }
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditDocument
                open={showModalEditDocument}
                handleClose={closeModalEditDocument}
                document={props.document}
            />
            <ModalDeleteConfirm
                open={showDeleteDocumentDialog}
                title="Suppression du document"
                content={`Confirmez-vous la suppression définitive du document ${props.document.title} ?`}
                handleClose={closeDeleteDocumentDialog}
                handleConfirm={confirmedDeleteDocument}
            />
            <ModalSheets
                open={showModalSheets}
                handleClose={closeModalSheets}
                document={props.document}
            />
            <ModalDocumentPublik
                open={showModalDocumentPublik}
                handleClose={closeModalDocumentPublik}
                document={props.document}
            />
            <ModalSendByMailDocument
                open={showModalSendByMailDocument}
                handleClose={closeModalSendByMailDocument}
                document={props.document}
            />
        </>
    )
}
