import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { apolloClient } from "../../../ApolloClient";
import { EnumDateFormat } from "../../../enums";
import { GQL_SEARCH } from "../../../graphql/Queries";
import { IDirectory, IDirectorySearchResult, IDocument, IDocumentSearchResult, ILocker, INoteSearchResult } from "../../../interfaces";
import { dateFormat } from "../../../utils/Utils";
import { IRootState, store } from "../../store";

interface ISearchSliceState {
    searchQuery: string,
    targets: {
        desktopLockers: boolean,
        vaultLockers: boolean,
        sharedLockers: boolean,
        desktopDirectories: boolean,
        vaultDirectories: boolean,
        sharedDirectories: boolean,
        desktopDocuments: boolean,
        vaultDocuments: boolean,
        sharedDocuments: boolean,
        desktopNotes: boolean,
        vaultNotes: boolean,
        sharedNotes: boolean,
    },
    dates: {
        dateFrom: string,
        dateTo: string,
    },
    results: {
        lockers: ILocker[],
        directories: IDirectorySearchResult[],
        documents: IDocumentSearchResult[],
        notes: INoteSearchResult[],
    },
    refetchNeeded: boolean,
}

const initialState: ISearchSliceState = {
    searchQuery: "",
    targets: {
        desktopLockers: false,
        vaultLockers: false,
        sharedLockers: false,
        desktopDirectories: false,
        vaultDirectories: false,
        sharedDirectories: false,
        desktopDocuments: true,
        vaultDocuments: true,
        sharedDocuments: true,
        desktopNotes: false,
        vaultNotes: false,
        sharedNotes: false,
    },
    dates: {
        dateFrom: "",
        dateTo: "",
    },
    results: {
        lockers: [],
        directories: [],
        documents: [],
        notes: [],
    },
    refetchNeeded: true,
}

export const searchSlice = createSlice({
    name: "searchSlice",
    initialState,
    reducers: {
        setSearchParamsAction: (state, action) => {
            // state = initialState
            state.searchQuery = action.payload.searchQuery
            state.targets = action.payload.targets
            state.dates = action.payload.dates
            state.results = initialState.results
            state.refetchNeeded = true
        },
        setSearchResultsAction: (state, action) => {
            state.results = action.payload
            state.refetchNeeded = true
        },
        setSearchRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        toggleExpandNoteAction: (state, action) => {
            const noteId = action.payload
            if (state.results.notes.length > 0) {
                state.results.notes = state.results.notes?.map(
                    note => {
                        return note.id === noteId ? { ...note, expanded: !note.expanded } : note
                    }
                )

            }
        },
    }
})

export const { setSearchParamsAction, setSearchResultsAction, setSearchRefetchNeeded, toggleExpandNoteAction,
} = searchSlice.actions

export const useGetSearchResults = () => {
    const searchQuery: string = useSelector((state: IRootState) => state.searchReducer.searchQuery)
    const targets = useSelector((state: IRootState) => state.searchReducer.targets)
    const dates = useSelector((state: IRootState) => state.searchReducer.dates)
    const results = useSelector((state: IRootState) => state.searchReducer.results)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.searchReducer.refetchNeeded)

    if (refetchNeeded) {
        // console.log("fetching searchResults : searchQuery = ", searchQuery, " targets = ", targets);
        apolloClient.query({
            query: GQL_SEARCH,
            fetchPolicy: 'network-only',
            variables: {
                searchQuery: searchQuery,
                desktopLockers: targets.desktopLockers,
                vaultLockers: targets.vaultLockers,
                sharedLockers: targets.sharedLockers,
                desktopDirectories: targets.desktopDirectories,
                vaultDirectories: targets.vaultDirectories,
                sharedDirectories: targets.sharedDirectories,
                desktopDocuments: targets.desktopDocuments,
                vaultDocuments: targets.vaultDocuments,
                sharedDocuments: targets.sharedDocuments,
                desktopNotes: targets.desktopNotes,
                vaultNotes: targets.vaultNotes,
                sharedNotes: targets.sharedNotes,
                dateFrom: dateFormat(dates.dateFrom, EnumDateFormat.human, EnumDateFormat.sql),
                dateTo: dateFormat(dates.dateTo, EnumDateFormat.human, EnumDateFormat.sql),
            }
        }
        ).then(response => {
            const results = {
                lockers: response.data.search.lockers,
                directories: response.data.search.directories,
                documents: response.data.search.documents,
                notes: response.data.search.notes,
            }
            store.dispatch(setSearchResultsAction(results))
            store.dispatch(setSearchRefetchNeeded(false))
            // console.log("return searchResults by fetching..")
            return results
        })
    } else {
        // console.log("return searchResults by useSeletor..")
        return results
    }
}

export default searchSlice.reducer