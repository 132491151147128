import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    LinearProgressProps,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumBasePathFor, EnumListLockersMode, EnumMessageType } from '../../../enums';
import { IDirectory, IFileToUpload } from '../../../interfaces';
import { DirectoryIcon } from '../../svg_icons/DirectoryIcon';
import { Add, ShareOutlined } from '@mui/icons-material';
import useFileUpload from "../../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { pluralize } from '../../../utils/Utils';
import { GQL_MUTATION_ADD_SHEET, GQL_MUTATION_CREATE_DOCUMENTS } from '../../../graphql/Mutations';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { LinearProgressWithLabel } from '../../spinner/LinearProgressWithLabel';
import { Sheet } from '../../sheets/Sheet';
import { addSheetAction } from '../../../redux/features/directories/directorySlice';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';
import { constructPath, getBasePath } from '../../../utils/Navigation';
import { Flexbox } from '../../utils/Flexbox';
import { CopyToClipboard } from '../../utils/CopyToClipboard';

interface IProps {
    open: boolean;
    handleClose: () => void;
    directory: IDirectory;
}

export const ModalDirectoryPublik: FC<IProps> = (props) => {

    const directoryFullPath = `${window.location.origin}${constructPath(getBasePath(EnumBasePathFor.publikDirectory, EnumListLockersMode.desktop), [
        { key: "directoryId", value: props.directory.id.toString() },
    ])}?access=${props.directory.pubToken.toString()}`
    const theme = useTheme()


    return (
        <div className="ModalDirectoryPublik">
            <Dialog open={props.open} onClose={() => props.handleClose()} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <ShareOutlined color="shared" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Lien public
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Typography sx={{ mt: 1, mb: 4 }}>
                            Envoyez ce lien à quelqu'un pour lui donner accès à votre dossier.
                        </Typography>
                        <Divider flexItem></Divider>
                        <Flexbox direction='column' sx={{
                            width: "100%",
                            my: 2,
                        }}>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: 14 }}>
                                {directoryFullPath}
                            </Typography>
                            <CopyToClipboard
                                text={directoryFullPath}
                                message="Lien public copié."
                                label='Copier'
                                sx={{
                                    mt: 1,
                                    color: theme.palette.shared.main,
                                }}
                            />
                        </Flexbox>
                        <Divider flexItem></Divider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.handleClose()} variant="outlined" color="cancel">Fermer</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
