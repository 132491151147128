import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PublikLayout } from './PublikLayout';
import { useQuery } from '@apollo/client';
import { Spinner } from '../components/spinner/Spinner';
import { GQL_PUBLIK_ALBUM } from '../graphql/Queries';
import { IAlbum } from '../interfaces';
import { addMessage } from '../components/messages/Message';
import { EnumMessageType } from '../enums';
import { PublikAlbumDetails } from '../components/albums/PublikAlbumDetails';
import { ListPublikFotos } from '../components/albums/ListPublikFotos';

interface IProps {
}

export const PublikAlbumPage: FC<IProps> = (props) => {
  const params = useParams()
  const albumId = params.albumId || ""
  const accessParams = new URLSearchParams(window.location.search);
  const pubToken = accessParams.get('access');
  const [publikAlbum, setPublikAlbum] = useState<IAlbum | undefined>(undefined)
  const { data: getPublikAlbumData, loading: getPublikAlbumLoading, error: getPublikAlbumError } = useQuery(GQL_PUBLIK_ALBUM, {
    fetchPolicy: 'network-only',
    variables: {
      albumId,
      pubToken,
    }
  })

  useEffect(() => {
    if (getPublikAlbumError) {
      addMessage({
        location: "PublikAlbumPage",
        type: EnumMessageType.Error,
        message: getPublikAlbumError.message,
      })
    } else if (getPublikAlbumData) {
      setPublikAlbum(getPublikAlbumData.publikAlbum)
    }
  }, [getPublikAlbumData, getPublikAlbumError])


  return (
    <div className="PublikAlbumPage">
      <PublikLayout >
        <div className="page-body">
          {
            getPublikAlbumLoading && <Spinner classes="big-spinner" />
          }
          {
            publikAlbum &&
            <>
              <PublikAlbumDetails album={publikAlbum} />
              <ListPublikFotos album={publikAlbum} />
            </>
          }
        </div>
      </PublikLayout>
    </div >
  )
}
