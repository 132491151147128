import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid2,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumLockerIconMode, EnumMessageType } from '../../enums';
import { IFileToUpload } from '../../interfaces';
import { Add } from '@mui/icons-material';
import useFileUpload from "../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { setAllRefetchNeeded } from '../../redux/store';
import { pluralize } from '../../utils/Utils';
import { GQL_MUTATION_CREATE_QUICK_NOTE, GQL_MUTATION_QUICK_DROP } from '../../graphql/Mutations';
import { LinearProgressWithLabel } from '../spinner/LinearProgressWithLabel';
import { FloatingSpinner } from '../spinner/FloatingSpinner';
import { LockerIcon } from '../svg_icons/LockerIcon';
import { Flexbox } from '../utils/Flexbox';
import { NoteIcon } from '../svg_icons/NoteIcon';
import { AccountOperationIcon } from '../svg_icons/AccountOperationIcon';
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice';
import { QuickAccountOperation } from './QuickAccountOperation';
import Grid from '@mui/material/Grid2';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalQuickDrop: FC<IProps> = (props) => {
    const [filesToUpload, setFilesToUpload] = useState<IFileToUpload[]>([])
    const [noteContent, setNoteContent] = useState("")
    const [quickDrop, { data: quickDropData, loading: quickDropLoading, error: quickDropError }] = useMutation(GQL_MUTATION_QUICK_DROP)
    const [createQuickNote, { data: createQuickNoteData, loading: createQuickNoteLoading, error: createQuickNoteError }] = useMutation(GQL_MUTATION_CREATE_QUICK_NOTE)

    const currentUser = useGetCurrentUser()
    const theme = useTheme()

    useEffect(() => {
        let trigger_mutation = filesToUpload.length === 0 ? false : true
        let sheetFiles: string[] = []
        filesToUpload.forEach(fileToUpload => {
            if (!fileToUpload.sheetFile) {
                trigger_mutation = false
            } else {
                sheetFiles.push(fileToUpload.sheetFile)
            }
        });
        if (trigger_mutation) {
            quickDrop({
                variables: {
                    sheetFiles: sheetFiles,
                }
            })
        }
    }, [filesToUpload])

    useEffect(() => {
        if (quickDropError) {
            addMessage({
                location: "ModalQuickDrop",
                type: EnumMessageType.Error,
                message: quickDropError.message,
            })
        } else if (quickDropData) {
            if (quickDropData.quickDrop.statusCode === 200) {
                cleanDatas()
                setAllRefetchNeeded("ModalQuickDrop")
                addMessage({
                    type: EnumMessageType.Success,
                    message: pluralize("Document créé.", quickDropData.quickDrop.createdCount, `${quickDropData.quickDrop.createdCount} documents créés.`),
                })
                handleClose()
            } else {
                quickDropData.quickDrop.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [quickDropData, quickDropError])

    useEffect(() => {
        if (createQuickNoteError) {
            addMessage({
                location: "ModalQuickDrop",
                type: EnumMessageType.Error,
                message: createQuickNoteError.message,
            })
        } else if (createQuickNoteData) {
            if (createQuickNoteData.createQuickNote.statusCode === 200) {
                cleanDatas()
                setAllRefetchNeeded("ModalQuickDrop")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Note ajoutée.",
                })
                handleClose()
            } else {
                createQuickNoteData.createQuickNote.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createQuickNoteData, createQuickNoteError])

    const cleanDatas = () => {
        setFilesToUpload([])
        setNoteContent("")
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const { handleUpload } = useFileUpload();
    const handlePercentage = (fileIndex: number, paramPercentage: number) => {
        setFilesToUpload((currentFilesToUpload) => {
            return currentFilesToUpload.map(currentFileToUpload => {
                return currentFileToUpload.index === fileIndex ?
                    { ...currentFileToUpload, percentage: paramPercentage } :
                    currentFileToUpload
            }
            )
        })
    }

    const handleFileChange = (files: FileList | null, handleCallback: Function) => {
        if (files && files.length > 0) {
            let newFilesToUpload: IFileToUpload[] = []
            for (let i = 0; i < files.length; i++) {
                newFilesToUpload.push({
                    index: i,
                    percentage: 0,
                    filename: files[i].name,
                })
            }
            setFilesToUpload(newFilesToUpload)
            handleUpload(files, handlePercentage, handleUploadDone);
        }
    }

    const handleUploadDone = (fileIndex: number, fileMetaData: any) => {
        setFilesToUpload(currentFilesToUpload => currentFilesToUpload.map(currentFileToUpload => {
            return currentFileToUpload.index === fileIndex ?
                { ...currentFileToUpload, sheetFile: JSON.stringify(fileMetaData) } :
                currentFileToUpload
        }))
    }

    const handleAddQuickNote = () => {
        createQuickNote({
            variables: {
                content: noteContent,
            }
        })
    }

    return (
        <>
            <FloatingSpinner dependances={[quickDropLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <LockerIcon mode={EnumLockerIconMode.delivery} color="desktop" sx={{ fontSize: 18, mr: 1 }} />
                        <Typography sx={{ fontSize: 18 }}>
                            Dépôt rapide
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                Vous pouvez sélectionner plusieurs fichiers
                            </Typography>
                            <IconButton color="primary" component="label">
                                <input hidden type="file" multiple onChange={(event) => handleFileChange(event.target.files, handlePercentage)} />
                                <Add fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider flexItem></Divider>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column ", justifyContent: "flex-start", alignItems: "flex-start", my: 2 }}>
                            {
                                filesToUpload.length > 0
                                &&
                                filesToUpload.map(fileToUpload => (
                                    <Box key={fileToUpload.index} sx={{ width: "100%" }}>
                                        <Typography>
                                            {fileToUpload.filename}
                                        </Typography>
                                        <LinearProgressWithLabel value={fileToUpload.percentage} color="success" />
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Flexbox direction='column' sx={{
                        // bgcolor: "green",
                        mb: 4,
                    }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            // mb: 1,
                        }}>
                            <AccountOperationIcon sx={{
                                fontSize: 18,
                                mr: 1,
                                color: theme.palette.debit.main,
                            }} />
                            <Typography sx={{ fontSize: 18, color: theme.palette.primary.main }}>
                                Opération rapide
                            </Typography>
                        </Flexbox>
                        <Grid container sx={{
                            // bgcolor: "red",
                            width: "100%",
                        }} >
                            {

                                currentUser?.bankAccountsShortcutted.map((bankAccount, bankAccountIndex) => {
                                    return (
                                        <Grid size={{ xs: 12, md: 6 }} key={`${bankAccount.id}`} sx={{
                                            width: "100%",
                                            // bgcolor: "blue",
                                            borderLeftStyle: {
                                                xs: "none",
                                                md: bankAccountIndex % 2 === 0 ? "inherit" : "solid",
                                            },
                                            borderLeftWidth: 1,
                                            borderLeftColor: theme.palette.cancel.main,
                                            // pl: 2,
                                            display: "flex",
                                            justifyContent: "center",
                                        }}>
                                            <QuickAccountOperation bankAccount={bankAccount} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Flexbox>
                    <Flexbox direction='column' sx={{
                        // bgcolor: "red",
                        // mt: 2,
                    }}>
                        <Flexbox direction='column' sx={{
                            // bgcolor: "yellow",
                            width: "100%",
                        }}>
                            <Flexbox sx={{ justifyContent: "flex-start", width: "100%", mb: 1 }}>
                                <NoteIcon color="desktop" sx={{ fontSize: 18, mr: 1 }} />
                                <Typography sx={{ fontSize: 18, color: theme.palette.primary.main }}>
                                    Note rapide
                                </Typography>
                            </Flexbox>
                            <TextField
                                margin="normal"
                                label="Contenu"
                                fullWidth
                                multiline
                                rows={2}
                                onChange={(event) => { setNoteContent(event.target.value) }}
                                value={noteContent}
                                sx={{ mt: 0 }}
                            />
                        </Flexbox>
                        <Flexbox sx={{ justifyContent: "flex-end", width: "100%", mb: 1 }}>
                            <Button onClick={handleAddQuickNote} variant="text" color="primary">
                                Ajouter
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                </DialogActions>
            </Dialog>
        </ >
    )
}
