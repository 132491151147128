import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { IAlbum } from '../../../interfaces';
import { ShareOutlined } from '@mui/icons-material';
import { NAVIGATE_PUBLIK_ALBUM, constructPath } from '../../../utils/Navigation';
import { Flexbox } from '../../utils/Flexbox';
import { CopyToClipboard } from '../../utils/CopyToClipboard';

interface IProps {
    open: boolean;
    handleClose: () => void;
    album: IAlbum;
}

export const ModalAlbumPublik: FC<IProps> = (props) => {

    const albumFullPath = `${window.location.origin}${constructPath(NAVIGATE_PUBLIK_ALBUM, [
        { key: "albumId", value: props.album.id.toString() },
    ])}?access=${props.album.pubToken.toString()}`
    const theme = useTheme()


    return (
        <div className="ModalAlbumPublik">
            <Dialog open={props.open} onClose={() => props.handleClose()} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <ShareOutlined color="shared" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Lien public
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Typography sx={{ mt: 1, mb: 4 }}>
                            Envoyez ce lien à quelqu'un pour lui donner accès à votre dossier.
                        </Typography>
                        <Divider flexItem></Divider>
                        <Flexbox direction='column' sx={{
                            width: "100%",
                            my: 2,
                        }}>
                            <Typography sx={{ color: theme.palette.primary.main, fontSize: 14 }}>
                                {albumFullPath}
                            </Typography>
                            <CopyToClipboard
                                text={albumFullPath}
                                message="Lien public copié."
                                label='Copier'
                                sx={{
                                    mt: 1,
                                    color: theme.palette.shared.main,
                                }}
                            />
                        </Flexbox>
                        <Divider flexItem></Divider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.handleClose()} variant="outlined" color="cancel">Fermer</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
