import { FC, useEffect, useState } from 'react'
import { Flexbox } from '../utils/Flexbox'
import { ITodolist } from '../../interfaces'
import { Checkbox, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { addMessage } from '../messages/Message'
import { EnumMessageType } from '../../enums'
import { setAllRefetchNeeded } from '../../redux/store'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_DELETE_TODOLIST, GQL_MUTATION_UPDATE_TODOLIST_DONE } from '../../graphql/Mutations'
import { Add, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm'
import { ModalEditTodolist } from './modals/ModalEditTodolist'
import { TodostepPrompt } from './TodostepPrompt'
import { TodostepItem } from './TodostepItem'


interface IProps {
    todolist: ITodolist
}

export const TodolistItem: FC<IProps> = (props) => {
    const [showDeleteTodolistDialog, setShowDeleteTodolistDialog] = useState(false)
    const [showModalEditTodolist, setShowModalEditTodolist] = useState(false)
    const [showAddTodostep, setShowAddTodostep] = useState(false)


    const [updateTodolistDone, { data: updateTodolistDoneData, loading: updateTodolistDoneLoading, error: updateTodolistDoneError }] = useMutation(GQL_MUTATION_UPDATE_TODOLIST_DONE)
    const [deleteTodolist, { data: deleteTodolistData, loading: deleteTodolistLoading, error: deleteTodolistError }] = useMutation(GQL_MUTATION_DELETE_TODOLIST)

    const theme = useTheme()

    useEffect(() => {
        if (updateTodolistDoneError) {
            addMessage({
                location: "TodolistItem",
                type: EnumMessageType.Error,
                message: updateTodolistDoneError.message,
            })
        } else if (updateTodolistDoneData) {
            if (updateTodolistDoneData.updateTodolistDone.statusCode === 200) {
                setAllRefetchNeeded("TodolistItem")
                // addMessage({
                //     type: EnumMessageType.Success,
                //     message: "Action modifiée.",
                // })
            } else {
                updateTodolistDoneData.updateTodolistDone.errors.map((error: string) => {
                    addMessage({
                        location: "TodolistItem",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateTodolistDoneData, updateTodolistDoneError])

    useEffect(() => {
        if (deleteTodolistError) {
            addMessage({
                location: "TodolistItem",
                type: EnumMessageType.Error,
                message: deleteTodolistError.message,
            })
        } else if (deleteTodolistData) {
            if (deleteTodolistData.deleteTodolist.statusCode === 200) {
                setAllRefetchNeeded("TodolistItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Action supprimée.",
                })
            } else {
                deleteTodolistData.deleteTodolist.errors.map((error: string) => {
                    addMessage({
                        location: "TodolistItem",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteTodolistData, deleteTodolistError])

    const setTodolistDone = () => {
        updateTodolistDone({
            variables: {
                todolistId: props.todolist.id,
                done: !props.todolist.done,
            }
        })
    }

    const handleDelete = () => {
        setShowDeleteTodolistDialog(true)
    }

    const closeDeleteTodolistDialog = () => {
        setShowDeleteTodolistDialog(false)
    }

    const confirmedDeleteTodolist = () => {
        deleteTodolist({
            variables: {
                todolistId: props.todolist.id
            }
        })
        setShowDeleteTodolistDialog(false)
    }

    const handleEdit = () => {
        setShowModalEditTodolist(true)
    }

    const closeModalEditTodolist = () => {
        setShowModalEditTodolist(false)
    }

    const handleAddTodostep = () => {
        setShowAddTodostep(!showAddTodostep)
    }

    return (
        <>
            <Flexbox direction='column' sx={{
                // bgcolor: "red",
                width: "100%",
                alignItems: "flex-start",
            }}>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "space-between",
                }}>
                    <Flexbox sx={{
                        justifyContent: "flex-start",
                        pl: 1,
                    }}>
                        <Checkbox
                            checked={props.todolist.done}
                            value={props.todolist.done}
                            onChange={setTodolistDone}
                            color='primary'
                        />
                        <Typography sx={{
                            color: theme.palette.primary.main,
                            textDecoration: props.todolist.done ? "line-through" : "none",
                        }}>
                            {props.todolist.title}
                        </Typography>
                    </Flexbox>
                    <Flexbox sx={{
                        justifyContent: "flex-end",
                        pr: 1,
                    }}>
                        {
                            props.todolist.done && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton
                                    onClick={handleDelete}
                                    size='small'
                                    sx={{
                                        color: theme.palette.cancel.main,
                                        ":hover": {
                                            color: theme.palette.error.main,
                                        }
                                    }}>
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            !props.todolist.done &&
                            <>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter une étape">
                                    <IconButton
                                        onClick={handleAddTodostep}
                                        size='small'
                                        sx={{
                                            color: theme.palette.cancel.main,
                                            ":hover": {
                                                color: theme.palette.primary.main,
                                            }
                                        }}>
                                        <Add fontSize="small" sx={{ transform: showAddTodostep ? "rotate(45deg)" : "none" }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                    <IconButton
                                        onClick={handleEdit}
                                        size='small'
                                        sx={{
                                            color: theme.palette.cancel.main,
                                            ":hover": {
                                                color: theme.palette.primary.main,
                                            }
                                        }}>
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </Flexbox>
                </Flexbox>
                {
                    showAddTodostep && <TodostepPrompt todolist={props.todolist} />
                }
                {
                    !props.todolist.done &&
                    <Flexbox direction='column' sx={{
                        // bgcolor: "green",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        {
                            props.todolist.todosteps.map(todostep => {
                                return (
                                    <TodostepItem key={todostep.id} todostep={todostep} />
                                )
                            })
                        }
                    </Flexbox>
                }
            </Flexbox>
            <ModalDeleteConfirm
                open={showDeleteTodolistDialog}
                title="Suppression de l'action"
                content={`Confirmez-vous la suppression définitive de l'action ${props.todolist.title} ?`}
                handleClose={closeDeleteTodolistDialog}
                handleConfirm={confirmedDeleteTodolist}
            />
            <ModalEditTodolist
                open={showModalEditTodolist}
                handleClose={closeModalEditTodolist}
                todolist={props.todolist}
            />
        </>
    )
}
