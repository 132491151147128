import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumMessageType, EnumShareLevel, EnumSize } from '../../../enums';
import { ILocker, IUser } from '../../../interfaces';
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { GQL_MUTATION_CREATE_PARTNERSHIP, GQL_MUTATION_SHARE_LOCKER } from '../../../graphql/Mutations';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { UserBadge } from '../../UserBadge';
import { useGetCurrentUser, setCurrentUserRefetchNeeded, useGetPartners } from '../../../redux/features/users/currentUserSlice';
import { setAllRefetchNeeded } from '../../../redux/store';
import { updateLockerAction } from '../../../redux/features/lockers/lockersSlice';
import { updateLockerAction as updateLockerDetailsAction } from '../../../redux/features/lockers/lockerSlice';
import { Flexbox } from '../../utils/Flexbox';

interface IProps {
    open: boolean;
    handleClose: () => void;
    locker: ILocker;
}

export const ModalShareLocker: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const partners = useGetPartners()
    const [triggerSetAllRefetchNeededOnClose, setTriggerSetAllRefetchNeededOnClose] = useState(false)
    const [shareLocker, { data: shareLockerData, loading: shareLockerLoading, error: shareLockerError }] = useMutation(GQL_MUTATION_SHARE_LOCKER)
    const dispatch = useDispatch()

    useEffect(() => {
        if (shareLockerError) {
            addMessage({
                location: "ModalShareLocker",
                type: EnumMessageType.Error,
                message: shareLockerError.message,
            })
        } else if (shareLockerData) {
            if (shareLockerData.shareLocker.statusCode === 200) {
                // setAllRefetchNeeded("ModalShareLocker")
                const updatedLocker: ILocker = shareLockerData.shareLocker.locker
                dispatch(updateLockerAction(updatedLocker))
                dispatch(updateLockerDetailsAction(updatedLocker))
                // setTriggerSetAllRefetchNeededOnClose(true)
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Partage effectué.",
                })
                // handleClose()
            } else {
                shareLockerData.shareLocker.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [shareLockerData, shareLockerError])

    const handleClose = () => {
        if (triggerSetAllRefetchNeededOnClose) {
            setAllRefetchNeeded("ModalShareLocker")
        }
        props.handleClose()
    }

    const handleShareLocker = (user: IUser, shareLevel: EnumShareLevel) => {
        if (currentUser) {
            shareLocker({
                variables: {
                    lockerId: props.locker.id,
                    userId: user.id,
                    shareLevel,
                }
            })
        }
    }

    return (
        <div className="ModalShareLocker">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <HandshakeOutlinedIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: { xs: 18, md: 24 } }}>
                            Partage du casier
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        {
                            currentUser &&
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <UserBadge user={currentUser} badge={{ label: "propriétaire", color: "desktop" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button size='small' color='shared' onClick={() => handleShareLocker(currentUser, EnumShareLevel.viewer)}>Partage</Button>
                                    <Button size='small' color='cancel' onClick={() => handleShareLocker(currentUser, EnumShareLevel.none)}>Annuler</Button>
                                </Box>
                            </Box>
                        }
                        {
                            partners && partners.map(partner => {
                                return (
                                    <Flexbox sx={{ width: "100%" }} key={`partner-box-${partner.id}`}>
                                        {
                                            props.locker.owners.map(owner => owner.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} badge={{ label: "propriétaire", color: "desktop" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='shared' onClick={() => handleShareLocker(partner, EnumShareLevel.viewer)}>Partage</Button>
                                                    <Button size='small' color='cancel' onClick={() => handleShareLocker(partner, EnumShareLevel.none)}>Annuler</Button>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            props.locker.viewers.map(viewer => viewer.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} badge={{ label: "partagé", color: "shared" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='desktop' onClick={() => handleShareLocker(partner, EnumShareLevel.owner)}>Propriétaire</Button>
                                                    <Button size='small' color='cancel' onClick={() => handleShareLocker(partner, EnumShareLevel.none)}>Annuler</Button>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            !props.locker.owners.map(owner => owner.id).includes(partner.id) &&
                                            !props.locker.viewers.map(viewer => viewer.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='desktop' onClick={() => handleShareLocker(partner, EnumShareLevel.owner)}>Propriétaire</Button>
                                                    <Button size='small' color='shared' onClick={() => handleShareLocker(partner, EnumShareLevel.viewer)}>Partage</Button>
                                                </Box>
                                            </Box>
                                        }
                                    </Flexbox>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
