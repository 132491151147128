import "../../css/components/notes/Note.css"
import "../../css/components/notes/markdown.css"
import { Box, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react'
import { IDocument, INote } from "../../interfaces";
import { DeleteOutline, CropFreeOutlined, EditOutlined } from '@mui/icons-material';
import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_DELETE_DOCUMENT, GQL_MUTATION_DELETE_NOTE } from "../../graphql/Mutations";
import { dateFormat, truncateString } from "../../utils/Utils";
import { removeDocumentAction, toggleExpandNoteAction, toggleSelectNoteAction } from "../../redux/features/directories/directorySlice";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addNotesAction as addNotesInPocketAction,
    removeNotesAction as removeNotesFromPocketAction,
    useGetNotes as useGetPocketNotes,
} from "../../redux/features/pocket/pocketSlice";
import { useNavigate } from "react-router-dom";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { NoteIcon } from '../svg_icons/NoteIcon';
import { ModalEditNote } from './modals/ModalEditNote';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';


interface IProps {
    note: INote,
}

export const PublikNote: FC<IProps> = (props) => {
    const [hasOverflow, setHasOverflow] = useState(false);
    const markdownBoxRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (markdownBoxRef.current) {
            const { scrollHeight, clientHeight } = markdownBoxRef.current;
            setHasOverflow(scrollHeight > clientHeight);
        }
    }, []);

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <Paper className="Note">
                <Box className="Note-container">
                    <Box className="Note-main-section">
                        <Box className="Note-icon-section">
                            <NoteIcon color={EnumListLockersMode.shared} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Box className="Note-content-section">
                            <Box className="Note-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24, overflowWrap: "anywhere" }}>
                                    {props.note.title}
                                </Typography>
                                {
                                    hasOverflow &&
                                    <ExpandMore
                                        expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }
                            </Box>
                            <Box
                                ref={markdownBoxRef}
                                className="Note-markdown markdown-body"
                                sx={{
                                    maxHeight: !expanded ? "200px" : "none",
                                    overflowY: !expanded ? "hidden" : "visible",
                                }}
                            >
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} >
                                    {props.note.content}
                                </ReactMarkdown>
                            </Box>
                        </Box>

                    </Box>
                    <Divider />
                </Box>
            </Paper>
        </>
    )
}
