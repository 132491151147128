import "../../css/components/documents/Document.css";
import { Box, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IDocument } from "../../interfaces";
import { EnumListLockersMode } from "../../enums";
import { dateFormat, truncateString } from "../../utils/Utils";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { Flexbox } from "../utils/Flexbox";

interface IProps {
    document: IDocument,
}

export const PublikDocument: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const theme = useTheme()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    return (
        <>
            <Paper className="Document">
                <Box className="Document-container">
                    <Box className="Document-main-section">
                        <Box className="Document-icon-section">
                            <DocumentIcon color={EnumListLockersMode.shared} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                            {
                                props.document.sheetPreviewUrl && <Flexbox sx={{ px: 1, py: 1 }}>
                                    {!previewLoaded && <Skeleton variant="rectangular" width={100} height={100} sx={{ borderRadius: 1 }} />}
                                    <Box component="img"
                                        src={props.document.sheetPreviewUrl}
                                        onLoad={handlePreviewLoaded}
                                        sx={{
                                            width: "100px",
                                            borderRadius: 1,
                                            borderStyle: "solid",
                                            borderWidth: "1px",
                                            borderColor: theme.palette.grey[200],
                                            display: previewLoaded ? 'block' : 'none',
                                        }}
                                    />
                                </Flexbox>
                            }
                            <Flexbox direction="column" sx={{
                                pl: "4px",
                                pb: "4px",
                            }}>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Typography color="text.primary" sx={{ fontSize: 18, overflowWrap: "anywhere" }}>
                                        {props.document.title}
                                    </Typography>
                                </Flexbox>
                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Typography sx={{ fontSize: 14 }} >
                                        {truncateString(props.document.description, 80)}
                                    </Typography>
                                </Flexbox>

                                <Flexbox sx={{ justifyContent: "flex-start" }}>
                                    <Flexbox sx={{ justifyContent: "flex-start", mt: 1 }}>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Dernière modification">
                                            <EventOutlinedIcon color={EnumListLockersMode.shared} sx={{ fontSize: 18, mr: .5 }} />
                                        </Tooltip>
                                        <Typography color="text.secondary" sx={{ fontSize: 14 }}>
                                            {`${dateFormat(props.document.updatedAt)}`}
                                        </Typography>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>

                        </Flexbox>

                    </Box>
                    <Flexbox sx={{
                        justifyContent: "flex-end",
                    }}>
                        {
                            props.document.sheetFileUrlForDownload &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.document.sheetFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Flexbox>
                </Box>
            </Paper>
        </>
    )
}
