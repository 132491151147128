import "../../css/components/directories/Directory.css";
import { Box, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IDirectory } from "../../interfaces";
import { DeleteOutline, EditOutlined, ShareOutlined } from '@mui/icons-material';
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumBasePathFor, EnumParentType, EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_DELETE_DIRECTORY } from "../../graphql/Mutations";
import { truncateString } from "../../utils/Utils";
import {
    removeDirectoryAction,
    toggleExpandDirectoryAction,
    toggleSelectDirectoryAction,
} from "../../redux/features/lockers/lockerSlice";
import { removeChildAction, toggleExpandChildAction, toggleSelectChildAction } from "../../redux/features/directories/directorySlice";
import { ModalEditDirectory } from "./modals/ModalEditDirectory";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addDirectoriesAction as addDirectoriesInPocketAction,
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
    useGetDirectories as useGetPocketDirectories,
} from "../../redux/features/pocket/pocketSlice";
import { useNavigate } from "react-router-dom";
import { constructPath, getBasePath } from "../../utils/Navigation";
import { DirectoryIcon } from "../svg_icons/DirectoryIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { Flexbox } from "../utils/Flexbox";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { ModalDirectoryPublik } from "./modals/ModalDirectoryPublik";

interface IProps {
    directory: IDirectory,
    // mode: EnumListLockersMode,
    directoryParentType: EnumParentType,
}

export const Directory: FC<IProps> = (props) => {
    const [showDeleteDirectoryDialog, setShowDeleteDirectoryDialog] = useState(false)
    const [showModalEditDirectory, setShowModalEditDirectory] = useState(false)
    const pocketDirectories = useGetPocketDirectories()
    const [showModalDirectoryPublik, setShowModalDirectoryPublik] = useState(false)

    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const [deleteDirectory, { data: deleteDirectoryData, loading: deleteDirectoryLoading, error: deleteDirectoryError }] = useMutation(GQL_MUTATION_DELETE_DIRECTORY)
    const dispatch = useDispatch()

    const theme = useTheme()
    const navigate = useNavigate()

    const directoryPath = constructPath(getBasePath(EnumBasePathFor.directory, props.directory.mode), [
        { key: "directoryId", value: props.directory.id.toString() }
    ])

    const closeModalEditDirectory = () => {
        setShowModalEditDirectory(false)
    }

    useEffect(() => {
        if (deleteDirectoryError) {
            addMessage({
                location: "Directory",
                type: EnumMessageType.Error,
                message: deleteDirectoryError.message,
            })
        } else if (deleteDirectoryData) {
            if (deleteDirectoryData.deleteDirectory.statusCode === 200) {
                if (props.directoryParentType === EnumParentType.locker) {
                    dispatch(removeDirectoryAction(props.directory.id))
                } else if (props.directoryParentType === EnumParentType.directory) {
                    dispatch(removeChildAction(props.directory.id))
                }
                // dispatch(removeDirectoriesFromPocketAction([props.directory.id]))
                // dispatch(setLockersRefetchNeeded(true))
                // dispatch(setLockerRefetchNeeded(true))
                // dispatch(setPocketRefetchNeeded(true))
                setAllRefetchNeeded("Directory")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier supprimé.",
                })
            } else {
                deleteDirectoryData.deleteDirectory.errors.map((error: string) => {
                    addMessage({
                        location: "Directory",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDirectoryData, deleteDirectoryError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Directory",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                // const addedDirectories: IDirectory[] = addInPocketData.addInPocket.directories
                // dispatch(addDirectoriesInPocketAction(addedDirectories))
                dispatch(addDirectoriesInPocketAction([props.directory]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
                // // Il peut y avoir certains qui n'ont pas fonctionner:
                // const errors: string[] = addInPocketData.addInPocket.errors
                // errors.map( error => addMessage({
                //     type: EnumMessageType.Warning,
                //     message: error,
                // }))

            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Directory",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDirectoriesFromPocketAction([props.directory.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    const handleEdit = () => {
        setShowModalEditDirectory(true)
    }

    const handleDelete = () => {
        setShowDeleteDirectoryDialog(true)
    }

    const closeDeleteDirectoryDialog = () => {
        setShowDeleteDirectoryDialog(false)
    }

    const confirmedDeleteDirectory = () => {
        deleteDirectory({
            variables: {
                directoryId: props.directory.id
            }
        })
        setShowDeleteDirectoryDialog(false)
    }

    const handleSelect = () => {
        if (props.directoryParentType === EnumParentType.locker) {
            dispatch(toggleSelectDirectoryAction(props.directory.id))
        } else if (props.directoryParentType === EnumParentType.directory) {
            dispatch(toggleSelectChildAction(props.directory.id))
        }
    }

    const handleExpand = () => {
        if (props.directoryParentType === EnumParentType.locker) {
            dispatch(toggleExpandDirectoryAction(props.directory.id))
        } else if (props.directoryParentType === EnumParentType.directory) {
            dispatch(toggleExpandChildAction(props.directory.id))
        }
    }

    const handlePutInPocket = () => {
        // dispatch(addDirectoryInPocketAction(props.directory))
        addInPocket({
            variables: {
                directoryIds: [props.directory.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        // dispatch(removeDirectoryFromPocketAction(props.directory.id))
        removeFromPocket({
            variables: {
                directoryIds: [props.directory.id],
            }
        })
    }

    const isInPocket = () => {
        if (pocketDirectories) {
            return pocketDirectories.filter(directory => directory.id === props.directory.id).length > 0
        }
        return false
    }

    const handleDirectoryPublik = () => {
        setShowModalDirectoryPublik(true)
    }

    const closeModalDirectoryPublik = () => {
        setShowModalDirectoryPublik(false)
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteDirectoryLoading]} />
            <Paper className={`Directory ${props.directory.selected ? "selected" : ""} `}>
                <Box className="Directory-container">
                    <Box className="Directory-main-section">
                        <Box className="Directory-icon-section">
                            <DirectoryIcon onClick={handleSelect} color={props.directory.publik ? EnumListLockersMode.shared : props.directory.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6, ":hover": { cursor: "pointer" } }} />
                        </Box>
                        <Box className="Directory-content-section">
                            <Box className="Directory-title-section">
                                <Typography
                                    onClick={() => navigate(directoryPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}>
                                    {props.directory.name}
                                </Typography>
                            </Box>
                            <Typography >
                                {truncateString(props.directory.description, 80)}
                            </Typography>
                            <Box className="Directory-infos-section">
                                <Box className="Directory-infos-directories" sx={{ mt: 1 }}>
                                    <SubDirectoriesInfos mode={props.directory.mode} detailsMode={false} parentType={EnumParentType.directory} directory={props.directory} />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    <Box className="Directory-footer-section">
                        <ExpandMore expand={props.directory.expanded == undefined ? false : props.directory.expanded}
                            onClick={handleExpand} aria-expanded={props.directory.expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        {/* <Button size="small" onClick={() => navigate(directoryPath)}>Ouvrir</Button> */}
                    </Box>
                    <Box className="Directory-expanded-section">
                        <Collapse in={props.directory.expanded} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box className="Locker-expanded-section-actions" sx={{ display: "flex", justifyContent: "space-between" }}>
                                {/* <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.directory.selected ? "Désélectionner" : "Sélectionner"}>
                                    <IconButton onClick={handleSelect} color={props.directory.selected ? "primary" : "secondary"}>
                                        <CropFreeOutlined />
                                    </IconButton>
                                </Tooltip> */}
                                {
                                    isInPocket() ?
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                            <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                                <PocketIcon variant={EnumSvgVariant.secondary} />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                            <IconButton onClick={handlePutInPocket} color="primary">
                                                <PocketIcon variant={EnumSvgVariant.primary} />
                                            </IconButton>
                                        </Tooltip>
                                }
                                {
                                    props.directory.mode !== EnumListLockersMode.shared &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                            <IconButton onClick={handleEdit} color="primary">
                                                <EditOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            props.directory.publik &&
                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Lien public">
                                                <IconButton onClick={handleDirectoryPublik} color="shared">
                                                    <ShareOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                            <IconButton onClick={handleDelete} color="error">
                                                <DeleteOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                    <Link href={directoryPath} underline="none" target="_blank">
                                        <IconButton color="primary">
                                            <OpenInNewOutlinedIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Box>
                            {
                                props.directory.sizeInfos &&
                                <>
                                    <Divider />
                                    <Flexbox sx={{ justifyContent: "flex-start", mt: 1, mx: 1 }} >
                                        <DatabaseIcon color={props.directory.mode} />
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.directory.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.directory.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                    </Flexbox>
                                </>
                            }
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditDirectory
                open={showModalEditDirectory}
                handleClose={closeModalEditDirectory}
                directory={props.directory}
            />
            <ModalDeleteConfirm
                open={showDeleteDirectoryDialog}
                title="Suppression du dossier"
                content={`Confirmez-vous la suppression définitive du dossier ${props.directory.name} ?`}
                handleClose={closeDeleteDirectoryDialog}
                handleConfirm={confirmedDeleteDirectory}
            />
            <ModalDirectoryPublik
                open={showModalDirectoryPublik}
                handleClose={closeModalDirectoryPublik}
                directory={props.directory}
            />
        </>
    )
}
