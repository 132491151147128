import { FC } from 'react'
import { ListLockers } from '../components/lockers/ListLockers'
import { EnumListLockersMode } from '../enums'
import { Layout } from "./Layout"

interface IProps {
}

export const Shared: FC<IProps> = (props) => {

  return (
    <div className="Shared">
      <Layout>
        <div className="page-body">
          <ListLockers mode={EnumListLockersMode.shared} />
        </div>
      </Layout>
    </div >
  )
}
