import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Link,
    TextField,
    Typography,
    useTheme,
    CircularProgress
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { IPassword } from '../../../interfaces';
import { KeysIcon } from '../../svg_icons/KeysIcon';
import { Flexbox } from '../../utils/Flexbox';
import { AES, enc } from 'crypto-js';
import { CopyToClipboard } from '../../utils/CopyToClipboard';

interface IProps {
    open: boolean,
    handleClose: () => void,
    password: IPassword,
}

export const ModalSeePassword: FC<IProps> = (props) => {
    const [codePin, setCodePin] = useState("")
    const codePinRef = useRef<HTMLInputElement>(null);
    const [reveal, setReveal] = useState(false)
    const theme = useTheme()

    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        if (timeLeft === 0) {
            console.log('Le compte à rebours est terminé !')
            setCodePin("")
            if (reveal) {
                setReveal(false)
            }
        } else {
            const timerId = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1)
            }, 1000)
            // Nettoyer le timer lorsque le composant est démonté ou lorsque le temps est écoulé
            return () => clearInterval(timerId);
        }
    }, [timeLeft])

    useEffect(() => {
        if (reveal) {
            setTimeLeft(10)
        } else {
            setTimeLeft(0)
        }
    }, [reveal])

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (codePinRef.current) {
                    codePinRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    const cleanDatas = () => {
        setCodePin("")
        setReveal(false)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const handleCodePin = (newCodePin: string) => {
        setReveal(false)
        setCodePin(newCodePin)
    }

    const handleReveal = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // setReveal(!reveal)
        setReveal(currentReveal => !currentReveal)

    }

    const decrypt = () => {
        let res = ""
        try {
            res = AES.decrypt(props.password.encryptedPassword, codePin).toString(enc.Utf8)
        } catch (error) {
            console.error(error)
        }
        return res
    }

    return (
        <div className="ModalSeePassword">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleReveal}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <KeysIcon color="secondary" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Révéler le password
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Flexbox direction='column'>
                            <Typography>
                                {props.password.title}
                            </Typography>
                            <Link href={props.password.url} target="_blank"
                                sx={{
                                    color: theme.palette.info.main,
                                    fontSize: 16,
                                }}
                            >
                                {props.password.url}
                            </Link>
                        </Flexbox>
                        <TextField
                            inputRef={codePinRef}
                            autoFocus
                            margin="dense"
                            label="Code PIN"
                            fullWidth
                            variant="standard"
                            type="password"
                            onChange={(event) => { handleCodePin(event.target.value) }}
                            value={codePin}
                        />
                        <Flexbox direction='column' sx={{
                            boxSizing: "border-box",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderColor: theme.palette.secondary.light,
                            borderRadius: "4px",
                            color: theme.palette.secondary.main,
                            mt: 2,
                            alignItems: "flex-start",
                        }}>
                            <Grid container spacing={0} sx={{
                                width: "100%",
                                px: 1,
                                py: 1,
                            }}>
                                <Grid size={{ xs: 12, md: 3 }} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography>
                                        Login:
                                    </Typography>
                                </Grid>
                                {
                                    reveal &&
                                    <>
                                        <Grid size={{ xs: 11, md: 8 }} sx={{ display: "flex", alignItems: "center" }} >
                                            <Typography>
                                                {props.password.login}
                                            </Typography>

                                        </Grid>
                                        <Grid size={{ xs: 1 }} sx={{ display: "flex", justifyContent: "center" }}>
                                            <CopyToClipboard
                                                text={props.password.login}
                                                message="Login copié dans le presse-papier."
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <Grid container spacing={0} sx={{
                                width: "100%",
                                px: 1,
                                py: 1,
                            }}>
                                <Grid size={{ xs: 12, md: 3 }} sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography>
                                        Mot de passe:
                                    </Typography>
                                </Grid>
                                {
                                    reveal && decrypt().length > 0 &&
                                    <>
                                        <Grid size={{ xs: 11, md: 8 }} sx={{ display: "flex", alignItems: "center" }} >
                                            <Typography>
                                                {decrypt()}
                                            </Typography>

                                        </Grid>
                                        <Grid size={{ xs: 1 }} sx={{ display: "flex", justifyContent: "center" }}>
                                            <CopyToClipboard
                                                text={decrypt()}
                                                message="Mot de passe copié dans le presse-papier."
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Flexbox>


                    </DialogContent>
                    <DialogActions>
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            // bgcolor: "red",
                            // boxSizing: "border-box",
                            mb: .1,
                        }}>

                            <Flexbox sx={{
                                justifyContent: "flex-start",
                            }}>
                                {
                                    timeLeft > 0 && <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress color='secondary' />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                color="text.secondary"
                                            >{timeLeft}</Typography>
                                        </Box>
                                    </Box>
                                }

                            </Flexbox>


                            <Flexbox sx={{
                                // bgcolor: "red",
                                gap: 2,
                                justifyContent: "flex-end",
                            }}>
                                <Button onClick={handleClose} variant="outlined" color="cancel">Fermer</Button>
                                <Button type="submit" variant="outlined" color="secondary">
                                    {
                                        reveal
                                            ?
                                            "Cacher"
                                            :
                                            "Révéler"
                                    }
                                </Button>

                            </Flexbox>
                        </Flexbox>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
