import { Box, Container, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ModalNewFotos } from './modals/ModalNewFotos';
import { Foto } from './Foto';
import { Video as VideoComponent } from './Video';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Video from "yet-another-react-lightbox/plugins/video";
import { IAlbum } from '../../interfaces';
import { EnumAlbumItemType } from '../../enums';
import { Audio } from './Audio';


interface IProps {
    album: IAlbum
}

export const ListFotos: FC<IProps> = (props) => {
    const [showModalNewFotos, setShowModalNewFotos] = useState(false)
    const [openedLightbox, setOpenedLightbox] = useState<boolean>(false)
    const [lightboxIndex, setLightboxIndex] = useState<number>(0)

    const closeModalNewFotos = () => {
        setShowModalNewFotos(false)
    }

    const handleFotoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    const handleVideoClick = (index: number) => {
        setLightboxIndex(index)
        setOpenedLightbox(true)
    }

    return (
        <div className='ListFotos'>
            <Container className="ListFotos-actions"
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: "12px",
                }}
            >
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter des photos">
                    <IconButton color="primary" onClick={() => setShowModalNewFotos(true)}><CloudUploadOutlinedIcon fontSize="medium" /></IconButton>
                </Tooltip>
                <ModalNewFotos
                    album={props.album}
                    open={showModalNewFotos}
                    handleClose={closeModalNewFotos}
                />
            </Container>

            <Container className="ListFotos-container">
                <Grid container spacing={0} className="ListFotos-grid-container">
                    {
                        props.album.albumItems?.map((albumItem, index) => {
                            if (albumItem.albumItemType === EnumAlbumItemType.foto && albumItem.foto) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Foto
                                            foto={albumItem.foto}
                                            onClick={handleFotoClick}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            }
                            if (albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <VideoComponent
                                            video={albumItem.video}
                                            onClick={handleVideoClick}
                                            index={index}
                                        />
                                    </Grid>
                                )
                            }
                            if (albumItem.albumItemType === EnumAlbumItemType.audio && albumItem.audio) {
                                return (
                                    <Grid key={`${albumItem.id}`} size={{ xs: 12, md: 6, lg: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Audio
                                            audio={albumItem.audio}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                    {
                        props.album !== undefined && props.album.albumItems !== undefined && props.album.albumItems.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucune photo.
                            </Typography>
                        </Box>
                    }
                    {
                        props.album && props.album.albumItems && < Lightbox
                            open={openedLightbox}
                            index={lightboxIndex}
                            close={() => setOpenedLightbox(false)}
                            slides={
                                props.album.albumItems.filter((albumItem) => [EnumAlbumItemType.foto, EnumAlbumItemType.video].includes(albumItem.albumItemType)).map((albumItem) => {
                                    if (albumItem.albumItemType === EnumAlbumItemType.video && albumItem.video) {
                                        return {
                                            type: "video",
                                            autoPlay: true,
                                            // width: 1280,
                                            // height: 720,
                                            width: 3840,
                                            height: 2560,
                                            poster: albumItem.video.videoPreviewUrl,
                                            sources: [
                                                {
                                                    src: albumItem.video.mp4VideoFileUrl,
                                                    type: "video/mp4",
                                                },
                                            ],
                                        }
                                    } else {
                                        return {
                                            src: albumItem.foto?.fotoFileUrl || "",
                                            alt: `photo ${albumItem.foto?.id}`,
                                            width: 3840,
                                            height: 2560,
                                            srcSet: [
                                                { src: albumItem.foto?.fotoPreviewUrl || "", width: 300, height: 300 },
                                                { src: albumItem.foto?.fotoFileUrl || "", width: 3840, height: 2560 },
                                            ],
                                        }

                                    }

                                })
                            }
                            plugins={[Fullscreen, Slideshow, Video]}
                            fullscreen={{
                                auto: true
                            }}
                        />
                    }
                </Grid>
            </Container>
        </div>
    )
}
