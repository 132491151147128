import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_PASSWORD } from '../../../graphql/Mutations';
import { IPassword, ILocker } from '../../../interfaces';
import { setAllRefetchNeeded } from '../../../redux/store';
// import { addLockerAction } from '../../../redux/features/lockers/lockersSlice';
import { addMessage } from '../../messages/Message';
import { KeysIcon } from '../../svg_icons/KeysIcon';
import { Flexbox } from '../../utils/Flexbox';
import { AES, enc } from 'crypto-js';
import { truncateString } from '../../../utils/Utils';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalNewPassword: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const titleRef = useRef<HTMLInputElement>(null);
    const [url, setUrl] = useState("")
    const [login, setLogin] = useState("")
    const [decryptedPassword, setDecryptedPassword] = useState("")
    const [codePin, setCodePin] = useState("")
    const [encryptedPassword, setEncryptedPassword] = useState("")
    const [description, setDescription] = useState("")
    const [createPassword, { data: createPasswordData, loading: createPasswordLoading, error: createPasswordError }] = useMutation(GQL_MUTATION_CREATE_PASSWORD)
    const theme = useTheme()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createPasswordError) {
            addMessage({
                location: "ModalNewPassword",
                type: EnumMessageType.Error,
                message: createPasswordError.message,
            })
        } else if (createPasswordData) {
            if (createPasswordData.createPassword.statusCode === 200) {
                setAllRefetchNeeded("ModalNewPassword")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Password ajouté.",
                })
                handleClose()
            } else {
                createPasswordData.createPassword.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createPasswordData, createPasswordError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setUrl("")
        setLogin("")
        setDecryptedPassword("")
        setCodePin("")
        setEncryptedPassword("")
        setDescription("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createPassword({
                variables: {
                    title,
                    url,
                    description,
                    login,
                    encryptedPassword,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const handleDecryptedPassword = (newDecryptedPassword: string) => {
        setDecryptedPassword(newDecryptedPassword)
        encryptPassword(newDecryptedPassword, codePin)
    }

    const handleCodePin = (newCodePin: string) => {
        setCodePin(newCodePin)
        encryptPassword(decryptedPassword, newCodePin)
    }

    const encryptPassword = (givenDecryptedPassword: string, givenCodePin: string) => {
        // const newEncryptedPassword = AES.encrypt(decryptedPassword, codePin).toString(enc.Utf8)
        const newEncryptedPassword = AES.encrypt(givenDecryptedPassword, givenCodePin).toString()
        setEncryptedPassword(newEncryptedPassword)
    }

    return (
        <div className="ModalNewPassword">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <KeysIcon color="secondary" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouveau password
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Titre"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="dense"
                            label="URL"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setUrl(event.target.value) }}
                            value={url}
                            // helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                            placeholder='https://gmail.com'
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                        <TextField
                            margin="dense"
                            label="Login"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setLogin(event.target.value) }}
                            value={login}
                        />
                        <TextField
                            margin="dense"
                            label="Mot de passe"
                            fullWidth
                            variant="standard"
                            type="password"
                            onChange={(event) => { handleDecryptedPassword(event.target.value) }}
                            value={decryptedPassword}
                            helperText="Saisissez ici le vrai mot de passe, il sera crypté avec le code PIN."
                        />
                        <TextField
                            margin="dense"
                            label="Code PIN"
                            fullWidth
                            variant="standard"
                            type="password"
                            onChange={(event) => { handleCodePin(event.target.value) }}
                            value={codePin}
                            helperText="Conseil: Utilisez un code PIN à 4 chiffres, le même pour tous vos passwords."
                        />
                        <Flexbox direction='column' sx={{
                            backgroundColor: theme.palette.secondary.light,
                            borderRadius: "4px",
                            color: theme.palette.secondary.contrastText,
                        }}>
                            <Typography>
                                Mot de passe crypté:
                            </Typography>
                            <Typography>
                                &nbsp;
                                {truncateString(encryptedPassword, 25)}
                                &nbsp;
                            </Typography>

                        </Flexbox>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
