import { createSlice } from "@reduxjs/toolkit";
import { IAlbum } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_ALBUMS } from "../../../graphql/Queries";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { EnumDayjsFormat } from "../../../enums";



interface IAlbumsSliceState {
    albums: undefined | IAlbum[];
    refetchNeeded: boolean,
    dateFrom: string,
    dateTo: string,
    filter: string,
}

const initialState: IAlbumsSliceState = {
    albums: undefined,
    refetchNeeded: false,
    dateFrom: dayjs().startOf('year').locale('fr').format(EnumDayjsFormat.date),
    dateTo: dayjs().endOf('year').locale('fr').format(EnumDayjsFormat.date),
    filter: "",
}

export const albumsSlice = createSlice({
    name: "albumsSlice",
    initialState,
    reducers: {
        setAlbumsAction: (state, action) => {
            const newAlbums = [...action.payload]
            // state.albums = sortByTitle(newLockers) as ILocker[]
            state.albums = newAlbums as IAlbum[]
        },
        unsetAlbumsAction: (state) => {
            state.albums = initialState.albums
        },
        addAlbumAction: (state, action) => {
            const newAlbum: IAlbum = action.payload
            if (state.albums) {
                // state.albums = sortByTitle([...state.albums, newAlbum])
                state.albums = [...state.albums, newAlbum]
            } else {
                state.albums = [newAlbum]
            }
        },
        removeAlbumAction: (state, action) => {
            const albumId = action.payload
            state.albums = state.albums?.filter(album => album.id !== albumId)
        },
        updateAlbumAction: (state, action) => {
            const updatedAlbum: IAlbum = action.payload
            state.albums = state.albums?.map(
                album => {
                    return album.id === updatedAlbum.id ? updatedAlbum : album
                }
            )
        },
        toggleSelectAlbumAction: (state, action) => {
            const albumId = action.payload
            state.albums = state.albums?.map(
                album => {
                    return album.id === albumId ? { ...album, selected: !album.selected } : album
                }
            )
        },
        selectAllAlbumAction: (state) => {
            state.albums = state.albums?.map(
                album => {
                    return { ...album, selected: true }
                }
            )
        },
        unSelectAllAlbumAction: (state) => {
            state.albums = state.albums?.map(
                album => {
                    return { ...album, selected: false }
                }
            )
        },
        toggleExpandAlbumAction: (state, action) => {
            const albumId = action.payload
            state.albums = state.albums?.map(
                album => {
                    return album.id === albumId ? { ...album, expanded: !album.expanded } : album
                }
            )
        },
        expandSelectedAlbumsAction: (state) => {
            state.albums = state.albums?.map(
                album => {
                    return album.selected ? { ...album, expanded: true } : album
                }
            )
        },
        unExpandSelectedAlbumsAction: (state) => {
            state.albums = state.albums?.map(
                album => {
                    return album.selected ? { ...album, expanded: false } : album
                }
            )
        },
        expandAllAlbumsAction: (state) => {
            state.albums = state.albums?.map(
                album => {
                    return { ...album, expanded: true }
                }
            )
        },
        removeAlbumsAction: (state, action) => {
            const albumIds = action.payload
            state.albums = state.albums?.filter(album => !albumIds.includes(album.id))
        },
        setAlbumsRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        updateInfosAlbumAction: (state, action) => {
            const albumId = action.payload.albumId
            const newInfos = action.payload.newInfos
            state.albums = state.albums?.map(
                album => {
                    return album.id === albumId ? { ...album, ...newInfos } : album
                }
            )
        },
        setSizeInfosAction: (state, action) => {
            action.payload.forEach((payloadAlbum: IAlbum) => {
                state.albums = state.albums?.map(
                    album => {
                        return album.id === payloadAlbum.id ? { ...album, sizeInfos: payloadAlbum.sizeInfos } : album
                    }
                )
            });
        },
        setPublikAlbumAction: (state, action) => {
            state.albums = state.albums?.map(
                album => {
                    return album.id === action.payload ? { ...album, publik: true } : album
                }
            )
        },
        unsetPublikAlbumAction: (state, action) => {
            state.albums = state.albums?.map(
                album => {
                    return album.id === action.payload ? { ...album, publik: false } : album
                }
            )
        },
        setDateFromAction: (state, action) => {
            state.dateFrom = action.payload
        },
        setDateToAction: (state, action) => {
            state.dateTo = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        },
    }
})

export const { setAlbumsAction, unsetAlbumsAction, addAlbumAction, removeAlbumAction, updateAlbumAction,
    toggleSelectAlbumAction, selectAllAlbumAction, unSelectAllAlbumAction,
    removeAlbumsAction, toggleExpandAlbumAction, expandSelectedAlbumsAction, unExpandSelectedAlbumsAction,
    setAlbumsRefetchNeeded, updateInfosAlbumAction, setSizeInfosAction, expandAllAlbumsAction,
    setPublikAlbumAction, unsetPublikAlbumAction,
    setDateFromAction,
    setDateToAction,
    setFilter,
} = albumsSlice.actions


export const useGetAlbums = () => {
    const albums: (undefined | IAlbum[]) = useSelector((state: IRootState) => state.albumsReducer.albums)
    const dateFrom: Dayjs = dayjs(useSelector((state: IRootState) => state.albumsReducer.dateFrom), EnumDayjsFormat.date)
    const dateTo: Dayjs = dayjs(useSelector((state: IRootState) => state.albumsReducer.dateTo), EnumDayjsFormat.date)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.albumsReducer.refetchNeeded)
    if (albums === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_ALBUMS,
            fetchPolicy: 'network-only',
            variables: {
                dateFrom: dateFrom.format(EnumDayjsFormat.dateForBackend),
                dateTo: dateTo.format(EnumDayjsFormat.dateForBackend),
            }
        }
        ).then(response => {
            store.dispatch(setAlbumsAction(response.data.albums))
            store.dispatch(setAlbumsRefetchNeeded(false))
            return response.data.albums
        })
    } else {
        return albums
    }
}

export default albumsSlice.reducer