import { Box, Paper, Skeleton, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IFoto } from "../../interfaces";

import { Flexbox } from "../utils/Flexbox";

interface IProps {
    foto: IFoto,
    // width: number,
    maxHeight: number,
}

export const FotoPreview: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);

    const theme = useTheme()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    return (
        <>
            <Flexbox sx={{
                // py: 1,
                // backgroundColor: theme.palette.fotoBackground.main,
            }}>
                {
                    props.foto.fotoPreviewUrl === null
                        ?
                        <Skeleton variant="rectangular" width={props.maxHeight * 2} height={props.maxHeight} sx={{ borderRadius: 1 }} />
                        :
                        <Flexbox sx={{
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            // ml: -4,
                            webkitBoxShadow: "-2px 0px 2px 0px rgba(255,255,255,1)",
                            boxShadow: "-2px 0px 2px 0px rgba(255,255,255,1)",

                        }}>
                            {!previewLoaded && <Skeleton variant="rectangular" width={props.maxHeight * 2} height={props.maxHeight} sx={{ borderRadius: 1 }} />}
                            <Box component="img"
                                src={props.foto.fotoPreviewUrl}
                                onLoad={handlePreviewLoaded}
                                sx={{
                                    maxHeight: `${props.maxHeight}px`,
                                    borderRadius: 1,
                                    display: previewLoaded ? 'block' : 'none',
                                }}
                            />

                        </Flexbox>
                }
            </Flexbox>
        </>
    )
}
