import "../../css/components/directories/Directory.css";
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { IDirectory } from "../../interfaces";
import { EnumBasePathFor, EnumParentType, EnumListLockersMode } from "../../enums";
import { truncateString } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { constructPath, getBasePath } from "../../utils/Navigation";
import { DirectoryIcon } from "../svg_icons/DirectoryIcon";
import { PublikSubDirectoriesInfos } from "../PublikSubDirectoriesInfos";

interface IProps {
    directory: IDirectory,
}

export const PublikDirectory: FC<IProps> = (props) => {
    const accessParams = new URLSearchParams(window.location.search);
    const pubToken = accessParams.get('access');

    const theme = useTheme()
    const navigate = useNavigate()

    const publikDirectoryPath = `${constructPath(getBasePath(EnumBasePathFor.publikDirectory, EnumListLockersMode.desktop), [
        { key: "directoryId", value: props.directory.id.toString() },
    ])}?access=${pubToken}`

    return (
        <>
            <Paper className="Directory">
                <Box className="Directory-container">
                    <Box className="Directory-main-section">
                        <Box className="Directory-icon-section">
                            <DirectoryIcon color={EnumListLockersMode.shared} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Box className="Directory-content-section">
                            <Box className="Directory-title-section">
                                <Typography
                                    onClick={() => navigate(publikDirectoryPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}>
                                    {props.directory.name}
                                </Typography>
                            </Box>
                            <Typography >
                                {truncateString(props.directory.description, 80)}
                            </Typography>
                            <Box className="Directory-infos-section">
                                <Box className="Directory-infos-directories" sx={{ mt: 1 }}>
                                    <PublikSubDirectoriesInfos directory={props.directory} />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Paper>
        </>
    )
}
