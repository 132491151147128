import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { filterMatch, pluralize } from "../../utils/Utils";
import { useGetContacts } from '../../redux/features/contacts/contactsSlice';
import { IContact } from '../../interfaces';
import { Contact } from './Contact';
import { GQL_MUTATION_DELETE_CONTACTS } from '../../graphql/Mutations';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { ContactsFilter } from './ContactsFilter';
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { setAllRefetchNeeded } from '../../redux/store';

interface IProps {
}

export const ListContacts: FC<IProps> = (props) => {
    const contacts = useGetContacts()
    const [filter, setFilter] = useState("")
    const [filteredContacts, setFilteredContacts] = useState<IContact[] | undefined>([])
    const [showDeleteContactsDialog, setShowDeleteContactsDialog] = useState(false)
    const [deleteContacts, { data: deleteContactsData, loading: deleteContactsLoading, error: deleteContactsError }] = useMutation(GQL_MUTATION_DELETE_CONTACTS)

    useEffect(() => {
        if (contacts) {
            if (filter.length === 0) {
                setFilteredContacts(contacts)
            } else {
                setFilteredContacts(contacts.filter(contact => (
                    filterMatch(contact.nickname, filter) ||
                    filterMatch(contact.firstname, filter) ||
                    filterMatch(contact.lastname, filter) ||
                    filterMatch(contact.enterpriseName, filter) ||
                    filterMatch(contact.email, filter) ||
                    filterMatch(contact.adresse, filter) ||
                    filterMatch(contact.description, filter)
                )))
            }
        }
    }, [contacts, filter])

    useEffect(() => {
        if (deleteContactsError) {
            addMessage({
                location: "ListContacts",
                type: EnumMessageType.Error,
                message: deleteContactsError.message,
            })
        } else if (deleteContactsData) {
            if (deleteContactsData.deleteContacts.statusCode === 200) {
                const selectedContactIds = getSelectedContactIds()
                setAllRefetchNeeded("ListContacts")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${selectedContactIds.length} ${pluralize("contact supprimé", selectedContactIds.length, "contacts supprimés")}.`,
                })
            } else {
                deleteContactsData.deleteContacts.errors.map((error: string) => {
                    addMessage({
                        location: "ListContacts",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteContactsData, deleteContactsError])

    const getSelectedContactIds = () => {
        if (filteredContacts === undefined) {
            return []
        } else {
            return filteredContacts.filter(filteredContact => filteredContact.selected).map(filteredContact => filteredContact.id)
        }
    }

    const handleDeleteSelection = () => {
        if (getSelectedContactIds().length > 0) {
            setShowDeleteContactsDialog(true)
        }
    }

    const closeDeleteContactsDialog = () => {
        setShowDeleteContactsDialog(false)
    }

    const confirmedDeleteContacts = () => {
        deleteContacts({
            variables: {
                contactIds: getSelectedContactIds()
            }
        })
        setShowDeleteContactsDialog(false)
    }

    return (
        <div className='ListContacts'>
            <ContactsFilter filteredContactsCount={filteredContacts?.length || 0} setFilter={setFilter} selectedContactIds={getSelectedContactIds()} handleDeleteSelection={handleDeleteSelection} />
            <Container className="ListContacts-container">
                <Grid container spacing={2} className="ListContacts-grid-container">
                    {
                        contacts === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            filteredContacts?.map((contact) => {
                                return (
                                    <Grid key={`grid-contact-${contact.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Contact key={`contact-${contact.id}`} contact={contact} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        contacts !== undefined && contacts.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun contact.
                            </Typography>
                        </Box>
                    }

                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteContactsDialog}
                title="Suppression des contacts"
                content={`Confirmez-vous la suppression définitive de ${pluralize("ce contact", getSelectedContactIds().length, `ces ${getSelectedContactIds().length} contacts`)} ?`}
                handleClose={closeDeleteContactsDialog}
                handleConfirm={confirmedDeleteContacts}
                danger={true}
            />
        </div>
    )
}
