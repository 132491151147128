import "../../css/components/messages/Messages.css"

import { FC } from 'react'
import { useSelector } from "react-redux"
import { Message } from "./Message"
import { IMessage } from "../../interfaces"



interface IProps {
}

export const Messages: FC<IProps> = (props) => {
  const messages: IMessage[] = useSelector((state: any) => state.messagesReducer.messages)
  return (
    <div className="Messages">
      {
        messages.map(message => <Message key={message.id} message={message} />)
      }
    </div>
  )
}


