
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';


export const HourglassIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox='0 0 128 128' {...props}>
      <path fill="secondaryColor" d="M66.75011,78.51769s-.09624-.17965-.14372-.2697a2.95289,2.95289,0,0,0-5.21374-.00006c-1.34757,2.55548-13.94178,19.84051-13.94178,19.84051s-.09813.16685-.14443.24728a1.95036,1.95036,0,0,0,1.6981,2.91417H78.99468A1.95082,1.95082,0,0,0,80.72242,98.389l-.02964-.05331Z"/>
      <path fill="currentColor" d="M106.83956,111.06676a25.37063,25.37063,0,0,0-.91394-3.59035C99.97977,92.71784,93.33218,81.30482,85.6129,72.57917a17.09008,17.09008,0,0,1-4.02557-7.43639l-.00262-.01128a5.01331,5.01331,0,0,1,0-2.263l.00262-.01128a17.09008,17.09008,0,0,1,4.02557-7.43639c7.71928-8.72566,14.36687-20.13868,20.31273-34.89724a26.15481,26.15481,0,0,0,.91935-3.59035l1.6864-11.2498a2.86146,2.86146,0,0,0-2.82878-3.28571H22.2974a2.86146,2.86146,0,0,0-2.82878,3.28571s1.681,11.2498,1.69185,11.2498l.91391,3.59035s.05439.14144.08158.20672C28.08006,35.38552,34.70046,46.73867,42.3871,55.42083a17.20336,17.20336,0,0,1,4.02567,7.43681,4.98944,4.98944,0,0,1,0,2.2847,17.20335,17.20335,0,0,1-4.02567,7.43682c-7.68664,8.68216-14.307,20.03532-20.23115,34.69052-.02719.06528-.08158.20672-.08158.20672s-.91391,3.59035-.9248,3.59035l-1.681,11.2498a2.86146,2.86146,0,0,0,2.82878,3.28571h83.4052a2.86146,2.86146,0,0,0,2.82878-3.28571S106.845,111.06676,106.83956,111.06676Zm-77.29616-.30464c5.5433-13.79569,11.9189-24.82246,18.95277-32.77021A25.20984,25.20984,0,0,0,54.355,67.00324a14.49917,14.49917,0,0,0,.475-3.00606,12.18781,12.18781,0,0,0-.475-3.00041,25.20981,25.20981,0,0,0-5.8588-10.98869C41.46231,42.06034,35.08671,31.03356,29.54341,17.227c0,0,68.91319,0,68.90774.01089-5.53786,13.79569-11.91345,24.82246-18.9473,32.77021A25.20954,25.20954,0,0,0,73.645,60.99676a12.43925,12.43925,0,0,0,0,6.00647,25.20954,25.20954,0,0,0,5.85883,10.98869c7.03384,7.94775,13.40944,18.97452,18.95274,32.7811C98.45659,110.773,29.54341,110.773,29.54341,110.76212Z"/>
      <path fill="secondaryColor" d="M80.71154,30.40254a2.08694,2.08694,0,0,0-1.94206-2.83967H49.23056a2.08694,2.08694,0,0,0-1.94206,2.83967c.04353.09791.14689.29919.14689.29919S58.98982,46.5374,60.105,48.32716a4.59225,4.59225,0,0,0,7.79,0c1.11518-1.78976,12.66961-17.62543,12.66961-17.62543S80.668,30.50046,80.71154,30.40254Z"/>
    </SvgIcon>
  )
}

