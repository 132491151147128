import { useMutation } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Radio, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumBankAccountType, EnumCbColor, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_BANK_ACCOUNT } from '../../../graphql/Mutations';
import { IBankAccount } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { BankAccountIcon } from '../../svg_icons/BankAccountIcon';
import { setAllRefetchNeeded } from '../../../redux/store';
import { Flexbox } from '../../utils/Flexbox';

interface IProps {
    open: boolean;
    handleClose: () => void;
    bankAccount: IBankAccount

}

export const ModalEditBankAccount: FC<IProps> = (props) => {
    const [title, setTitle] = useState(props.bankAccount.title)
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [description, setDescription] = useState(props.bankAccount.description)
    const titleRef = useRef<HTMLInputElement>(null);
    const [cbColor, setCbColor] = useState(props.bankAccount.cbColor)
    const [bankAccountType, setBankAccountType] = useState(props.bankAccount.bankAccountType)
    const [taxRate, setTaxRate] = useState<string>(props.bankAccount.taxRate)
    const [bankName, setBankName] = useState(props.bankAccount.bankName)
    const [updateBankAccount, { data: updateBankAccountData, loading: updateBankAccountLoading, error: updateBankAccountError }] = useMutation(GQL_MUTATION_UPDATE_BANK_ACCOUNT)
    const dispatch = useDispatch()



    // Après un update il faut faire çà sinon les données ne sont pas rafraichies
    useEffect(() => {
        setTitle(props.bankAccount.title)
        setCbColor(props.bankAccount.cbColor)
        setDescription(props.bankAccount.description)
        setBankName(props.bankAccount.bankName)
        setBankAccountType(props.bankAccount.bankAccountType)
        setTaxRate(props.bankAccount.taxRate)
    }, [props.bankAccount])

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateBankAccountError) {
            addMessage({
                location: "ModalEditBankAccount",
                type: EnumMessageType.Error,
                message: updateBankAccountError.message,
            })
        } else if (updateBankAccountData) {
            if (updateBankAccountData.updateBankAccount.statusCode === 200) {
                setAllRefetchNeeded("ModalEditBankAccount")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Compte modifié.",
                })
                handleClose()
            } else {
                updateBankAccountData.updateBankAccount.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateBankAccountData, updateBankAccountError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateBankAccount({
                variables: {
                    bankAccountId: props.bankAccount.id,
                    title,
                    cbColor,
                    description,
                    bankName,
                    bankAccountType,
                    taxRate: parseFloat(taxRate),
                }
            })
        }
    }

    const handleClose = () => {
        props.handleClose()
    }

    return (
        <div className="ModalEditBankAccount">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <BankAccountIcon color={props.bankAccount.cbColor} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier le compte
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Nom du compte"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <Flexbox sx={{
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                            gap: 1,
                        }}>
                            {Object.values(EnumCbColor).map((color) => (
                                <Flexbox key={color}
                                >
                                    <Radio
                                        checked={cbColor === color}
                                        onChange={() => { setCbColor(color) }}
                                        value={color}
                                    />
                                    <BankAccountIcon color={`${color}`} sx={{ fontSize: 24, mr: "12px" }} />
                                </Flexbox>
                            ))}
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                        <TextField
                            margin="dense"
                            label="Banque"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setBankName(event.target.value) }}
                            value={bankName}
                        />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Certaines spécificités sur les opérations.">
                            <FormControlLabel sx={{
                                mt: 2,
                            }}
                                control={
                                    <Checkbox
                                        checked={bankAccountType === EnumBankAccountType.business}
                                        value={bankAccountType === EnumBankAccountType.business}
                                        onChange={(event) => setBankAccountType(event.target.checked ? EnumBankAccountType.business : EnumBankAccountType.standard)}
                                    />
                                } label="Compte professionel" />
                        </Tooltip>
                        {
                            bankAccountType === EnumBankAccountType.business && <>
                                <FormControl variant="outlined" sx={{
                                    mt: 1,
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-tax-rate">Taux d'imposition</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-tax-rate"
                                        type='number'
                                        endAdornment={
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        }
                                        label="Taux d'imposition"
                                        color="primary"
                                        onChange={(event) => { setTaxRate(event.currentTarget.value) }}
                                        value={taxRate}
                                        autoComplete='off'
                                        sx={{
                                            width: "200px",
                                        }} />
                                    <FormHelperText>
                                        Appliqué au montant brut pour le pré-calcul de l'imposition.
                                    </FormHelperText>
                                </FormControl>
                            </>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
