// import "../../css/components/spinner/Spinner.css"
import { Box, Container, LinearProgress } from '@mui/material'
import { FC } from 'react'
import { DotLoader, RingLoader } from 'react-spinners'

import { LinearProgressProps, Typography } from '@mui/material';

interface IProps extends LinearProgressProps {
    value: number,
}

export const LinearProgressWithLabel: FC<IProps> = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {`${Math.round(props.value,)}%`}
                </Typography>
            </Box>
        </Box>
    )
}
