// import "../../css/components/lockers/LockerDetails.css";
import { Box, Collapse, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { IAlbum } from "../../interfaces";

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EnumDayjsFormat, EnumListLockersMode } from "../../enums";
import { CameraIcon } from "../svg_icons/CameraIcon";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { Flexbox } from '../utils/Flexbox';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';


interface IProps {
    album: IAlbum,
}

export const PublikAlbumDetails: FC<IProps> = (props) => {
    const [expanded, setExpanded] = useState(true)
    const handleExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <Paper className="LockerDetails">
                <Box className="LockerDetails-container">
                    <Box className="LockerDetails-main-section">
                        <Box className="LockerDetails-icon-section">
                            <CameraIcon color={EnumListLockersMode.shared} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />
                        </Box>
                        <Box className="LockerDetails-content-section">
                            <Box className="LockerDetails-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.album.title}
                                </Typography>
                                <Box className="LockerDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="LockerDetails-expanded-section">
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Flexbox direction='column' sx={{
                                // bgcolor: "red",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}>
                                <Flexbox sx={{
                                    // mb: 1,
                                }}>
                                    <EventOutlinedIcon color={EnumListLockersMode.desktop} sx={{ fontSize: 24, mr: 1 }} />
                                    <Typography>
                                        {dayjs(props.album.dateAlbum).locale('fr').format(EnumDayjsFormat.date)}
                                    </Typography>
                                </Flexbox>

                                {
                                    props.album.description && <>
                                        <Box className="LockerDetails-description-section">
                                            <Typography sx={{ fontSize: "16px", mb: 3 }}>
                                                {props.album.description}
                                            </Typography>
                                        </Box>
                                    </>
                                }
                            </Flexbox>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}
