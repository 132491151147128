import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { IBreadcrumb } from "../interfaces";
import { EnumBreadcrumbMode } from "../enums";
import { truncateString } from '../utils/Utils';



interface IProps {
    breadcrumbs?: IBreadcrumb[]
    sx?: object,
    mode: EnumBreadcrumbMode,
}


export const Breadcrumbs: FC<IProps> = (props) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const getBreadcrumbsString = (props: IProps) => {
        let res = ""
        props.breadcrumbs?.map((breadcrumb, index) => {
            if (index !== 0) {
                res += " > "
            }
            res += breadcrumb.title
        })
        return res
    }

    return (
        <Box sx={props.sx}>
            {
                props.breadcrumbs && (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "0.2rem",
                        flexWrap: "wrap",
                    }}
                    >
                        {
                            props.mode === EnumBreadcrumbMode.standard && props.breadcrumbs.map((breadcrumb, index) => (
                                <Box key={index} sx={{
                                    display: 'flex',
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                    {
                                        index !== 0 &&
                                        <Typography variant="body2" color="text.disabled" sx={{ px: .6 }}>
                                            &gt;
                                        </Typography>
                                    }
                                    {
                                        breadcrumb.path
                                            ?
                                            <Typography variant="body2" onClick={() => navigate(`${breadcrumb.path}`)} sx={{
                                                color: theme.palette.primary.main,
                                                ":hover": {
                                                    cursor: "pointer",
                                                    color: theme.palette.secondary.main,
                                                }
                                            }}>
                                                {breadcrumb.title}
                                            </Typography>
                                            :
                                            <Typography variant="body2" sx={{
                                                color: theme.palette.cancel.main,
                                                fontStyle: "italic",
                                            }}>
                                                {breadcrumb.title}
                                            </Typography>
                                    }
                                </Box>
                            ))
                        }
                        {
                            props.mode === EnumBreadcrumbMode.condensed && <Typography variant="body2" sx={{
                                color: theme.palette.cancel.main,
                                fontStyle: "italic",
                            }}>
                                {truncateString(getBreadcrumbsString(props), 18)}
                            </Typography>
                        }
                    </Box>
                )
            }
        </Box>
    )
}
