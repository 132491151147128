import { Avatar, Box, Button, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IUser } from "../../interfaces";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { UserBadge } from '../UserBadge';
import { EnumMessageType, EnumSize } from '../../enums';
import { HourglassIcon } from '../svg_icons/HourglassIcon';
import { Add } from '@mui/icons-material';
import { ModalNewPartnership } from './modals/ModalNewPartnership';
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { useDispatch } from 'react-redux';
import { setCurrentUserRefetchNeeded } from '../../redux/features/users/currentUserSlice';
import { GQL_MUTATION_ACCEPT_PARTNERSHIP, GQL_MUTATION_DELETE_PARTNERSHIP } from '../../graphql/Mutations';
import { setAllRefetchNeeded } from '../../redux/store';


interface IProps {
    user: IUser,
}

export const Partners: FC<IProps> = (props) => {
    const [showModalNewPartnership, setShowModalNewPartnership] = useState(false)
    const [showDeletePartnershipDialog, setShowDeletePartnershipDialog] = useState(false)
    const [partnerToCancelPartnership, setPartnerToCancelPartnership] = useState<IUser | undefined>(undefined)
    const [deletePartnership, { data: deletePartnershipData, loading: deletePartnershipLoading, error: deletePartnershipError }] = useMutation(GQL_MUTATION_DELETE_PARTNERSHIP)
    const [acceptPartnership, { data: acceptPartnershipData, loading: acceptPartnershipLoading, error: acceptPartnershipError }] = useMutation(GQL_MUTATION_ACCEPT_PARTNERSHIP)

    const dispatch = useDispatch()

    useEffect(() => {
        if (deletePartnershipError) {
            addMessage({
                location: "Partners",
                type: EnumMessageType.Error,
                message: deletePartnershipError.message,
            })
        } else if (deletePartnershipData) {
            if (deletePartnershipData.deletePartnership.statusCode === 200) {
                // dispatch(setCurrentUserRefetchNeeded(true))
                setAllRefetchNeeded("Partners")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Partenariat annulé.",
                })
            } else {
                deletePartnershipData.deletePartnership.errors.map((error: string) => {
                    addMessage({
                        location: "Partners",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deletePartnershipData, deletePartnershipError])

    useEffect(() => {
        if (acceptPartnershipError) {
            addMessage({
                location: "Partners",
                type: EnumMessageType.Error,
                message: acceptPartnershipError.message,
            })
        } else if (acceptPartnershipData) {
            if (acceptPartnershipData.acceptPartnership.statusCode === 200) {
                // dispatch(setCurrentUserRefetchNeeded(true))
                setAllRefetchNeeded("Partners")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Partenariat accepté.",
                })
            } else {
                acceptPartnershipData.acceptPartnership.errors.map((error: string) => {
                    addMessage({
                        location: "Partners",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [acceptPartnershipData, acceptPartnershipError])


    const handleNewPartnership = () => {
        setShowModalNewPartnership(true)
    }

    const closeModalNewPartnership = () => {
        setShowModalNewPartnership(false)
    }

    const handleDeletePartnership = (partner: IUser) => {
        setPartnerToCancelPartnership(partner)
        setShowDeletePartnershipDialog(true)
    }

    const closeDeletePartnershipDialog = () => {
        setShowDeletePartnershipDialog(false)
    }

    const confirmedDeletePartnership = () => {
        if (partnerToCancelPartnership) {
            deletePartnership({
                variables: {
                    userOneId: props.user.id,
                    userTwoId: partnerToCancelPartnership.id,
                }
            })
        }
        setShowDeletePartnershipDialog(false)
    }

    const handleAcceptPartnership = (partner: IUser) => {
        acceptPartnership({
            variables: {
                userOneId: props.user.id,
                userTwoId: partner.id,
            }
        })
    }

    return (
        <>
            <Paper className="Partners" sx={{ width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", py: 2, px: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <Typography variant="h5" color="primary">
                            Partenaires
                        </Typography>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Nouvelle demande">
                            <IconButton onClick={handleNewPartnership} color="primary">
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        {
                            props.user.partnerships?.map(partnership => {
                                return (
                                    <Box key={partnership.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <UserBadge user={partnership.partner} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 }, "filter": !partnership.confirmed ? "grayscale(100%)" : "" }} />
                                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            {
                                                !partnership.confirmed &&
                                                <>
                                                    {
                                                        partnership.isResponder &&
                                                        <>
                                                            <Button size='small' color='success' onClick={() => handleAcceptPartnership(partnership.partner)}>Accepter</Button>
                                                            <Button size='small' color='error' onClick={() => handleDeletePartnership(partnership.partner)}>Refuser</Button>
                                                        </>
                                                    }
                                                    {
                                                        partnership.isRequester &&
                                                        <>
                                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="En attente de la réponse..">
                                                                <Box>
                                                                    <HourglassIcon color='secondary' />
                                                                </Box>
                                                            </Tooltip>
                                                            <Button size='small' color='error' onClick={() => handleDeletePartnership(partnership.partner)}>Annuler</Button>
                                                        </>
                                                    }
                                                </>
                                            }
                                            {
                                                partnership.confirmed &&
                                                <>
                                                    <Button size='small' color='error' onClick={() => handleDeletePartnership(partnership.partner)}>Supprimer</Button>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Paper>
            <ModalNewPartnership
                open={showModalNewPartnership}
                handleClose={closeModalNewPartnership}
                user={props.user}
            />
            <ModalDeleteConfirm
                open={showDeletePartnershipDialog}
                title="Suppression du partenariat"
                content={`Confirmez-vous la suppression du partenariat avec ${partnerToCancelPartnership?.firstname} ${partnerToCancelPartnership?.lastname} ?`}
                handleClose={closeDeletePartnershipDialog}
                handleConfirm={confirmedDeletePartnership}
            />
        </>
    )
}
