import { createSlice } from "@reduxjs/toolkit";
import { ILocker } from "../../../interfaces";
import { sortByTitle } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_LOCKERS } from "../../../graphql/Queries";
import { EnumListLockersMode } from "../../../enums";
import { log } from "console";


interface ILockersSliceState {
    lockers: undefined | ILocker[];
    lockersMode: undefined | EnumListLockersMode;
    refetchNeeded: boolean
}

const initialState: ILockersSliceState = {
    lockers: undefined,
    lockersMode: undefined,
    refetchNeeded: false,
}

export const lockersSlice = createSlice({
    name: "lockersSlice",
    initialState,
    reducers: {
        setLockersModeAction: (state, action) => {
            state.lockersMode = action.payload
        },
        setLockersAction: (state, action) => {
            const newLockers = [...action.payload]
            state.lockers = sortByTitle(newLockers) as ILocker[]
        },
        unsetLockersAction: (state) => {
            state.lockers = initialState.lockers
        },
        addLockerAction: (state, action) => {
            const newLocker: ILocker = action.payload
            if (state.lockers) {
                state.lockers = sortByTitle([...state.lockers, newLocker])
            } else {
                state.lockers = [newLocker]
            }
        },
        removeLockerAction: (state, action) => {
            const lockerId = action.payload
            state.lockers = state.lockers?.filter(locker => locker.id !== lockerId)
        },
        updateLockerAction: (state, action) => {
            const updatedLocker: ILocker = action.payload
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === updatedLocker.id ? updatedLocker : locker
                }
            )
        },
        toggleSelectLockerAction: (state, action) => {
            const lockerId = action.payload
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === lockerId ? { ...locker, selected: !locker.selected } : locker
                }
            )
        },
        selectAllLockerAction: (state) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return { ...locker, selected: true }
                }
            )
        },
        unSelectAllLockerAction: (state) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return { ...locker, selected: false }
                }
            )
        },
        toggleExpandLockerAction: (state, action) => {
            const lockerId = action.payload
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === lockerId ? { ...locker, expanded: !locker.expanded } : locker
                }
            )
        },
        expandSelectedLockersAction: (state) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.selected ? { ...locker, expanded: true } : locker
                }
            )
        },
        unExpandSelectedLockersAction: (state) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.selected ? { ...locker, expanded: false } : locker
                }
            )
        },
        expandAllLockersAction: (state) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return { ...locker, expanded: true }
                }
            )
        },
        removeLockersAction: (state, action) => {
            const lockerIds = action.payload
            state.lockers = state.lockers?.filter(locker => !lockerIds.includes(locker.id))
        },
        setLockersRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        updateInfosLockerAction: (state, action) => {
            const lockerId = action.payload.lockerId
            const newInfos = action.payload.newInfos
            // console.log("getting infos to update : lockerId = ", lockerId, " ; newInfos = ", newInfos);

            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === lockerId ? { ...locker, ...newInfos } : locker
                }
            )
        },
        setSizeInfosAction: (state, action) => {
            action.payload.forEach((payloadLocker: ILocker) => {
                // console.log("MED: payloadLocker.id is : ", payloadLocker.id, " payloadLocker.sizeInfos: ", payloadLocker.sizeInfos);
                state.lockers = state.lockers?.map(
                    locker => {
                        // return locker.id === payloadLocker.id ? { ...locker, sizeInfos: { ...locker.sizeInfos, ...payloadLocker.sizeInfos }} : locker
                        // return locker.id === payloadLocker.id ? { ...locker, ...payloadLocker.sizeInfos } : locker
                        return locker.id === payloadLocker.id ? { ...locker, sizeInfos: payloadLocker.sizeInfos } : locker
                    }
                )
            });
        },
        setPublikLockerAction: (state, action) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === action.payload ? { ...locker, publik: true } : locker
                }
            )
        },
        unsetPublikLockerAction: (state, action) => {
            state.lockers = state.lockers?.map(
                locker => {
                    return locker.id === action.payload ? { ...locker, publik: false } : locker
                }
            )
        },
    }
})

export const { setLockersModeAction, setLockersAction, unsetLockersAction, addLockerAction, removeLockerAction, updateLockerAction,
    toggleSelectLockerAction, selectAllLockerAction, unSelectAllLockerAction,
    removeLockersAction, toggleExpandLockerAction, expandSelectedLockersAction, unExpandSelectedLockersAction,
    setLockersRefetchNeeded, updateInfosLockerAction, setSizeInfosAction, expandAllLockersAction,
    setPublikLockerAction, unsetPublikLockerAction
} = lockersSlice.actions


export const useGetLockers = (mode: EnumListLockersMode) => {
    const lockers: (undefined | ILocker[]) = useSelector((state: IRootState) => state.lockersReducer.lockers)
    const lockersMode: (undefined | EnumListLockersMode) = useSelector((state: IRootState) => state.lockersReducer.lockersMode)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.lockersReducer.refetchNeeded)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    if (lockers === undefined || lockersMode !== mode || refetchNeeded) {
        // console.log("fetching lockers : lockers = ", lockers, " lockerMode !== mode = ", lockersMode !== mode, " refetchNeeded = ", refetchNeeded);

        apolloClient.query({
            query: GQL_LOCKERS,
            fetchPolicy: 'network-only',
            variables: {
                mode: mode,
                filter: filterQuery,
            }
        }
        ).then(response => {
            store.dispatch(setLockersAction(response.data.lockers))
            store.dispatch(setLockersModeAction(mode))
            store.dispatch(setLockersRefetchNeeded(false))
            // console.log("return lockers by fetching..")
            return response.data.lockers
        })
    } else {
        // console.log("return lockers by useSeletor..")
        return lockers
    }
}

export default lockersSlice.reducer