import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { EnumMessageType } from '../../../enums';
import { ITodostep } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { GQL_MUTATION_UPDATE_TODOSTEP } from '../../../graphql/Mutations';

interface IProps {
    open: boolean;
    handleClose: () => void;
    todostep: ITodostep

}

export const ModalEditTodostep: FC<IProps> = (props) => {
    const [title, setTitle] = useState(props.todostep.title)
    const titleRef = useRef<HTMLInputElement>(null);

    const theme = useTheme()
    const [updateTodostep, { data: updateTodostepData, loading: updateTodostepLoading, error: updateTodostepError }] = useMutation(GQL_MUTATION_UPDATE_TODOSTEP)

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updateTodostepError) {
            addMessage({
                location: "ModalEditTodostep",
                type: EnumMessageType.Error,
                message: updateTodostepError.message,
            })
        } else if (updateTodostepData) {
            if (updateTodostepData.updateTodostep.statusCode === 200) {
                setAllRefetchNeeded("ModalEditTodostep")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Etape modifiée.",
                })
                handleClose()
            } else {
                updateTodostepData.updateTodostep.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateTodostepData, updateTodostepError])

    const checkErrors = () => {
        let res = true
        if ((title === undefined || title === "")
        ) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Le titre doit être renseigné.",
            })
            res = false
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updateTodostep({
                variables: {
                    todostepId: props.todostep.id,
                    title,
                }
            })
        }
    }

    const cleanDatas = () => {
        setTitle(props.todostep.title)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalEditTodostep">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <TaskAltIcon sx={{ fontSize: 24, mr: 3, color: theme.palette.primary.main }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Modifier l'étape
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Titre"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
