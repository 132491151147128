import { EnumBasePathFor, EnumListLockersMode } from "../enums"
import { IAlbum, IBreadcrumb, IDirectory, IDirectoryMinimal, IDirectoryShortcutted, ILocker, INavigationParam } from "../interfaces"

export const NAVIGATE_ROOT = "/"
export const NAVIGATE_LOGIN = "/login"

export const NAVIGATE_DESKTOP = "/desktop"
export const NAVIGATE_DESKTOP_LOCKER = `${NAVIGATE_DESKTOP}/lockers/:lockerId`
export const NAVIGATE_DESKTOP_DIRECTORY = `${NAVIGATE_DESKTOP}/directories/:directoryId`
export const NAVIGATE_VAULT = "/vault"
export const NAVIGATE_VAULT_LOCKER = `${NAVIGATE_VAULT}/lockers/:lockerId`
export const NAVIGATE_VAULT_DIRECTORY = `${NAVIGATE_VAULT}/directories/:directoryId`
export const NAVIGATE_SHARED = "/shared"
export const NAVIGATE_SHARED_LOCKER = `${NAVIGATE_SHARED}/lockers/:lockerId`
export const NAVIGATE_SHARED_DIRECTORY = `${NAVIGATE_SHARED}/directories/:directoryId`
export const NAVIGATE_PUBLIK = "/public"
// export const NAVIGATE_PUBLIK_DOCUMENT = `${NAVIGATE_PUBLIK}/documents/:documentId?access=:pubToken`
export const NAVIGATE_PUBLIK_DOCUMENT = `${NAVIGATE_PUBLIK}/documents/:documentId`
export const NAVIGATE_PUBLIK_DIRECTORY = `${NAVIGATE_PUBLIK}/directories/:directoryId`

export const NAVIGATE_SEARCH = "/search"
export const NAVIGATE_PROFILE = "/profil"
export const NAVIGATE_PASSWORD_RESET = "/reset-password/:passwordResetToken"
export const NAVIGATE_ADMIN = "/admin"
export const NAVIGATE_ADMIN_USER = "/admin/user/:userId"
export const NAVIGATE_CHOOSE_PASSWORD = "/choose-password/:choosePasswordToken"

export const NAVIGATE_PASSWORDS = "/passwords"

export const NAVIGATE_ALBUMS = "/albums"
export const NAVIGATE_ALBUM = "/albums/:albumId"
export const NAVIGATE_PUBLIK_ALBUM = `${NAVIGATE_PUBLIK}${NAVIGATE_ALBUM}`

export const NAVIGATE_CONTACTS = "/contacts"

export const NAVIGATE_CASHFLOW = "/cashflow"
export const NAVIGATE_BANK_ACCOUNT = "/cashflow/:bankAccountId"


export const constructPath = (base_path: string, params: INavigationParam[]) => {
    let res = base_path
    for (let i = 0; i < params.length; i++) {
        res = res.replaceAll(`:${params[i].key}`, `${params[i].value}`)
    }
    return res
}

export const getModePath = (mode: (EnumListLockersMode | undefined)): IBreadcrumb => {
    if (mode === undefined) {
        return {
            title: "Accueil",
            path: NAVIGATE_ROOT,
        }
    }
    if (mode === EnumListLockersMode.desktop) {
        return {
            title: "Bureau",
            path: NAVIGATE_DESKTOP,
        }
    }
    if (mode === EnumListLockersMode.vault) {
        return {
            title: "Coffre",
            path: NAVIGATE_VAULT,
        }
    }
    if (mode === EnumListLockersMode.shared) {
        return {
            title: "Partage",
            path: NAVIGATE_SHARED,
        }
    }
    return {
        title: "Accueil",
        path: NAVIGATE_ROOT,
    }
}


export const getBasePath = (baseFor: EnumBasePathFor, mode: EnumListLockersMode) => {
    // "/desktop/directories/:directoryId"
    if (mode === EnumListLockersMode.desktop) {
        if (baseFor === EnumBasePathFor.mode) {
            return NAVIGATE_DESKTOP
        }
        if (baseFor === EnumBasePathFor.locker) {
            return NAVIGATE_DESKTOP_LOCKER
        }
        if (baseFor === EnumBasePathFor.directory) {
            return NAVIGATE_DESKTOP_DIRECTORY
        }
        if (baseFor === EnumBasePathFor.publikDocument) {
            return NAVIGATE_PUBLIK_DOCUMENT
        }
        if (baseFor === EnumBasePathFor.publikDirectory) {
            return NAVIGATE_PUBLIK_DIRECTORY
        }
    }
    if (mode === EnumListLockersMode.vault) {
        if (baseFor === EnumBasePathFor.mode) {
            return NAVIGATE_VAULT
        }
        if (baseFor === EnumBasePathFor.locker) {
            return NAVIGATE_VAULT_LOCKER
        } if (baseFor === EnumBasePathFor.directory) {
            return NAVIGATE_VAULT_DIRECTORY
        }
    }
    if (mode === EnumListLockersMode.shared) {
        if (baseFor === EnumBasePathFor.mode) {
            return NAVIGATE_SHARED
        }
        if (baseFor === EnumBasePathFor.locker) {
            return NAVIGATE_SHARED_LOCKER
        } if (baseFor === EnumBasePathFor.directory) {
            return NAVIGATE_SHARED_DIRECTORY
        }
    }
    return NAVIGATE_ROOT
}

const isObjectOfType = (pObject: any, objectType: string) => {
    return "__typename" in pObject && pObject["__typename"] === objectType;
}

export const getObjectPath = (pObject: IDirectory | IDirectoryMinimal | IDirectoryShortcutted) => {
    if (isObjectOfType(pObject, "Directory")) {
        return constructPath(getBasePath(EnumBasePathFor.directory, pObject.mode), [
            { key: "directoryId", value: pObject.id.toString() }
        ])
    }

    return NAVIGATE_ROOT
}

export const isActivePath = (known_path: string, current_path: string) => {
    // console.log("comparing known_path :", known_path, " with current_path: ", current_path);

    // if (known_path === current_path) {
    //     return true
    // }

    if (current_path.startsWith(known_path)) {
        return true
    }

    // if (known_path === NAVIGATE_PATIENTS) {
    //     if ([NAVIGATE_NEW_PATIENT].includes(current_path)) {
    //         return true
    //     }
    //     if (current_path.startsWith(basePathOf(NAVIGATE_PATIENT))) {
    //         return true
    //     }
    // }
    // if (known_path === NAVIGATE_DOCTORS) {
    //     if ([NAVIGATE_NEW_DOCTOR].includes(current_path)) {
    //         return true
    //     }
    //     if (current_path.startsWith(basePathOf(NAVIGATE_DOCTOR))) {
    //         return true
    //     }
    // }
    // if (known_path === NAVIGATE_PRATICIENS) {
    //     if ([NAVIGATE_NEW_PRATICIEN].includes(current_path)) {
    //         return true
    //     }
    //     if (current_path.startsWith(basePathOf(NAVIGATE_EDIT_PRATICIEN))) {
    //         return true
    //     }
    // }
    return false
}

const basePathOf = (knownPath: string) => {
    return knownPath.substring(0, knownPath.indexOf(':'))
}

