import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { METADOC_WEB_JWT_TOKEN } from "./redux/features/global/apolloSlice";
import { EnumGraphQLErrorCode, EnumMessageType } from './enums';
import { addMessage } from './components/messages/Message';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_METADOC_CORE_URI,
    headers: {
        FrontApp: "metadoc_web",
        FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
    },
})

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authToken: localStorage.getItem(METADOC_WEB_JWT_TOKEN) || "",
        }
    }
})

const errorLink = onError(anError => {
    // console.log(anError)
    if (anError.graphQLErrors) {
        anError.graphQLErrors.map(graphQLError => {
            console.error(`Graphql ERROR : ${graphQLError.message}`)
            console.log(graphQLError)
            addMessage({
                location: "App",
                type: EnumMessageType.Error,
                message: graphQLError.message,
            })
            if (graphQLError.extensions?.code === EnumGraphQLErrorCode.session_expired) {
                console.log("La session est expirée, déconnexion..")
                localStorage.removeItem(METADOC_WEB_JWT_TOKEN)
                window.location.reload()
            }
            return false
        })
    }
})

export const apolloClient = new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
})





// Old one ..
/*
const errorLink = onError(anError => {
    // console.log(anError)
    if (anError.graphQLErrors) {
        anError.graphQLErrors.map(graphQLError => {
            console.error(`Graphql ERROR : ${graphQLError.message}`)
            // console.log(graphQLError)
            addMessage({
                location: "App",
                type: EnumMessageType.Error,
                message: graphQLError.message,
            })
            return false
        })
    }
})

let link = from([
    errorLink,
    new HttpLink({
        uri: process.env.REACT_APP_METADOC_CORE_URI,
        headers: {
            FrontApp: "metadoc_web",
            FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
        }
    })
])

const metadocJwtToken = localStorage.getItem(METADOC_WEB_JWT_TOKEN)
if (metadocJwtToken) {
    link = from([
        errorLink,
        new HttpLink({
            uri: process.env.REACT_APP_METADOC_CORE_URI,
            headers: {
                FrontApp: "metadoc_web",
                FrontAppToken: process.env.REACT_APP_FRONTAPP_TOKEN || "",
                authToken: metadocJwtToken,
            }
        })
    ])
}

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
})

*/
