// theme.ts
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Palette {
        desktop: Palette['primary'];
        cancel: Palette['primary'];
        vault: Palette['primary'];
        shared: Palette['primary'];
        noteBackground: Palette['primary'];
        fotoBackground: Palette['primary'];
        buttonBackground: Palette['primary'];
        cbBlue: Palette['primary'];
        cbGold: Palette['primary'];
        cbBlack: Palette['primary'];
        cbGreen: Palette['primary'];
        cbRed: Palette['primary'];
        credit: Palette['primary'];
        debit: Palette['primary'];
    }

    interface PaletteOptions {
        desktop: PaletteOptions['primary'];
        cancel: PaletteOptions['primary'];
        vault: PaletteOptions['primary'];
        shared: PaletteOptions['primary'];
        noteBackground: PaletteOptions['primary'];
        fotoBackground: PaletteOptions['primary'];
        buttonBackground: PaletteOptions['primary'];
        cbBlue: PaletteOptions['primary'];
        cbGold: PaletteOptions['primary'];
        cbBlack: PaletteOptions['primary'];
        cbGreen: PaletteOptions['primary'];
        cbRed: PaletteOptions['primary'];
        credit: PaletteOptions['primary'];
        debit: PaletteOptions['primary'];
    }
}

declare module "@mui/material/SvgIcon" {
    interface SvgIconPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/Checkbox" {
    interface CheckboxPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/Switch" {
    interface SwitchPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/TextField" {
    interface TextFieldPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

declare module "@mui/material/Chip" {
    interface ChipPropsColorOverrides {
        desktop: true;
        cancel: true;
        vault: true;
        shared: true;
        noteBackground: true;
        fotoBackground: true;
        buttonBackground: true;
        cbBlue: true;
        cbGold: true;
        cbBlack: true;
        cbGreen: true;
        cbRed: true;
        credit: true;
        debit: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#4c1a57",
        },
        secondary: {
            main: "#fa9500",
            contrastText: '#F5F3F5',
        },
        cancel: {
            main: "#888888",
            contrastText: '#F5F3F5',
        },
        background: {
            default: "#F5F3F5",
        },
        desktop: {
            main: "#4284d2",
            light: '#E3ECF3',
            contrastText: '#F5F3F5',
        },
        vault: {
            main: "#cd3131",
        },
        shared: {
            main: '#29a162',
            light: "#DFF7EA",
            contrastText: '#F5F3F5',
        },
        noteBackground: {
            main: '#f4f79f',
        },
        fotoBackground: {
            main: "#2E2E2E",
        },
        buttonBackground: {
            main: "#F0F0F0",
        },
        cbBlue: {
            main: "#4284d2",
            light: "#6aa9e9",
        },
        cbGold: {
            main: "#f0c674",
            light: "#f5d89e",
        },
        cbBlack: {
            main: "#424258",
            light: "#6b6b83",
        },
        cbGreen: {
            main: "#4caf50",
            light: "#80e27e",
        },
        cbRed: {
            main: "#cd3131",
            light: "#f17c7c",
        },
        credit: {
            main: "#4caf50",
            light: "#80e27e",
        },
        debit: {
            main: "#cd3131",
            light: "#f17c7c",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

export default theme;