import { Box, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Flexbox } from '../utils/Flexbox';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_CREATE_TODOLIST } from '../../graphql/Mutations';


interface IProps {
}

export const TodolistPrompt: FC<IProps> = (props) => {
    const [newTodolist, setNewTodolist] = useState("")
    const [createTodolist, { data: createTodolistData, loading: createTodolistLoading, error: createTodolistError }] = useMutation(GQL_MUTATION_CREATE_TODOLIST)

    useEffect(() => {
        if (createTodolistError) {
            addMessage({
                location: "TodolistPrompt",
                type: EnumMessageType.Error,
                message: createTodolistError.message,
            })
        } else if (createTodolistData) {
            if (createTodolistData.createTodolist.statusCode === 200) {
                setAllRefetchNeeded("TodolistPrompt")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Action ajoutée.",
                })
                setNewTodolist("")
            } else {
                createTodolistData.createTodolist.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createTodolistData, createTodolistError])

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createTodolist({
            variables: {
                title: newTodolist,
            }
        })
    }


    return (
        <Flexbox sx={{
            // bgcolor: "red",
            width: "100%",
            justifyContent: "flex-start",
            mb: 1,
        }}>
            <Box
                noValidate
                component="form"
                onSubmit={handleConfirm}
                autoComplete="off"
                sx={{
                    width: "100%",
                }}
            >
                <TextField
                    name="new-todolist"
                    margin="dense"
                    label="Ajouter une action.."
                    fullWidth
                    variant="standard"
                    onChange={(event) => { setNewTodolist(event.target.value) }}
                    value={newTodolist}
                    autoComplete="off"
                    color='primary'
                />
            </Box>
        </Flexbox>
    )
}
