import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_DIRECTORY, GQL_MUTATION_CREATE_NOTE } from '../../../graphql/Mutations';
import { IDirectory, ILocker } from '../../../interfaces';
import { setAllRefetchNeeded } from '../../../redux/store';
// import { addLockerAction } from '../../../redux/features/lockers/lockersSlice';
import { addMessage } from '../../messages/Message';
import { DirectoryIcon } from '../../svg_icons/DirectoryIcon';
import { NoteIcon } from '../../svg_icons/NoteIcon';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight/lib';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (markdownToAdd: String) => void;
}

export const ModalInsertionMarkdown: FC<IProps> = (props) => {

    const formatedStrings = [
        "**Gras**",
        "*Italic*",
        "***Gras et italic***",
        // "_souligné_",
        "~~barré~~",
        // "==Sur fond gris==",
        // "`rouge`",
        "\n# Très gros titre\n",
        "\n## Gros titre\n",
        "\n### Moyen titre\n",
        "\n#### Petit titre\n",
        "\n##### Tout petit titre\n",
        "\n- item de liste\n",
        // "   - sous-item de liste",
        "\n> Citation\n",
        "\n```\nParagraphe formatté\n```",
        "\n---\n",
        "[Lien](https://lien.com)"
    ]

    const handleClose = () => {
        props.handleClose()
    }

    return (
        <div className="ModalInsertionMarkdown">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 18 }}>
                            Insertion de format
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection:"column", justifyContent: "space-between", alignItems: "self-start", gap: 2 }}>
                        {
                            formatedStrings.map((formatedString, index) => {
                                return <Box key={index} sx={{display:"flex", justifyContent:"space-between", alignItems:"center", width: "100%"}}>
                                    <Box sx={{
                                        backgroundColor: "#f4f79f",
                                        minWidth: "200px",
                                        px: 1,
                                        py: 1,
                                    }}
                                    className="markdown-body"
                                    >
                                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} >
                                            {formatedString}
                                        </ReactMarkdown>
                                    </Box>
                                    <Button onClick={() => props.handleConfirm(formatedString)} color='secondary'>
                                        Insérer
                                    </Button>
                                </Box>
                            })
                        }


                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
