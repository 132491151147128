// import "../../css/components/lockers/ListLockers.css"
import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { Album } from './Album';
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { ModalNewAlbum } from "./modals/ModalNewAlbum";
import { expandAllAlbumsAction, expandSelectedAlbumsAction, selectAllAlbumAction, setDateFromAction, setDateToAction, setSizeInfosAction, unExpandSelectedAlbumsAction, unSelectAllAlbumAction, useGetAlbums, setFilter as setReduxFilter } from "../../redux/features/albums/albumsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { addMessage } from "../messages/Message";
import { EnumDatePeriodMode, EnumDayjsFormat, EnumMessageType } from "../../enums";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_DELETE_ALBUMS } from "../../graphql/Mutations";
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IRootState, setAllRefetchNeeded } from "../../redux/store";
import { filterMatch, pluralize } from "../../utils/Utils";
import { Filter } from "../Filter";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { apolloClient } from "../../ApolloClient";
import { GQL_QUERY_GET_SIZE_INFOS } from "../../graphql/Queries";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { Flexbox } from '../utils/Flexbox';
import { DatePeriod } from '../utils/DatePeriod';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import { IAlbum } from '../../interfaces';
import { BasicFilter } from '../utils/BasicFilter';


interface IProps {
}

export const ListAlbums: FC<IProps> = (props) => {
    const albums = useGetAlbums()
    const [showModalNewAlbum, setShowModalNewAlbum] = useState(false)
    const [selectAllAlbums, setSelectAllAlbums] = useState(true)
    const [hasSelectedAlbum, setHasSelectedAlbum] = useState(false)
    const [showDeleteAlbumsDialog, setShowDeleteAlbumsDialog] = useState(false)
    const [expandSelectedAlbums, setExpandSelectedAlbums] = useState(true)
    const dateFrom: Dayjs = dayjs(useSelector((state: IRootState) => state.albumsReducer.dateFrom), EnumDayjsFormat.date)
    const dateTo: Dayjs = dayjs(useSelector((state: IRootState) => state.albumsReducer.dateTo), EnumDayjsFormat.date)
    // const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const filter: string = useSelector((state: IRootState) => state.albumsReducer.filter)
    // const [filteredAlbums, setFilteredAlbums] = useState<IAlbum[] | undefined>([])
    const [filteredAlbums, setFilteredAlbums] = useState<IAlbum[] | undefined>(undefined)

    const [deleteAlbums, { data: deleteAlbumsData, loading: deleteAlbumsLoading, error: deleteAlbumsError }] = useMutation(GQL_MUTATION_DELETE_ALBUMS)
    const dispatch = useDispatch()

    useEffect(() => {
        if (albums === undefined) {
            setFilteredAlbums(undefined)
        } else if (albums.length > 0) {
            let tempFilteredAlbums = [...albums]

            // On applique le filtre
            if (filter.length !== 0) {
                tempFilteredAlbums = tempFilteredAlbums.filter(tempFilteredAlbum => (
                    filterMatch(tempFilteredAlbum.title, filter) ||
                    filterMatch(tempFilteredAlbum.description, filter) ||
                    filterMatch(tempFilteredAlbum.dateAlbum, filter)
                ))
            }
            setFilteredAlbums([...tempFilteredAlbums])
        } else {
            setFilteredAlbums([])
        }
    }, [albums, filter])


    const closeModalNewAlbum = () => {
        setShowModalNewAlbum(false)
    }

    const handleSelectAll = () => {
        selectAllAlbums ? dispatch(selectAllAlbumAction()) : dispatch(unSelectAllAlbumAction())
        setSelectAllAlbums(!selectAllAlbums)
    }

    const handleDeleteSelection = () => {
        if (getSelectedAlbumIds().length > 0) {
            setShowDeleteAlbumsDialog(true)
        }
    }

    const closeDeleteAlbumsDialog = () => {
        setShowDeleteAlbumsDialog(false)
    }

    const confirmedDeleteAlbums = () => {
        deleteAlbums({
            variables: {
                albumIds: getSelectedAlbumIds()
            }
        })
        setShowDeleteAlbumsDialog(false)
    }

    const getSelectedAlbumIds = () => {
        if (filteredAlbums === undefined) {
            return []
        } else {
            return filteredAlbums.filter(album => album.selected).map(album => album.id)
        }
    }

    const handleExpandSelectedAlbums = () => {
        if (getSelectedAlbumIds().length > 0) {
            expandSelectedAlbums ? dispatch(expandSelectedAlbumsAction()) : dispatch(unExpandSelectedAlbumsAction())
            setExpandSelectedAlbums(!expandSelectedAlbums)
        }
    }

    useEffect(() => {
        if (deleteAlbumsError) {
            addMessage({
                location: "ListAlbums",
                type: EnumMessageType.Error,
                message: deleteAlbumsError.message,
            })
        } else if (deleteAlbumsData) {
            if (deleteAlbumsData.deleteAlbums.statusCode === 200) {
                const selectedAlbumIds = getSelectedAlbumIds()
                // dispatch(removeAlbumsAction(selectedAlbumIds))
                setAllRefetchNeeded("ListAlbums")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${selectedAlbumIds.length} ${pluralize("album supprimé", selectedAlbumIds.length, "albums supprimés")}.`,
                })
            } else {
                deleteAlbumsData.deleteAlbums.errors.map((error: string) => {
                    addMessage({
                        location: "ListAlbums",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAlbumsData, deleteAlbumsError])

    useEffect(() => {
        if (getSelectedAlbumIds().length > 0) {
            setHasSelectedAlbum(true)
        } else {
            setHasSelectedAlbum(false)
        }
    }, [filteredAlbums])

    const handleGetSizeInfosSelection = () => {
        apolloClient.query({
            query: GQL_QUERY_GET_SIZE_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                albumIds: getSelectedAlbumIds(),
            }
        }
        ).then(response => {
            const payloadObject = response.data.getSizeInfos.albums
            dispatch(setSizeInfosAction(payloadObject))
            dispatch(expandAllAlbumsAction())
            setExpandSelectedAlbums(false)
        })
    }

    const handleDates = (pDateFrom: Dayjs, pDateTo: Dayjs) => {
        dispatch(setDateFromAction(pDateFrom.format(EnumDayjsFormat.date)))
        dispatch(setDateToAction(pDateTo.format(EnumDayjsFormat.date)))
        setAllRefetchNeeded("ListAlbums")
    }

    const setFilter = (pFilter: string) => {
        dispatch(setReduxFilter(pFilter))
    }


    return (
        <div className='ListAlbums'>
            <FloatingSpinner dependances={[deleteAlbumsLoading]} />
            <Container className="ListAlbums-actions" sx={{
                mt: "12px",
            }}>
                <Flexbox sx={{
                    justifyContent: "space-between",
                }}>
                    <Flexbox>
                        <DatePeriod mode={EnumDatePeriodMode.byYear} dateFrom={dateFrom} dateTo={dateTo} handleDates={handleDates} />
                    </Flexbox>
                    <Flexbox>
                        {/* Desktop only */}
                        <Flexbox sx={{
                            display: { xs: "none", md: "flex" },
                        }}>
                            <BasicFilter setFilter={setFilter} defaultFilterValue={filter} />
                        </Flexbox>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        {
                            hasSelectedAlbum && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les albums sélectionnés">
                                <IconButton onClick={handleDeleteSelection} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            hasSelectedAlbum &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Calculer les tailles sélectionnnés">
                                <IconButton onClick={handleGetSizeInfosSelection} color="primary">
                                    <DatabaseIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            hasSelectedAlbum &&
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={expandSelectedAlbums ? "Déplier les albums sélectionnnés" : "Plier les albums sélectionnnés"}>
                                <ExpandMore expand={!expandSelectedAlbums} onClick={handleExpandSelectedAlbums} aria-expanded={!expandSelectedAlbums} aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Tooltip>
                        }
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllAlbums ? "Sélectionner tout" : "Désélectionner tout"}>
                            <IconButton onClick={handleSelectAll} color={selectAllAlbums ? "secondary" : "cancel"}>
                                <CropFreeOutlined />
                            </IconButton>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un album">
                            <IconButton color="primary" onClick={() => setShowModalNewAlbum(true)}><Add fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewAlbum
                            open={showModalNewAlbum}
                            handleClose={closeModalNewAlbum}
                        />
                    </Flexbox>
                </Flexbox>
                {/* Mobile only */}
                <Flexbox sx={{
                    display: { xs: "flex", md: "none" },
                }}>
                    <BasicFilter setFilter={setFilter} defaultFilterValue={filter} />
                </Flexbox>
            </Container>
            <Container className="ListAlbums-container">
                <Grid container spacing={0} className="ListAlbums-grid-container">
                    {
                        filteredAlbums === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            filteredAlbums.map((filteredAlbum) => {
                                return (
                                    <Grid key={`${filteredAlbum.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Album album={filteredAlbum} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        filteredAlbums !== undefined && filteredAlbums.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun album.
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteAlbumsDialog}
                title="Suppression des albums"
                content={`Confirmez-vous la suppression définitive de ${pluralize("cet album", getSelectedAlbumIds().length, `ces ${getSelectedAlbumIds().length} albums`)} ?`}
                handleClose={closeDeleteAlbumsDialog}
                handleConfirm={confirmedDeleteAlbums}
                danger={true}
            />
        </div>
    )
}
