import { createSlice } from "@reduxjs/toolkit";
import { IPassword } from "../../../interfaces";
import { sortByTitle } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_PASSWORDS } from "../../../graphql/Queries";


interface IPasswordsSliceState {
    passwords: undefined | IPassword[];
    refetchNeeded: boolean
}

const initialState: IPasswordsSliceState = {
    passwords: undefined,
    refetchNeeded: false,
}

export const passwordsSlice = createSlice({
    name: "passwordsSlice",
    initialState,
    reducers: {
        setPasswordsAction: (state, action) => {
            const newPasswords = [...action.payload]
            state.passwords = sortByTitle(newPasswords) as IPassword[]
        },
        unsetPasswordsAction: (state) => {
            state.passwords = initialState.passwords
        },
        setPasswordsRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
    }
})

export const { setPasswordsAction, unsetPasswordsAction, setPasswordsRefetchNeeded,
} = passwordsSlice.actions


export const useGetPasswords = () => {
    const passwords: (undefined | IPassword[]) = useSelector((state: IRootState) => state.passwordsReducer.passwords)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.passwordsReducer.refetchNeeded)
    if (passwords === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_PASSWORDS,
            fetchPolicy: 'network-only',
            // variables: {
            //     mode: mode,
            //     filter: filterQuery,
            // }
        }
        ).then(response => {
            store.dispatch(setPasswordsAction(response.data.passwords))
            store.dispatch(setPasswordsRefetchNeeded(false))
            // console.log("return passwords by fetching..")
            return response.data.passwords
        })
    } else {
        // console.log("return passwords by useSeletor..")
        return passwords
    }
}

export default passwordsSlice.reducer