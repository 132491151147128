import "../../css/components/lockers/Locker.css"
import { Avatar, Box, Button, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { FC, useState } from 'react';
import { ILocker } from "../../interfaces";
import { LockerIcon } from "../svg_icons/LockerIcon";

import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VaultIcon } from "../svg_icons/VaultIcon";
import { EnumBasePathFor, EnumListLockersMode, EnumLockerIconMode, EnumParentType } from "../../enums";
import { truncateString } from "../../utils/Utils";
import { SharedIcon } from "../svg_icons/SharedIcon";
import { useNavigate } from "react-router-dom";
import { constructPath, getBasePath } from "../../utils/Navigation";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { Flexbox } from "../utils/Flexbox";

interface IProps {
    locker: ILocker,
}

export const LockerSearchResult: FC<IProps> = (props) => {

    const theme = useTheme()
    const navigate = useNavigate()

    const lockerPath = constructPath(getBasePath(EnumBasePathFor.locker, props.locker.mode), [
        { key: "lockerId", value: props.locker.id.toString() }
    ])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", mx: 2, width: "100%" }}>
            <Paper className="Locker">
                <Box className="Locker-container">
                    <Box className="Locker-main-section">
                        <Box className="Locker-icon-section">
                            {
                                props.locker.mode === EnumListLockersMode.vault &&
                                <VaultIcon color="vault" sx={{ fontSize: 24, mx: .5 }} />
                            }
                            {
                                props.locker.mode === EnumListLockersMode.desktop &&
                                <LockerIcon mode={props.locker.delivery ? EnumLockerIconMode.delivery : EnumLockerIconMode.standard} color="desktop" sx={{ fontSize: 24, mx: .5 }} />
                            }
                            {
                                props.locker.mode === EnumListLockersMode.shared &&
                                <SharedIcon color="shared" sx={{ fontSize: 24, mx: .5 }} />
                            }

                        </Box>
                        <Box className="Locker-content-section">
                            <Box className="Locker-title-section">
                                <Typography
                                    onClick={() => navigate(lockerPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}>
                                    {props.locker.title}
                                </Typography>
                                <Box className="Locker-users-section">
                                    {
                                        props.locker.owners.map((owner) => {
                                            return (
                                                <Tooltip key={owner.id} title={`${owner.firstname} ${owner.lastname}`}>
                                                    <Avatar className="avatar-icon" alt={`${owner.firstname} ${owner.lastname}`} src={`${owner.userAvatarPreviewUrl}`} />
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            <Typography >
                                {truncateString(props.locker.description, 80)}
                            </Typography>
                            <Box className="Locker-infos-section">
                                <Box className="Locker-infos-directories">
                                    <SubDirectoriesInfos mode={props.locker.mode} detailsMode={false} parentType={EnumParentType.locker} locker={props.locker} />
                                </Box>
                                {
                                    props.locker.viewers.length > 0 && <>
                                        <Box className="Locker-infos-viewers">
                                            |
                                            {/* <IosShare color="desktop" sx={{ fontSize: 18, mr: .5, ml: 1 }} /> */}
                                            {
                                                props.locker.viewers.map((viewer) => {
                                                    return (
                                                        <Tooltip key={viewer.id} title={`${viewer.firstname} ${viewer.lastname}`}>
                                                            <Avatar sx={{ ml: 1 }} className="avatar-icon" alt={`${viewer.firstname} ${viewer.lastname}`} src={`${viewer.userAvatarPreviewUrl}`} />
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>

                    </Box>
                    <Flexbox sx={{ justifyContent: "flex-end" }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                            <Link href={lockerPath} underline="none" target="_blank">
                                <IconButton color="primary">
                                    <OpenInNewOutlinedIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        {/* <Button size="small" onClick={() => navigate(lockerPath)}>Ouvrir</Button> */}
                    </Flexbox>
                </Box>
            </Paper>
        </Box>
    )
}
