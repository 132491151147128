import { createSlice } from "@reduxjs/toolkit";

export const METADOC_WEB_JWT_TOKEN = "metadoc_web_jwt_token"
// export const METADOC_CORE_URI = "http://192.168.0.12:3000/graphql"



interface IApolloSliceState {
    hasJwt: Boolean;
}

const initialState: IApolloSliceState = {
    hasJwt: localStorage.getItem(METADOC_WEB_JWT_TOKEN) ? true : false
}

export const apolloSlice = createSlice({
    name: "apolloSlice",
    initialState,
    reducers: {
        setJwt: (state, action) => {
            localStorage.setItem(METADOC_WEB_JWT_TOKEN, action.payload)
            state.hasJwt = true
        },
        removeJwt: (state) => {
            localStorage.removeItem(METADOC_WEB_JWT_TOKEN)
            state.hasJwt = false
        },
    }
})

export const { setJwt, removeJwt } = apolloSlice.actions

export default apolloSlice.reducer