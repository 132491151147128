import { FC, ReactElement, ReactNode } from 'react';
import { Box, SxProps } from "@mui/material";

interface IProps {
    direction?: string,
    children: ReactNode,
    sx?: SxProps,
    onClick?: () => void;
}

export const Flexbox: FC<IProps> = (props) => {
    return (
        <>
            {
                (props.direction === undefined || props.direction === "row") &&
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        // width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        ...props.sx,
                    }}
                    onClick={props.onClick}
                >
                    {props.children}
                </Box>
            }
            {
                props.direction === "column" &&
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        // width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        ...props.sx,
                    }}
                    onClick={props.onClick}
                >
                    {props.children}
                </Box>
            }
        </>
    )
}


