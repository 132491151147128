import { FC, useEffect, useState } from 'react';
import { Layout } from "./Layout";
import { useParams } from 'react-router-dom';
import { useGetBankAccount } from '../redux/features/cashflow/bankAccountSlice';
import { Spinner } from '../components/spinner/Spinner';
import { BankAccountDetails } from '../components/cashflow/BankAccountDetails';
import { IBreadcrumb } from '../interfaces';
import { NAVIGATE_CASHFLOW } from '../utils/Navigation';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { EnumBreadcrumbMode } from '../enums';
import { ListAccountOperations } from '../components/cashflow/accountOperations/ListAccountOperations';
// import { BankAccountDetails } from '../components/bankAccounts/BankAccountDetails';

interface IProps {
}

export const BankAccountPage: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  const params = useParams()
  const bankAccountId = params.bankAccountId || ""
  const bankAccount = useGetBankAccount(bankAccountId)

  useEffect(() => {
    setBreadcrumbs(
      bankAccount === undefined
        ?
        [
          {
            title: "Cashflow",
            path: NAVIGATE_CASHFLOW
          },
          { title: "..." }
        ]
        :
        [
          {
            title: "Cashflow",
            path: NAVIGATE_CASHFLOW
          },
          {
            title: bankAccount.title,
          }
        ])
  }, [bankAccount])


  return (
    <div className="BankAccountPage">
      <Layout>
        <div className="page-body">
          {
            bankAccount === undefined ? (
              <Spinner classes="big-spinner" />
            ) : (
              <>
                <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ py: 1.5 }} />
                {
                  <BankAccountDetails bankAccount={bankAccount} />
                }
                <ListAccountOperations bankAccount={bankAccount} />
              </>
            )
          }
        </div>
      </Layout>
    </div >
  )
}
