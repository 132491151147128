import { Container, Divider, IconButton, Tooltip, FormControl, InputLabel, Input, InputAdornment } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { selectAllContactAction, unSelectAllContactAction } from '../../redux/features/contacts/contactsSlice';
import ClearIcon from '@mui/icons-material/Clear';
import { ModalNewContact } from './modals/ModalNewContact';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ModalContactImportVcards } from './modals/ModalContactImportVcards';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { ModalContactDownloadVcards } from './modals/ModalContactDownloadVcards';
import { GQL_MUTATION_EXPORT_CONTACT_VCARDS } from '../../graphql/Mutations';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType, EnumModalContactDownloadVcardsMode } from '../../enums';
import { useDispatch } from 'react-redux';

interface IProps {
    selectedContactIds: string[],
    setFilter: (filter: string) => void;
    handleDeleteSelection: () => void;
    filteredContactsCount: number;
}

export const ContactsFilter: FC<IProps> = (props) => {
    const [showModalNewContact, setShowModalNewContact] = useState(false)
    const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [showModalContactImportVcards, setShowModalContactImportVcards] = useState(false)
    const [downloadContactsUrl, setDownloadContactsUrl] = useState<string | undefined>(undefined)
    const [showModalContactDownloadVcards, setShowModalContactDownloadVcards] = useState(false)
    const [exportContactVcards, { data: exportContactVcardsData, loading: exportContactVcardsLoading, error: exportContactVcardsError }] = useMutation(GQL_MUTATION_EXPORT_CONTACT_VCARDS)
    const [selectAllContacts, setSelectAllContacts] = useState(true)

    const dispatch = useDispatch()


    const hasSelectedContact = () => {
        return props.selectedContactIds.length > 0
    }

    useEffect(() => {
        if (exportContactVcardsError) {
            closeModalContactDownloadVcards()
            addMessage({
                location: "ListContacts",
                type: EnumMessageType.Error,
                message: exportContactVcardsError.message,
            })
        } else if (exportContactVcardsData) {
            if (exportContactVcardsData.exportContactVcards.statusCode === 200) {
                setDownloadContactsUrl(exportContactVcardsData.exportContactVcards.sheetFileUrl)
            } else {
                exportContactVcardsData.exportContactVcards.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [exportContactVcardsData, exportContactVcardsError])

    const closeModalNewContact = () => {
        setShowModalNewContact(false)
    }

    const closeModalContactImportVcards = () => {
        setShowModalContactImportVcards(false)
    }

    const closeModalContactDownloadVcards = () => {
        setShowModalContactDownloadVcards(false)
        setDownloadContactsUrl(undefined)
    }

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            props.setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);


    const handleDownloadContacts = () => {
        setShowModalContactDownloadVcards(true)
        exportContactVcards(
            {
                variables: {
                    contactIds: props.selectedContactIds,
                    fileName: `mes_contacts.vcf`,
                }
            }
        )
    }

    const handleSelectAll = () => {
        selectAllContacts ? dispatch(selectAllContactAction()) : dispatch(unSelectAllContactAction())
        setSelectAllContacts(!selectAllContacts)
    }

    return (
        <Container className="ListContacts-actions"
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: "12px",
            }}
        >
            <FormControl sx={{
                pb: 2,
                my: 0,
                mr: 1,
                width: "200px",
            }} variant="standard">
                <InputLabel htmlFor="standard-adornment-contact">Filtrer {props.filteredContactsCount > 0 ? `(${props.filteredContactsCount})` : ""}...</InputLabel>
                <Input
                    type='text'
                    onChange={(event) => { handleFilterInput(event.target.value) }}
                    value={filterInput}
                    endAdornment={
                        filterInput.length > 0 && <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleFilterInput("") }}
                            >
                                <ClearIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Divider orientation="vertical" flexItem variant="middle" sx={{
                mx: 1,
            }} />
            {
                hasSelectedContact() && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les contacts sélectionnés">
                    <IconButton onClick={props.handleDeleteSelection} color="error">
                        <DeleteOutline />
                    </IconButton>
                </Tooltip>
            }
            {
                hasSelectedContact() && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger les contacts">
                    <IconButton color="primary" onClick={handleDownloadContacts}>
                        <CloudDownloadOutlinedIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllContacts ? "Sélectionner tout" : "Désélectionner tout"}>
                <IconButton onClick={handleSelectAll} color={selectAllContacts ? "secondary" : "cancel"}>
                    <CropFreeOutlined />
                </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem variant="middle" sx={{
                mx: 1,
            }} />

            <ModalContactDownloadVcards
                mode={EnumModalContactDownloadVcardsMode.multi}
                open={showModalContactDownloadVcards}
                handleClose={closeModalContactDownloadVcards}
                sheet_file_url={downloadContactsUrl}
            />
            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Importer des contacts">
                <IconButton color="primary" onClick={() => setShowModalContactImportVcards(true)}>
                    <CloudUploadOutlinedIcon fontSize="medium" />
                </IconButton>
            </Tooltip>
            <ModalContactImportVcards
                open={showModalContactImportVcards}
                handleClose={closeModalContactImportVcards}
            />

            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un contact">
                <IconButton color="primary" onClick={() => setShowModalNewContact(true)}><Add fontSize="medium" /></IconButton>
            </Tooltip>
            <ModalNewContact
                open={showModalNewContact}
                handleClose={closeModalNewContact}
            />
        </Container>
    )
}
