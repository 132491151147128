import { Avatar, Box, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { IBadge, ICustomContact } from "../../interfaces"
import { EnumCustomContactMode, EnumMessageType, EnumSize } from "../../enums"
import { Flexbox } from "../utils/Flexbox";
import CheckIcon from '@mui/icons-material/Check';
import { DeleteOutline } from "@mui/icons-material";
import { fontSize } from "@mui/system";
import { useMutation } from "@apollo/client";
import { addMessage } from "../messages/Message";
import { setAllRefetchNeeded } from "../../redux/store";
import { GQL_MUTATION_DELETE_CUSTOM_CONTACT, GQL_MUTATION_UPDATE_CUSTOM_CONTACT } from "../../graphql/Mutations";
import { useDispatch } from "react-redux";
import { deleteCustomContactAction, updateCustomContactAction } from "../../redux/features/customContacts/customContactsSlice";
import { findAndDeleteACustomContactAction, findAndUpdateACustomContactAction, setBankAccountRefetchNeeded } from "../../redux/features/cashflow/bankAccountSlice";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";



interface IProps {
    customContact: ICustomContact,
    size: EnumSize,
    sx?: object,
    badge?: IBadge,
    displayTooltip?: boolean,
    onClick?: (selectedCustomContact: ICustomContact) => void;
    mode: EnumCustomContactMode,
    showContactAvatar: boolean,
}


export const CustomContactBadge: FC<IProps> = (props) => {
    const [title, setTitle] = useState(props.customContact.title)

    const [showDeleteCustomContactDialog, setShowDeleteCustomContactDialog] = useState(false)
    const [updateCustomContact, { data: updateCustomContactData, loading: updateCustomContactLoading, error: updateCustomContactError }] = useMutation(GQL_MUTATION_UPDATE_CUSTOM_CONTACT)
    const [deleteCustomContact, { data: deleteCustomContactData, loading: deleteCustomContactLoading, error: deleteCustomContactError }] = useMutation(GQL_MUTATION_DELETE_CUSTOM_CONTACT)

    const theme = useTheme()
    const dispatch = useDispatch()


    const baseSizes = {
        tiny: 4,
        small: 6,
        medium: 10,
        large: 16,
        xlarge: 24,
    }

    enum EnumInnerSize {
        avatarWidth = "avatarWidth",
        avatarMr = "avatarMr",
        fontSize = "fontSize",
        paperPl = "paperPl",
        paperPr = "paperPr",
        paperBorderRightRadius = "paperBorderRightRadius",
    }

    enum EnumInnerDevice {
        desktop = "desktop",
        mobile = "mobile",

    }

    const baseCoefficients = {
        avatarWidth: 4,
        avatarMr: -1.5,
        fontSize: 1.8,
        paperPl: 2,
        paperPr: 1,
        paperBorderRightRadius: 2,
    }



    const getSize = (innerSize: EnumInnerSize, device: EnumInnerDevice = EnumInnerDevice.desktop) => {
        let baseCoefficient = baseCoefficients.avatarWidth
        if (innerSize === EnumInnerSize.fontSize) {
            baseCoefficient = baseCoefficients.fontSize
        } else if (innerSize === EnumInnerSize.paperPl) {
            baseCoefficient = baseCoefficients.paperPl
        } else if (innerSize === EnumInnerSize.paperPr) {
            baseCoefficient = baseCoefficients.paperPr
        } else if (innerSize === EnumInnerSize.avatarMr) {
            baseCoefficient = baseCoefficients.avatarMr
        } else if (innerSize === EnumInnerSize.paperBorderRightRadius) {
            baseCoefficient = baseCoefficients.paperBorderRightRadius
        }

        let mappedSize = baseSizes.xlarge
        if (props.size === EnumSize.medium) {
            mappedSize = baseSizes.medium
        } else if (props.size === EnumSize.tiny) {
            mappedSize = baseSizes.tiny
        } else if (props.size === EnumSize.small) {
            mappedSize = baseSizes.small
        } else if (props.size === EnumSize.large) {
            mappedSize = baseSizes.large
        }

        if (device === EnumInnerDevice.mobile) {
            mappedSize = (mappedSize / 1.5)
        }

        const res = `${baseCoefficient * mappedSize}px`
        return res
    }

    const getBadgeColor = (forText: boolean) => {
        if (props.badge) {
            if (props.badge.color === "desktop") {
                return forText ? theme.palette.desktop.contrastText : theme.palette.desktop.main
            }
            if (props.badge.color === "shared") {
                return forText ? theme.palette.shared.contrastText : theme.palette.shared.main
            }
            if (props.badge.color === "cancel") {
                return forText ? theme.palette.cancel.contrastText : theme.palette.cancel.main
            }
        }
    }

    const getAvatar = () => {
        let avatarUrl = undefined
        if (props.showContactAvatar) {
            avatarUrl = `${process.env.PUBLIC_URL}/images/store.png`
            if (props.customContact.contactAvatarPreviewUrl) {
                avatarUrl = props.customContact.contactAvatarPreviewUrl
            }
        }
        return <Avatar
            sx={{
                width: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                height: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                mr: { xs: getSize(EnumInnerSize.avatarMr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarMr) },
                border: "1px solid rgba(0, 0, 0, .2)",
                zIndex: 2,
                display: 'flex',
                borderRadius: "25%",
                backgroundColor: theme.palette.desktop.light,
            }}
            className="avatar-icon"
            alt={`${props.customContact.title}`}
            src={avatarUrl}
        />
    }

    useEffect(() => {
        if (updateCustomContactError) {
            addMessage({
                location: "CustomContactBadge",
                type: EnumMessageType.Error,
                message: updateCustomContactError.message,
            })
        } else if (updateCustomContactData) {
            if (updateCustomContactData.updateCustomContact.statusCode === 200) {
                // setAllRefetchNeeded("CustomContactBadge")
                // Cas spécial, Pour garder la modale open et son state settings, on effectue les opérations directement dans les reducers
                dispatch(updateCustomContactAction(updateCustomContactData.updateCustomContact.customContact))
                dispatch(findAndUpdateACustomContactAction(updateCustomContactData.updateCustomContact.customContact))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Payeur / Bénéficiaire modifié.",
                })
                // handleClose()
            } else {
                updateCustomContactData.updateCustomContact.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateCustomContactData, updateCustomContactError])

    useEffect(() => {
        if (deleteCustomContactError) {
            addMessage({
                location: "CustomContact",
                type: EnumMessageType.Error,
                message: deleteCustomContactError.message,
            })
        } else if (deleteCustomContactData) {
            if (deleteCustomContactData.deleteCustomContact.statusCode === 200) {
                // setAllRefetchNeeded("CustomContact")
                // Cas spécial, Pour garder la modale open et son state settings, on effectue les opérations directement dans les reducers
                dispatch(deleteCustomContactAction(props.customContact.id))
                dispatch(findAndDeleteACustomContactAction(props.customContact.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Payeur / Bénéficiaire supprimé.",
                })
            } else {
                deleteCustomContactData.deleteCustomContact.errors.map((error: string) => {
                    addMessage({
                        location: "CustomContact",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteCustomContactData, deleteCustomContactError])

    const confirmedDeleteCustomContact = () => {
        deleteCustomContact({
            variables: {
                customContactId: props.customContact.id
            }
        })
        setShowDeleteCustomContactDialog(false)
    }

    const handleDelete = () => {
        setShowDeleteCustomContactDialog(true)
    }

    const closeDeleteCustomContactDialog = () => {
        setShowDeleteCustomContactDialog(false)
    }

    const checkErrors = () => {
        let res = true
        if (title === undefined || title.length === 0) {
            addMessage({
                type: EnumMessageType.Error,
                message: "La saisie est vide.",
            })
            res = false
        }
        return res
    }

    const confirmNewTitle = () => {
        if (checkErrors()) {
            updateCustomContact({
                variables: {
                    customContactId: props.customContact.id,
                    title,
                }
            })
        }
    }

    return (
        <>
            <Box className="CustomContactBadge" sx={props.sx}>
                <Box
                    onClick={() => props.onClick ? props.onClick(props.customContact) : null}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        ":hover": {
                            cursor: props.onClick ? "pointer" : "default",
                            ".avatar-icon": {
                                borderColor: props.onClick ? theme.palette.secondary.main : "rgba(0, 0, 0, .2)",
                            },
                            ".contact-title": {
                                borderColor: props.onClick ? theme.palette.secondary.main : "rgba(0, 0, 0, .15)",
                            }
                        },
                    }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>

                        {
                            (props.mode === EnumCustomContactMode.selection || props.mode === EnumCustomContactMode.standard) && <>
                                {
                                    props.displayTooltip
                                        ?
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.customContact.title}>
                                            {getAvatar()}
                                        </Tooltip>
                                        :
                                        <>
                                            {getAvatar()}
                                        </>
                                }
                                <Box className="contact-title" sx={{
                                    pl: { xs: getSize(EnumInnerSize.paperPl, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPl) },
                                    pr: { xs: getSize(EnumInnerSize.paperPr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPr) },
                                    border: "1px solid rgba(0, 0, 0, .15)",
                                    borderTopRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                                    borderBottomRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                                    backgroundColor: theme.palette.desktop.light,
                                    zIndex: 1,
                                }}>
                                    <Typography sx={{
                                        fontSize: { xs: getSize(EnumInnerSize.fontSize, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.fontSize) }
                                    }}>
                                        {props.customContact.title}
                                    </Typography>
                                </Box>
                            </>
                        }
                        {
                            props.mode === EnumCustomContactMode.settings && <>
                                <Flexbox>
                                    <TextField
                                        variant="standard"
                                        onChange={(event) => { setTitle(event.target.value) }}
                                        value={title}
                                        required
                                    />
                                    <IconButton
                                        onClick={confirmNewTitle}

                                    >
                                        <CheckIcon sx={{
                                            fontSize: 18,
                                        }} />
                                    </IconButton>
                                    <IconButton
                                        onClick={handleDelete}
                                    >
                                        <DeleteOutline sx={{
                                            fontSize: 18,
                                        }} />
                                    </IconButton>

                                </Flexbox>
                            </>
                        }
                    </Box>
                    {
                        props.badge &&
                        <Box sx={{
                            backgroundColor: getBadgeColor(false),
                            color: getBadgeColor(true),
                            px: 1,
                            borderBottomLeftRadius: 6,
                            borderBottomRightRadius: 6,
                            mt: -1,
                            zIndex: 0,
                        }}>
                            <Typography sx={{
                                fontSize: 12,
                            }}>
                                {props.badge.label}
                            </Typography>
                        </Box>
                    }

                </Box >
            </Box >
            <ModalDeleteConfirm
                open={showDeleteCustomContactDialog}
                title="Suppression du Payeur / Bénéficiaire"
                content={`Confirmez-vous la suppression définitive du Payeur / Bénéficiaire ${props.customContact.title} ?`}
                handleClose={closeDeleteCustomContactDialog}
                handleConfirm={confirmedDeleteCustomContact}
                danger={true}
            />
        </>
    )
}





