import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setFilterQueryAction } from '../../redux/features/filter/filterSlice';
import { IRootState, setAllRefetchNeeded } from '../../redux/store';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalFilter: FC<IProps> = (props) => {
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const [newFilterQuery, setNewFilterQuery] = useState(filterQuery || "")
    const queryRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch()
    const theme = useTheme()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (queryRef.current) {
                    queryRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(setFilterQueryAction(newFilterQuery))
        setAllRefetchNeeded("ModalFilter")
        handleClose()
    }

    const handleReset = () => {
        setNewFilterQuery("")
        dispatch(setFilterQueryAction(undefined))
        setAllRefetchNeeded("ModalFilter")
        handleClose()
    }

    const handleClose = () => {
        props.handleClose()
    }

    return (
        <div className="ModalFilter">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <FilterAltOutlinedIcon sx={{ fontSize: 28, mr: 1, color: theme.palette.primary.main, }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Saisie du filtre
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={queryRef}
                            autoFocus
                            margin="dense"
                            label="Filtre de recherche"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNewFilterQuery(event.target.value) }}
                            value={newFilterQuery}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button onClick={handleReset} variant="contained" color="secondary">Effacer</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Appliquer
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
