import "../../css/components/spinner/Spinner.css"
import { Container } from '@mui/material'
import { FC } from 'react'
import { DotLoader, RingLoader } from 'react-spinners'

interface IProps {
    size?: number
    classes?: string
}

// https://www.davidhu.io/react-spinners/
export const Spinner: FC<IProps> = (props) => {
    const color = "#4c1a57"
    return (
        <Container disableGutters className={`Spinner ${props.classes}`}>
            {/* <DotLoader color={color} size={props.size || 60} /> */}
            <RingLoader color={color} size={props.size || 60} />
        </Container>
    )
}
