import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import "../css/components/ResponsiveAppBar.css"
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { NAVIGATE_ROOT, NAVIGATE_LOGIN, isActivePath, NAVIGATE_PROFILE, NAVIGATE_ADMIN } from '../utils/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { removeJwt } from '../redux/features/global/apolloSlice';
import { IRootState } from '../redux/store';
import { setCurrentUser, useGetCurrentUser } from '../redux/features/users/currentUserSlice';
import { useQuery } from '@apollo/client';
import { GQL_WHO_AM_I } from '../graphql/Queries';
import { addMessage } from './messages/Message';
import { ICurrentUser } from '../interfaces';
import { EnumMessageType, EnumMetadocLogoMode } from '../enums';
import { MetadocLogo } from './MetadocLogo';
import { Skeleton, useTheme } from '@mui/material';

export const AvatarMenu = () => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const theme = useTheme()

    const currentUser: (ICurrentUser | undefined) = useSelector((state: IRootState) => state.currentUserReducer.user)
    const [avatarLoaded, setAvatarLoaded] = React.useState(false);

    const hasJwt = useSelector((state: IRootState) => state.apolloReducer.hasJwt)

    React.useEffect(() => {
        if (!hasJwt) {
            // Il n'y a pas de JWT, on redirect sur login..
            // Ne pas mettre de message à cause des redirects..
            navigate(`${NAVIGATE_LOGIN}`)
        }
    }, [])

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = (withMessage: boolean = false) => {
        setAnchorElUser(null);
        dispatch(removeJwt())
        if (withMessage) {
            addMessage({
                type: EnumMessageType.Success,
                message: "Déconnexion effectuée.",
            })
        }
        // Refresh page :
        // navigate(0)
        navigate(NAVIGATE_LOGIN)
    }

    const handleAvatarLoaded = () => {
        setAvatarLoaded(true);
    }

    return (
        <Box>
            <IconButton onClick={handleOpenUserMenu} sx={{ px: 0 }}>
                {
                    !avatarLoaded && currentUser?.userAvatarPreviewUrl && <Skeleton variant="circular" animation="wave"
                        sx={{
                            border: "1px solid white",
                            backgroundColor: theme.palette.grey[200],
                            width: "42px",
                            height: "42px",
                        }}
                    />
                }
                <Avatar
                    onLoad={handleAvatarLoaded}
                    className="avatar-icon"
                    alt={`${currentUser?.firstname} ${currentUser?.lastname}`}
                    src={`${currentUser?.userAvatarPreviewUrl}`}
                    sx={{ display: avatarLoaded || !currentUser || !currentUser.userAvatarPreviewUrl ? 'flex' : 'none', }}
                />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="avatar-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem
                    onClick={() => { navigate(NAVIGATE_PROFILE) }}
                    sx={{ textDecoration: isActivePath(NAVIGATE_PROFILE, location.pathname) ? "underline" : "", textDecorationColor: theme.palette.secondary.main }}
                >
                    <Typography textAlign="center">Profil</Typography>
                </MenuItem>
                {
                    currentUser && currentUser.admin &&
                    <MenuItem
                        onClick={() => { navigate(NAVIGATE_ADMIN) }}
                        sx={{ textDecoration: isActivePath(NAVIGATE_ADMIN, location.pathname) ? "underline" : "", textDecorationColor: theme.palette.secondary.main }}
                    >
                        <Typography textAlign="center">Admin</Typography>
                    </MenuItem>
                }
                <MenuItem onClick={() => logout(true)}>
                    <Typography textAlign="center">Se déconnecter</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};
