import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMessageType } from '../../../enums';
import { IContact, IFileToUpload, IUser } from '../../../interfaces';
import { Add } from '@mui/icons-material';
import useFileUpload from "../../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { GQL_MUTATION_UPDATE_CONTACT_AVATAR } from '../../../graphql/Mutations';
import { LinearProgressWithLabel } from '../../spinner/LinearProgressWithLabel';
import { useDispatch } from 'react-redux';
import { setCurrentUserAvatarPreviewUrl, setCurrentUserAvatarUrl } from '../../../redux/features/users/currentUserSlice';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';

interface IProps {
    open: boolean;
    handleClose: () => void;
    contact: IContact;
}

export const ModalContactAvatar: FC<IProps> = (props) => {
    const [fileToUpload, setFileToUpload] = useState<IFileToUpload | undefined>(undefined)
    const [updateContactAvatar, { data: updateContactAvatarData, loading: updateContactAvatarLoading, error: updateContactAvatarError }] = useMutation(GQL_MUTATION_UPDATE_CONTACT_AVATAR)

    const dispatch = useDispatch()

    useEffect(() => {
        if (fileToUpload && fileToUpload.sheetFile) {
            updateContactAvatar({
                variables: {
                    contactId: props.contact.id,
                    sheetFile: fileToUpload.sheetFile,
                }
            })
        }
    }, [fileToUpload])

    useEffect(() => {
        if (updateContactAvatarError) {
            addMessage({
                location: "ModalContactAvatar",
                type: EnumMessageType.Error,
                message: updateContactAvatarError.message,
            })
        } else if (updateContactAvatarData) {
            if (updateContactAvatarData.updateContactAvatar.statusCode === 200) {
                setAllRefetchNeeded("ModalContactAvatar")

                addMessage({
                    type: EnumMessageType.Success,
                    message: "Avatar du contact modifié.",
                })
                handleClose()
            } else {
                updateContactAvatarData.updateContactAvatar.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateContactAvatarData, updateContactAvatarError])

    const cleanDatas = () => {
        setFileToUpload(undefined)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const { handleUpload } = useFileUpload();
    const handlePercentage = (fileIndex: number, paramPercentage: number) => {
        setFileToUpload((currentFileToUpload) => {
            return currentFileToUpload !== undefined ?
                { ...currentFileToUpload, percentage: paramPercentage } :
                currentFileToUpload
        })
    }

    const handleFileChange = (files: FileList | null, handleCallback: Function) => {
        if (files && files.length > 0) {
            const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
            const maxSize = 1 * 1024 * 1024; // 1 Mo

            // Vérifier si le type de fichier est autorisé
            if (!allowedTypes.includes(files[0].type)) {
                //   console.log("Le fichier sélectionné n'est pas une image.");
                addMessage({
                    type: EnumMessageType.Error,
                    message: "Seules les images sont autorisées.",
                })
                return;
            }

            // Vérifier si la taille du fichier est autorisée
            if (files[0].size > maxSize) {
                //   console.log("Le fichier sélectionné est trop volumineux.");
                addMessage({
                    type: EnumMessageType.Error,
                    message: "Le fichier ne doit pas dépasser 1 Mo.",
                })
                return;
            }


            const newFileToUpload: IFileToUpload = {
                index: 0,
                percentage: 0,
                filename: files[0].name,
            }
            setFileToUpload(newFileToUpload)
            handleUpload(files, handlePercentage, handleUploadDone);
        }
    }

    const handleUploadDone = (fileIndex: number, fileMetaData: any) => {
        setFileToUpload(currentFileToUpload => currentFileToUpload !== undefined ?
            { ...currentFileToUpload, sheetFile: JSON.stringify(fileMetaData) } :
            currentFileToUpload
        )
    }

    return (
        <div className="ModalContactAvatar">
            <FloatingSpinner dependances={[updateContactAvatarLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Avatar alt={`${props.contact.firstname} ${props.contact.lastname}`} src={`${props.contact.contactAvatarPreviewUrl}`} sx={{ width: 24, height: 24, mr: 2 }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Modifier l'avatar
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                Sélectionner une image
                            </Typography>
                            <IconButton color="primary" component="label">
                                <input hidden type="file" onChange={(event) => handleFileChange(event.target.files, handlePercentage)} />
                                <Add fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider flexItem></Divider>
                        {
                            fileToUpload !== undefined
                            &&
                            <Box sx={{ width: "100%" }}>
                                <Typography>
                                    {fileToUpload.filename}
                                </Typography>
                                <LinearProgressWithLabel value={fileToUpload.percentage} color="success" />
                            </Box>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
