import { FC } from 'react';
import { Typography } from '@mui/material';
import { IDirectory } from "../interfaces";
import { DirectoryIcon } from "./svg_icons/DirectoryIcon";
import { EnumListLockersMode } from "../enums";
import { DocumentIcon } from './svg_icons/DocumentIcon';
import { Flexbox } from './utils/Flexbox';
import { NoteIcon } from './svg_icons/NoteIcon';


interface IProps {
    directory: IDirectory,
}

export const PublikSubDirectoriesInfos: FC<IProps> = (props) => {
    return (
        <Flexbox sx={{ justifyContent: "flex-start" }}>
            {
                props.directory.directoriesCount > 0 &&
                <>
                    <DirectoryIcon color={EnumListLockersMode.shared} sx={{ fontSize: 18, mr: .5 }} />
                    <Typography sx={{ mr: 1 }}>
                        {props.directory.directoriesCount}
                    </Typography>
                </>
            }
            {
                props.directory.documentsCount > 0 &&
                <>
                    <DocumentIcon color={EnumListLockersMode.shared} sx={{ fontSize: 18, mr: .5 }} />
                    <Typography sx={{ mr: 1 }}>
                        {props.directory.documentsCount}
                    </Typography>
                </>
            }
            {
                props.directory?.notesCount > 0 &&
                <>
                    <NoteIcon color={EnumListLockersMode.shared} sx={{ fontSize: 18, mr: .5 }} />
                    <Typography sx={{ mr: 1 }}>
                        {props.directory.notesCount}
                    </Typography>
                </>
            }

        </Flexbox>
    )
}
