import "../../css/components/lockers/Locker.css";
import { Avatar, Box, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ILocker } from "../../interfaces";
import { LockerIcon } from "../svg_icons/LockerIcon";
import {
    DeleteOutline, EditOutlined, ShareOutlined
} from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VaultIcon } from "../svg_icons/VaultIcon";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumBasePathFor, EnumListLockersMode, EnumLockerIconMode, EnumMessageType, EnumParentType } from "../../enums";
import { GQL_MUTATION_DELETE_LOCKER, GQL_MUTATION_PUT_LOCKER_IN_VAULT, GQL_MUTATION_EXTRACT_LOCKER_FROM_VAULT, GQL_MUTATION_EXIT_SHARE, GQL_MUTATION_SET_LOCKER_PUBLIK, GQL_MUTATION_UNSET_LOCKER_PUBLIK } from "../../graphql/Mutations";
import { removeLockerAction, setSizeInfosAction, toggleExpandLockerAction, toggleSelectLockerAction } from "../../redux/features/lockers/lockersSlice";
import { ModalEditLocker } from "./modals/ModalEditLocker";
import { truncateString } from "../../utils/Utils";
import { SharedIcon } from "../svg_icons/SharedIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { constructPath, getBasePath } from "../../utils/Navigation";
import { SharedIconCrossed } from "../svg_icons/SharedIconCrossed";
import { ModalExitShareConfirm } from "../modals/ModalExitShareConfirm";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { useGetCurrentUser } from "../../redux/features/users/currentUserSlice";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { apolloClient } from "../../ApolloClient";
import { GQL_QUERY_GET_SIZE_INFOS } from "../../graphql/Queries";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { ModalShareLocker } from "./modals/ModalShareLocker";
import { FloatingSpinner } from "../spinner/FloatingSpinner";

interface IProps {
    locker: ILocker,
}

export const Locker: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const [showDeleteLockerDialog, setShowDeleteLockerDialog] = useState(false)
    const [showExitShareDialog, setShowExitShareDialog] = useState(false)
    const [showModalEditLocker, setShowModalEditLocker] = useState(false)
    const [showModalShareLocker, setShowModalShareLocker] = useState(false)
    const [deleteLocker, { data: deleteLockerData, loading: deleteLockerLoading, error: deleteLockerError }] = useMutation(GQL_MUTATION_DELETE_LOCKER)
    const [putLockerInVault, { data: putLockerInVaultData, loading: putLockerInVaultLoading, error: putLockerInVaultError }] = useMutation(GQL_MUTATION_PUT_LOCKER_IN_VAULT)
    const [extractLockerFromVault, { data: extractLockerFromVaultData, loading: extractLockerFromVaultLoading, error: extractLockerFromVaultError }] = useMutation(GQL_MUTATION_EXTRACT_LOCKER_FROM_VAULT)
    const [exitShare, { data: exitShareData, loading: exitShareLoading, error: exitShareError }] = useMutation(GQL_MUTATION_EXIT_SHARE)
    const [setLockerPublik, { data: setLockerPublikData, loading: setLockerPublikLoading, error: setLockerPublikError }] = useMutation(GQL_MUTATION_SET_LOCKER_PUBLIK)
    const [unsetLockerPublik, { data: unsetLockerPublikData, loading: unsetLockerPublikLoading, error: unsetLockerPublikError }] = useMutation(GQL_MUTATION_UNSET_LOCKER_PUBLIK)
    const dispatch = useDispatch()

    const theme = useTheme()
    const navigate = useNavigate()

    const lockerPath = constructPath(getBasePath(EnumBasePathFor.locker, props.locker.mode), [
        { key: "lockerId", value: props.locker.id.toString() }
    ])


    useEffect(() => {
        if (deleteLockerError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: deleteLockerError.message,
            })
        } else if (deleteLockerData) {
            if (deleteLockerData.deleteLocker.statusCode === 200) {
                // dispatch(removeLockerAction(props.locker.id))
                setAllRefetchNeeded("Locker")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier supprimé.",
                })
            } else {
                deleteLockerData.deleteLocker.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteLockerData, deleteLockerError])

    useEffect(() => {
        if (putLockerInVaultError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: putLockerInVaultError.message,
            })
        } else if (putLockerInVaultData) {
            if (putLockerInVaultData.putLockerInVault.statusCode === 200) {
                dispatch(removeLockerAction(props.locker.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier placé dans le coffre.",
                })
            } else {
                putLockerInVaultData.putLockerInVault.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [putLockerInVaultData, putLockerInVaultError])

    useEffect(() => {
        if (extractLockerFromVaultError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: extractLockerFromVaultError.message,
            })
        } else if (extractLockerFromVaultData) {
            if (extractLockerFromVaultData.extractLockerFromVault.statusCode === 200) {
                dispatch(removeLockerAction(props.locker.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier retiré du coffre.",
                })
            } else {
                extractLockerFromVaultData.extractLockerFromVault.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [extractLockerFromVaultData, extractLockerFromVaultError])

    useEffect(() => {
        if (exitShareError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: exitShareError.message,
            })
        } else if (exitShareData) {
            if (exitShareData.exitShare.statusCode === 200) {
                setAllRefetchNeeded("Locker")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Sortie du partage effectuée.",
                })
            } else {
                exitShareData.exitShare.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [exitShareData, exitShareError])

    useEffect(() => {
        if (setLockerPublikError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: setLockerPublikError.message,
            })
        } else if (setLockerPublikData) {
            if (setLockerPublikData.setLockerPublik.statusCode === 200) {
                setAllRefetchNeeded("Locker")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier rendu public.",
                })
            } else {
                setLockerPublikData.setLockerPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setLockerPublikData, setLockerPublikError])

    useEffect(() => {
        if (unsetLockerPublikError) {
            addMessage({
                location: "Locker",
                type: EnumMessageType.Error,
                message: unsetLockerPublikError.message,
            })
        } else if (unsetLockerPublikData) {
            if (unsetLockerPublikData.unsetLockerPublik.statusCode === 200) {
                setAllRefetchNeeded("Locker")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier rendu privé.",
                })
            } else {
                unsetLockerPublikData.unsetLockerPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Locker",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [unsetLockerPublikData, unsetLockerPublikError])

    const handleEdit = () => {
        setShowModalEditLocker(true)
    }

    const handleShareLocker = () => {
        setShowModalShareLocker(true)
    }

    const handleDelete = () => {
        setShowDeleteLockerDialog(true)
    }

    const handleExitShare = () => {
        setShowExitShareDialog(true)
    }

    const closeDeleteLockerDialog = () => {
        setShowDeleteLockerDialog(false)
    }

    const confirmedDeleteLocker = () => {
        deleteLocker({
            variables: {
                lockerId: props.locker.id
            }
        })
        setShowDeleteLockerDialog(false)
    }

    const closeExitShareDialog = () => {
        setShowExitShareDialog(false)
    }

    const confirmedExitShare = () => {
        exitShare({
            variables: {
                lockerId: props.locker.id
            }
        })
        setShowExitShareDialog(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectLockerAction(props.locker.id))
    }

    const handleExpand = () => {
        dispatch(toggleExpandLockerAction(props.locker.id))
    }

    const handlePutInVault = () => {
        putLockerInVault({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const handleSetPublik = () => {
        setLockerPublik({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const handleUnsetPublik = () => {
        unsetLockerPublik({
            variables: {
                lockerId: props.locker.id
            }
        })
    }


    const handleExtractFromVault = () => {
        extractLockerFromVault({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const handleGetSizeInfos = () => {
        apolloClient.query({
            query: GQL_QUERY_GET_SIZE_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                lockerIds: [props.locker.id],
            }
        }
        ).then(response => {
            const payloadObject = response.data.getSizeInfos.lockers
            dispatch(setSizeInfosAction(payloadObject))
        })
    }

    const closeModalEditLocker = () => {
        setShowModalEditLocker(false)
    }

    const closeModalShareLocker = () => {
        setShowModalShareLocker(false)
    }


    return (
        <>
            <FloatingSpinner dependances={[deleteLockerLoading]} />
            <Paper className={`Locker ${props.locker.selected ? "selected" : ""} `}>
                <Box className="Locker-container">
                    <Box className="Locker-main-section">
                        <Box className="Locker-icon-section">
                            {
                                props.locker.mode === EnumListLockersMode.vault &&
                                <VaultIcon onClick={handleSelect} color="vault" sx={{ fontSize: 24, mx: .5, ":hover": { cursor: "pointer" } }} />
                            }
                            {
                                props.locker.mode === EnumListLockersMode.desktop &&
                                <LockerIcon mode={props.locker.delivery ? EnumLockerIconMode.delivery : EnumLockerIconMode.standard} onClick={handleSelect} color="desktop" sx={{ fontSize: 24, mx: .5, ":hover": { cursor: "pointer" } }} />
                            }
                            {
                                props.locker.mode === EnumListLockersMode.shared &&
                                <SharedIcon onClick={handleSelect} color="shared" sx={{ fontSize: 24, mx: .5, ":hover": { cursor: "pointer" } }} />
                            }

                        </Box>
                        <Box className="Locker-content-section">
                            <Box className="Locker-title-section">
                                <Typography
                                    onClick={() => navigate(lockerPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}
                                >
                                    {props.locker.title}
                                </Typography>
                                <Box className="Locker-users-section">
                                    {
                                        props.locker.owners.map((owner) => {
                                            return (
                                                <Tooltip key={owner.id} title={`${owner.firstname} ${owner.lastname}`}>
                                                    <Avatar className="avatar-icon" alt={`${owner.firstname} ${owner.lastname}`} src={`${owner.userAvatarPreviewUrl}`} />
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            <Typography >
                                {truncateString(props.locker.description, 80)}
                            </Typography>
                            <Box className="Locker-infos-section">
                                <Box className="Locker-infos-directories">
                                    <SubDirectoriesInfos mode={props.locker.mode} detailsMode={false} parentType={EnumParentType.locker} locker={props.locker} />
                                </Box>
                                {
                                    props.locker.viewers.length > 0 && <>
                                        <Box className="Locker-infos-viewers">
                                            |
                                            {/* <IosShare color="desktop" sx={{ fontSize: 18, mr: .5, ml: 1 }} /> */}
                                            {
                                                props.locker.viewers.map((viewer) => {
                                                    return (
                                                        <Tooltip key={viewer.id} title={`${viewer.firstname} ${viewer.lastname}`}>
                                                            <Avatar sx={{ ml: 1 }} className="avatar-icon" alt={`${viewer.firstname} ${viewer.lastname}`} src={`${viewer.userAvatarPreviewUrl}`} />
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>

                    </Box>
                    <Box className="Locker-footer-section">
                        <ExpandMore expand={props.locker.expanded == undefined ? false : props.locker.expanded}
                            onClick={handleExpand} aria-expanded={props.locker.expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Box>
                    <Box className="Locker-expanded-section">
                        <Collapse in={props.locker.expanded} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box className="Locker-expanded-section-actions">
                                {
                                    props.locker.mode !== EnumListLockersMode.shared &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                            <IconButton onClick={handleEdit} color="primary">
                                                <EditOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            !props.locker.delivery &&
                                            !props.locker.vault &&
                                            <>
                                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Partager">
                                                    <IconButton color="primary" onClick={handleShareLocker}>
                                                        <HandshakeOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {
                                                    props.locker.publik
                                                        ?
                                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rendre privé">
                                                            <IconButton color="shared" onClick={handleUnsetPublik}>
                                                                <ShareOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rendre public">
                                                            <IconButton color="primary" onClick={handleSetPublik}>
                                                                <ShareOutlined />
                                                            </IconButton>
                                                        </Tooltip>

                                                }
                                            </>
                                        }
                                        {
                                            !props.locker.delivery &&
                                            !props.locker.vault &&
                                            props.locker.viewers.length === 0 &&
                                            props.locker.owners.length === 1 &&
                                            props.locker.owners[0].id === currentUser?.id &&
                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans le coffre">
                                                <IconButton color="vault" onClick={handlePutInVault}>
                                                    <VaultIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            props.locker.vault &&
                                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer du coffre">
                                                <IconButton color="desktop" onClick={handleExtractFromVault}>
                                                    <LockerIcon mode={EnumLockerIconMode.standard} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                            <IconButton onClick={handleDelete} color="error">
                                                <DeleteOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                {
                                    props.locker.mode === EnumListLockersMode.shared &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Sortir du partage">
                                            <IconButton onClick={handleExitShare} color="error">
                                                <SharedIconCrossed />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                    <Link href={lockerPath} underline="none" target="_blank">
                                        <IconButton color="primary">
                                            <OpenInNewOutlinedIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Calculer la taille">
                                    <IconButton onClick={handleGetSizeInfos} color="primary">
                                        <DatabaseIcon />
                                    </IconButton>
                                </Tooltip>
                                {
                                    props.locker.sizeInfos === undefined
                                        ?
                                        <Typography>
                                            Taille non calculée.
                                        </Typography>
                                        :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.locker.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.locker.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                }
                            </Box>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditLocker
                open={showModalEditLocker}
                handleClose={closeModalEditLocker}
                locker={props.locker}
            />
            <ModalDeleteConfirm
                open={showDeleteLockerDialog}
                title="Suppression du casier"
                content={`Confirmez-vous la suppression définitive du casier ${props.locker.title} ?`}
                handleClose={closeDeleteLockerDialog}
                handleConfirm={confirmedDeleteLocker}
            />
            <ModalExitShareConfirm
                open={showExitShareDialog}
                title="Sortie du partage"
                content={`En sortant du partage, vous ne verrez plus le casier ${props.locker.title}.`}
                handleClose={closeExitShareDialog}
                handleConfirm={confirmedExitShare}
            />
            <ModalShareLocker
                open={showModalShareLocker}
                handleClose={closeModalShareLocker}
                locker={props.locker}
            />
        </>
    )
}
