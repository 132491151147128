import { Tooltip, Typography, useTheme, Link } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IBreadcrumb, IDirectoryShortcutted } from "../../interfaces";
import { truncateString } from '../../utils/Utils';
import { DirectoryIcon } from '../svg_icons/DirectoryIcon';
import { useNavigate } from 'react-router-dom';
import { getObjectPath } from '../../utils/Navigation';
import { Flexbox } from '../utils/Flexbox';
import { Breadcrumbs } from '../Breadcrumbs';
import { EnumBreadcrumbMode } from '../../enums';

interface IProps {
    directory: IDirectoryShortcutted,
}


export const DirectoryShortcutted: FC<IProps> = (props) => {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
    const theme = useTheme()
    const navigate = useNavigate()

    const getAncestorsBreadcrumbs = () => {
        if (props.directory === undefined) {
            return [{ title: "..." }]
        }
        let res = []
        // Le locker d'abord:
        res.push(
            {
                title: props.directory.locker.title,
            }
        )
        // Puis les ancestors:
        res.push(
            ...props.directory.ancestors.map(ancestor => {
                return {
                    title: ancestor.name,
                }
            })
        )
        return res
    }

    useEffect(() => {
        setBreadcrumbs([
            ...getAncestorsBreadcrumbs(),
        ])
    }, [props.directory])

    return (
        <Flexbox direction='column' sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
        }}>
            <Link href={getObjectPath(props.directory)} underline="none">
                <Flexbox
                    sx={{
                        ":hover": {
                            cursor: "pointer",
                            "& .hover-effect": {
                                backgroundColor: theme.palette.buttonBackground.main,
                            }
                        }
                    }}>
                    <DirectoryIcon color={props.directory.mode}
                        className='hover-effect'
                        sx={{
                            fontSize: 16,
                            p: 1,
                            mx: .5,
                            mr: -1,
                            border: "1px solid rgba(0, 0, 0, .2)",
                            borderRadius: "50%",
                            backgroundColor: "white",
                            zIndex: 2,
                        }}
                    />
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.directory.name}>
                        <Typography color={theme.palette.text.primary}
                            className='hover-effect'
                            sx={{
                                fontSize: 16,
                                pl: 1.5,
                                pr: 1,
                                border: "1px solid rgba(0, 0, 0, .15)",
                                borderTopRightRadius: "7px",
                                borderBottomRightRadius: "7px",
                                backgroundColor: "white",
                                minWidth: 100,
                            }}
                        >
                            {truncateString(props.directory.name, 12)}
                        </Typography>
                    </Tooltip>
                </Flexbox>
            </Link>
            <Breadcrumbs mode={EnumBreadcrumbMode.condensed} breadcrumbs={breadcrumbs} sx={{
                mt: -0.5,
                // mb: 2,
                pl: 4,
            }} />
        </Flexbox>
    )
}
