import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { IVideo } from "../../interfaces";

import { Flexbox } from "../utils/Flexbox";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

interface IProps {
    video: IVideo,
    onClick: (index: number) => void,
    index: number,
}

export const PublikVideo: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);

    const theme = useTheme()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    // width: "100%",
                    width: "320px",
                }}>
                <Box>
                    {
                        props.video.videoPreviewUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                // cursor: "pointer",
                                // ":hover": {
                                //     color: theme.palette.secondary.main,
                                // }
                            }}
                            // onClick={() => props.onClick(props.index)}
                            >
                                Video en construction
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>
                                    (Patientez puis recharchez la page..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.video.videoPreviewUrl && <>
                            <Flexbox
                                onClick={() => props.onClick(props.index)}
                                sx={{
                                    // bgcolor: "red",
                                    width: "304px", // 320 - 2*8 (padding)
                                    height: "200px",
                                    // backgroundColor: theme.palette.fotoBackground.main,
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    position: "absolute",
                                    cursor: "pointer",
                                }}>
                                <PlayCircleOutlinedIcon sx={{ fontSize: 50, color: "white" }} />
                            </Flexbox>
                            <Flexbox sx={{
                                px: 1,
                                py: 1,
                                backgroundColor: theme.palette.fotoBackground.main,
                                borderTopLeftRadius: 4,
                                borderTopRightRadius: 4,
                            }}>
                                {!previewLoaded && <Skeleton variant="rectangular" width={200} height={200} sx={{ borderRadius: 1 }} />}
                                <Box component="img"
                                    src={props.video.videoPreviewUrl}
                                    onLoad={handlePreviewLoaded}
                                    sx={{
                                        maxHeight: "200px",
                                        borderRadius: 1,
                                        display: previewLoaded ? 'block' : 'none',
                                        cursor: "pointer",
                                    }}
                                    onClick={() => props.onClick(props.index)}
                                />

                            </Flexbox>
                        </>
                    }


                    <Box>
                        <Divider />
                        <Flexbox
                            sx={{
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.video.mp4VideoFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}
