import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PublikLayout } from './PublikLayout';
import { useQuery } from '@apollo/client';
import { Spinner } from '../components/spinner/Spinner';
import { IDocument } from '../interfaces';
import { addMessage } from '../components/messages/Message';
import { EnumMessageType } from '../enums';
import { PublikDocumentDetails } from '../components/documents/PublikDocumentDetails';
import { GQL_PUBLIK_DOCUMENT } from '../graphql/Queries';
import { PublikDocument } from '../components/documents/PublikDocument';
import Grid from '@mui/material/Grid2';

interface IProps {
}

export const PublikDocumentPage: FC<IProps> = (props) => {
  const params = useParams()
  const documentId = params.documentId || ""
  const accessParams = new URLSearchParams(window.location.search);
  const pubToken = accessParams.get('access');
  const [publikDocument, setPublikDocument] = useState<IDocument | undefined>(undefined)
  const { data: getPublikDocumentData, loading: getPublikDocumentLoading, error: getPublikDocumentError } = useQuery(GQL_PUBLIK_DOCUMENT, {
    fetchPolicy: 'network-only',
    variables: {
      documentId,
      pubToken,
    }
  })

  useEffect(() => {
    if (getPublikDocumentError) {
      addMessage({
        location: "PublikDocumentPage",
        type: EnumMessageType.Error,
        message: getPublikDocumentError.message,
      })
    } else if (getPublikDocumentData) {
      setPublikDocument(getPublikDocumentData.publikDocument)
    }
  }, [getPublikDocumentData, getPublikDocumentError])

  return (
    <div className="PublikDocumentPage">
      <PublikLayout >
        <div className="page-body">
          {
            getPublikDocumentLoading && <Spinner classes="big-spinner" />
          }
          {
            publikDocument &&
            <>
              <PublikDocumentDetails document={publikDocument} />
              <Grid container spacing={0}>
                <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                  &nbsp;
                </Grid>
                <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                  <PublikDocument document={publikDocument} />
                </Grid>
              </Grid>
            </>
          }
        </div>
      </PublikLayout>
    </div >
  )
}
