import "../../css/components/notes/Note.css"
import "../../css/components/notes/markdown.css"
import { Box, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react'
import { IDocument, INote } from "../../interfaces";
import { DeleteOutline, CropFreeOutlined, EditOutlined } from '@mui/icons-material';
import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_DELETE_DOCUMENT, GQL_MUTATION_DELETE_NOTE } from "../../graphql/Mutations";
import { dateFormat, truncateString } from "../../utils/Utils";
import { removeDocumentAction, toggleExpandNoteAction, toggleSelectNoteAction } from "../../redux/features/directories/directorySlice";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addNotesAction as addNotesInPocketAction,
    removeNotesAction as removeNotesFromPocketAction,
    useGetNotes as useGetPocketNotes,
} from "../../redux/features/pocket/pocketSlice";
import { useNavigate } from "react-router-dom";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { NoteIcon } from '../svg_icons/NoteIcon';
import { ModalEditNote } from './modals/ModalEditNote';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';


interface IProps {
    note: INote,
}

export const Note: FC<IProps> = (props) => {
    const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false)
    const [showModalEditNote, setShowModalEditNote] = useState(false)
    const pocketNotes = useGetPocketNotes()
    const [hasOverflow, setHasOverflow] = useState(false);
    const markdownBoxRef = useRef(null);

    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)

    const [deleteNote, { data: deleteNoteData, loading: deleteNoteLoading, error: deleteNoteError }] = useMutation(GQL_MUTATION_DELETE_NOTE)
    const dispatch = useDispatch()

    const closeModalEditNote = () => {
        setShowModalEditNote(false)
    }

    useEffect(() => {
        if (markdownBoxRef.current) {
            const { scrollHeight, clientHeight } = markdownBoxRef.current;
            setHasOverflow(scrollHeight > clientHeight);
        }
        // }, [props.note.content, props.note.expanded]);
    }, []);

    useEffect(() => {
        if (deleteNoteError) {
            addMessage({
                location: "Note",
                type: EnumMessageType.Error,
                message: deleteNoteError.message,
            })
        } else if (deleteNoteData) {
            if (deleteNoteData.deleteNote.statusCode === 200) {
                // dispatch(removeNoteAction(props.note.id))
                setAllRefetchNeeded("Note")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Note supprimée.",
                })
            } else {
                deleteNoteData.deleteNote.errors.map((error: string) => {
                    addMessage({
                        location: "Note",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteNoteData, deleteNoteError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "Note",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addNotesInPocketAction([props.note]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "Note",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeNotesFromPocketAction([props.note.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    const handleEdit = () => {
        setShowModalEditNote(true)
    }

    const handleDelete = () => {
        setShowDeleteNoteDialog(true)
    }

    const closeDeleteNoteDialog = () => {
        setShowDeleteNoteDialog(false)
    }

    const confirmedDeleteNote = () => {
        deleteNote({
            variables: {
                noteId: props.note.id
            }
        })
        setShowDeleteNoteDialog(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectNoteAction(props.note.id))
    }

    const handleExpand = () => {
        dispatch(toggleExpandNoteAction(props.note.id))
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                noteIds: [props.note.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                noteIds: [props.note.id],
            }
        })
    }

    const isInPocket = () => {
        if (pocketNotes) {
            return pocketNotes.filter(note => note.id === props.note.id).length > 0
        }
        return false
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteNoteLoading]} />
            <Paper className={`Note ${props.note.selected ? "selected" : ""} `}>
                <Box className="Note-container">
                    <Box className="Note-main-section">
                        <Box className="Note-icon-section">
                            <NoteIcon onClick={handleSelect} color={props.note.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6, ":hover": { cursor: "pointer" } }} />
                        </Box>
                        <Box className="Note-content-section">
                            <Box className="Note-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24, overflowWrap: "anywhere" }}>
                                    {props.note.title}
                                </Typography>
                                {
                                    hasOverflow &&
                                    <ExpandMore
                                        expand={props.note.expanded == undefined ? false : props.note.expanded}
                                        onClick={handleExpand} aria-expanded={props.note.expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }
                            </Box>
                            <Box
                                ref={markdownBoxRef}
                                className="Note-markdown markdown-body"
                                sx={{
                                    maxHeight: !props.note.expanded ? "200px" : "none",
                                    overflowY: !props.note.expanded ? "hidden" : "visible",
                                }}
                            >
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} >
                                    {props.note.content}
                                </ReactMarkdown>
                            </Box>
                        </Box>

                    </Box>
                    <Divider />
                    <Box className="Note-footer-section">
                        {
                            isInPocket() ?
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                    <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                        <PocketIcon variant={EnumSvgVariant.secondary} />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                    <IconButton onClick={handlePutInPocket} color="primary">
                                        <PocketIcon variant={EnumSvgVariant.primary} />
                                    </IconButton>
                                </Tooltip>
                        }
                        {
                            props.note.mode !== EnumListLockersMode.shared &&
                            <>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                    <IconButton onClick={handleEdit} color="primary">
                                        <EditOutlined />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                    <IconButton onClick={handleDelete} color="error">
                                        <DeleteOutline />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </Box>
                </Box>
            </Paper>
            <ModalEditNote
                open={showModalEditNote}
                handleClose={closeModalEditNote}
                note={props.note}
            />
            <ModalDeleteConfirm
                open={showDeleteNoteDialog}
                title="Suppression de la note"
                content={`Confirmez-vous la suppression définitive de la note ${props.note.title} ?`}
                handleClose={closeDeleteNoteDialog}
                handleConfirm={confirmedDeleteNote}
            />
        </>
    )
}
