import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMandatoryContactFields, EnumMessageType } from '../../../enums';
import { IContact, IDocument } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import { validateEmail } from '../../../utils/Utils';
import { useGetCurrentUser } from '../../../redux/features/users/currentUserSlice';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Flexbox } from '../../utils/Flexbox';
import { ContactSelection } from '../../contacts/ContactSelection';
import { GQL_MUTATION_SEND_DOCUMENT_BY_MAIL } from '../../../graphql/Mutations';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';

interface IProps {
    open: boolean;
    handleClose: () => void;
    document: IDocument

}

export const ModalSendByMailDocument: FC<IProps> = (props) => {
    const [receiverEmail, setReceiverEmail] = useState("")
    const [errorOnReceiverEmail, setErrorOnReceiverEmail] = useState(false)
    const [mailSubject, setMailSubject] = useState("")
    const [errorOnMailSubject, setErrorOnMailSubject] = useState(false)
    const [mailContent, setMailContent] = useState("")
    const [showContactList, setShowContactList] = useState(false)

    const [sendDocumentByMail, { data: sendDocumentByMailData, loading: sendDocumentByMailLoading, error: sendDocumentByMailError }] = useMutation(GQL_MUTATION_SEND_DOCUMENT_BY_MAIL)
    const currentUser = useGetCurrentUser()



    useEffect(() => {
        setDefaultMailSubject()
        setDefaultMailContent()
    }, [currentUser, props.document]);

    useEffect(() => {
        if (sendDocumentByMailError) {
            addMessage({
                location: "ModalSendByMailDocument",
                type: EnumMessageType.Error,
                message: sendDocumentByMailError.message,
            })
        } else if (sendDocumentByMailData) {
            if (sendDocumentByMailData.sendDocumentByMail.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Mail envoyé.",
                })
                handleClose()
            } else {
                sendDocumentByMailData.sendDocumentByMail.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [sendDocumentByMailData, sendDocumentByMailError])

    const setDefaultMailSubject = () => {
        setMailSubject("[Metadoc] - Envoi de document")
    }

    const setDefaultMailContent = () => {
        if (currentUser) {
            const currentUserDisplayName = `${currentUser.firstname} ${currentUser.lastname}`
            // Déterminer le nom du fichier s'il y en a un
            let documentFileName = ""
            const applicableSheet = props.document.sheets?.filter(sheet => sheet.applicable)[0]
            if (applicableSheet) {
                documentFileName = applicableSheet.filename
            }
            let documentDescription = ""
            if (props.document.description) {
                documentDescription = `Description : ${props.document.description}`
            }
            let mailContent = `Bonjour,`
            mailContent += `\n\n${currentUserDisplayName} vous fait parvenir en pièce-jointe le document dont voici les détails :`
            mailContent += `\n\nNom du document : ${props.document.title}`
            mailContent += `\n\nNom du fichier : ${documentFileName}`
            mailContent += `\n\n${documentDescription}`
            setMailContent(mailContent)
        }
    }

    const checkErrors = () => {
        let res = true
        setErrorOnReceiverEmail(false)
        setErrorOnMailSubject(false)
        if (receiverEmail === undefined || receiverEmail === "" || !validateEmail(receiverEmail)) {
            res = false;
            setErrorOnReceiverEmail(true)
        }
        if (mailSubject === undefined || mailSubject === "") {
            res = false
            setErrorOnMailSubject(true)
        }
        return res
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            sendDocumentByMail({
                variables: {
                    documentId: props.document.id,
                    receiverEmail,
                    mailSubject,
                    mailContent,
                }
            })
        }
    }

    const cleanDatas = () => {
        setReceiverEmail("")
        setErrorOnReceiverEmail(false)
        setDefaultMailSubject()
        setErrorOnMailSubject(false)
        setDefaultMailContent()
        setShowContactList(false)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const handleShowContactList = () => {
        setShowContactList(!showContactList)
    }

    const handleSelectContact = (contact: IContact) => {
        console.log("MED: => handleSelectContact", contact)
        setReceiverEmail(contact.email || "")
        setShowContactList(false)
    }

    return (
        <div className="ModalSendByMailDocument">
            <FloatingSpinner dependances={[sendDocumentByMailLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <ForwardToInboxIcon color="desktop" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Envoi par mail
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Vous pouvez renseigner manuellement l'adresse mail du destinataire ou <Button color='secondary' onClick={handleShowContactList}>sélectionner un contact</Button>.
                        </Typography>
                        <Flexbox sx={{
                            display: showContactList ? "flex" : "none",
                            // bgcolor: "yellow",
                        }}>
                            <ContactSelection
                                handleSelect={(contact) => handleSelectContact(contact)}
                                mandatoryContactFields={[EnumMandatoryContactFields.email]}
                            />
                        </Flexbox>
                        <TextField
                            margin="dense"
                            label="Email du destinataire"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setReceiverEmail(event.target.value) }}
                            value={receiverEmail}
                            required
                            error={errorOnReceiverEmail}
                            helperText={errorOnReceiverEmail ? "Ce champ est obligatoire." : ""}
                        />
                        <Box sx={{ display: showContactList ? "none" : "block" }}>
                            <TextField
                                margin="dense"
                                label="Objet"
                                fullWidth
                                variant="standard"
                                onChange={(event) => { setMailSubject(event.target.value) }}
                                value={mailSubject}
                                required
                                error={errorOnMailSubject}
                                helperText={errorOnMailSubject ? "Ce champ est obligatoire." : ""}
                            />
                            <TextField
                                margin="normal"
                                label="Contenu"
                                fullWidth
                                multiline
                                rows={10}
                                onChange={(event) => { setMailContent(event.target.value) }}
                                value={mailContent}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type='submit' startIcon={<ForwardToInboxIcon />} variant="contained" color="desktop" size="small" >Envoyer</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
