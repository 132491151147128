import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';


interface IProps {
    open: boolean;
    title: string,
    disableFuture: boolean,
    defaultDate: Dayjs,
    handleClose: () => void;
    handleConfirm: (date: Dayjs) => void;
    handleReset?: () => void;
}

export const ModalDatePicker: FC<IProps> = (props) => {
    // const [date, setDate] = useState(dayjs(props.defaultDate || dayjs()))
    const [date, setDate] = useState(props.defaultDate)

    const theme = useTheme()

    // Quand la date est changée de l'extérieur, il faut mettre à jour le state
    useEffect(() => {
        setDate(props.defaultDate)
    }, [props.defaultDate])

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        props.handleConfirm(date)
        handleClose()
    }

    const handleReset = () => {
        // setDate(props.defaultDate)
        if (props.handleReset) {
            props.handleReset()
        }
        handleClose()
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleChange = (pDate: Dayjs) => {
        // On ne peut pas valider directement la date sur sélection car lors de la sélection d'une autre année, il set la date.
        // props.handleConfirm(pDate)
        // handleClose()
        setDate(pDate)
    }

    return (
        <div className="ModalDatePicker">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <EventOutlinedIcon sx={{ fontSize: 28, mr: 1, color: theme.palette.primary.main, }} />
                            <Typography sx={{ fontSize: 24 }}>
                                {props.title}
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <StaticDatePicker
                                    displayStaticWrapperAs='desktop'
                                    sx={{ px: 0 }}
                                    onChange={(newDate) => { handleChange(newDate || dayjs()) }}
                                    disableFuture={props.disableFuture}
                                    value={date}
                                />
                            </LocalizationProvider>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {
                            props.handleReset &&
                            <Button onClick={handleReset} variant="contained" color="secondary">Effacer</Button>
                        }
                        <Button type="submit" variant="contained" color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
