import { createSlice } from "@reduxjs/toolkit";
import { IAccountOperationStatesFilter, IBankAccount } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_BANK_ACCOUNT } from "../../../graphql/Queries";
import { EnumAccountOperationImputableVisibility, EnumAccountOperationTypeVisibility, EnumDayjsFormat } from "../../../enums";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';


interface IBankAccountSliceState {
    bankAccount: undefined | IBankAccount,
    refetchNeeded: boolean,
    dateFrom: string,
    dateTo: string,
    filter: string,
    statesFilter: IAccountOperationStatesFilter,
    imputableVisibility: EnumAccountOperationImputableVisibility,
    accountOperationTypeVisibility: EnumAccountOperationTypeVisibility,
}

const initialState: IBankAccountSliceState = {
    bankAccount: undefined,
    refetchNeeded: false,
    dateFrom: dayjs().startOf('month').locale('fr').format(EnumDayjsFormat.date),
    dateTo: dayjs().endOf('month').locale('fr').format(EnumDayjsFormat.date),
    filter: "",
    statesFilter: {
        prepared: true,
        created: true,
        paid: true,
        consolidated: true,
    },
    imputableVisibility: EnumAccountOperationImputableVisibility.both,
    accountOperationTypeVisibility: EnumAccountOperationTypeVisibility.both,

}

export const bankAccountSlice = createSlice({
    name: "bankAccountSlice",
    initialState,
    reducers: {
        setBankAccountAction: (state, action) => {
            state.bankAccount = action.payload
        },
        setBankAccountRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        findAndUpdateACustomContactAction: (state, action) => {
            if (state.bankAccount) {
                state.bankAccount = {
                    ...state.bankAccount,
                    accountOperations: state.bankAccount.accountOperations?.map(accountOperation => {
                        // if (
                        //     accountOperation.counterparty?.counterpartyType === EnumCounterpartyType.customContact &&
                        //     accountOperation.counterparty?.customContact?.id === action.payload.id
                        // ) {
                        //     return {
                        //         ...accountOperation,
                        //         counterparty: {
                        //             ...accountOperation.counterparty,
                        //             customContact: {
                        //                 ...accountOperation.counterparty.customContact,
                        //                 ...action.payload
                        //             }
                        //         }
                        //     };
                        // }
                        if (
                            accountOperation.customContact?.id === action.payload.id
                        ) {
                            return {
                                ...accountOperation,
                                customContact: {
                                    ...accountOperation.customContact,
                                    ...action.payload
                                }
                            };
                        }
                        return accountOperation;
                    })
                };
            }
        },
        findAndDeleteACustomContactAction: (state, action) => {
            if (state.bankAccount) {
                state.bankAccount = {
                    ...state.bankAccount,
                    accountOperations: state.bankAccount.accountOperations?.map(accountOperation => {
                        if (
                            accountOperation.customContact?.id === action.payload
                        ) {
                            return {
                                ...accountOperation,
                                counterparty: undefined
                            };
                        }
                        return accountOperation;
                    })
                };
            }
        },
        setDateFromAction: (state, action) => {
            state.dateFrom = action.payload
        },
        setDateToAction: (state, action) => {
            state.dateTo = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        setStatesFilter: (state, action) => {
            state.statesFilter = action.payload
        },
        toggleImputableVisibility: (state) => {
            const order_array = [
                EnumAccountOperationImputableVisibility.notImputable,
                EnumAccountOperationImputableVisibility.imputable,
                EnumAccountOperationImputableVisibility.both,
            ]
            const currentIndex = order_array.indexOf(state.imputableVisibility)
            const newIndex = (currentIndex + 1) % order_array.length
            state.imputableVisibility = order_array[newIndex]
        },
        toggleAccountOperationTypeVisibility: (state) => {
            const order_array = [
                EnumAccountOperationTypeVisibility.credit,
                EnumAccountOperationTypeVisibility.debit,
                EnumAccountOperationTypeVisibility.both,
            ]
            const currentIndex = order_array.indexOf(state.accountOperationTypeVisibility)
            const newIndex = (currentIndex + 1) % order_array.length
            state.accountOperationTypeVisibility = order_array[newIndex]
        },
        toggleTemporaryStepAsideAccountOperation: (state, action) => {
            if (state.bankAccount) {
                state.bankAccount = {
                    ...state.bankAccount,
                    accountOperations: state.bankAccount.accountOperations?.map(accountOperation => {
                        if (accountOperation.id === action.payload) {
                            return {
                                ...accountOperation,
                                temporaryStepAside: !accountOperation.temporaryStepAside
                            };
                        }
                        return accountOperation;
                    })
                };
            }
        }
    }
})

export const {
    setBankAccountRefetchNeeded,
    setBankAccountAction,
    findAndUpdateACustomContactAction,
    findAndDeleteACustomContactAction,
    setDateFromAction,
    setDateToAction,
    setFilter,
    setStatesFilter,
    toggleImputableVisibility,
    toggleAccountOperationTypeVisibility,
    toggleTemporaryStepAsideAccountOperation,
} = bankAccountSlice.actions


export const useGetBankAccount = (bankAccountId: string) => {
    const bankAccount: (undefined | IBankAccount) = useSelector((state: IRootState) => state.bankAccountReducer.bankAccount)
    const dateFrom: Dayjs = dayjs(useSelector((state: IRootState) => state.bankAccountReducer.dateFrom), EnumDayjsFormat.date)
    const dateTo: Dayjs = dayjs(useSelector((state: IRootState) => state.bankAccountReducer.dateTo), EnumDayjsFormat.date)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.bankAccountReducer.refetchNeeded)
    if (refetchNeeded || bankAccount === undefined || bankAccount.id !== bankAccountId) {
        apolloClient.query({
            query: GQL_BANK_ACCOUNT,
            fetchPolicy: 'network-only',
            variables: {
                bankAccountId,
                dateFrom: dateFrom.format(EnumDayjsFormat.dateForBackend),
                dateTo: dateTo.format(EnumDayjsFormat.dateForBackend),
            }
        }
        ).then(response => {
            store.dispatch(setBankAccountAction(response.data.bankAccount))
            store.dispatch(setBankAccountRefetchNeeded(false))
            return response.data.bankAccount
        })
    } else {
        return bankAccount
    }
}

export default bankAccountSlice.reducer