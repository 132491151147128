import { useMutation } from '@apollo/client';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_DIRECTORY } from '../../../graphql/Mutations';
import { IDirectory, ILocker } from '../../../interfaces';
import { setAllRefetchNeeded } from '../../../redux/store';
// import { addLockerAction } from '../../../redux/features/lockers/lockersSlice';
import { addMessage } from '../../messages/Message';
import { DirectoryIcon } from '../../svg_icons/DirectoryIcon';

interface IProps {
    open: boolean;
    handleClose: () => void;
    locker?: ILocker;
    directory?: IDirectory;
    mode: EnumListLockersMode;
}

export const ModalNewDirectory: FC<IProps> = (props) => {
    const [name, setName] = useState("")
    const [errorOnName, setErrorOnName] = useState(false)
    const [description, setDescription] = useState("")
    const nameRef = useRef<HTMLInputElement>(null);
    // const lockersMode: (undefined | EnumListLockersMode) = useSelector((state: IRootState) => state.lockersReducer.lockersMode)
    const [createDirectory, { data: createDirectoryData, loading: createDirectoryLoading, error: createDirectoryError }] = useMutation(GQL_MUTATION_CREATE_DIRECTORY)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (nameRef.current) {
                    nameRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createDirectoryError) {
            addMessage({
                location: "ModalNewDirectory",
                type: EnumMessageType.Error,
                message: createDirectoryError.message,
            })
        } else if (createDirectoryData) {
            if (createDirectoryData.createDirectory.statusCode === 200) {
                // const createdDirectory: IDirectory = createDirectoryData.createDirectory.directory
                // if (props.locker) {
                //     dispatch(addDirectoryAction(createdDirectory))
                // }
                // if (props.directory) {
                //     dispatch(addChildAction(createdDirectory))
                // }
                // dispatch(setLockersRefetchNeeded(true))
                setAllRefetchNeeded("ModalNewDirectory")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier ajouté.",
                })
                handleClose()
            } else {
                createDirectoryData.createDirectory.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createDirectoryData, createDirectoryError])

    const checkErrors = () => {
        if (name === undefined || name === "") {
            setErrorOnName(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setName("")
        setErrorOnName(false)
        setDescription("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createDirectory({
                variables: {
                    name,
                    description,
                    lockerId: props.locker?.id,
                    directoryId: props.directory?.id,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalNewDirectory">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <DirectoryIcon color={props.mode} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouveau dossier
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={nameRef}
                            autoFocus
                            margin="dense"
                            label="Nom du dossier"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setName(event.target.value) }}
                            value={name}
                            required
                            error={errorOnName}
                            helperText={errorOnName ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
