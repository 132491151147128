import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { store } from "./redux/store";
import { Provider } from 'react-redux';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode> effectue des checks supplémentaires mais peut entrainer des montages de composants en double et des PB de perfs..
  // En production, désactiver <React.StrictMode> permettrait de gagner en performances..
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);