// import "../../css/components/albums/Album.css";
import { Avatar, Box, Collapse, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IAlbum } from "../../interfaces";
import { CameraIcon } from "../svg_icons/CameraIcon";
import {
    DeleteOutline, EditOutlined, ShareOutlined
} from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumDayjsFormat, EnumListAlbumsMode, EnumMessageType } from "../../enums";
import { GQL_MUTATION_DELETE_ALBUM, GQL_MUTATION_EXIT_ALBUM_SHARE, GQL_MUTATION_SET_ALBUM_PUBLIK, GQL_MUTATION_UNSET_ALBUM_PUBLIK } from "../../graphql/Mutations";
import { setSizeInfosAction, toggleExpandAlbumAction, toggleSelectAlbumAction } from "../../redux/features/albums/albumsSlice";
import { ModalEditAlbum } from "./modals/ModalEditAlbum";
import { truncateString } from "../../utils/Utils";
import { setAllRefetchNeeded } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_ALBUM, constructPath } from "../../utils/Navigation";
import { SharedIconCrossed } from "../svg_icons/SharedIconCrossed";
import { ModalExitShareConfirm } from "../modals/ModalExitShareConfirm";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useGetCurrentUser } from "../../redux/features/users/currentUserSlice";
import { DatabaseIcon } from "../svg_icons/DatabaseIcon";
import { apolloClient } from "../../ApolloClient";
import { GQL_QUERY_GET_SIZE_INFOS } from "../../graphql/Queries";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { ModalShareAlbum } from "./modals/ModalShareAlbum";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { SubAlbumInfos } from './SubAlbumInfos';
import { Flexbox } from '../utils/Flexbox';
import { FotoPreview } from '../fotos/FotoPreview';
import { VideoPreview } from '../fotos/VideoPreview';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

interface IProps {
    album: IAlbum,
}

export const Album: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const [showDeleteAlbumDialog, setShowDeleteAlbumDialog] = useState(false)
    const [showExitShareDialog, setShowExitShareDialog] = useState(false)
    const [showModalEditAlbum, setShowModalEditAlbum] = useState(false)
    const [showModalShareAlbum, setShowModalShareAlbum] = useState(false)
    const [deleteAlbum, { data: deleteAlbumData, loading: deleteAlbumLoading, error: deleteAlbumError }] = useMutation(GQL_MUTATION_DELETE_ALBUM)
    const [exitAlbumShare, { data: exitAlbumShareData, loading: exitAlbumShareLoading, error: exitAlbumShareError }] = useMutation(GQL_MUTATION_EXIT_ALBUM_SHARE)
    const [setAlbumPublik, { data: setAlbumPublikData, loading: setAlbumPublikLoading, error: setAlbumPublikError }] = useMutation(GQL_MUTATION_SET_ALBUM_PUBLIK)
    const [unsetAlbumPublik, { data: unsetAlbumPublikData, loading: unsetAlbumPublikLoading, error: unsetAlbumPublikError }] = useMutation(GQL_MUTATION_UNSET_ALBUM_PUBLIK)
    const dispatch = useDispatch()

    const theme = useTheme()
    const navigate = useNavigate()

    // const albumPath = constructPath(getBasePath(EnumBasePathFor.album), [
    //     { key: "albumId", value: props.album.id.toString() }
    // ])
    const albumPath = constructPath(NAVIGATE_ALBUM, [
        { key: "albumId", value: props.album.id.toString() }
    ])


    useEffect(() => {
        if (deleteAlbumError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: deleteAlbumError.message,
            })
        } else if (deleteAlbumData) {
            if (deleteAlbumData.deleteAlbum.statusCode === 200) {
                // dispatch(removeAlbumAction(props.album.id))
                setAllRefetchNeeded("Album")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album supprimé.",
                })
            } else {
                deleteAlbumData.deleteAlbum.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAlbumData, deleteAlbumError])

    useEffect(() => {
        if (exitAlbumShareError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: exitAlbumShareError.message,
            })
        } else if (exitAlbumShareData) {
            if (exitAlbumShareData.exitAlbumShare.statusCode === 200) {
                setAllRefetchNeeded("Album")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Sortie du partage effectuée.",
                })
            } else {
                exitAlbumShareData.exitAlbumShare.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [exitAlbumShareData, exitAlbumShareError])

    useEffect(() => {
        if (setAlbumPublikError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: setAlbumPublikError.message,
            })
        } else if (setAlbumPublikData) {
            if (setAlbumPublikData.setAlbumPublik.statusCode === 200) {
                setAllRefetchNeeded("Album")
                // dispatch(setPublikAlbumAction(props.album.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album rendu public.",
                })
            } else {
                setAlbumPublikData.setAlbumPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setAlbumPublikData, setAlbumPublikError])

    useEffect(() => {
        if (unsetAlbumPublikError) {
            addMessage({
                location: "Album",
                type: EnumMessageType.Error,
                message: unsetAlbumPublikError.message,
            })
        } else if (unsetAlbumPublikData) {
            if (unsetAlbumPublikData.unsetAlbumPublik.statusCode === 200) {
                setAllRefetchNeeded("Album")
                // dispatch(unsetPublikAlbumAction(props.album.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album rendu privé.",
                })
            } else {
                unsetAlbumPublikData.unsetAlbumPublik.errors.map((error: string) => {
                    addMessage({
                        location: "Album",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [unsetAlbumPublikData, unsetAlbumPublikError])

    const handleEdit = () => {
        setShowModalEditAlbum(true)
    }

    const handleShareAlbum = () => {
        setShowModalShareAlbum(true)
    }

    const handleDelete = () => {
        setShowDeleteAlbumDialog(true)
    }

    const handleExitShare = () => {
        setShowExitShareDialog(true)
    }

    const closeDeleteAlbumDialog = () => {
        setShowDeleteAlbumDialog(false)
    }

    const confirmedDeleteAlbum = () => {
        deleteAlbum({
            variables: {
                albumId: props.album.id
            }
        })
        setShowDeleteAlbumDialog(false)
    }

    const closeExitShareDialog = () => {
        setShowExitShareDialog(false)
    }

    const confirmedExitShare = () => {
        // TODO A tester.
        exitAlbumShare({
            variables: {
                albumId: props.album.id
            }
        })
        setShowExitShareDialog(false)
    }

    const handleSelect = () => {
        dispatch(toggleSelectAlbumAction(props.album.id))
    }

    const handleExpand = () => {
        dispatch(toggleExpandAlbumAction(props.album.id))
    }

    const handleSetPublik = () => {
        setAlbumPublik({
            variables: {
                albumId: props.album.id
            }
        })
    }

    const handleUnsetPublik = () => {
        unsetAlbumPublik({
            variables: {
                albumId: props.album.id
            }
        })
    }


    const handleGetSizeInfos = () => {
        apolloClient.query({
            query: GQL_QUERY_GET_SIZE_INFOS,
            fetchPolicy: 'network-only',
            variables: {
                albumIds: [props.album.id],
            }
        }
        ).then(response => {
            const payloadObject = response.data.getSizeInfos.albums
            dispatch(setSizeInfosAction(payloadObject))
        })
    }

    const closeModalEditAlbum = () => {
        setShowModalEditAlbum(false)
    }

    const closeModalShareAlbum = () => {
        setShowModalShareAlbum(false)
    }


    return (
        <>
            <FloatingSpinner dependances={[deleteAlbumLoading]} />
            <Paper className="Album" sx={{
                mt: "24px",
                mb: "12px",
                ml: "12px",
                mr: "12px",
                width: "100%",
                boxShadow: props.album.selected ? `0px 0px 4px 2px ${theme.palette.secondary.main}` : "",
                maxWidth: "360px",
            }}>
                <Box className="Album-container" sx={{
                    pt: "12px",
                    pb: "6px",
                    pl: "12px",
                    pr: "12px",
                }}>
                    <Box className="Album-main-section" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}>
                        <Box className="Album-icon-section"
                            sx={{
                                pt: "16px",
                                pb: "8px",
                                pl: "8px",
                                pr: "8px",
                                mt: "-32px",
                                ml: "-24px",
                                backgroundColor: "white",
                                borderRadius: "50%",
                                borderLeft: "1px solid rgb(0 0 0 / 12%)",
                                ...(props.album.selected && {
                                    border: "none",
                                    boxShadow: `0px 0px 4px 2px ${theme.palette.secondary.main}`,
                                    "svg": {
                                        boxShadow: `13px 17px 0px 13px #fff`,
                                    }
                                })
                            }}>
                            <CameraIcon onClick={handleSelect} color={props.album.mode === EnumListAlbumsMode.owned ? "desktop" : "shared"} sx={{ fontSize: 24, mx: .5, ":hover": { cursor: "pointer" } }} />
                        </Box>
                        <Box className="Album-content-section" sx={{
                            width: "100%",
                            pl: "4px",
                            pr: "4px",
                            pb: "4px",
                        }}>
                            <Box className="Album-title-section" sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                            }}>
                                <Typography
                                    onClick={() => navigate(albumPath)}
                                    sx={{
                                        color: theme.palette.text.primary,
                                        fontSize: 24,
                                        ":hover": {
                                            cursor: "pointer",
                                            textShadow: `-1px 0px 4px ${theme.palette.grey[600]}`,
                                        }
                                    }}
                                >
                                    {props.album.title}
                                </Typography>
                                <Box className="Album-users-section" sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-start",
                                }}>
                                    {
                                        props.album.owners.map((owner) => {
                                            return (
                                                <Tooltip key={owner.id} title={`${owner.firstname} ${owner.lastname}`}>
                                                    <Avatar className="avatar-icon" alt={`${owner.firstname} ${owner.lastname}`} src={`${owner.userAvatarPreviewUrl}`} sx={{
                                                        width: "18px",
                                                        height: "auto",
                                                    }} />
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            <Typography >
                                {truncateString(props.album.description, 80)}
                            </Typography>
                            <Box className="Album-infos-section" sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}>
                                <Box className="Album-infos-directories" sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                    {/* <SubDirectoriesInfos mode={props.album.mode} detailsMode={false} parentType={EnumParentType.album} album={props.album} /> */}
                                    <SubAlbumInfos detailsMode={false} album={props.album} />
                                </Box>
                                {
                                    props.album.viewers.length > 0 && <>
                                        <Box className="Album-infos-viewers" sx={{
                                            ml: "12px",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                        }}>
                                            |
                                            {
                                                props.album.viewers.map((viewer) => {
                                                    return (
                                                        <Tooltip key={viewer.id} title={`${viewer.firstname} ${viewer.lastname}`}>
                                                            <Avatar sx={{
                                                                ml: 1,
                                                                width: "18px",
                                                                height: "auto",
                                                                "img": {
                                                                    webkitFilter: "grayscale(100%)",
                                                                    /* Safari 6.0 - 9.0 */
                                                                    filter: "grayscale(100%)",
                                                                }
                                                            }} className="avatar-icon" alt={`${viewer.firstname} ${viewer.lastname}`} src={`${viewer.userAvatarPreviewUrl}`} />
                                                        </Tooltip>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </>
                                }
                            </Box>
                        </Box>

                    </Box>
                    <Flexbox sx={{
                        // bgcolor: "red",
                        width: "100%",
                        // justifyContent: "center",
                    }}>
                        <Flexbox sx={{
                            // bgcolor: theme.palette.grey[500],
                            // pt: 2,
                            // pb: 1,
                            mt: 2,
                            mb: 1,
                            overflowX: "hidden",
                            boxShadow: `0px 0px 14px 4px ${theme.palette.grey[500]}`,
                            backgroundColor: theme.palette.grey[400],
                            width: "100%",
                            // maxWidth: "100%",
                            maxWidth: "320px",

                        }}>
                            {
                                props.album.randomFotos.map((randomFoto, randomFotoIndex) => {
                                    return <FotoPreview key={randomFotoIndex} foto={randomFoto} maxHeight={50} />
                                })
                            }
                            {
                                props.album.randomVideos.map((randomVideo, randomVideoIndex) => {
                                    return <VideoPreview key={randomVideoIndex} video={randomVideo} maxHeight={50} />
                                })
                            }
                        </Flexbox>

                    </Flexbox>
                    <Box className="Album-footer-section" sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <ExpandMore expand={props.album.expanded == undefined ? false : props.album.expanded}
                            onClick={handleExpand} aria-expanded={props.album.expanded} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                        <Typography sx={{
                            color: theme.palette.text.secondary,
                        }}>
                            {dayjs(props.album.dateAlbum).locale('fr').format(EnumDayjsFormat.date)}
                        </Typography>
                    </Box>
                    <Box className="Album-expanded-section">
                        <Collapse in={props.album.expanded} timeout="auto" unmountOnExit>
                            <Divider />
                            <Box className="Album-expanded-section-actions" sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                {
                                    props.album.mode === EnumListAlbumsMode.owned &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                            <IconButton onClick={handleEdit} color="primary">
                                                <EditOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Partager">
                                            <IconButton color="primary" onClick={handleShareAlbum}>
                                                <HandshakeOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {
                                            props.album.publik
                                                ?
                                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rendre privé">
                                                    <IconButton color="shared" onClick={handleUnsetPublik}>
                                                        <ShareOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Rendre public">
                                                    <IconButton color="primary" onClick={handleSetPublik}>
                                                        <ShareOutlined />
                                                    </IconButton>
                                                </Tooltip>

                                        }
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                            <IconButton onClick={handleDelete} color="error">
                                                <DeleteOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                {
                                    props.album.mode === EnumListAlbumsMode.viewed &&
                                    <>
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Sortir du partage">
                                            <IconButton onClick={handleExitShare} color="error">
                                                <SharedIconCrossed />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ouvrir dans un nouvel onglet">
                                    <Link href={albumPath} underline="none" target="_blank">
                                        <IconButton color="primary">
                                            <OpenInNewOutlinedIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </Box>
                            <Divider />
                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} >
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Calculer la taille">
                                    <IconButton onClick={handleGetSizeInfos} color="primary">
                                        <DatabaseIcon />
                                    </IconButton>
                                </Tooltip>
                                {
                                    props.album.sizeInfos === undefined
                                        ?
                                        <Typography>
                                            Taille non calculée.
                                        </Typography>
                                        :
                                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={`${props.album.sizeInfos.humanSizeByOwner} par propriétaire.`}>
                                            <Typography>
                                                {props.album.sizeInfos.humanSize}
                                            </Typography>
                                        </Tooltip>
                                }
                            </Box>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditAlbum
                open={showModalEditAlbum}
                handleClose={closeModalEditAlbum}
                album={props.album}
            />
            <ModalDeleteConfirm
                open={showDeleteAlbumDialog}
                title="Suppression de l'album"
                content={`Confirmez-vous la suppression définitive de l'album ${props.album.title} ?`}
                handleClose={closeDeleteAlbumDialog}
                handleConfirm={confirmedDeleteAlbum}
            />
            <ModalExitShareConfirm
                open={showExitShareDialog}
                title="Sortie du partage"
                content={`En sortant du partage, vous ne verrez plus l'album ${props.album.title}.`}
                handleClose={closeExitShareDialog}
                handleConfirm={confirmedExitShare}
            />
            <ModalShareAlbum
                open={showModalShareAlbum}
                handleClose={closeModalShareAlbum}
                album={props.album}
            />
        </>
    )
}
