import { createSlice } from "@reduxjs/toolkit";
import { IBankAccount } from "../../../interfaces";
import { sortByTitle } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_BANK_ACCOUNTS } from "../../../graphql/Queries";


interface IBankAccountsSliceState {
    bankAccounts: undefined | IBankAccount[];
    refetchNeeded: boolean
}

const initialState: IBankAccountsSliceState = {
    bankAccounts: undefined,
    refetchNeeded: false,
}

export const bankAccountsSlice = createSlice({
    name: "bankAccountsSlice",
    initialState,
    reducers: {
        setBankAccountsAction: (state, action) => {
            const newBankAccounts = [...action.payload]
            state.bankAccounts = sortByTitle(newBankAccounts) as IBankAccount[]
        },
        // unsetBankAccountsAction: (state) => {
        //     state.bankAccounts = initialState.bankAccounts
        // },
        // addBankAccountAction: (state, action) => {
        //     const newBankAccount: IBankAccount = action.payload
        //     if (state.bankAccounts) {
        //         state.bankAccounts = sortByTitle([...state.bankAccounts, newBankAccount])
        //     } else {
        //         state.bankAccounts = [newBankAccount]
        //     }
        // },
        // removeBankAccountAction: (state, action) => {
        //     const bankAccountId = action.payload
        //     state.bankAccounts = state.bankAccounts?.filter(bankAccount => bankAccount.id !== bankAccountId)
        // },
        // updateBankAccountAction: (state, action) => {
        //     const updatedBankAccount: IBankAccount = action.payload
        //     state.bankAccounts = state.bankAccounts?.map(
        //         bankAccount => {
        //             return bankAccount.id === updatedBankAccount.id ? updatedBankAccount : bankAccount
        //         }
        //     )
        // },
        toggleSelectBankAccountAction: (state, action) => {
            const bankAccountId = action.payload
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return bankAccount.id === bankAccountId ? { ...bankAccount, selected: !bankAccount.selected } : bankAccount
                }
            )
        },
        selectAllBankAccountAction: (state) => {
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return { ...bankAccount, selected: true }
                }
            )
        },
        unSelectAllBankAccountAction: (state) => {
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return { ...bankAccount, selected: false }
                }
            )
        },
        toggleExpandBankAccountAction: (state, action) => {
            const bankAccountId = action.payload
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return bankAccount.id === bankAccountId ? { ...bankAccount, expanded: !bankAccount.expanded } : bankAccount
                }
            )
        },
        expandSelectedBankAccountsAction: (state) => {
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return bankAccount.selected ? { ...bankAccount, expanded: true } : bankAccount
                }
            )
        },
        unExpandSelectedBankAccountsAction: (state) => {
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return bankAccount.selected ? { ...bankAccount, expanded: false } : bankAccount
                }
            )
        },
        expandAllBankAccountsAction: (state) => {
            state.bankAccounts = state.bankAccounts?.map(
                bankAccount => {
                    return { ...bankAccount, expanded: true }
                }
            )
        },
        // removeBankAccountsAction: (state, action) => {
        //     const bankAccountIds = action.payload
        //     state.bankAccounts = state.bankAccounts?.filter(bankAccount => !bankAccountIds.includes(bankAccount.id))
        // },
        setBankAccountsRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        // updateInfosBankAccountAction: (state, action) => {
        //     const bankAccountId = action.payload.bankAccountId
        //     const newInfos = action.payload.newInfos
        //     // console.log("getting infos to update : bankAccountId = ", bankAccountId, " ; newInfos = ", newInfos);

        //     state.bankAccounts = state.bankAccounts?.map(
        //         bankAccount => {
        //             return bankAccount.id === bankAccountId ? { ...bankAccount, ...newInfos } : bankAccount
        //         }
        //     )
        // },
    }
})

export const {
    // setBankAccountsModeAction,
    setBankAccountsAction,
    // unsetBankAccountsAction,
    // addBankAccountAction,
    // removeBankAccountAction,
    // updateBankAccountAction,
    toggleSelectBankAccountAction,
    selectAllBankAccountAction,
    unSelectAllBankAccountAction,
    // removeBankAccountsAction,
    toggleExpandBankAccountAction,
    expandSelectedBankAccountsAction,
    unExpandSelectedBankAccountsAction,
    setBankAccountsRefetchNeeded,
    // updateInfosBankAccountAction,
    // setSizeInfosAction,
    expandAllBankAccountsAction,
    // setPublikBankAccountAction,
    // unsetPublikBankAccountAction
} = bankAccountsSlice.actions


export const useGetBankAccounts = () => {
    const bankAccounts: (undefined | IBankAccount[]) = useSelector((state: IRootState) => state.bankAccountsReducer.bankAccounts)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.bankAccountsReducer.refetchNeeded)
    if (bankAccounts === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_BANK_ACCOUNTS,
            fetchPolicy: 'network-only',
            // variables: {
            //     mode: mode,
            //     filter: filterQuery,
            // }
        }
        ).then(response => {
            store.dispatch(setBankAccountsAction(response.data.bankAccounts))
            store.dispatch(setBankAccountsRefetchNeeded(false))
            return response.data.bankAccounts
        })
    } else {
        return bankAccounts
    }
}

export default bankAccountsSlice.reducer