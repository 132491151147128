import { createSlice } from "@reduxjs/toolkit";
import { ILocker, IUser } from "../../../interfaces";
import { sortByTitle } from "../../../utils/Utils";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_LOCKERS, GQL_USERS } from "../../../graphql/Queries";
import { EnumListLockersMode } from "../../../enums";
import { log } from "console";


interface IAdminSliceState {
    users: undefined | IUser[];
    refetchNeeded: boolean
}

const initialState: IAdminSliceState = {
    users: undefined,
    refetchNeeded: true,
}

export const adminSlice = createSlice({
    name: "adminSlice",
    initialState,
    reducers: {
        setUsersAction: (state, action) => {
            state.users = action.payload
        },
        setAdminRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
    }
})

export const { setUsersAction, setAdminRefetchNeeded,
} = adminSlice.actions


export const useGetUsers = () => {
    const users: (undefined | IUser[]) = useSelector((state: IRootState) => state.adminReducer.users)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.adminReducer.refetchNeeded)
    if (users === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_USERS,
            fetchPolicy: 'network-only',
            variables: {
            }
        }
        ).then(response => {
            store.dispatch(setUsersAction(response.data.users))
            store.dispatch(setAdminRefetchNeeded(false))
            return response.data.users
        })
    } else {
        return users
    }
}

export default adminSlice.reducer