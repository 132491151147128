import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../components/Breadcrumbs'
import { DirectoryDetails } from '../components/directories/DirectoryDetails';
import { ListSubDirectories } from '../components/directories/ListSubDirectories';
import { EnumBasePathFor, EnumBreadcrumbMode, EnumListLockersMode, EnumPocketTargetType } from '../enums'
import { IBreadcrumb } from '../interfaces';
import { useGetDirectory } from '../redux/features/directories/directorySlice';
import { constructPath, getBasePath, getModePath } from '../utils/Navigation';
import { Layout } from "./Layout"

interface IProps {
  mode: EnumListLockersMode
}

export const DirectoryPage: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  const params = useParams()
  const directoryId = params.directoryId || ""
  const directory = useGetDirectory(directoryId)

  const getAncestorsBreadcrumbs = () => {
    if (directory === undefined) {
      return [{ title: "..." }]
    }
    let res = []
    // Le locker d'abord:
    if (directory.locker !== undefined) {
      res.push(
        {
          title: directory.locker.title,
          path: constructPath(getBasePath(EnumBasePathFor.locker, props.mode), [
            { key: "lockerId", value: directory.locker.id.toString() }
          ])
        }
      )
    }

    // Puis les ancestors:
    if (directory.ancestors !== undefined) {
      res.push(
        ...directory.ancestors.map(ancestor => {
          return {
            title: ancestor.name,
            path: constructPath(getBasePath(EnumBasePathFor.directory, props.mode), [
              { key: "directoryId", value: ancestor.id.toString() }
            ])
          }
        })
      )
    }

    return res
  }

  useEffect(() => {
    setBreadcrumbs([
      getModePath(props.mode),
      ...getAncestorsBreadcrumbs(),
      {
        title: directory?.name || "...",
      },
    ])
  }, [directory])
  return (
    <div className="DirectoryPage">
      <Layout pocketTargetType={EnumPocketTargetType.directory} pocketTargetId={directory?.id} pocketTargetMode={props.mode}>
        <div className="page-body">
          <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ py: 1.5 }} />
          {
            directory && <DirectoryDetails directory={directory} mode={props.mode} />
          }
          <ListSubDirectories mode={props.mode} />
        </div>
      </Layout>
    </div >
  )
}
