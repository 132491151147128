import { FC } from 'react'
import { Layout } from "./Layout"
import { ListBankAccounts } from '../components/cashflow/ListBankAccounts'

interface IProps {
}

export const Cashflow: FC<IProps> = (props) => {

  return (
    <div className="Cashflow">
      <Layout>
        <div className="page-body">
          <ListBankAccounts />
        </div>
      </Layout>
    </div >
  )
}
