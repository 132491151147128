import { FC, useEffect, useState } from 'react';
import { Layout } from "./Layout";
import { ListFotos } from '../components/fotos/ListFotos';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { IBreadcrumb } from '../interfaces';
import { useParams } from 'react-router-dom';
import { useGetAlbum } from '../redux/features/albums/albumSlice';
import { NAVIGATE_ALBUMS } from '../utils/Navigation';
import { Spinner } from '../components/spinner/Spinner';
import { AlbumDetails } from '../components/albums/AlbumDetails';
import { EnumBreadcrumbMode, EnumListAlbumsMode, EnumListLockersMode, EnumPocketTargetType } from '../enums';

interface IProps {
}

export const AlbumPage: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  const params = useParams()
  const albumId = params.albumId || ""
  const album = useGetAlbum(albumId)


  useEffect(() => {
    setBreadcrumbs(
      album === undefined
        ?
        [
          {
            title: "Albums",
            path: NAVIGATE_ALBUMS
          },
          { title: "..." }
        ]
        :
        [
          {
            title: "Albums",
            path: NAVIGATE_ALBUMS
          },
          {
            title: album.title,
          }
        ])
  }, [album])

  const pocketTargetMode = () => {
    let res = undefined
    if (album?.mode === EnumListAlbumsMode.owned || album?.mode === EnumListAlbumsMode.standard) {
      res = EnumListLockersMode.desktop
    } else if (album?.mode === EnumListAlbumsMode.viewed) {
      res = EnumListLockersMode.shared
    }
    return res
  }

  return (
    <div className="AlbumPage">
      <Layout pocketTargetType={EnumPocketTargetType.album} pocketTargetId={album?.id} pocketTargetMode={pocketTargetMode()}>
        {/* <Layout> */}
        <div className="page-body">
          {
            album === undefined ? (
              <Spinner classes="big-spinner" />
            ) : (
              <>
                <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ py: 1.5 }} />
                {
                  <AlbumDetails album={album} />
                }
                <ListFotos album={album} />
              </>
            )
          }
        </div>
      </Layout>
    </div >
  )
}
