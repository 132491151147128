import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { Add, CropFreeOutlined, DeleteOutline } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumParentType, EnumListLockersMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_DELETE_DIRECTORIES, GQL_MUTATION_REMOVE_FROM_POCKET, GQL_MUTATION_DELETE_DOCUMENTS, GQL_MUTATION_DELETE_NOTES, GQL_MUTATION_CREATE_ZIP } from "../../graphql/Mutations";
import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from "react-router-dom";
import {
    expandSelectedChildrenAction,
    selectAllChildrenAction,
    unExpandSelectedChildrenAction,
    unSelectAllChildrenAction,
    useGetDirectory,
} from "../../redux/features/directories/directorySlice";
import { Directory } from "./Directory";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { ModalNewDirectory } from "./modals/ModalNewDirectory";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addDirectoriesAction as addDirectoriesInPocketAction,
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
    addDocumentsAction as addDocumentsInPocketAction,
    removeDocumentsAction as removeDocumentsFromPocketAction,
    addNotesAction as addNotesInPocketAction,
    removeNotesAction as removeNotesFromPocketAction,
} from "../../redux/features/pocket/pocketSlice";
import { IRootState, setAllRefetchNeeded } from "../../redux/store";
import { Document } from "../documents/Document";
import { pluralize } from "../../utils/Utils";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ModalNewDocuments } from "../documents/modals/ModalNewDocuments";
import { Filter } from "../Filter";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import { Note } from "../notes/Note";
import { ModalNewNote } from "../notes/modals/ModalNewNote";
import { NoteIcon } from "../svg_icons/NoteIcon";
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import { ModalNewZip } from "./modals/ModalNewZip";
import { IZipPrototype } from "../../interfaces";
import { ZipIcon } from "../svg_icons/ZipIcon";

interface IProps {
    mode: EnumListLockersMode,
}

export const ListSubDirectories: FC<IProps> = (props) => {
    const params = useParams()
    const directoryId = params.directoryId || ""
    const directory = useGetDirectory(directoryId)
    const [showModalNewDirectory, setShowModalNewDirectory] = useState(false)
    const [showModalNewDocuments, setShowModalNewDocuments] = useState(false)
    const [selectAllElements, setSelectAllElements] = useState(true)
    const [hasSelectedElement, setHasSelectedElement] = useState(false)
    const [showDeleteElementsDialog, setShowDeleteElementsDialog] = useState(false)
    const [expandSelectedElements, setExpandSelectedElements] = useState(true)
    const [putInPocketSelectedElements, setPutInPocketSelectedElements] = useState(true)
    const filterQuery: string | undefined = useSelector((state: IRootState) => state.filterReducer.filterQuery)
    const [deleteDirectories, { data: deleteDirectoriesData, loading: deleteDirectoriesLoading, error: deleteDirectoriesError }] = useMutation(GQL_MUTATION_DELETE_DIRECTORIES)
    const [deleteDocuments, { data: deleteDocumentsData, loading: deleteDocumentsLoading, error: deleteDocumentsError }] = useMutation(GQL_MUTATION_DELETE_DOCUMENTS)
    const [deleteNotes, { data: deleteNotesData, loading: deleteNotesLoading, error: deleteNotesError }] = useMutation(GQL_MUTATION_DELETE_NOTES)
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [createZip, { data: createZipData, loading: createZipLoading, error: createZipError }] = useMutation(GQL_MUTATION_CREATE_ZIP)
    const [showModalNewNote, setShowModalNewNote] = useState(false)
    const [showZipElementsDialog, setShowZipElementsDialog] = useState(false)
    const dispatch = useDispatch()

    const theme = useTheme()

    const closeModalNewDirectory = () => {
        setShowModalNewDirectory(false)
    }
    const closeModalNewDocuments = () => {
        setShowModalNewDocuments(false)
    }
    const closeModalNewNote = () => {
        setShowModalNewNote(false)
    }

    const handleSelectAll = () => {
        selectAllElements ? dispatch(selectAllChildrenAction()) : dispatch(unSelectAllChildrenAction())
        setSelectAllElements(!selectAllElements)
    }

    const handleDeleteSelection = () => {
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            setShowDeleteElementsDialog(true)
        }
    }

    const closeDeleteElementsDialog = () => {
        setShowDeleteElementsDialog(false)
    }

    const confirmedDeleteElements = () => {
        if (getSelectedDirectoryIds().length > 0) {
            deleteDirectories({
                variables: {
                    directoryIds: getSelectedDirectoryIds()
                }
            })
        }
        if (getSelectedDocumentIds().length > 0) {
            deleteDocuments({
                variables: {
                    documentIds: getSelectedDocumentIds()
                }
            })
        }
        if (getSelectedNoteIds().length > 0) {
            deleteNotes({
                variables: {
                    noteIds: getSelectedNoteIds()
                }
            })
        }
        setShowDeleteElementsDialog(false)
    }




    const getSelectedDirectoryIds = () => {
        if (directory && directory.children) {
            return directory.children?.filter(child => child.selected).map(directory => directory.id)
        } else {
            return []
        }
    }

    const getSelectedDocumentIds = () => {
        if (directory && directory.documents) {
            return directory.documents?.filter(document => document.selected).map(document => document.id)
        } else {
            return []
        }
    }

    const getSelectedNoteIds = () => {
        if (directory && directory.notes) {
            return directory.notes?.filter(note => note.selected).map(note => note.id)
        } else {
            return []
        }
    }

    const getSelectedDirectories = () => {
        if (directory && directory.children) {
            return directory.children.filter(child => child.selected)
        } else {
            return []
        }
    }

    const getSelectedDocuments = () => {
        if (directory && directory.documents) {
            return directory.documents.filter(document => document.selected)
        } else {
            return []
        }
    }

    const getSelectedNotes = () => {
        if (directory && directory.notes) {
            return directory.notes.filter(note => note.selected)
        } else {
            return []
        }
    }

    const handleExpandSelectedElements = () => {
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            expandSelectedElements ? dispatch(expandSelectedChildrenAction()) : dispatch(unExpandSelectedChildrenAction())
            setExpandSelectedElements(!expandSelectedElements)
        }
    }

    const handlePutInPocket = () => {
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            addInPocket({
                variables: {
                    directoryIds: getSelectedDirectoryIds(),
                    documentIds: getSelectedDocumentIds(),
                    noteIds: getSelectedNoteIds(),
                }
            })

        }
    }

    const handleRemoveFromPocket = () => {
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            removeFromPocket({
                variables: {
                    directoryIds: getSelectedDirectoryIds(),
                    documentIds: getSelectedDocumentIds(),
                    noteIds: getSelectedNoteIds(),
                }
            })

        }
    }

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addDirectoriesInPocketAction(getSelectedDirectories()))
                dispatch(addDocumentsInPocketAction(getSelectedDocuments()))
                dispatch(addNotesInPocketAction(getSelectedNotes()))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
                // Il peut y avoir certains qui n'ont pas fonctionner:
                const errors: string[] = addInPocketData.addInPocket.errors
                errors.map(error => addMessage({
                    type: EnumMessageType.Warning,
                    message: error,
                }))
                setPutInPocketSelectedElements(false)

            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDirectoriesFromPocketAction(getSelectedDirectoryIds()))
                dispatch(removeDocumentsFromPocketAction(getSelectedDocumentIds()))
                dispatch(removeNotesFromPocketAction(getSelectedNoteIds()))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
                setPutInPocketSelectedElements(true)
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        if (deleteDirectoriesError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: deleteDirectoriesError.message,
            })
        } else if (deleteDirectoriesData) {
            if (deleteDirectoriesData.deleteDirectories.statusCode === 200) {
                const deletedDirectoriesCount = deleteDirectoriesData.deleteDirectories.deletedCount
                setAllRefetchNeeded("ListSubDirectoies")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedDirectoriesCount} ${pluralize("dossier supprimé", deletedDirectoriesCount, "dossiers supprimés")}.`,
                })
            } else {
                deleteDirectoriesData.deleteDirectories.errors.map((error: string) => {
                    addMessage({
                        location: "ListDirectories",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDirectoriesData, deleteDirectoriesError])

    useEffect(() => {
        if (deleteDocumentsError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: deleteDocumentsError.message,
            })
        } else if (deleteDocumentsData) {
            if (deleteDocumentsData.deleteDocuments.statusCode === 200) {
                const deletedDocumentsCount = deleteDocumentsData.deleteDocuments.deletedCount
                setAllRefetchNeeded("ListSubDirectoies")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedDocumentsCount} ${pluralize("document supprimé", deletedDocumentsCount, "documents supprimés")}.`,
                })
            } else {
                deleteDocumentsData.deleteDocuments.errors.map((error: string) => {
                    addMessage({
                        location: "ListDirectories",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDocumentsData, deleteDocumentsError])

    useEffect(() => {
        if (deleteNotesError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: deleteNotesError.message,
            })
        } else if (deleteNotesData) {
            if (deleteNotesData.deleteNotes.statusCode === 200) {
                const deletedNotesCount = deleteNotesData.deleteNotes.deletedCount
                setAllRefetchNeeded("ListSubDirectoies")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${deletedNotesCount} ${pluralize("note supprimée", deletedNotesCount, "notes supprimées")}.`,
                })
            } else {
                deleteNotesData.deleteNotes.errors.map((error: string) => {
                    addMessage({
                        location: "ListDirectories",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteNotesData, deleteNotesError])

    useEffect(() => {
        // console.log("detecting hasSelectedDirectories..");
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            setHasSelectedElement(true)
        } else {
            setHasSelectedElement(false)
        }
    }, [directory?.children, directory?.documents, directory?.notes])

    const handleZipSelection = () => {
        if (getSelectedDirectoryIds().length > 0 || getSelectedDocumentIds().length > 0 || getSelectedNoteIds().length > 0) {
            setShowZipElementsDialog(true)
        }
    }

    const closeZipElementsDialog = () => {
        setShowZipElementsDialog(false)
    }

    const confirmedZipElements = (zipPrototype: IZipPrototype) => {
        if (directory && hasSelectedElement) {
            createZip({
                variables: {
                    ...zipPrototype,
                    directoryId: directory.id,
                    directoryIds: getSelectedDirectoryIds(),
                    documentIds: getSelectedDocumentIds(),
                    noteIds: getSelectedNoteIds(),
                }
            })

        }
    }
    useEffect(() => {
        if (createZipError) {
            addMessage({
                location: "ListSubDirectories",
                type: EnumMessageType.Error,
                message: createZipError.message,
            })
        } else if (createZipData) {
            if (createZipData.createZip.statusCode === 200) {
                setAllRefetchNeeded("ListSubDirectories")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Zip créé.",
                })
            } else {
                createZipData.createZip.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createZipData, createZipError])


    return (
        <div className='ListDirectories'>
            <FloatingSpinner dependances={[deleteDocumentsLoading, deleteDirectoriesLoading, deleteNotesLoading, createZipLoading]} />
            <Container sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: 2,
            }}>
                <Filter />
                <Divider orientation="vertical" flexItem variant="middle" />
                {
                    hasSelectedElement && props.mode !== EnumListLockersMode.shared && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer les éléments sélectionnés">
                        <IconButton onClick={handleDeleteSelection} color="error">
                            <DeleteOutline />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedElement && props.mode !== EnumListLockersMode.shared && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Zipper les éléments sélectionnés">
                        <IconButton onClick={handleZipSelection} color="primary">
                            <ZipIcon color="desktop" />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedElement && putInPocketSelectedElements &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer les éléments sélectionnés dans la pocket">
                        <IconButton onClick={handlePutInPocket} color="primary">
                            <PocketIcon variant={EnumSvgVariant.primary} sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedElement && !putInPocketSelectedElements &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer les éléments sélectionnés de la pocket">
                        <IconButton onClick={handleRemoveFromPocket} color="secondary">
                            <PocketIcon variant={EnumSvgVariant.secondary} sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    hasSelectedElement &&
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={expandSelectedElements ? "Déplier les éléments sélectionnnés" : "Plier les éléments sélectionnnés"}>
                        <ExpandMore expand={!expandSelectedElements} onClick={handleExpandSelectedElements} aria-expanded={!expandSelectedElements} aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Tooltip>
                }
                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={selectAllElements ? "Sélectionner tout" : "Désélectionner tout"}>
                    <IconButton onClick={handleSelectAll} color={selectAllElements ? "secondary" : "cancel"}>
                        <CropFreeOutlined />
                    </IconButton>
                </Tooltip>
                {
                    props.mode !== EnumListLockersMode.shared && directory &&
                    <>
                        <Divider orientation="vertical" flexItem variant="middle" />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter un dossier">
                            <IconButton color="primary" onClick={() => setShowModalNewDirectory(true)}><Add fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewDirectory
                            open={showModalNewDirectory}
                            handleClose={closeModalNewDirectory}
                            directory={directory}
                            mode={props.mode}
                        />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter une note">
                            <IconButton color="primary" onClick={() => setShowModalNewNote(true)}><NoteIcon fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewNote
                            open={showModalNewNote}
                            handleClose={closeModalNewNote}
                            directory={directory}
                            mode={props.mode}
                        />
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Ajouter des documents">
                            <IconButton color="primary" onClick={() => setShowModalNewDocuments(true)}><CloudUploadOutlinedIcon fontSize="medium" /></IconButton>
                        </Tooltip>
                        <ModalNewDocuments
                            open={showModalNewDocuments}
                            handleClose={closeModalNewDocuments}
                            directory={directory}
                            mode={props.mode}
                        />
                    </>
                }

            </Container>
            <Container >
                <Grid container spacing={0} className="ListDirectories-grid-container">
                    {
                        directory === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            directory.children?.map((child) => {
                                return (
                                    <Grid key={`${child.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Directory directory={child} directoryParentType={EnumParentType.directory} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        directory && (
                            directory.documents?.map((document) => {
                                return (
                                    <Grid key={`${document.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Document document={document} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        directory && (
                            directory.notes?.map((note) => {
                                return (
                                    <Grid key={`${note.id}`} size={{ xs: 12 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <Note note={note} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        directory !== undefined &&
                        directory.children?.length === 0 &&
                        directory.documents?.length === 0 &&
                        directory.notes?.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun élément.
                            </Typography>
                            {
                                filterQuery &&
                                <Typography sx={{ fontSize: 24, color: theme.palette.warning.main }}>
                                    Un filtre est actif.
                                </Typography>
                            }
                        </Box>
                    }
                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteElementsDialog}
                title="Suppression des éléments"
                content={
                    getSelectedDirectoryIds().length + getSelectedDocumentIds().length + getSelectedNoteIds().length > 0
                        ?
                        `Confirmez-vous la suppression définitive de ces éléments ?`
                        :
                        `Confirmez-vous la suppression définitive de cet élément ?`
                }
                handleClose={closeDeleteElementsDialog}
                handleConfirm={confirmedDeleteElements}
                danger={true}
            />
            <ModalNewZip
                open={showZipElementsDialog}
                handleClose={closeZipElementsDialog}
                handleConfirm={confirmedZipElements}
                mode={props.mode}
            />
        </div>
    );
}
