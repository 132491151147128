import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    LinearProgressProps,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumListLockersMode, EnumMessageType } from '../../../enums';
import { IDirectory, IDocument, IFileToUpload } from '../../../interfaces';
import { DocumentIcon } from '../../svg_icons/DocumentIcon';
import { Add } from '@mui/icons-material';
import useFileUpload from "../../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { pluralize } from '../../../utils/Utils';
import { GQL_MUTATION_ADD_SHEET, GQL_MUTATION_CREATE_DOCUMENTS } from '../../../graphql/Mutations';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { LinearProgressWithLabel } from '../../spinner/LinearProgressWithLabel';
import { Sheet } from '../../sheets/Sheet';
import { addSheetAction } from '../../../redux/features/directories/directorySlice';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';

interface IProps {
    open: boolean;
    handleClose: () => void;
    document: IDocument;
}

export const ModalSheets: FC<IProps> = (props) => {
    const [provoqueRefetchOnClose, setProvoqueRefetchOnClose] = useState(false)
    const [fileToUpload, setFileToUpload] = useState<IFileToUpload | undefined>(undefined)
    const [addSheet, { data: addSheetData, loading: addSheetLoading, error: addSheetError }] = useMutation(GQL_MUTATION_ADD_SHEET)
    const dispatch = useDispatch()

    useEffect(() => {
        if (fileToUpload && fileToUpload.sheetFile) {
            addSheet({
                variables: {
                    documentId: props.document.id,
                    sheetFile: fileToUpload.sheetFile,
                }
            })
        }
    }, [fileToUpload])

    useEffect(() => {
        if (addSheetError) {
            addMessage({
                location: "ModalSheets",
                type: EnumMessageType.Error,
                message: addSheetError.message,
            })
        } else if (addSheetData) {
            if (addSheetData.addSheet.statusCode === 200) {
                cleanDatas()
                const payload = {
                    documentId: props.document.id,
                    newSheet: addSheetData.addSheet.sheet,
                }
                dispatch(addSheetAction(payload))
                handleProvoqueRefetchOnClose()
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Version ajoutée.",
                })
                // handleClose()
            } else {
                addSheetData.addSheet.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addSheetData, addSheetError])

    const cleanDatas = () => {
        setFileToUpload(undefined)
    }

    const handleClose = () => {
        cleanDatas()
        if (provoqueRefetchOnClose) {
            setAllRefetchNeeded("ModalNewDocument")
        }
        props.handleClose()
    }

    const handleProvoqueRefetchOnClose = () => {
        // console.log("handleProvoqueRefetchOnClose called.");
        setProvoqueRefetchOnClose(true)
    }


    const { handleUpload } = useFileUpload();
    const handlePercentage = (fileIndex: number, paramPercentage: number) => {
        setFileToUpload((currentFileToUpload) => {
            return currentFileToUpload !== undefined ?
                { ...currentFileToUpload, percentage: paramPercentage } :
                currentFileToUpload
        })
    }

    const handleFileChange = (files: FileList | null, handleCallback: Function) => {
        if (files && files.length > 0) {
            const newFileToUpload: IFileToUpload = {
                index: 0,
                percentage: 0,
                filename: files[0].name,
            }
            setFileToUpload(newFileToUpload)
            handleUpload(files, handlePercentage, handleUploadDone);
        }
    }

    const handleUploadDone = (fileIndex: number, fileMetaData: any) => {
        setFileToUpload(currentFileToUpload => currentFileToUpload !== undefined ?
            { ...currentFileToUpload, sheetFile: JSON.stringify(fileMetaData) } :
            currentFileToUpload
        )
    }

    return (
        <div className="ModalSheets">
            <FloatingSpinner dependances={[addSheetLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <TextSnippetOutlinedIcon color={props.document.mode} sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Versions
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                Ajouter une version
                            </Typography>
                            <IconButton color="primary" component="label">
                                <input hidden type="file" onChange={(event) => handleFileChange(event.target.files, handlePercentage)} />
                                <Add fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider flexItem></Divider>
                        {
                            fileToUpload !== undefined
                            &&
                            <Box sx={{ width: "100%" }}>
                                <Typography>
                                    {fileToUpload.filename}
                                </Typography>
                                <LinearProgressWithLabel value={fileToUpload.percentage} color="success" />
                            </Box>
                        }
                        <Divider flexItem></Divider>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column ", justifyContent: "flex-start", alignItems: "flex-start", my: 2 }}>
                            {
                                props.document.sheets && props.document.sheets.map(sheet => (
                                    <Box key={sheet.id} sx={{ width: "100%" }}>
                                        <Sheet document={props.document} sheet={sheet} provoqueRefetch={handleProvoqueRefetchOnClose} />
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
