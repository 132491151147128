import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton, Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMessageType } from '../../../enums';
import { IContact, IFileToUpload, IUser } from '../../../interfaces';
import { Add } from '@mui/icons-material';
import useFileUpload from "../../../utils/useFileUpload";
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { GQL_MUTATION_IMPORT_CONTACT_VCARDS, GQL_MUTATION_UPDATE_CONTACT_AVATAR } from '../../../graphql/Mutations';
import { LinearProgressWithLabel } from '../../spinner/LinearProgressWithLabel';
import { useDispatch } from 'react-redux';
import { setCurrentUserAvatarPreviewUrl, setCurrentUserAvatarUrl } from '../../../redux/features/users/currentUserSlice';
import { FloatingSpinner } from '../../spinner/FloatingSpinner';
import { ContactIcon } from '../../svg_icons/ContactIcon';
import { Flexbox } from '../../utils/Flexbox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalContactImportVcards: FC<IProps> = (props) => {
    const [fileToUpload, setFileToUpload] = useState<IFileToUpload | undefined>(undefined)
    const [importContactVcards, { data: importContactVcardsData, loading: importContactVcardsLoading, error: importContactVcardsError }] = useMutation(GQL_MUTATION_IMPORT_CONTACT_VCARDS)


    const theme = useTheme()

    useEffect(() => {
        if (fileToUpload && fileToUpload.sheetFile) {
            // console.log(`MED: ==>  ${JSON.parse(fileToUpload.sheetFile).id}`)
            importContactVcards({
                variables: {
                    // vcfFileId: JSON.parse(fileToUpload.sheetFile).id,
                    // vcfFileUrl: fileToUpload.sheetFile,
                    sheetFile: fileToUpload.sheetFile,
                }
            })
        }
    }, [fileToUpload])

    useEffect(() => {
        if (importContactVcardsError) {
            addMessage({
                location: "ModalContactImportVcards",
                type: EnumMessageType.Error,
                message: importContactVcardsError.message,
            })
        } else if (importContactVcardsData) {
            if (importContactVcardsData.importContactVcards.statusCode === 200) {
                setAllRefetchNeeded("ModalContactImportVcards")

                addMessage({
                    type: EnumMessageType.Success,
                    message: "Importation des contacts effectuée.",
                })
                handleClose()
            } else {
                importContactVcardsData.importContactVcards.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [importContactVcardsData, importContactVcardsError])

    const cleanDatas = () => {
        setFileToUpload(undefined)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    const { handleUpload } = useFileUpload();
    const handlePercentage = (fileIndex: number, paramPercentage: number) => {
        setFileToUpload((currentFileToUpload) => {
            return currentFileToUpload !== undefined ?
                { ...currentFileToUpload, percentage: paramPercentage } :
                currentFileToUpload
        })
    }

    const handleFileChange = (files: FileList | null, handleCallback: Function) => {
        if (files && files.length > 0) {
            const allowedTypes = ["text/vcard", "text/x-vcard"];
            const maxSize = 50 * 1024 * 1024; // 50 Mo

            // Vérifier si le type de fichier est autorisé
            if (!allowedTypes.includes(files[0].type)) {
                //   console.log("Le fichier sélectionné n'est pas une image.");
                addMessage({
                    type: EnumMessageType.Error,
                    message: "Seuls les fichiers vcard (format vcf) sont autorisés.",
                })
                return;
            }

            // Vérifier si la taille du fichier est autorisée
            if (files[0].size > maxSize) {
                //   console.log("Le fichier sélectionné est trop volumineux.");
                addMessage({
                    type: EnumMessageType.Error,
                    message: "Le fichier ne doit pas dépasser 50 Mo.",
                })
                return;
            }


            const newFileToUpload: IFileToUpload = {
                index: 0,
                percentage: 0,
                filename: files[0].name,
            }
            setFileToUpload(newFileToUpload)
            handleUpload(files, handlePercentage, handleUploadDone);
        }
    }

    const handleUploadDone = (fileIndex: number, fileMetaData: any) => {
        setFileToUpload(currentFileToUpload => currentFileToUpload !== undefined ?
            { ...currentFileToUpload, sheetFile: JSON.stringify(fileMetaData) } :
            currentFileToUpload
        )
    }

    return (
        <div className="ModalContactImportVcards">
            <FloatingSpinner dependances={[importContactVcardsLoading]} />
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <ContactIcon sx={{ fontSize: 24, mr: 3, color: theme.palette.desktop.main }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Import de contacts
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Flexbox sx={{
                            // bgcolor: "red",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            mb: 2,
                        }}>
                            <WarningAmberIcon sx={{
                                color: theme.palette.warning.main,
                                mr: 1,
                            }} />
                            <Typography sx={{
                                color: theme.palette.warning.main,
                            }}>
                                Les contacts de même numéro de téléphone seront fusionnés !
                            </Typography>
                        </Flexbox>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography>
                                Sélectionner un fichier .vcf
                            </Typography>
                            <IconButton color="primary" component="label">
                                <input hidden type="file" accept="text/vcard,text/x-vcard,.vcf" onChange={(event) => handleFileChange(event.target.files, handlePercentage)} />
                                <Add fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider flexItem></Divider>
                        {
                            fileToUpload !== undefined
                            &&
                            <Box sx={{ width: "100%" }}>
                                <Typography>
                                    {fileToUpload.filename}
                                </Typography>
                                <LinearProgressWithLabel value={fileToUpload.percentage} color="success" />
                            </Box>
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
