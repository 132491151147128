import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '../components/Breadcrumbs'
import { ListDirectories } from '../components/directories/ListDirectories'
import { LockerDetails } from '../components/lockers/LockerDetails';
import { Pocket } from '../components/Pocket';
import { EnumBreadcrumbMode, EnumListLockersMode, EnumPocketTargetType } from '../enums'
import { IBreadcrumb } from '../interfaces';
import { useGetLocker } from '../redux/features/lockers/lockerSlice';
import { getModePath } from '../utils/Navigation';
import { Layout } from "./Layout"

interface IProps {
  mode: EnumListLockersMode
}

export const LockerPage: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  // const locker: (undefined | ILocker) = useSelector((state: IRootState) => state.lockerReducer.locker)
  const params = useParams()
  const lockerId = params.lockerId || ""
  const locker = useGetLocker(lockerId)
  useEffect(() => {
    setBreadcrumbs([
      getModePath(props.mode),
      {
        title: locker?.title || "...",
      },
    ])
  }, [locker])
  return (
    <div className="LockerPage">
      <Layout pocketTargetType={EnumPocketTargetType.locker} pocketTargetId={locker?.id} pocketTargetMode={props.mode}>
        <div className="page-body">
          <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ py: 1.5 }} />
          {
            locker && <LockerDetails locker={locker} mode={props.mode} />
          }
          <ListDirectories mode={props.mode} />
        </div>
      </Layout>
    </div >
  )
}
