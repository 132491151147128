import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { EnumMessageType } from '../../../enums';
import { GQL_MUTATION_UPDATE_PASSWORD } from '../../../graphql/Mutations';
import { IUser } from '../../../interfaces';
import { addMessage } from '../../messages/Message';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

interface IProps {
    open: boolean;
    handleClose: () => void;
    user: IUser;
}

export const ModalEditPassword: FC<IProps> = (props) => {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")

    const [errorOnCurrentPassword, setErrorOnCurrentPassword] = useState(false)
    const [errorOnNewPassword, setErrorOnNewPassword] = useState(false)
    const currentPasswordRef = useRef<HTMLInputElement>(null);
    const [updatePassword, { data: updatePasswordData, loading: updatePasswordLoading, error: updatePasswordError }] = useMutation(GQL_MUTATION_UPDATE_PASSWORD)

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (currentPasswordRef.current) {
                    currentPasswordRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (updatePasswordError) {
            addMessage({
                location: "ModalEditPassword",
                type: EnumMessageType.Error,
                message: updatePasswordError.message,
            })
        } else if (updatePasswordData) {
            if (updatePasswordData.updatePassword.statusCode === 200) {
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Mot de passe modifié.",
                })
                handleClose()
            } else {
                updatePasswordData.updatePassword.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updatePasswordData, updatePasswordError])

    const checkErrors = () => {
        if (currentPassword === undefined || currentPassword === "") {
            setErrorOnCurrentPassword(true)
            return false
        }
        if (newPassword === undefined || newPassword === "") {
            setErrorOnNewPassword(true)
            return false
        }
        if (newPassword !== newPasswordConfirmation) {
            addMessage({
                type: EnumMessageType.Error,
                message: "Le nouveau mot de passe et sa confirmation sont différents."
            })
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setCurrentPassword("")
        setErrorOnCurrentPassword(false)
        setNewPassword("")
        setErrorOnNewPassword(false)
        setNewPasswordConfirmation("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            updatePassword({
                variables: {
                    userId: props.user.id,
                    currentPassword,
                    newPassword,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalEditPassword">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <VpnKeyOutlinedIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Changer le mot de passe
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            type='password'
                            inputRef={currentPasswordRef}
                            autoFocus
                            margin="dense"
                            label="Mot de passe actuel"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setCurrentPassword(event.target.value) }}
                            value={currentPassword}
                            required
                            error={errorOnCurrentPassword}
                            helperText={errorOnCurrentPassword ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            type='password'
                            margin="dense"
                            label="Nouveau mot de passe"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNewPassword(event.target.value) }}
                            value={newPassword}
                            required
                            error={errorOnNewPassword}
                            helperText={errorOnNewPassword ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            type='password'
                            margin="dense"
                            label="Confirmez le mot de passe"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setNewPasswordConfirmation(event.target.value) }}
                            value={newPasswordConfirmation}
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Confirmer
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
