import { FC, useEffect, useState } from 'react'
import { Flexbox } from '../utils/Flexbox'
import { ITodostep } from '../../interfaces'
import { Checkbox, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { addMessage } from '../messages/Message'
import { EnumMessageType } from '../../enums'
import { setAllRefetchNeeded } from '../../redux/store'
import { useMutation } from '@apollo/client'
import { GQL_MUTATION_DELETE_TODOSTEP, GQL_MUTATION_UPDATE_TODOSTEP_DONE } from '../../graphql/Mutations'
import { Add, DeleteOutline, EditOutlined } from '@mui/icons-material'
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm'
import { TodostepPrompt } from './TodostepPrompt'
import { ModalEditTodostep } from './modals/ModalEditTodostep'


interface IProps {
    todostep: ITodostep
}

export const TodostepItem: FC<IProps> = (props) => {
    const [showDeleteTodostepDialog, setShowDeleteTodostepDialog] = useState(false)
    const [showModalEditTodostep, setShowModalEditTodostep] = useState(false)


    const [updateTodostepDone, { data: updateTodostepDoneData, loading: updateTodostepDoneLoading, error: updateTodostepDoneError }] = useMutation(GQL_MUTATION_UPDATE_TODOSTEP_DONE)
    const [deleteTodostep, { data: deleteTodostepData, loading: deleteTodostepLoading, error: deleteTodostepError }] = useMutation(GQL_MUTATION_DELETE_TODOSTEP)

    const theme = useTheme()

    useEffect(() => {
        if (updateTodostepDoneError) {
            addMessage({
                location: "TodostepItem",
                type: EnumMessageType.Error,
                message: updateTodostepDoneError.message,
            })
        } else if (updateTodostepDoneData) {
            if (updateTodostepDoneData.updateTodostepDone.statusCode === 200) {
                setAllRefetchNeeded("TodostepItem")
                // addMessage({
                //     type: EnumMessageType.Success,
                //     message: "Etape modifiée.",
                // })
            } else {
                updateTodostepDoneData.updateTodostepDone.errors.map((error: string) => {
                    addMessage({
                        location: "TodostepItem",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateTodostepDoneData, updateTodostepDoneError])

    useEffect(() => {
        if (deleteTodostepError) {
            addMessage({
                location: "TodostepItem",
                type: EnumMessageType.Error,
                message: deleteTodostepError.message,
            })
        } else if (deleteTodostepData) {
            if (deleteTodostepData.deleteTodostep.statusCode === 200) {
                setAllRefetchNeeded("TodostepItem")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Etape supprimée.",
                })
            } else {
                deleteTodostepData.deleteTodostep.errors.map((error: string) => {
                    addMessage({
                        location: "TodostepItem",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteTodostepData, deleteTodostepError])

    const setTodostepDone = () => {
        updateTodostepDone({
            variables: {
                todostepId: props.todostep.id,
                done: !props.todostep.done,
            }
        })
    }

    const handleDelete = () => {
        setShowDeleteTodostepDialog(true)
    }

    const closeDeleteTodostepDialog = () => {
        setShowDeleteTodostepDialog(false)
    }

    const confirmedDeleteTodostep = () => {
        deleteTodostep({
            variables: {
                todostepId: props.todostep.id
            }
        })
        setShowDeleteTodostepDialog(false)
    }

    const handleEdit = () => {
        setShowModalEditTodostep(true)
    }

    const closeModalEditTodostep = () => {
        setShowModalEditTodostep(false)
    }

    return (
        <>
            <Flexbox direction='column' sx={{
                // bgcolor: "yellow",
                width: "100%",
                alignItems: "flex-start",
                mb: 1,
            }}>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "space-between",
                }}>
                    <Flexbox sx={{
                        justifyContent: "flex-start",
                        pl: 5,
                    }}>
                        <Checkbox
                            checked={props.todostep.done}
                            value={props.todostep.done}
                            onChange={setTodostepDone}
                            color='desktop'
                        />
                        <Typography sx={{
                            color: theme.palette.desktop.main,
                            textDecoration: props.todostep.done ? "line-through" : "none",
                        }}>
                            {props.todostep.title}
                        </Typography>
                    </Flexbox>
                    <Flexbox sx={{
                        justifyContent: "flex-end",
                        pr: 5,
                    }}>
                        {
                            props.todostep.done && <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton
                                    onClick={handleDelete}
                                    size='small'
                                    sx={{
                                        color: theme.palette.cancel.main,
                                        ":hover": {
                                            color: theme.palette.error.main,
                                        }
                                    }}>
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            !props.todostep.done &&
                            <>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                    <IconButton
                                        onClick={handleEdit}
                                        size='small'
                                        sx={{
                                            color: theme.palette.cancel.main,
                                            ":hover": {
                                                color: theme.palette.desktop.main,
                                            }
                                        }}>
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </Flexbox>
                </Flexbox>
            </Flexbox>
            <ModalDeleteConfirm
                open={showDeleteTodostepDialog}
                title="Suppression de l'étape"
                content={`Confirmez-vous la suppression définitive de l'étape ${props.todostep.title} ?`}
                handleClose={closeDeleteTodostepDialog}
                handleConfirm={confirmedDeleteTodostep}
            />
            <ModalEditTodostep
                open={showModalEditTodostep}
                handleClose={closeModalEditTodostep}
                todostep={props.todostep}
            />
        </>
    )
}
