import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

import "../css/components/ResponsiveAppBar.css";
import { Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { MetadocLogo } from './MetadocLogo';
import { EnumMetadocLogoMode } from '../enums';
import { NAVIGATE_ROOT } from '../utils/Navigation';
import { Flexbox } from './utils/Flexbox';

const PublikResponsiveAppBar = () => {
    const theme = useTheme()
    return (
        <AppBar className='PublikResponsiveAppBar' position="static" sx={{ backgroundColor: theme.palette.shared.main }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Grid container spacing={0} sx={{ width: "100%" }}>
                        <Grid size={{ xs: 2 }} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <MetadocLogo mode={EnumMetadocLogoMode.publik} link={true} />
                        </Grid>
                        <Grid size={{ xs: 8 }} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            <Flexbox direction='column'>
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="a"
                                    href={NAVIGATE_ROOT}
                                    sx={{
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Metadoc
                                </Typography>
                                <Typography sx={{
                                    fontSize: 14,
                                }}>
                                    Accès public
                                </Typography>

                            </Flexbox>
                        </Grid>
                        <Grid size={{ xs: 2 }} sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}>
                            &nbsp;
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default PublikResponsiveAppBar;
