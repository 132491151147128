import { FC } from 'react'
import { Flexbox } from '../utils/Flexbox'
import { TodolistPrompt } from './TodolistPrompt'
import { useGetCurrentUser } from '../../redux/features/users/currentUserSlice'
import { TodolistItem } from './TodolistItem'


interface IProps {
}

export const Todolist: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    // const dispatch = useDispatch()
    // const theme = useTheme()



    return (
        <Flexbox direction='column' sx={{
            // bgcolor: "yellow",
            width: "100%",
        }}>
            <TodolistPrompt />
            <Flexbox direction='column' sx={{
                // bgcolor: "green",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
            }}>
                {
                    currentUser?.todolists.map(todolist => {
                        return (
                            <TodolistItem key={todolist.id} todolist={todolist} />
                        )
                    })
                }
            </Flexbox>
        </Flexbox>
    )
}
