import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnumDayjsFormat, EnumMessageType } from '../../../enums';
import { GQL_MUTATION_CREATE_ALBUM } from '../../../graphql/Mutations';
import { IAlbum } from '../../../interfaces';
import { addAlbumAction } from '../../../redux/features/albums/albumsSlice';
import { addMessage } from '../../messages/Message';
import { CameraIcon } from '../../svg_icons/CameraIcon';
import { Flexbox } from '../../utils/Flexbox';
import { DateSelector } from '../../utils/DateSelector';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalNewAlbum: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const titleRef = useRef<HTMLInputElement>(null);
    const [description, setDescription] = useState("")
    const [dateAlbum, setDateAlbum] = useState<Dayjs>(dayjs())
    const [createAlbum, { data: createAlbumData, loading: createAlbumLoading, error: createAlbumError }] = useMutation(GQL_MUTATION_CREATE_ALBUM)
    const dispatch = useDispatch()

    const theme = useTheme()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (titleRef.current) {
                    titleRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createAlbumError) {
            addMessage({
                location: "ModalNewAlbum",
                type: EnumMessageType.Error,
                message: createAlbumError.message,
            })
        } else if (createAlbumData) {
            if (createAlbumData.createAlbum.statusCode === 200) {
                const createdAlbum: IAlbum = createAlbumData.createAlbum.album
                dispatch(addAlbumAction(createdAlbum))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Album ajouté.",
                })
                handleClose()
            } else {
                createAlbumData.createAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createAlbumData, createAlbumError])

    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setDescription("")
        setDateAlbum(dayjs())
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createAlbum({
                variables: {
                    title,
                    description,
                    dateAlbum: dateAlbum.format(EnumDayjsFormat.dateForBackend),
                }
            })
        }
    }

    const handleDateAlbum = (pDate: Dayjs) => {
        setDateAlbum(pDate)
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalNewAlbum">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <CameraIcon color="desktop" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouvel album
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Titre de l'album"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Date
                            </Typography>
                            <DateSelector
                                date={dateAlbum}
                                title="Date associée"
                                disableFuture={false}
                                handleDate={handleDateAlbum}
                                // resetDate={resetDateAlbum}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
