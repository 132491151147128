import { Box, Divider, IconButton, Paper, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IFoto } from "../../interfaces";
import {
    DeleteOutline
} from '@mui/icons-material';

import { Flexbox } from "../utils/Flexbox";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_FOTO } from '../../graphql/Mutations';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

interface IProps {
    foto: IFoto,
    onClick: (index: number) => void,
    index: number,
}

export const PublikFoto: FC<IProps> = (props) => {
    const [previewLoaded, setPreviewLoaded] = useState(false);

    const theme = useTheme()

    const handlePreviewLoaded = () => {
        setPreviewLoaded(true);
    }

    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    // width: "100%",
                    width: "320px",
                }}>
                <Box>
                    {
                        props.foto.fotoPreviewUrl === null && <Flexbox sx={{
                            height: "200px",
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            <Typography sx={{
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer",
                                ":hover": {
                                    color: theme.palette.secondary.main,
                                }
                            }}
                                onClick={() => props.onClick(props.index)}
                            >
                                Afficher la photo
                                <br />
                                <Typography sx={{ color: theme.palette.cancel.main }}>
                                    (miniature en construction..)
                                </Typography>
                            </Typography>
                        </Flexbox>
                    }
                    {
                        props.foto.fotoPreviewUrl && <Flexbox sx={{
                            px: 1,
                            py: 1,
                            backgroundColor: theme.palette.fotoBackground.main,
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                        }}>
                            {!previewLoaded && <Skeleton variant="rectangular" width={200} height={200} sx={{ borderRadius: 1 }} />}
                            <Box component="img"
                                src={props.foto.fotoPreviewUrl}
                                onLoad={handlePreviewLoaded}
                                sx={{
                                    maxHeight: "200px",
                                    borderRadius: 1,
                                    display: previewLoaded ? 'block' : 'none',
                                    cursor: "pointer",
                                }}
                                onClick={() => props.onClick(props.index)}
                            />

                        </Flexbox>
                    }


                    <Box>
                        <Divider />
                        <Flexbox
                            sx={{
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Télécharger">
                                <IconButton href={props.foto.fotoFileUrlForDownload} color="primary">
                                    <CloudDownloadOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}
