// import "../css/components/MetadocLogo.css"
import { Avatar, Box, Paper, Skeleton, Typography, useTheme } from "@mui/material"
import { fontSize } from "@mui/system"
import { FC, useState } from "react"
import { EnumSize } from "../enums"
import { IBadge, IUser } from "../interfaces"
import { log } from "console"
// import { EnumMetadocLogoMode } from "../enums"



interface IProps {
    user: IUser,
    size: EnumSize,
    sx?: object,
    badge?: IBadge,
}


export const UserBadge: FC<IProps> = (props) => {
    const [avatarLoaded, setAvatarLoaded] = useState(props.user.userAvatarPreviewUrl ? false : true);


    const theme = useTheme()

    const baseSizes = {
        tiny: 4,
        small: 6,
        medium: 10,
        large: 16,
        xlarge: 24,
    }

    enum EnumInnerSize {
        avatarWidth = "avatarWidth",
        avatarMr = "avatarMr",
        fontSize = "fontSize",
        paperPl = "paperPl",
        paperPr = "paperPr",
        paperBorderRightRadius = "paperBorderRightRadius",
    }

    enum EnumInnerDevice {
        desktop = "desktop",
        mobile = "mobile",

    }

    const baseCoefficients = {
        avatarWidth: 4,
        avatarMr: -1.5,
        fontSize: 1.8,
        paperPl: 2,
        paperPr: 1,
        paperBorderRightRadius: 2,
    }



    const getSize = (innerSize: EnumInnerSize, device: EnumInnerDevice = EnumInnerDevice.desktop) => {
        let baseCoefficient = baseCoefficients.avatarWidth
        if (innerSize === EnumInnerSize.fontSize) {
            baseCoefficient = baseCoefficients.fontSize
        } else if (innerSize === EnumInnerSize.paperPl) {
            baseCoefficient = baseCoefficients.paperPl
        } else if (innerSize === EnumInnerSize.paperPr) {
            baseCoefficient = baseCoefficients.paperPr
        } else if (innerSize === EnumInnerSize.avatarMr) {
            baseCoefficient = baseCoefficients.avatarMr
        } else if (innerSize === EnumInnerSize.paperBorderRightRadius) {
            baseCoefficient = baseCoefficients.paperBorderRightRadius
        }

        let mappedSize = baseSizes.xlarge
        if (props.size === EnumSize.medium) {
            mappedSize = baseSizes.medium
        } else if (props.size === EnumSize.tiny) {
            mappedSize = baseSizes.tiny
        } else if (props.size === EnumSize.small) {
            mappedSize = baseSizes.small
        } else if (props.size === EnumSize.large) {
            mappedSize = baseSizes.large
        }

        if (device === EnumInnerDevice.mobile) {
            // console.log("in mode mobile..")
            // console.log("mappedSize changed from ", mappedSize)
            mappedSize = (mappedSize / 1.5)
            // console.log("to ", mappedSize)
        }

        const res = `${baseCoefficient * mappedSize}px`
        // console.log("res is ", res)
        return res
    }

    const getBadgeColor = (forText: boolean) => {
        if (props.badge) {
            if (props.badge.color === "desktop") {
                return forText ? theme.palette.desktop.contrastText : theme.palette.desktop.main
            }
            if (props.badge.color === "shared") {
                return forText ? theme.palette.shared.contrastText : theme.palette.shared.main
            }
            if (props.badge.color === "cancel") {
                return forText ? theme.palette.cancel.contrastText : theme.palette.cancel.main
            }
        }
    }

    const handleAvatarLoaded = () => {
        setAvatarLoaded(true);
    }

    return (
        <Box className="UserBadge" sx={props.sx}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {
                        !avatarLoaded && <Skeleton variant="circular" animation="wave"
                            sx={{
                                width: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                                height: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                                mr: { xs: getSize(EnumInnerSize.avatarMr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarMr) },
                                border: "1px solid rgba(0, 0, 0, .2)",
                                zIndex: 2,
                                backgroundColor: theme.palette.grey[200],
                            }}
                        />
                    }
                    <Avatar
                        onLoad={handleAvatarLoaded}
                        sx={{
                            width: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                            height: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                            mr: { xs: getSize(EnumInnerSize.avatarMr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarMr) },
                            border: "1px solid rgba(0, 0, 0, .2)",
                            zIndex: 2,
                            display: avatarLoaded ? 'flex' : 'none',
                        }}
                        className="avatar-icon"
                        alt={`${props.user.firstname} ${props.user.lastname}`}
                        src={`${props.user.userAvatarPreviewUrl}`}
                    />
                    <Box sx={{
                        pl: { xs: getSize(EnumInnerSize.paperPl, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPl) },
                        pr: { xs: getSize(EnumInnerSize.paperPr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPr) },
                        border: "1px solid rgba(0, 0, 0, .15)",
                        borderTopRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                        borderBottomRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                        backgroundColor: theme.palette.desktop.light,
                        zIndex: 1,
                    }}>
                        <Typography sx={{
                            fontSize: { xs: getSize(EnumInnerSize.fontSize, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.fontSize) },
                            maxWidth: {
                                xs: 120,
                                md: 240,
                            },
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                        }}>
                            {props.user.firstname} {props.user.lastname}
                        </Typography>

                    </Box>
                </Box>
                {
                    props.badge &&
                    <Box sx={{
                        backgroundColor: getBadgeColor(false),
                        color: getBadgeColor(true),
                        px: 1,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                        mt: -1,
                        zIndex: 0,
                    }}>
                        <Typography sx={{
                            fontSize: 12,
                        }}>
                            {props.badge.label}
                        </Typography>
                    </Box>
                }

            </Box>
        </Box>
    )
}





