import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { ContactIcon } from '../../svg_icons/ContactIcon';
import { Flexbox } from '../../utils/Flexbox';
import { Spinner } from '../../spinner/Spinner';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { EnumModalContactDownloadVcardsMode } from '../../../enums';

interface IProps {
    mode: EnumModalContactDownloadVcardsMode,
    open: boolean,
    handleClose: () => void,
    sheet_file_url?: string,
}

export const ModalContactDownloadVcards: FC<IProps> = (props) => {

    const theme = useTheme()


    return (
        <div className="ModalContactDownloadVcards">
            <Dialog open={props.open} onClose={props.handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <ContactIcon sx={{ fontSize: 24, mr: 3, color: theme.palette.desktop.main }} />
                        <Typography sx={{ fontSize: 24 }}>
                            {
                                props.mode === EnumModalContactDownloadVcardsMode.single
                                    ?
                                    `Télécharger le contact`
                                    :
                                    `Télécharger les contacts`
                            }
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                            mb: 2,
                        }}>
                            {
                                props.sheet_file_url === undefined
                                    ?
                                    <Flexbox direction='column' sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 1,
                                    }}>
                                        <Spinner size={18} />
                                        <Typography sx={{
                                            mt: 1,
                                            color: theme.palette.primary.main,
                                        }}>
                                            Préparation du fichier..
                                        </Typography>
                                    </Flexbox>
                                    :
                                    <Flexbox direction='column' sx={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 1,
                                    }}>
                                        <Button startIcon={<CloudDownloadOutlinedIcon />} variant='contained' color="secondary" size="medium" href={props.sheet_file_url}>Télécharger</Button>
                                    </Flexbox>
                            }
                        </Flexbox>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} variant="outlined" color="cancel">Fermer</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
