import { Avatar, Box, Skeleton, Tooltip, Typography, useTheme } from "@mui/material"
import { FC, useState } from "react"
import { IBadge, IContact, IContactMinimal } from "../../interfaces"
import { EnumSize } from "../../enums"



interface IProps {
    contact: IContactMinimal,
    size: EnumSize,
    sx?: object,
    badge?: IBadge,
    displayContactTitle: boolean,
    displayTooltip?: boolean,
    onClick?: (selectedContact: IContact) => void;
}


export const ContactBadge: FC<IProps> = (props) => {
    const [avatarLoaded, setAvatarLoaded] = useState(props.contact.contactAvatarPreviewUrl ? false : true);


    const theme = useTheme()

    const baseSizes = {
        tiny: 4,
        small: 6,
        medium: 10,
        large: 16,
        xlarge: 24,
    }

    enum EnumInnerSize {
        avatarWidth = "avatarWidth",
        avatarMr = "avatarMr",
        fontSize = "fontSize",
        paperPl = "paperPl",
        paperPr = "paperPr",
        paperBorderRightRadius = "paperBorderRightRadius",
    }

    enum EnumInnerDevice {
        desktop = "desktop",
        mobile = "mobile",

    }

    const baseCoefficients = {
        avatarWidth: 4,
        avatarMr: -1.5,
        fontSize: 1.8,
        paperPl: 2,
        paperPr: 1,
        paperBorderRightRadius: 2,
    }



    const getSize = (innerSize: EnumInnerSize, device: EnumInnerDevice = EnumInnerDevice.desktop) => {
        let baseCoefficient = baseCoefficients.avatarWidth
        if (innerSize === EnumInnerSize.fontSize) {
            baseCoefficient = baseCoefficients.fontSize
        } else if (innerSize === EnumInnerSize.paperPl) {
            baseCoefficient = baseCoefficients.paperPl
        } else if (innerSize === EnumInnerSize.paperPr) {
            baseCoefficient = baseCoefficients.paperPr
        } else if (innerSize === EnumInnerSize.avatarMr) {
            baseCoefficient = baseCoefficients.avatarMr
        } else if (innerSize === EnumInnerSize.paperBorderRightRadius) {
            baseCoefficient = baseCoefficients.paperBorderRightRadius
        }

        let mappedSize = baseSizes.xlarge
        if (props.size === EnumSize.medium) {
            mappedSize = baseSizes.medium
        } else if (props.size === EnumSize.tiny) {
            mappedSize = baseSizes.tiny
        } else if (props.size === EnumSize.small) {
            mappedSize = baseSizes.small
        } else if (props.size === EnumSize.large) {
            mappedSize = baseSizes.large
        }

        if (device === EnumInnerDevice.mobile) {
            mappedSize = (mappedSize / 1.5)
        }

        const res = `${baseCoefficient * mappedSize}px`
        return res
    }

    const getBadgeColor = (forText: boolean) => {
        if (props.badge) {
            if (props.badge.color === "desktop") {
                return forText ? theme.palette.desktop.contrastText : theme.palette.desktop.main
            }
            if (props.badge.color === "shared") {
                return forText ? theme.palette.shared.contrastText : theme.palette.shared.main
            }
            if (props.badge.color === "cancel") {
                return forText ? theme.palette.cancel.contrastText : theme.palette.cancel.main
            }
        }
    }

    const handleAvatarLoaded = () => {
        setAvatarLoaded(true);
    }

    const getAvatar = () => {
        return <Avatar
            onLoad={handleAvatarLoaded}
            sx={{
                width: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                height: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                mr: { xs: getSize(EnumInnerSize.avatarMr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarMr) },
                border: "1px solid rgba(0, 0, 0, .2)",
                zIndex: 2,
                display: avatarLoaded ? 'flex' : 'none',

            }}
            className="avatar-icon"
            alt={`${props.contact.firstname} ${props.contact.lastname}`}
            src={`${props.contact.contactAvatarPreviewUrl}`}
        />
    }

    return (
        <Box className="ContactBadge" sx={props.sx}>
            <Box
                onClick={() => props.onClick ? props.onClick(props.contact) : null}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    ":hover": {
                        cursor: props.onClick ? "pointer" : "default",
                        ".avatar-icon": {
                            borderColor: props.onClick ? theme.palette.secondary.main : "rgba(0, 0, 0, .2)",
                        },
                        ".contact-title": {
                            borderColor: props.onClick ? theme.palette.secondary.main : "rgba(0, 0, 0, .15)",
                        }
                    },
                }}>
                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    {
                        !avatarLoaded && <Skeleton variant="circular" animation="wave"
                            sx={{
                                width: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                                height: { xs: getSize(EnumInnerSize.avatarWidth, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarWidth) },
                                mr: { xs: getSize(EnumInnerSize.avatarMr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.avatarMr) },
                                border: "1px solid rgba(0, 0, 0, .2)",
                                zIndex: 2,
                                backgroundColor: theme.palette.grey[200],
                            }}
                        />
                    }
                    {
                        props.displayTooltip
                            ?
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={props.contact.displayTitle}>
                                {getAvatar()}
                            </Tooltip>
                            :
                            <>
                                {getAvatar()}
                            </>
                    }
                    {
                        props.displayContactTitle &&
                        <Box className="contact-title" sx={{
                            pl: { xs: getSize(EnumInnerSize.paperPl, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPl) },
                            pr: { xs: getSize(EnumInnerSize.paperPr, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperPr) },
                            border: "1px solid rgba(0, 0, 0, .15)",
                            borderTopRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                            borderBottomRightRadius: { xs: getSize(EnumInnerSize.paperBorderRightRadius, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.paperBorderRightRadius) },
                            backgroundColor: theme.palette.desktop.light,
                            zIndex: 1,
                        }}>
                            <Typography sx={{
                                fontSize: { xs: getSize(EnumInnerSize.fontSize, EnumInnerDevice.mobile), md: getSize(EnumInnerSize.fontSize) }
                            }}>
                                {props.contact.displayTitle}
                            </Typography>

                        </Box>
                    }
                </Box>
                {
                    props.displayContactTitle &&
                    props.badge &&
                    <Box sx={{
                        backgroundColor: getBadgeColor(false),
                        color: getBadgeColor(true),
                        px: 1,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                        mt: -1,
                        zIndex: 0,
                    }}>
                        <Typography sx={{
                            fontSize: 12,
                        }}>
                            {props.badge.label}
                        </Typography>
                    </Box>
                }

            </Box>
        </Box>
    )
}





