import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../redux/store";
import { LockerIcon } from "./svg_icons/LockerIcon";
import { DirectoryIcon } from "./svg_icons/DirectoryIcon";
import { DocumentIcon } from "./svg_icons/DocumentIcon";
import { VaultIcon } from "./svg_icons/VaultIcon";
import { SharedIcon } from "./svg_icons/SharedIcon";
import { setSearchParamsAction } from "../redux/features/search/searchSlice";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_SEARCH } from "../utils/Navigation";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { ModalDatePicker } from "./modals/ModalDatePicker";
import { Dayjs } from "dayjs";
import { NoteIcon } from "./svg_icons/NoteIcon";
import { EnumLockerIconMode } from "../enums";

interface IProps {
}

export const SearchTool: FC<IProps> = (props) => {
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [searchInput, setSearchInput] = useState("")
    const [desktopLocker, setDesktopLocker] = useState(false)
    const [vaultLocker, setVaultLocker] = useState(false)
    const [sharedLocker, setSharedLocker] = useState(false)
    const [desktopDirectory, setDesktopDirectory] = useState(false)
    const [vaultDirectory, setVaultDirectory] = useState(false)
    const [sharedDirectory, setSharedDirectory] = useState(false)
    const [desktopDocument, setDesktopDocument] = useState(true)
    const [vaultDocument, setVaultDocument] = useState(true)
    const [sharedDocument, setSharedDocument] = useState(false)
    const [desktopNote, setDesktopNote] = useState(true)
    const [vaultNote, setVaultNote] = useState(true)
    const [sharedNote, setSharedNote] = useState(false)

    const [dateFrom, setDateFrom] = useState("")
    const [showModalDatePickerFrom, setShowModalDatePickerFrom] = useState(false)

    const [dateTo, setDateTo] = useState("")
    const [showModalDatePickerTo, setShowModalDatePickerTo] = useState(false)

    const searchQuery: string = useSelector((state: IRootState) => state.searchReducer.searchQuery)
    const searchTargets = useSelector((state: IRootState) => state.searchReducer.targets)
    const searchDates = useSelector((state: IRootState) => state.searchReducer.dates)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const theme = useTheme()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        setTimeout(() => {
            if (searchInputRef.current) {
                searchInputRef.current.focus();
            }
        }, 500);

        setSearchInput(searchQuery)
        setDesktopLocker(searchTargets.desktopLockers)
        setVaultLocker(searchTargets.vaultLockers)
        setSharedLocker(searchTargets.sharedLockers)
        setDesktopDirectory(searchTargets.desktopDirectories)
        setVaultDirectory(searchTargets.vaultDirectories)
        setSharedDirectory(searchTargets.sharedDirectories)
        setDesktopDocument(searchTargets.desktopDocuments)
        setVaultDocument(searchTargets.vaultDocuments)
        setSharedDocument(searchTargets.sharedDocuments)
        setDesktopNote(searchTargets.desktopNotes)
        setVaultNote(searchTargets.vaultNotes)
        setSharedNote(searchTargets.sharedNotes)
        setDateFrom(searchDates.dateFrom)
        setDateTo(searchDates.dateTo)

    }, []);

    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const payload = {
            searchQuery: searchInput,
            targets: {
                desktopLockers: desktopLocker,
                vaultLockers: vaultLocker,
                sharedLockers: sharedLocker,
                desktopDirectories: desktopDirectory,
                vaultDirectories: vaultDirectory,
                sharedDirectories: sharedDirectory,
                desktopDocuments: desktopDocument,
                vaultDocuments: vaultDocument,
                sharedDocuments: sharedDocument,
                desktopNotes: desktopNote,
                vaultNotes: vaultNote,
                sharedNotes: sharedNote,
            },
            dates: {
                dateFrom: dateFrom,
                dateTo: dateTo,
            },
        }
        dispatch(setSearchParamsAction(payload))
        navigate(NAVIGATE_SEARCH)
    }

    const isAllChecked = () => {
        return desktopLocker &&
            vaultLocker &&
            sharedLocker &&
            desktopDirectory &&
            vaultDirectory &&
            sharedDirectory &&
            desktopDocument &&
            vaultDocument &&
            sharedDocument &&
            desktopNote &&
            vaultNote &&
            sharedNote
    }

    const setAllChecked = (checkedValue: boolean) => {
        setDesktopLocker(checkedValue)
        setVaultLocker(checkedValue)
        setSharedLocker(checkedValue)
        setDesktopDirectory(checkedValue)
        setVaultDirectory(checkedValue)
        setSharedDirectory(checkedValue)
        setDesktopDocument(checkedValue)
        setVaultDocument(checkedValue)
        setSharedDocument(checkedValue)
        setDesktopNote(checkedValue)
        setVaultNote(checkedValue)
        setSharedNote(checkedValue)
    }

    const handleDateFrom = () => {
        setShowModalDatePickerFrom(true)
    }

    const closeModalDatePickerFrom = () => {
        setShowModalDatePickerFrom(false)
    }

    const confirmModalDatePickerFrom = (pDate: Dayjs) => {
        setDateFrom(pDate.format("DD/MM/YYYY"))
    }

    const resetModalDatePickerFrom = () => {
        setDateFrom("")
    }

    const handleDateTo = () => {
        setShowModalDatePickerTo(true)
    }

    const closeModalDatePickerTo = () => {
        setShowModalDatePickerTo(false)
    }

    const confirmModalDatePickerTo = (pDate: Dayjs) => {
        setDateTo(pDate.format("DD/MM/YYYY"))
    }

    const resetModalDatePickerTo = () => {
        setDateTo("")
    }

    return (
        <Box className="SearchTool">
            <Box noValidate component="form"
                onSubmit={handleSearch}
                sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                    inputRef={searchInputRef}
                    margin="dense"
                    label="Recherche.."
                    fullWidth
                    variant="standard"
                    onChange={(event) => { setSearchInput(event.target.value) }}
                    value={searchInput}
                    sx={{ mb: 1 }}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les casiers du bureau">
                        <FormControlLabel control={
                            <Checkbox checked={desktopLocker} value={desktopLocker} onChange={(event) => setDesktopLocker(event.target.checked)} color="desktop" />
                        } label={<LockerIcon mode={EnumLockerIconMode.standard} color="desktop" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les casiers du coffre">
                        <FormControlLabel control={
                            <Checkbox checked={vaultLocker} value={vaultLocker} onChange={(event) => setVaultLocker(event.target.checked)} color="vault" />
                        } label={<VaultIcon color="vault" sx={{ mt: 1 }} />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les casiers du partage">
                        <FormControlLabel control={
                            <Checkbox checked={sharedLocker} value={sharedLocker} onChange={(event) => setSharedLocker(event.target.checked)} color="shared" />
                        } label={<SharedIcon color="shared" />} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les dossiers du bureau">
                        <FormControlLabel control={
                            <Checkbox checked={desktopDirectory} value={desktopDirectory} onChange={(event) => setDesktopDirectory(event.target.checked)} color="desktop" />
                        } label={<DirectoryIcon color="desktop" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les dossiers du coffre">
                        <FormControlLabel control={
                            <Checkbox checked={vaultDirectory} value={vaultDirectory} onChange={(event) => setVaultDirectory(event.target.checked)} color="vault" />
                        } label={<DirectoryIcon color="vault" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les dossiers du partage">
                        <FormControlLabel control={
                            <Checkbox checked={sharedDirectory} value={sharedDirectory} onChange={(event) => setSharedDirectory(event.target.checked)} color="shared" />
                        } label={<DirectoryIcon color="shared" />} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les documents du bureau">
                        <FormControlLabel control={
                            <Checkbox checked={desktopDocument} value={desktopDocument} onChange={(event) => setDesktopDocument(event.target.checked)} color="desktop" />
                        } label={<DocumentIcon color="desktop" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les documents du coffre">
                        <FormControlLabel control={
                            <Checkbox checked={vaultDocument} value={vaultDocument} onChange={(event) => setVaultDocument(event.target.checked)} color="vault" />
                        } label={<DocumentIcon color="vault" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les documents du partage">
                        <FormControlLabel control={
                            <Checkbox checked={sharedDocument} value={sharedDocument} onChange={(event) => setSharedDocument(event.target.checked)} color="shared" />
                        } label={<DocumentIcon color="shared" />} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les notes du bureau">
                        <FormControlLabel control={
                            <Checkbox checked={desktopNote} value={desktopNote} onChange={(event) => setDesktopNote(event.target.checked)} color="desktop" />
                        } label={<NoteIcon color="desktop" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les notes du coffre">
                        <FormControlLabel control={
                            <Checkbox checked={vaultNote} value={vaultNote} onChange={(event) => setVaultNote(event.target.checked)} color="vault" />
                        } label={<NoteIcon color="vault" />} />
                    </Tooltip>
                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Inclure les notes du partage">
                        <FormControlLabel control={
                            <Checkbox checked={sharedNote} value={sharedNote} onChange={(event) => setSharedNote(event.target.checked)} color="shared" />
                        } label={<NoteIcon color="shared" />} />
                    </Tooltip>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {
                        isAllChecked()
                            ?
                            <Button size="small" color="primary" onClick={() => setAllChecked(false)} >
                                Tout décocher
                            </Button>
                            :
                            <Button size="small" color="primary" onClick={() => setAllChecked(true)} >
                                Tout cocher
                            </Button>
                    }
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Depuis">
                            <IconButton onClick={handleDateFrom} color="primary">
                                <EventOutlinedIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                        <Box sx={{ borderBottom: dateFrom === "" ? `1px solid ${theme.palette.secondary.main}` : "none", width: "100%" }}>
                            {
                                dateFrom !== ""
                                    ?
                                    <Typography variant="body2" color="secondary"  >
                                        {dateFrom}
                                    </Typography>
                                    :
                                    <Typography variant="body2"  >
                                        &nbsp;
                                    </Typography>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "100%" }}>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Jusqu'au">
                            <IconButton onClick={handleDateTo} color="primary">
                                <EventOutlinedIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                        </Tooltip>
                        <Box sx={{ borderBottom: dateTo === "" ? `1px solid ${theme.palette.secondary.main}` : "none", width: "100%" }}>
                            {
                                dateTo !== ""
                                    ?
                                    <Typography variant="body2" color="secondary"  >
                                        {dateTo}
                                    </Typography>
                                    :
                                    <Typography variant="body2"  >
                                        &nbsp;
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </Box>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
                    Rechercher
                </Button>
            </Box>
            <ModalDatePicker
                title="Date de début"
                disableFuture={true}
                open={showModalDatePickerFrom}
                handleClose={closeModalDatePickerFrom}
                handleConfirm={confirmModalDatePickerFrom}
                handleReset={resetModalDatePickerFrom}
            />
            <ModalDatePicker
                title="Date de fin"
                disableFuture={true}
                open={showModalDatePickerTo}
                handleClose={closeModalDatePickerTo}
                handleConfirm={confirmModalDatePickerTo}
                handleReset={resetModalDatePickerTo}
            />
        </Box>
    )
}





