import { createSlice } from "@reduxjs/toolkit";


// To keep modals opened even after a refetchAll !!
interface IModalsSliceState {
    openedModal: string;
}

const initialState: IModalsSliceState = {
    openedModal: "",
}

export const modalsSlice = createSlice({
    name: "modalsSlice",
    initialState,
    reducers: {
        setOpenedModal: (state, action) => {
            state.openedModal = action.payload
        },
        clearOpenedModal: (state) => {
            state.openedModal = initialState.openedModal
        },
    }
})

export const { setOpenedModal, clearOpenedModal } = modalsSlice.actions

export default modalsSlice.reducer