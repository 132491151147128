import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PublikLayout } from './PublikLayout';
import { useQuery } from '@apollo/client';
import { Spinner } from '../components/spinner/Spinner';
import { GQL_PUBLIK_DIRECTORY } from '../graphql/Queries';
import { IBreadcrumb, IDirectory } from '../interfaces';
import { addMessage } from '../components/messages/Message';
import { EnumBasePathFor, EnumBreadcrumbMode, EnumListLockersMode, EnumMessageType } from '../enums';
import { PublikDirectoryDetails } from '../components/directories/PublikDirectoryDetails';
import { ListPublikSubDirectories } from '../components/directories/ListPublikSubDirectories';
import { constructPath, getBasePath } from '../utils/Navigation';
import { Breadcrumbs } from '../components/Breadcrumbs';

interface IProps {
}

export const PublikDirectoryPage: FC<IProps> = (props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
  const params = useParams()
  const directoryId = params.directoryId || ""
  const accessParams = new URLSearchParams(window.location.search);
  const pubToken = accessParams.get('access');
  const [publikDirectory, setPublikDirectory] = useState<IDirectory | undefined>(undefined)
  const { data: getPublikDirectoryData, loading: getPublikDirectoryLoading, error: getPublikDirectoryError } = useQuery(GQL_PUBLIK_DIRECTORY, {
    fetchPolicy: 'network-only',
    variables: {
      directoryId,
      pubToken,
    }
  })

  useEffect(() => {
    if (getPublikDirectoryError) {
      addMessage({
        location: "PublikDirectoryPage",
        type: EnumMessageType.Error,
        message: getPublikDirectoryError.message,
      })
    } else if (getPublikDirectoryData) {
      setPublikDirectory(getPublikDirectoryData.publikDirectory)
    }
  }, [getPublikDirectoryData, getPublikDirectoryError])

  const getAncestorsBreadcrumbs = () => {
    if (publikDirectory === undefined) {
      return [{ title: "..." }]
    }
    let res = []
    // Puis les ancestors:
    if (publikDirectory.ancestors !== undefined) {
      res.push(
        ...publikDirectory.ancestors.map(ancestor => {
          return {
            title: ancestor.name,
            path: `${constructPath(getBasePath(EnumBasePathFor.publikDirectory, EnumListLockersMode.desktop), [
              { key: "directoryId", value: ancestor.id.toString() },
            ])}?access=${pubToken}`,
          }
        })
      )
    }

    return res
  }

  useEffect(() => {
    setBreadcrumbs([
      ...getAncestorsBreadcrumbs(),
      {
        title: publikDirectory?.name || "...",
      },
    ])
  }, [publikDirectory])

  return (
    <div className="PublikDirectoryPage">
      <PublikLayout >
        <div className="page-body">
          <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ py: 1.5 }} />
          {
            getPublikDirectoryLoading && <Spinner classes="big-spinner" />
          }
          {
            publikDirectory &&
            <>
              <PublikDirectoryDetails directory={publikDirectory} />
              <ListPublikSubDirectories directory={publikDirectory} />
            </>
          }
        </div>
      </PublikLayout>
    </div >
  )
}
