import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect } from 'react';
import { DirectorySearchResult } from '../components/directories/DirectorySearchResult';
import { DocumentSearchResult } from '../components/documents/DocumentSearchResult';
import { LockerSearchResult } from '../components/lockers/LockerSearchResult';
import { addMessage } from '../components/messages/Message';
import { Spinner } from '../components/spinner/Spinner';
import { EnumMessageType } from '../enums';
import { useGetSearchResults } from '../redux/features/search/searchSlice';
import { pluralize } from '../utils/Utils';
import { Layout } from "./Layout";
import { NoteSearchResult } from '../components/notes/NoteSearchResult';

interface IProps {
}

export const SearchPage: FC<IProps> = (props) => {
  const results = useGetSearchResults()

  useEffect(() => {
    if (results !== undefined) {
      if (
        results.lockers.length === 0 &&
        results.directories.length === 0 &&
        results.documents.length === 0 &&
        results.notes.length === 0
      ) {
        addMessage({
          type: EnumMessageType.Info,
          message: "Aucun résultat.",
        }
        )
      } else {
        const nbElements = results.lockers.length + results.directories.length + results.documents.length + results.notes.length
        addMessage({
          type: EnumMessageType.Info,
          message: `${nbElements} ${pluralize("élément trouvé", nbElements, "éléments trouvés")}.`,
        }
        )
      }
    }
    // }, [results])
  }, [])

  return (
    <div className="SearchPage">
      <Layout>
        <div className="page-body">
          <Container sx={{ mt: 4 }}>
            <Grid container spacing={0}>
              {
                results === undefined
                  ?
                  <Spinner classes="big-spinner" />
                  :
                  results.lockers.map((locker) => {
                    return (
                      <Grid key={`${locker.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                        <LockerSearchResult locker={locker} />
                      </Grid>
                    )
                  })
              }
              {
                results !== undefined && results.directories.map((directory) => {
                  return (
                    <Grid key={`${directory.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="flex-start" alignItems="flex-start">
                      <DirectorySearchResult directory={directory} />
                    </Grid>
                  )
                })
              }
              {
                results !== undefined && results.documents.map((document) => {
                  return (
                    <Grid key={`${document.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="flex-start" alignItems="flex-start">
                      <DocumentSearchResult document={document} />
                    </Grid>
                  )
                })
              }
              {
                results !== undefined && results.notes.map((note) => {
                  return (
                    <Grid key={`${note.id}`} size={{ xs: 12 }} display="flex" justifyContent="flex-start" alignItems="flex-start">
                      <NoteSearchResult note={note} />
                    </Grid>
                  )
                })
              }
              {
                results !== undefined &&
                results.lockers.length === 0 &&
                results.directories.length === 0 &&
                results.documents.length === 0 &&
                results.notes.length === 0 &&
                <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                  Aucun résultat.
                </Typography>
              }
            </Grid>
          </Container>
        </div>
      </Layout>
    </div >
  )
}
