import "../css/components/MetadocLogo.css"
import { FC } from "react"
import { EnumMetadocLogoMode } from "../enums"
import { Box, Link } from "@mui/material"



interface IProps {
    mode: EnumMetadocLogoMode,
    link: boolean,
    sx?: object,
}


export const MetadocLogo: FC<IProps> = ({ mode, link, sx }) => {

    // const StandardVersion = () => <img src="images/metadoc_logo.png" className="MetadocLogo standard" />
    // const StandardVersion = () => <div className="MetadocLogo standard">
    //     <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
    // </div>
    // const AccueilVersion = () => <div className="MetadocLogo accueil">
    //     <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
    // </div>
    // const TinyVersion = () => <div className="MetadocLogo tiny">
    //     <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
    // </div>
    // const PublikVersion = () => <div className="MetadocLogo publik">
    //     <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
    // </div>

    // return (
    //     <>
    //         {
    //             mode === EnumMetadocLogoMode.standard && <StandardVersion />
    //         }
    //         {
    //             mode === EnumMetadocLogoMode.accueil && <AccueilVersion />
    //         }
    //         {
    //             mode === EnumMetadocLogoMode.tiny && <TinyVersion />
    //         }

    //     </>
    // )

    return (
        // <div className={`MetadocLogo ${mode}`}>
        //     <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
        // </div>
        <>
            {
                link
                    ?
                    <>
                        <Link sx={sx} href={`${window.location.origin}`
                        } className={`MetadocLogo ${mode}`}>
                            <img src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
                        </Link>
                    </>
                    :
                    <>
                        <Box sx={sx} component="img" src={`${process.env.PUBLIC_URL}/images/metadoc_logo.png`} />
                    </>
            }
        </>
    )
}


