import "../../css/components/lockers/LockerDetails.css"
import { Box, Button, Collapse, Divider, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react'
import { IDirectory } from "../../interfaces";
import { DeleteOutline, EditOutlined } from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumBasePathFor, EnumListLockersMode, EnumMessageType, EnumParentType, EnumSize, EnumSliceReference, EnumSvgVariant } from "../../enums";
import {
    GQL_MUTATION_DELETE_DIRECTORY,
    GQL_MUTATION_ADD_IN_POCKET,
    GQL_MUTATION_REMOVE_FROM_POCKET,
    GQL_MUTATION_ADD_IN_DIRECTORIES_SHORTCUTTED,
    GQL_MUTATION_REMOVE_FROM_DIRECTORIES_SHORTCUTTED,
} from "../../graphql/Mutations";
import { useGetLocker } from "../../redux/features/lockers/lockerSlice";
import { ModalEditDirectory } from "./modals/ModalEditDirectory";
import { useNavigate } from "react-router-dom";
import { getBasePath, constructPath } from "../../utils/Navigation";
import { UserBadge } from "../UserBadge";
import { PocketIcon } from "../svg_icons/PocketIcon";
import {
    addDirectoriesAction as addDirectoriesInPocketAction,
    removeDirectoriesAction as removeDirectoriesFromPocketAction,
    useGetDirectories as useGetPocketDirectories,
} from "../../redux/features/pocket/pocketSlice";
import { DirectoryIcon } from "../svg_icons/DirectoryIcon";
import { setAllRefetchNeeded } from "../../redux/store";
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { Flexbox } from "../utils/Flexbox";
import { FloatingSpinner } from "../spinner/FloatingSpinner";
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import { useGetCurrentUser } from "../../redux/features/users/currentUserSlice";


interface IProps {
    directory: IDirectory,
    mode: EnumListLockersMode,
}

export const DirectoryDetails: FC<IProps> = (props) => {
    const locker = useGetLocker(props.directory.locker?.id || "")
    const [showDeleteDirectoryDialog, setShowDeleteDirectoryDialog] = useState(false)
    const [showModalEditDirectory, setShowModalEditDirectory] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const pocketDirectories = useGetPocketDirectories()
    const currentUser = useGetCurrentUser()
    const closeModalEditDirectory = () => {
        setShowModalEditDirectory(false)
    }
    const [deleteDirectory, { data: deleteDirectoryData, loading: deleteDirectoryLoading, error: deleteDirectoryError }] = useMutation(GQL_MUTATION_DELETE_DIRECTORY)
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [addInDirectoriesShortcutted, { data: addInDirectoriesShortcuttedData, loading: addInDirectoriesShortcuttedLoading, error: addInDirectoriesShortcuttedError }] = useMutation(GQL_MUTATION_ADD_IN_DIRECTORIES_SHORTCUTTED)
    const [removeFromDirectoriesShortcutted, { data: removeFromDirectoriesShortcuttedData, loading: removeFromDirectoriesShortcuttedLoading, error: removeFromDirectoriesShortcuttedError }] = useMutation(GQL_MUTATION_REMOVE_FROM_DIRECTORIES_SHORTCUTTED)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        if (deleteDirectoryError) {
            addMessage({
                location: "Directory",
                type: EnumMessageType.Error,
                message: deleteDirectoryError.message,
            })
        } else if (deleteDirectoryData) {
            if (deleteDirectoryData.deleteDirectory.statusCode === 200) {
                // Attention, ne pas removeDirectoryAction principal car çà reprovoque des refetch et le backend génère une erreur car il ne trouve plus le directory en question !
                // dispatch(removeDirectoryAction())

                // dispatch(setLockersRefetchNeeded(true))
                // dispatch(setLockerRefetchNeeded(true))
                // dispatch(setPocketRefetchNeeded(true))
                setAllRefetchNeeded("DirectoryDetails", [EnumSliceReference.directory])

                // On navigate vers le parent:
                if (props.directory.ancestors !== undefined && props.directory.ancestors.length > 0) {
                    const parent = props.directory.ancestors[props.directory.ancestors.length - 1]
                    const parentPath = constructPath(getBasePath(EnumBasePathFor.directory, props.mode), [
                        { key: "directoryId", value: parent.id.toString() }
                    ])
                    navigate(parentPath)
                } else if (props.directory.locker !== undefined) {
                    const lockerPath = constructPath(getBasePath(EnumBasePathFor.locker, props.mode), [
                        { key: "lockerId", value: props.directory.locker.id.toString() }
                    ])
                    navigate(lockerPath)
                }

                addMessage({
                    type: EnumMessageType.Success,
                    message: "Dossier supprimé.",
                })
            } else {
                deleteDirectoryData.deleteDirectory.errors.map((error: string) => {
                    addMessage({
                        location: "DirectoryDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteDirectoryData, deleteDirectoryError])

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "DirectoryDetails",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(addDirectoriesInPocketAction([props.directory]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
                // // Il peut y avoir certains qui n'ont pas fonctionner:
                // const errors: string[] = addInPocketData.addInPocket.errors
                // errors.map( error => addMessage({
                //     type: EnumMessageType.Warning,
                //     message: error,
                // }))

            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "DirectoryDetails",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(removeDirectoriesFromPocketAction([props.directory.id]))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        if (addInDirectoriesShortcuttedError) {
            addMessage({
                location: "DirectoryDetails",
                type: EnumMessageType.Error,
                message: addInDirectoriesShortcuttedError.message,
            })
        } else if (addInDirectoriesShortcuttedData) {
            if (addInDirectoriesShortcuttedData.addInDirectoriesShortcutted.statusCode === 200) {
                setAllRefetchNeeded("DirectoryDetails")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Raccourci créé.",
                })
            } else {
                addInDirectoriesShortcuttedData.addInDirectoriesShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInDirectoriesShortcuttedData, addInDirectoriesShortcuttedError])

    useEffect(() => {
        if (removeFromDirectoriesShortcuttedError) {
            addMessage({
                location: "DirectoryDetails",
                type: EnumMessageType.Error,
                message: removeFromDirectoriesShortcuttedError.message,
            })
        } else if (removeFromDirectoriesShortcuttedData) {
            if (removeFromDirectoriesShortcuttedData.removeFromDirectoriesShortcutted.statusCode === 200) {
                setAllRefetchNeeded("DirectoryDetails")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Raccourci supprimé.",
                })
            } else {
                removeFromDirectoriesShortcuttedData.removeFromDirectoriesShortcutted.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromDirectoriesShortcuttedData, removeFromDirectoriesShortcuttedError])


    const handleEdit = () => {
        setShowModalEditDirectory(true)
    }

    const handleDelete = () => {
        setShowDeleteDirectoryDialog(true)
    }

    const closeDeleteDirectoryDialog = () => {
        setShowDeleteDirectoryDialog(false)
    }

    const confirmedDeleteDirectory = () => {
        deleteDirectory({
            variables: {
                directoryId: props.directory.id
            }
        })
        setShowDeleteDirectoryDialog(false)
    }

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    const isInPocket = () => {
        if (pocketDirectories) {
            return pocketDirectories.filter(directory => directory.id === props.directory.id).length > 0
        }
        return false
    }

    const isInDirectoriesShortcutted = () => {
        if (currentUser) {
            return currentUser.directoriesShortcutted.filter(directory => directory.id === props.directory.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                directoryIds: [props.directory.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                directoryIds: [props.directory.id],
            }
        })
    }

    const handlePutInDirectoriesShortcutted = () => {
        addInDirectoriesShortcutted({
            variables: {
                directoryId: props.directory.id,
            }
        })
    }

    const handleRemoveFromDirectoriesShortcutted = () => {
        removeFromDirectoriesShortcutted({
            variables: {
                directoryId: props.directory.id,
            }
        })
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteDirectoryLoading]} />
            <Paper className={`LockerDetails ${props.directory.selected ? "selected" : ""} `}>
                <Box className="LockerDetails-container">
                    <Box className="LockerDetails-main-section">
                        <Box className="LockerDetails-icon-section">
                            <DirectoryIcon color={props.directory.publik ? EnumListLockersMode.shared : props.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6 }} />

                        </Box>
                        <Box className="LockerDetails-content-section">
                            <Box className="LockerDetails-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.directory.name}
                                </Typography>
                                <Box className="LockerDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="LockerDetails-expanded-section">
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {
                                props.directory.description && <>
                                    {/* <Divider /> */}
                                    <Box className="LockerDetails-description-section">
                                        <Typography sx={{ fontSize: "16px", my: 3 }}>
                                            {props.directory.description}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            <Grid container className="LockerDetails-infos-section">
                                <Grid size={{ xs: 12, md: 3 }} className="LockerDetails-infos-content" sx={{ mb: 2 }}>
                                    <Divider flexItem sx={{ mb: 1 }}>Informations</Divider>
                                    <SubDirectoriesInfos mode={props.mode} detailsMode={true} parentType={EnumParentType.directory} directory={props.directory} />
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-infos-users" sx={{ mb: 2 }}>
                                    <Box className="LockerDetails-infos-owners">
                                        <Divider flexItem>Propriétaires</Divider>
                                        {
                                            locker && locker.owners.map((owner) => {
                                                return (
                                                    <UserBadge key={owner.id} user={owner} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                )
                                            })
                                        }
                                    </Box>
                                    {
                                        locker && locker.viewers.length > 0 && <>
                                            <Box className="LockerDetails-infos-viewers">
                                                <Divider flexItem>Partagés</Divider>
                                                {
                                                    locker.viewers.map((viewer) => {
                                                        return (
                                                            <UserBadge key={viewer.id} user={viewer} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    }
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-actions"
                                    sx={{ mb: 2 }}
                                >
                                    <Divider flexItem sx={{ mb: 1 }}>Actions</Divider>
                                    <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }} >
                                            {
                                                props.mode !== EnumListLockersMode.shared &&
                                                <Button startIcon={<EditOutlined />} color="primary" size="small" onClick={handleEdit}>Modifier</Button>
                                            }
                                            {
                                                isInPocket()
                                                    ?
                                                    <Button startIcon={<PocketIcon variant={EnumSvgVariant.secondary} />} color="secondary" size="small" onClick={handleRemoveFromPocket}>Retirer de la pocket</Button>
                                                    :
                                                    <Button startIcon={<PocketIcon variant={EnumSvgVariant.primary} />} color="primary" size="small" onClick={handlePutInPocket}>Placer dans la pocket</Button>
                                            }
                                        </Flexbox>
                                        <Flexbox direction="column" sx={{ alignItems: "flex-start" }}>
                                            {
                                                isInDirectoriesShortcutted()
                                                    ?
                                                    <Button startIcon={<SwitchAccessShortcutIcon />} color="secondary" size="small" onClick={handleRemoveFromDirectoriesShortcutted}>Retirer des raccourcis</Button>
                                                    :
                                                    <Button startIcon={<SwitchAccessShortcutIcon />} color="primary" size="small" onClick={handlePutInDirectoriesShortcutted}>Ajouter en raccourci</Button>
                                            }
                                            {
                                                props.mode !== EnumListLockersMode.shared &&
                                                <Button startIcon={<DeleteOutline />} color="error" size="small" onClick={handleDelete}>Supprimer</Button>
                                            }
                                        </Flexbox >
                                    </Flexbox>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditDirectory
                open={showModalEditDirectory}
                handleClose={closeModalEditDirectory}
                directory={props.directory}
            />
            <ModalDeleteConfirm
                open={showDeleteDirectoryDialog}
                title="Suppression du dossier"
                content={`Confirmez-vous la suppression définitive du dossier ${props.directory.name} ?`}
                handleClose={closeDeleteDirectoryDialog}
                handleConfirm={confirmedDeleteDirectory}
            />
        </>
    )
}
