import { useMutation } from '@apollo/client';
import {
    Avatar,
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, IconButton, Input, InputAdornment, InputLabel, Switch, Typography,
    useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { EnumMessageType, EnumSize } from '../../../enums';
import { GQL_MUTATION_UPDATE_CONTACT_RELATIONSHIPS } from '../../../graphql/Mutations';
import { setAllRefetchNeeded } from '../../../redux/store';
import { addMessage } from '../../messages/Message';
import { Flexbox } from '../../utils/Flexbox';
import { IContact, IContactMinimal } from '../../../interfaces';
import { useGetContacts } from '../../../redux/features/contacts/contactsSlice';
import { Spinner } from '../../spinner/Spinner';
import { ContactBadge } from '../ContactBadge';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { filterMatch } from '../../../utils/Utils';

interface IProps {
    open: boolean;
    handleClose: () => void;
    contact: IContact,
}

export const ModalNewContactRelationship: FC<IProps> = (props) => {
    const contacts = useGetContacts()
    const [otherContacts, setOtherContacts] = useState<IContactMinimal[]>(props.contact.contactRelations || [])
    const [updateContactRelationships, { data: updateContactRelationshipsData, loading: updateContactRelationshipsLoading, error: updateContactRelationshipsError }] = useMutation(GQL_MUTATION_UPDATE_CONTACT_RELATIONSHIPS)
    const [filterInput, setFilterInput] = useState("") // Pour temporiser la saisie..
    const [filter, setFilter] = useState("")
    const [filteredContacts, setFilteredContacts] = useState<IContact[] | undefined>([])

    const theme = useTheme()

    useEffect(() => {
        if (contacts) {
            if (filter.length === 0) {
                setFilteredContacts(contacts)
            } else {
                setFilteredContacts(contacts.filter(contact => (
                    filterMatch(contact.nickname, filter) ||
                    filterMatch(contact.firstname, filter) ||
                    filterMatch(contact.lastname, filter) ||
                    filterMatch(contact.enterpriseName, filter)
                    // filterMatch(contact.email, filter) ||
                    // filterMatch(contact.adresse, filter) ||
                    // filterMatch(contact.description, filter)
                )))
            }
        }
    }, [contacts, filter])

    // Utiliser useEffect pour réinitialiser otherContacts à chaque fois que props.contact change
    useEffect(() => {
        setOtherContacts(props.contact.contactRelations || []);
    }, [props.contact]);

    useEffect(() => {
        if (updateContactRelationshipsError) {
            addMessage({
                location: "ModalNewContactRelationship",
                type: EnumMessageType.Error,
                message: updateContactRelationshipsError.message,
            })
        } else if (updateContactRelationshipsData) {
            if (updateContactRelationshipsData.updateContactRelationships.statusCode === 200) {
                setAllRefetchNeeded("ModalNewContactRelationship")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Relations mise à jour.",
                })
                handleClose()
            } else {
                updateContactRelationshipsData.updateContactRelationships.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [updateContactRelationshipsData, updateContactRelationshipsError])

    const handleClose = () => {
        props.handleClose()
    }

    const isContactRelation = (contact: IContactMinimal) => {
        let res = false
        otherContacts.forEach((otherContact: IContactMinimal) => {
            if (otherContact.id === contact.id) {
                res = true
            }
        })
        return res
    }

    const handleSetContactRelation = (contactCandidate: IContactMinimal) => {
        if (isContactRelation(contactCandidate)) {
            setOtherContacts(
                otherContacts.filter((otherContact) => { return contactCandidate.id !== otherContact.id })
            )

        } else {
            setOtherContacts(
                [...otherContacts, contactCandidate]
            )
        }
    }

    const handleConfirmContactRelations = () => {
        updateContactRelationships({
            variables: {
                mainContactId: props.contact.id,
                otherContactIds: otherContacts.map((otherContact) => otherContact.id),
            }
        })
    }

    // Debounce effect for filtering
    useEffect(() => {
        const handler = setTimeout(() => {
            setFilter(filterInput); // Met à jour le filtre après un délai
        }, 500);

        // Nettoie le timeout si l'utilisateur tape avant la fin du délai
        return () => {
            clearTimeout(handler);
        };
    }, [filterInput]);

    const handleFilterInput = (newFilterInput: string) => {
        setFilterInput(newFilterInput)
    }

    return (
        <div className="ModalNewContactRelationship">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary" sx={{
                    pb: 0,
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Avatar alt={`${props.contact.firstname} ${props.contact.lastname}`}
                            src={`${props.contact.contactAvatarPreviewUrl}`}
                            sx={{
                                width: 36,
                                height: 36,
                                display: "flex",
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: theme.palette.primary.main,
                                mr: 3,
                            }} />
                        <Typography sx={{ fontSize: 24 }}>
                            Connaissances
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Flexbox sx={{
                        // bgcolor: "yellow",
                        justifyContent: "flex-end",
                    }}>
                        <FormControl sx={{
                            pb: 2,
                            my: 0,
                            mr: 1,
                            width: "200px",
                        }} variant="standard">
                            <InputLabel >Filtrer...</InputLabel>
                            <Input
                                type='text'
                                onChange={(event) => { handleFilterInput(event.target.value) }}
                                value={filterInput}
                                endAdornment={
                                    filterInput.length > 0 && <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => { handleFilterInput("") }}
                                        >
                                            <ClearIcon fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Flexbox>
                    {
                        contacts === undefined
                            ?
                            (
                                <Spinner classes="big-spinner" />
                            )
                            :
                            (
                                <Flexbox direction='column' sx={{
                                    height: '300px', // Forcer la hauteur du contenant
                                    overflowY: 'auto', // Ajouter l'ascenseur vertical si le contenu dépasse
                                }}>
                                    <Grid container sx={{
                                        width: "100%",
                                    }}>
                                        {

                                            filteredContacts?.filter((filteredContact) => { return filteredContact.id !== props.contact.id }).map((filteredContact, filteredContactIndex) => {
                                                return (
                                                    <Grid
                                                        key={`filteredContactRelation-${filteredContact.id}`}
                                                        sx={{

                                                            borderLeftStyle: {
                                                                xs: "none",
                                                                md: filteredContactIndex % 2 === 0 ? "inherit" : "solid",
                                                            },
                                                            borderLeftWidth: 1,
                                                            borderLeftColor: theme.palette.cancel.main,
                                                            pl: 2,
                                                        }}
                                                        size={{ xs: 12, md: 6 }}
                                                    >
                                                        <Flexbox sx={{
                                                            justifyContent: "space-between",
                                                            py: 1,
                                                        }}>

                                                            <ContactBadge contact={filteredContact} size={EnumSize.small} displayContactTitle={true} />
                                                            <Switch
                                                                checked={isContactRelation(filteredContact)}
                                                                onChange={() => handleSetContactRelation(filteredContact)}
                                                                color="desktop"
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        </Flexbox>
                                                    </Grid>
                                                )
                                            })
                                        }

                                    </Grid>

                                </Flexbox>
                            )
                    }
                    {
                        contacts !== undefined && contacts.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun contact.
                            </Typography>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                    <Button onClick={handleConfirmContactRelations} variant="contained" color="primary">Enregistrer</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
