import * as React from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router"
import { NAVIGATE_PUBLIK, NAVIGATE_PUBLIK_DOCUMENT, NAVIGATE_ROOT } from "../utils/Navigation"
import { useDispatch, useSelector } from "react-redux";
import { setJwt } from "../redux/features/global/apolloSlice";
import { IRootState } from "../redux/store"
import { useMutation } from "@apollo/client"
import { GQL_MUTATION_LOGIN, GQL_MUTATION_SEND_FORGOTTEN_PASSWORD_MAIL } from "../graphql/Mutations";
import { Messages } from "../components/messages/Messages";
import { addMessage } from "../components/messages/Message";
import { EnumMessageType, EnumMetadocLogoMode } from "../enums";
import { MetadocLogo } from '../components/MetadocLogo';
import { Copyright } from '../components/utils/Copyright';
import { useTheme } from '@mui/material';



export default function Page404() {
    const theme = useTheme()

    return (
        <div className="Page404">
            <Messages />
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        paddingTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <MetadocLogo mode={EnumMetadocLogoMode.accueil} link={true} />
                    <Typography component="h1" sx={{
                        color: theme.palette.primary.main,
                        mt: 4,
                        fontSize: 28,
                    }}>
                        404
                    </Typography>
                    <Typography component="h1" sx={{
                        color: theme.palette.primary.main,
                        mt: 4,
                        fontSize: 24,
                    }}>
                        Désolé, cette page est introuvable.
                    </Typography>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
    );
}