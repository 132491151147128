import { FC } from 'react';
import { Layout } from "./Layout";
import { ListPasswords } from '../components/passwords/ListPasswords';

interface IProps {
}

export const PasswordsPage: FC<IProps> = (props) => {
  return (
    <div className="PasswordsPage">
      <Layout>
        <div className="page-body">
          <ListPasswords />
        </div>
      </Layout>
    </div >
  )
}
