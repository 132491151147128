import { Divider, IconButton, Paper, Switch, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import "yet-another-react-lightbox/styles.css";
import { IAccountOperation } from '../../../interfaces';
import { Flexbox } from '../../utils/Flexbox';
import { EnumAccountOperationColorTarget, EnumAccountOperationType, EnumCustomContactMode, EnumDayjsFormat, EnumMessageType, EnumSize } from '../../../enums';
import { AccountOperationIcon } from '../../svg_icons/AccountOperationIcon';
import { dateIsInPeriod, formatFancyCurrency } from '../../../utils/Utils';
import Grid from '@mui/material/Grid2';
import { CustomContactBadge } from '../../customContacts/CustomContactBadge';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';

import { AccountOperationState } from './AccountOperationState';
import { addMessage } from '../../messages/Message';
import { setAllRefetchNeeded } from '../../../redux/store';
import { ModalDeleteConfirm } from '../../modals/ModalDeleteConfirm';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { GQL_MUTATION_DELETE_ACCOUNT_OPERATION, GQL_MUTATION_SET_ACCOUNT_OPERATION_DUPLICATION } from '../../../graphql/Mutations';
import { useMutation } from '@apollo/client';
import { ModalEditAccountOperation } from './modals/ModalEditAccountOperation';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { ModalNewAccountOperation } from './modals/ModalNewAccountOperation';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useDispatch } from 'react-redux';
import { toggleTemporaryStepAsideAccountOperation } from '../../../redux/features/cashflow/bankAccountSlice';

interface IProps {
    accountOperation: IAccountOperation,
    dateFrom: Dayjs,
    dateTo: Dayjs,
}

export const AccountOperation: FC<IProps> = (props) => {
    const [showDeleteAccountOperationDialog, setShowDeleteAccountOperationDialog] = useState(false)
    const [deleteAccountOperation, { data: deleteAccountOperationData, loading: deleteAccountOperationLoading, error: deleteAccountOperationError }] = useMutation(GQL_MUTATION_DELETE_ACCOUNT_OPERATION)
    const [showModalEditAccountOperation, setShowModalEditAccountOperation] = useState(false)
    const [showModalNewAccountOperationByCopy, setShowModalNewAccountOperationByCopy] = useState(false)
    const [setAccountOperationDuplication, { data: setAccountOperationDuplicationData, loading: setAccountOperationDuplicationLoading, error: setAccountOperationDuplicationError }] = useMutation(GQL_MUTATION_SET_ACCOUNT_OPERATION_DUPLICATION)


    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        if (deleteAccountOperationError) {
            addMessage({
                location: "AccountOperation",
                type: EnumMessageType.Error,
                message: deleteAccountOperationError.message,
            })
        } else if (deleteAccountOperationData) {
            if (deleteAccountOperationData.deleteAccountOperation.statusCode === 200) {
                setAllRefetchNeeded("AccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opération supprimée.",
                })
            } else {
                deleteAccountOperationData.deleteAccountOperation.errors.map((error: string) => {
                    addMessage({
                        location: "AccountOperation",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteAccountOperationData, deleteAccountOperationError])

    useEffect(() => {
        if (setAccountOperationDuplicationError) {
            addMessage({
                location: "AccountOperation",
                type: EnumMessageType.Error,
                message: setAccountOperationDuplicationError.message,
            })
        } else if (setAccountOperationDuplicationData) {
            if (setAccountOperationDuplicationData.setAccountOperationDuplication.statusCode === 200) {
                setAllRefetchNeeded("AccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Paramétrage de duplication effectué.",
                })
            } else {
                setAccountOperationDuplicationData.setAccountOperationDuplication.errors.map((error: string) => {
                    addMessage({
                        location: "AccountOperation",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setAccountOperationDuplicationData, setAccountOperationDuplicationError])

    const confirmedDeleteAccountOperation = () => {
        deleteAccountOperation({
            variables: {
                accountOperationId: props.accountOperation.id
            }
        })
        setShowDeleteAccountOperationDialog(false)
    }

    const handleDelete = () => {
        setShowDeleteAccountOperationDialog(true)
    }

    const closeDeleteAccountOperationDialog = () => {
        setShowDeleteAccountOperationDialog(false)
    }

    const handleEdit = () => {
        setShowModalEditAccountOperation(true)
    }

    const closeModalEditAccountOperation = () => {
        setShowModalEditAccountOperation(false)
    }

    const handleCopy = () => {
        setShowModalNewAccountOperationByCopy(true)
    }

    const closeModalNewAccountOperationByCopy = () => {
        setShowModalNewAccountOperationByCopy(false)
    }

    const getAccountOperationColor = (target: EnumAccountOperationColorTarget) => {
        if (!props.accountOperation.imputable) {
            return theme.palette.cancel.main
        }
        if (props.accountOperation.accountOperationType === EnumAccountOperationType.credit) {
            return target === EnumAccountOperationColorTarget.title ? theme.palette.text.primary : theme.palette.credit.main
        }
        return target === EnumAccountOperationColorTarget.title ? theme.palette.text.primary : theme.palette.debit.main
    }

    const getDuplicationTooltipTitle = () => {
        if (props.accountOperation.toDuplicate) {
            return "Se duplique automatiquement le mois prochain"
        }
        return "Ne se duplique pas le mois prochain"
    }

    const getStepAsideTooltipTitle = () => {
        if (props.accountOperation.temporaryStepAside) {
            return "Reconsidérer l'opération"
        }
        return "Ne pas considérer l'opération temporairement"
    }


    const handleToDuplicate = () => {
        setAccountOperationDuplication({
            variables: {
                accountOperationId: props.accountOperation.id,
                toDuplicate: !props.accountOperation.toDuplicate,
            }
        })
    }

    const handleTemporaryStepAside = () => {
        const payload = props.accountOperation.id
        dispatch(toggleTemporaryStepAsideAccountOperation(payload))
    }

    const isNotConsidered = () => {
        return (
            !props.accountOperation.imputable ||
            props.accountOperation.temporaryStepAside === true ||
            (props.accountOperation.paidAt && !dateIsInPeriod(dayjs(props.accountOperation.paidAt), props.dateFrom, props.dateTo))
        )
    }

    const isInFuture = () => {
        return dayjs(props.accountOperation.dateOperation).isAfter(dayjs())
    }


    return (
        <>
            <Paper id={props.accountOperation.id} sx={{
                pt: 1,
                pb: 1,
                px: 1,
                mx: 1,
                my: 1,
                width: "100%",
                background: isNotConsidered() ? `repeating-linear-gradient(
                    -45deg,
                    ${theme.palette.grey[200]},
                    ${theme.palette.grey[200]} 10px,
                    ${theme.palette.grey[300]} 10px,
                    ${theme.palette.grey[300]} 20px
                )` : isInFuture() ? `repeating-linear-gradient(
                    45deg,
                    ${theme.palette.desktop.light},
                    ${theme.palette.desktop.light} 10px,
                    ${theme.palette.grey[200]} 10px,
                    ${theme.palette.grey[200]} 20px
                )` : "inherit",
            }}>
                <Grid container>
                    <Grid size={{ xs: 12, md: 4.5 }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            // bgcolor: "green",
                            height: "100%",
                        }}>
                            {/* <AccountOperationIcon color={getAccountOperationColor()} sx={{ fontSize: 24, mr: 1 }} /> */}
                            <AccountOperationIcon sx={{
                                fontSize: 24,
                                mr: 1,
                                color: getAccountOperationColor(EnumAccountOperationColorTarget.default),
                            }} />
                            <Typography sx={{
                                fontSize: 18,
                                color: getAccountOperationColor(EnumAccountOperationColorTarget.title),
                            }}>
                                {props.accountOperation.title}
                            </Typography>
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            pl: 2,
                            // bgcolor: "orange",
                            height: "100%",
                        }}>
                            {
                                props.accountOperation.customContact &&
                                <>
                                    <CustomContactBadge
                                        mode={EnumCustomContactMode.standard}
                                        showContactAvatar={true}
                                        size={EnumSize.small}
                                        customContact={props.accountOperation.customContact}
                                    />
                                </>
                            }
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            // bgcolor: "red",
                            height: "100%",
                        }}>
                            <AccountOperationState accountOperation={props.accountOperation} />
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 1.5 }}>
                        <Flexbox sx={{
                            // bgcolor: "red",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            height: "100%",
                        }}>
                            {
                                isNotConsidered() && <>
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Non considérée dans le calcul des totaux">
                                        <ReportGmailerrorredIcon sx={{
                                            pr: 1,
                                            fontSize: 16,
                                            color: theme.palette.secondary.main,
                                        }} />
                                    </Tooltip>
                                </>
                            }
                            <Typography sx={{
                                color: getAccountOperationColor(EnumAccountOperationColorTarget.default),
                            }}>
                                {formatFancyCurrency(parseFloat(props.accountOperation.amount), props.accountOperation.accountOperationType)}
                            </Typography>

                        </Flexbox>
                    </Grid>
                </Grid>
                <Divider flexItem sx={{ mt: 1 }} />
                <Grid container>
                    <Grid size={{ xs: 12, md: 1 }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // bgcolor: "red",
                        }}>
                            <Typography sx={{
                                fontSize: 14,
                                color: dateIsInPeriod(dayjs(props.accountOperation.dateOperation), props.dateFrom, props.dateTo) ? theme.palette.text.secondary : theme.palette.secondary.main,
                                // pl: 1,
                                pt: 1,
                            }}>
                                {dayjs(props.accountOperation.dateOperation).locale('fr').format(EnumDayjsFormat.date)}
                            </Typography>
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 7.5 }}>
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            // bgcolor: "red",
                            widows: "100%",
                        }}>
                            <Typography sx={{
                                fontSize: 14,
                                color: theme.palette.text.secondary,
                                pl: 1,
                                pt: 1,
                            }}>
                                <span dangerouslySetInnerHTML={{ __html: props.accountOperation.description.replace(/\n/g, '<br />') }} />
                            </Typography>
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <Flexbox direction='column' sx={{
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                            // bgcolor: "red",
                            // height: "100%",
                        }}>
                            {
                                props.accountOperation.paidAt && <>
                                    <Flexbox sx={{
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}>
                                        <Flexbox>
                                            {
                                                !dateIsInPeriod(dayjs(props.accountOperation.paidAt), props.dateFrom, props.dateTo) && <>
                                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Non considérée dans le calcul des totaux">
                                                        <ReportGmailerrorredIcon sx={{
                                                            pt: 1,
                                                            pr: 1,
                                                            fontSize: 16,
                                                            color: theme.palette.secondary.main,
                                                        }} />
                                                    </Tooltip>
                                                </>
                                            }
                                            <Typography sx={{
                                                fontSize: 14,
                                                color: dateIsInPeriod(dayjs(props.accountOperation.paidAt), props.dateFrom, props.dateTo) ? theme.palette.text.secondary : theme.palette.secondary.main,
                                                pt: 1,
                                            }}>
                                                Payé le :
                                            </Typography>

                                        </Flexbox>
                                        <Typography sx={{
                                            fontSize: 14,
                                            color: dateIsInPeriod(dayjs(props.accountOperation.paidAt), props.dateFrom, props.dateTo) ? theme.palette.text.secondary : theme.palette.secondary.main,
                                            pt: 1,
                                        }}>
                                            {dayjs(props.accountOperation.paidAt).locale('fr').format(EnumDayjsFormat.date)}
                                        </Typography>
                                    </Flexbox>
                                </>
                            }
                            {
                                props.accountOperation.reference && <>
                                    <Flexbox sx={{
                                        justifyContent: "space-between",
                                        width: "100%",
                                    }}>
                                        <Typography sx={{
                                            fontSize: 14,
                                            color: theme.palette.text.secondary,
                                            pt: 1,
                                        }}>
                                            Référence :
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: 14,
                                            color: theme.palette.text.secondary,
                                            pt: 1,
                                        }}>
                                            {props.accountOperation.reference}
                                        </Typography>

                                    </Flexbox>
                                </>
                            }
                        </Flexbox>
                    </Grid>
                    <Grid size={{ xs: 12, md: 1.5 }}>
                        <Flexbox direction='column' sx={{
                            // bgcolor: "yellow",
                        }}>
                            <Flexbox sx={{
                                width: "100%",
                                // bgcolor: "red",
                                justifyContent: "flex-end",
                                alignItems: "flex-start",
                                // height: "100%",
                            }}>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Nouvelle opération par copie">
                                    <IconButton onClick={handleCopy} color="primary">
                                        <FileCopyOutlinedIcon sx={{
                                            fontSize: 16,
                                        }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                    <IconButton onClick={handleEdit} color="primary">
                                        <EditOutlined sx={{
                                            fontSize: 18,
                                        }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                    <IconButton onClick={handleDelete} color="error">
                                        <DeleteOutline sx={{
                                            fontSize: 18,
                                        }} />
                                    </IconButton>
                                </Tooltip>
                            </Flexbox>
                            <Flexbox sx={{
                                width: "100%",
                                // bgcolor: "green",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                // height: "100%",
                            }}>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={getStepAsideTooltipTitle()}>
                                    <IconButton onClick={handleTemporaryStepAside} color={props.accountOperation.temporaryStepAside ? "secondary" : "primary"}>
                                        <DoDisturbIcon sx={{
                                            fontSize: 18,
                                        }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={getDuplicationTooltipTitle()}>
                                    <AutorenewIcon sx={{
                                        color: theme.palette.primary.main,
                                        fontSize: 18,
                                    }} />
                                </Tooltip>
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title={getDuplicationTooltipTitle()}>
                                    <Switch
                                        checked={props.accountOperation.toDuplicate}
                                        onChange={() => handleToDuplicate()}
                                        color="desktop"
                                        // inputProps={{ 'aria-label': 'controlled' }}
                                        size='small'
                                    />
                                </Tooltip>
                            </Flexbox>

                        </Flexbox>
                    </Grid>
                </Grid>
            </Paper>
            <ModalEditAccountOperation
                open={showModalEditAccountOperation}
                handleClose={closeModalEditAccountOperation}
                accountOperation={props.accountOperation}
            />
            <ModalDeleteConfirm
                open={showDeleteAccountOperationDialog}
                title="Suppression de l'opération"
                content={`Confirmez-vous la suppression définitive de l'opération ${props.accountOperation.title} ?`}
                handleClose={closeDeleteAccountOperationDialog}
                handleConfirm={confirmedDeleteAccountOperation}
            />
            <ModalNewAccountOperation
                bankAccountId={props.accountOperation.bankAccountId}
                accountOperation={props.accountOperation}
                open={showModalNewAccountOperationByCopy}
                handleClose={closeModalNewAccountOperationByCopy}
            />
        </>
    )
}
