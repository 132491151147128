import { FC } from 'react';
import { Button, IconButton, SxProps, Typography, useTheme } from "@mui/material";
import { EnumMessageType } from '../../enums';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { addMessage } from '../messages/Message';

interface IProps {
    text: string,
    message: string,
    sx?: SxProps,
    label?: string,
}

export const CopyToClipboard: FC<IProps> = (props) => {

    const theme = useTheme()

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(props.text);
            addMessage({
                type: EnumMessageType.Success,
                message: props.message,
            })
        } catch (err) {
            console.error('Unable to copy text to clipboard:', err);
            addMessage({
                type: EnumMessageType.Warning,
                message: "Impossible de copier dans le presse-papier. Ne fonctionne qu'avec le https.",
            })
        }
    };

    return (
        <>
            {
                props.label
                    ?
                    <>
                        <Button
                            variant="text"
                            startIcon={<ContentPasteIcon />}
                            onClick={handleCopy}
                            sx={{
                                color: theme.palette.primary.main,
                                ...props.sx,
                            }}>
                            {props.label}
                        </Button>
                    </>
                    :
                    <>
                        <IconButton size='small' onClick={handleCopy} sx={{
                            color: theme.palette.primary.main,
                            ...props.sx,
                        }}>
                            <ContentPasteIcon fontSize='small' />
                        </IconButton>
                    </>
            }
        </>
    )
}


