import { Box, Divider, IconButton, Link, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { IPassword } from "../../interfaces";
import {
    DeleteOutline, EditOutlined
} from '@mui/icons-material';

import { truncateString } from "../../utils/Utils";
import { KeysIcon } from "../svg_icons/KeysIcon";
import { Flexbox } from "../utils/Flexbox";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ModalSeePassword } from "./modals/ModalSeePassword";
import { ModalDeleteConfirm } from '../modals/ModalDeleteConfirm';
import { useMutation } from '@apollo/client';
import { addMessage } from '../messages/Message';
import { EnumMessageType } from '../../enums';
import { setAllRefetchNeeded } from '../../redux/store';
import { GQL_MUTATION_DELETE_PASSWORD } from '../../graphql/Mutations';
import { ModalEditPassword } from './modals/ModalEditPassword';

interface IProps {
    password: IPassword,
}

export const Password: FC<IProps> = (props) => {
    const [showDeletePasswordDialog, setShowDeletePasswordDialog] = useState(false)
    const [showModalEditPassword, setShowModalEditPassword] = useState(false)
    const [showModalSeePassword, setShowModalSeePassword] = useState(false)
    const [deletePassword, { data: deletePasswordData, loading: deletePasswordLoading, error: deletePasswordError }] = useMutation(GQL_MUTATION_DELETE_PASSWORD)
    const [faviconUrl, setFaviconUrl] = useState<string | null>(null);

    const theme = useTheme()

    useEffect(() => {
        if (deletePasswordError) {
            addMessage({
                location: "Password",
                type: EnumMessageType.Error,
                message: deletePasswordError.message,
            })
        } else if (deletePasswordData) {
            if (deletePasswordData.deletePassword.statusCode === 200) {
                setAllRefetchNeeded("Password")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Password supprimé.",
                })
            } else {
                deletePasswordData.deletePassword.errors.map((error: string) => {
                    addMessage({
                        location: "Password",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deletePasswordData, deletePasswordError])

    useEffect(() => {
        const fetchFavicon = async () => {
            if (props.password.url) {
                try {
                    let url = props.password.url;
                    if (!url.startsWith('http')) {
                        url = `https://${url}`;
                    }
                    setFaviconUrl(`${url}/favicon.ico`);
                } catch (error) {
                    setFaviconUrl(null);
                }
            }
        };

        fetchFavicon();
    }, [props.password.url]);


    const handleEdit = () => {
        setShowModalEditPassword(true)
    }

    const handleSee = () => {
        setShowModalSeePassword(true)
    }

    const handleDelete = () => {
        setShowDeletePasswordDialog(true)
    }

    const closeDeletePasswordDialog = () => {
        setShowDeletePasswordDialog(false)
    }

    const confirmedDeletePassword = () => {
        deletePassword({
            variables: {
                passwordId: props.password.id
            }
        })
        setShowDeletePasswordDialog(false)
    }

    const closeModalEditPassword = () => {
        setShowModalEditPassword(false)
    }

    const closeModalSeePassword = () => {
        setShowModalSeePassword(false)
    }


    return (
        <>
            <Paper
                sx={{
                    marginTop: "24px",
                    marginBottom: "12px",
                    marginLeft: "12px",
                    marginRight: "12px",
                    width: "100%",
                }}>
                <Box sx={{
                    paddingTop: "12px",
                    paddingBottom: "6px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                }}>
                    <Flexbox sx={{
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}>
                        <Box sx={{
                            paddingTop: "16px",
                            paddingBottom: "8px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            marginTop: "-32px",
                            marginLeft: "-24px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            borderLeft: "1px solid rgb(0 0 0 / 12%)",
                        }}>
                            {faviconUrl ? (
                                <Box component="img"
                                    src={faviconUrl}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        mx: .5,
                                    }}
                                    onError={() => setFaviconUrl(null)}
                                />
                            ) : (
                                <KeysIcon color="secondary" sx={{ fontSize: 24, mx: .5 }} />
                            )}
                        </Box>
                        <Box sx={{
                            width: "100%",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            paddingBottom: "4px",
                        }}>
                            <Typography
                                sx={{
                                    color: theme.palette.text.primary,
                                    fontSize: 24,
                                }}
                            >
                                {props.password.title}
                            </Typography>
                            <Link href={props.password.url} target="_blank"
                                sx={{
                                    color: theme.palette.info.main,
                                    fontSize: 16,
                                }}
                            >
                                {props.password.url}
                            </Link>
                            <Typography >
                                {truncateString(props.password.description, 80)}
                            </Typography>
                        </Box>
                    </Flexbox>
                    <Box>
                        <Divider />
                        <Flexbox sx={{
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                        }}>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Modifier">
                                <IconButton onClick={handleEdit} color="primary">
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Voir">
                                <IconButton onClick={handleSee} color="primary">
                                    <VisibilityOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Supprimer">
                                <IconButton onClick={handleDelete} color="error">
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        </Flexbox>
                    </Box>
                </Box>
            </Paper>
            <ModalSeePassword
                open={showModalSeePassword}
                handleClose={closeModalSeePassword}
                password={props.password}
            />
            <ModalDeleteConfirm
                open={showDeletePasswordDialog}
                title="Suppression du password"
                content={`Confirmez-vous la suppression définitive du password ${props.password.title} ?`}
                handleClose={closeDeletePasswordDialog}
                handleConfirm={confirmedDeletePassword}
            />
            <ModalEditPassword
                open={showModalEditPassword}
                handleClose={closeModalEditPassword}
                password={props.password}
            />
        </>
    )
}
