import { useMutation } from '@apollo/client';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { IAccountOperation, IBankAccount, ICustomContact } from '../../../../interfaces';
import { EnumAccountOperationType, EnumCustomContactMode, EnumDayjsFormat, EnumMessageType, EnumSize } from '../../../../enums';
import { addMessage } from '../../../messages/Message';
import { setAllRefetchNeeded } from '../../../../redux/store';
import { AccountOperationIcon } from '../../../svg_icons/AccountOperationIcon';
import { GQL_MUTATION_CREATE_ACCOUNT_OPERATION } from '../../../../graphql/Mutations';
import { Flexbox } from '../../../utils/Flexbox';
import { CustomContactSelection } from '../../../customContacts/CustomContactSelection';
import { CustomContactBadge } from '../../../customContacts/CustomContactBadge';
import { DateSelector } from '../../../utils/DateSelector';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
    bankAccountId: IBankAccount["id"];
    accountOperation?: IAccountOperation;
    open: boolean;
    handleClose: () => void;
}

export const ModalNewAccountOperation: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [description, setDescription] = useState("")
    const amountRef = useRef<HTMLInputElement>(null);
    const [amount, setAmount] = useState<string>("")
    const [errorOnAmount, setErrorOnAmount] = useState(false)
    const [accountOperationType, setAccountOperationType] = useState(EnumAccountOperationType.debit)
    // const [counterparty, setCounterparty] = useState<ICounterparty | undefined>()
    const [customContact, setCustomContact] = useState<ICustomContact | undefined>()
    const [dateOperation, setDateOperation] = useState<Dayjs>(dayjs())
    // const [showContactList, setShowContactList] = useState(false)
    // const [showCustomContactList, setShowCustomContactList] = useState(false)
    const [reference, setReference] = useState("")
    const [imputable, setImputable] = useState(true)
    const [consolidateImmediately, setConsolidateImmediately] = useState(false)

    const [createAccountOperation, { data: createAccountOperationData, loading: createAccountOperationLoading, error: createAccountOperationError }] = useMutation(GQL_MUTATION_CREATE_ACCOUNT_OPERATION)

    const theme = useTheme()

    // Dans le cas d'une nouvelle opération par copie, on pré-remplit les champs:
    useEffect(() => {
        if (props.accountOperation) {
            setTitle(props.accountOperation.title)
            setAccountOperationType(props.accountOperation.accountOperationType)
            setAmount(props.accountOperation.amount)
            setDescription(props.accountOperation.description)
            // setCounterparty(props.accountOperation.counterparty)
            setCustomContact(props.accountOperation.customContact)
            setImputable(props.accountOperation.imputable)
        }
    }, [props.accountOperation])

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (amountRef.current) {
                    amountRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createAccountOperationError) {
            addMessage({
                location: "ModalNewAccountOperation",
                type: EnumMessageType.Error,
                message: createAccountOperationError.message,
            })
        } else if (createAccountOperationData) {
            if (createAccountOperationData.createAccountOperation.statusCode === 200) {
                setAllRefetchNeeded("ModalNewAccountOperation")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Opération ajoutée.",
                })
                handleClose()
            } else {
                createAccountOperationData.createAccountOperation.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createAccountOperationData, createAccountOperationError])

    const checkErrors = () => {
        let res = true
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            res = false
        }
        if (amount === undefined || amount === "") {
            setErrorOnAmount(true)
            addMessage({
                type: EnumMessageType.Error,
                message: "Le montant de l'opération est obligatoire.",
            })
            res = false
        }
        return res
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setAccountOperationType(EnumAccountOperationType.debit)
        // setAmount("0")
        setAmount("")
        setErrorOnAmount(false)
        setDescription("")
        // setCounterparty(undefined)
        setCustomContact(undefined)
        setDateOperation(dayjs())
        setReference("")
        setImputable(true)
        setConsolidateImmediately(false)
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createAccountOperation({
                variables: {
                    bankAccountId: props.bankAccountId,
                    title,
                    accountOperationType,
                    amount: parseFloat(amount),
                    description,
                    // counterpartyId: counterparty?.counterpartyType === EnumCounterpartyType.contact ? counterparty.contact?.id : counterparty?.customContact?.id,
                    // counterpartyType: counterparty?.counterpartyType,
                    customContactId: customContact?.id,
                    dateOperation: dateOperation?.format(EnumDayjsFormat.dateForBackend),
                    reference,
                    imputable,
                    consolidateImmediately,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    // const handleShowContactList = () => {
    //     if (showContactList) {
    //         setShowContactList(false)
    //     } else {
    //         setShowCustomContactList(false)
    //         setShowContactList(true)
    //     }
    // }

    // const handleShowCustomContactList = () => {
    //     if (showCustomContactList) {
    //         setShowCustomContactList(false)
    //     } else {
    //         setShowContactList(false)
    //         setShowCustomContactList(true)
    //     }
    // }

    // const handleSelectContact = (contact: IContactMinimal) => {
    //     setCounterparty({
    //         counterpartyType: EnumCounterpartyType.contact,
    //         contact: contact,
    //         customContact: undefined,
    //     })
    //     setShowContactList(false)
    // }

    const handleSelectCustomContact = (customContact: ICustomContact) => {
        // setCounterparty({
        //     counterpartyType: EnumCounterpartyType.customContact,
        //     contact: undefined,
        //     customContact: customContact,
        // })
        // setShowCustomContactList(false)
        setCustomContact(customContact)
    }

    const handleDateOperation = (pDate: Dayjs) => {
        setDateOperation(pDate)
    }

    const removeCounterparty = () => {
        // setCounterparty(undefined)
        setCustomContact(undefined)
    }

    const getAccountOperationColor = () => {
        if (!imputable) {
            return theme.palette.cancel.main
        }
        if (accountOperationType === EnumAccountOperationType.credit) {
            return theme.palette.credit.main
        }
        return theme.palette.debit.main
    }

    return (
        <div className="ModalNewAccountOperation">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            {/* <AccountOperationIcon color={accountOperationType} sx={{ fontSize: 24, mr: "12px" }} /> */}
                            <AccountOperationIcon sx={{
                                fontSize: 24,
                                mr: "12px",
                                color: getAccountOperationColor(),
                            }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouvelle opération
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                    }}>
                        <FormControl variant="outlined" sx={{
                            mt: 2,
                        }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Montant de l'opération</InputLabel>
                            <OutlinedInput
                                inputRef={amountRef}
                                autoFocus
                                id="outlined-adornment-amount"
                                type='number'
                                endAdornment={
                                    <InputAdornment position="end">
                                        €
                                    </InputAdornment>
                                }
                                label="Montant de l'opération"
                                color="primary"
                                onChange={(event) => { setAmount(event.currentTarget.value) }}
                                value={amount}
                                autoComplete='off'
                                error={errorOnAmount}
                                sx={{
                                    width: "264px",
                                }} />
                        </FormControl>

                        <Flexbox sx={{
                            flexWrap: "wrap",
                            justifyContent: "flex-start",
                            gap: 4,
                        }}>
                            <Flexbox >
                                <Radio
                                    checked={accountOperationType === EnumAccountOperationType.debit}
                                    onChange={() => { setAccountOperationType(EnumAccountOperationType.debit) }}
                                    value={EnumAccountOperationType.debit}
                                />
                                <AccountOperationIcon color={`${EnumAccountOperationType.debit}`} sx={{ fontSize: 24, mr: 1 }} />
                                <Typography>
                                    Débit
                                </Typography>
                            </Flexbox>
                            <Flexbox >
                                <Radio
                                    checked={accountOperationType === EnumAccountOperationType.credit}
                                    onChange={() => { setAccountOperationType(EnumAccountOperationType.credit) }}
                                    value={EnumAccountOperationType.credit}
                                />
                                <AccountOperationIcon color={`${EnumAccountOperationType.credit}`} sx={{ fontSize: 24, mr: 1 }} />
                                <Typography>
                                    Crédit
                                </Typography>
                            </Flexbox>
                        </Flexbox>
                        <TextField
                            margin="dense"
                            label="Nom de l'opération"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <Flexbox sx={{
                            justifyContent: "flex-start",
                            width: "100%",
                            mt: 2,
                        }}>
                            <Typography sx={{
                                fontSize: 16,
                                color: theme.palette.text.secondary,
                                width: "100%",
                            }}>
                                Date
                            </Typography>
                            <DateSelector
                                date={dateOperation}
                                title="Date de l'opération"
                                disableFuture={false}
                                handleDate={handleDateOperation}
                                // resetDate={resetDateOperation}
                                showArrows={true}
                                displayDateOnLeft={true}
                            />
                        </Flexbox>

                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Passer l'opération directement en consolidé">
                            <FormControlLabel control={
                                <Checkbox
                                    checked={consolidateImmediately}
                                    value={consolidateImmediately}
                                    onChange={(event) => setConsolidateImmediately(event.target.checked)}
                                />
                            } label="Consolidation immédiate" />
                        </Tooltip>
                        <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Prise en compte dans le calcul de certains soldes">
                            <FormControlLabel control={
                                <Checkbox
                                    checked={imputable}
                                    value={imputable}
                                    onChange={(event) => setImputable(event.target.checked)}
                                />
                            } label="Imputable" />
                        </Tooltip>
                        <TextField
                            margin="dense"
                            label="Référence"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setReference(event.target.value) }}
                            value={reference}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                        <Divider flexItem sx={{
                            mt: 2,
                        }}>
                            {accountOperationType === EnumAccountOperationType.credit ? `Payeur` : `Bénéficiaire`}
                        </Divider>
                        <Flexbox sx={{
                            my: 1,
                            width: "100%",
                        }}>
                            {/* {
                                counterparty &&
                                counterparty.counterpartyType === EnumCounterpartyType.contact &&
                                counterparty.contact &&
                                <Flexbox>
                                    <ContactBadge displayContactTitle={true} size={EnumSize.small} contact={counterparty.contact} />
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer le Contact">
                                        <IconButton color="error" onClick={removeCounterparty}><ClearIcon fontSize="small" /></IconButton>
                                    </Tooltip>
                                </Flexbox>
                            } */}
                            {
                                customContact &&
                                <Flexbox>
                                    <CustomContactBadge
                                        mode={EnumCustomContactMode.selection}
                                        showContactAvatar={true}
                                        size={EnumSize.small}
                                        customContact={customContact} />
                                    <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer le Payeur / Bénéficiaire">
                                        <IconButton color="error" onClick={removeCounterparty}><ClearIcon fontSize="small" /></IconButton>
                                    </Tooltip>
                                </Flexbox>
                            }
                        </Flexbox>
                        {/* <Flexbox sx={{
                            // bgcolor: "lightgray",
                            // backgroundColor: theme.palette.background.default,
                            width: "100%",
                        }}>
                            <Typography>
                                <Button onClick={handleShowContactList} sx={{
                                    color: showContactList ? theme.palette.text.primary : theme.palette.secondary.main,
                                    backgroundColor: showContactList ? theme.palette.background.default : "inherit",
                                }}>Contact</Button>
                                &nbsp; ou &nbsp;
                                <Button onClick={handleShowCustomContactList} sx={{
                                    color: showCustomContactList ? theme.palette.text.primary : theme.palette.secondary.main,
                                    backgroundColor: showCustomContactList ? theme.palette.background.default : "inherit",
                                }}>Payeur / Bénéficiaire</Button>
                            </Typography>
                        </Flexbox> */}
                        {/* <Flexbox sx={{
                            display: showContactList ? "flex" : "none",
                            backgroundColor: theme.palette.background.default,
                            width: "100%",
                        }}>
                            <ContactSelection
                                handleSelect={(contact) => handleSelectContact(contact)}
                                mandatoryContactConditions={[EnumMandatoryContactConditions.isBusiness]}
                                heightSize={EnumSize.tiny}
                            />
                        </Flexbox> */}
                        <Flexbox sx={{
                            // display: showCustomContactList ? "flex" : "none",
                            backgroundColor: theme.palette.background.default,
                            width: "100%",
                        }}>
                            <CustomContactSelection
                                handleSelect={(customContact) => handleSelectCustomContact(customContact)}
                                heightSize={EnumSize.tiny}
                            />
                        </Flexbox>
                        {/* <Divider flexItem sx={{
                            mt: 2,
                        }} /> */}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
