import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { IZipPrototype } from '../../../interfaces';
import { Flexbox } from '../../utils/Flexbox';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import { EnumListLockersMode } from '../../../enums';
import { ZipIcon } from '../../svg_icons/ZipIcon';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (zipPrototype: IZipPrototype) => void;
    mode: EnumListLockersMode;
}

export const ModalNewZip: FC<IProps> = (props) => {
    const [title, setTitle] = useState("")
    const [errorOnTitle, setErrorOnTitle] = useState(false)
    const [description, setDescription] = useState("")
    const titleRef = useRef<HTMLInputElement>(null);

    const theme = useTheme()


    const checkErrors = () => {
        if (title === undefined || title === "") {
            setErrorOnTitle(true)
            return false
        }
        return true
    }

    const cleanDatas = () => {
        setTitle("")
        setErrorOnTitle(false)
        setDescription("")
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            const stateToSend: IZipPrototype = {
                title,
                description,
            }
            props.handleConfirm(stateToSend)
            handleClose()
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalNewZip">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Flexbox sx={{ justifyContent: 'flex-start' }}>
                            <ZipIcon color={props.mode} sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouveau zip
                            </Typography>
                        </Flexbox>
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Les éléments sélectionnés seront inclus dans le zip.
                        </Typography>
                        <Typography sx={{ color: theme.palette.error.main }}>
                            Attention: Cette opération peut prendre plusieurs minutes de traitement.
                        </Typography>
                        <TextField
                            inputRef={titleRef}
                            autoFocus
                            margin="dense"
                            label="Nom du zip"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setTitle(event.target.value) }}
                            value={title}
                            required
                            error={errorOnTitle}
                            helperText={errorOnTitle ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="normal"
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            onChange={(event) => { setDescription(event.target.value) }}
                            value={description}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Constituer
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
