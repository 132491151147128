import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumMessageType } from '../../enums';
import { addMessage } from '../messages/Message';
import { setAllRefetchNeeded } from '../../redux/store';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { GQL_MUTATION_CREATE_USER } from '../../graphql/Mutations';

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export const ModalNewUser: FC<IProps> = (props) => {
    const [email, setEmail] = useState("")
    const [errorOnEmail, setErrorOnEmail] = useState(false)
    const [firstname, setFirstname] = useState("")
    const [errorOnFirstname, setErrorOnFirstname] = useState(false)
    const [lastname, setLastname] = useState("")
    const [errorOnLastname, setErrorOnLastname] = useState(false)

    const emailRef = useRef<HTMLInputElement>(null);
    const [createUser, { data: createUserData, loading: createUserLoading, error: createUserError }] = useMutation(GQL_MUTATION_CREATE_USER)
    const dispatch = useDispatch()

    useEffect(() => {
        // Le input n'est pas monté avant le test, donc on bricole avec un setTimeout..
        if (props.open) {
            setTimeout(() => {
                if (emailRef.current) {
                    emailRef.current.focus();
                }
            }, 0);
        }
    }, [props.open]);

    useEffect(() => {
        if (createUserError) {
            addMessage({
                location: "ModalNewUser",
                type: EnumMessageType.Error,
                message: createUserError.message,
            })
        } else if (createUserData) {
            if (createUserData.createUser.statusCode === 200) {
                setAllRefetchNeeded("ModalNewUser")
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Utilisateur invité.",
                })
                handleClose()
            } else {
                createUserData.createUser.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [createUserData, createUserError])

    const checkErrors = () => {
        let res = true
        if (email === undefined || email === "") {
            setErrorOnEmail(true)
            res = false
        } else {
            setErrorOnEmail(false)
        }
        if (firstname === undefined || firstname === "") {
            setErrorOnFirstname(true)
            res = false
        } else {
            setErrorOnFirstname(false)
        }
        if (lastname === undefined || lastname === "") {
            setErrorOnLastname(true)
            res = false
        } else {
            setErrorOnLastname(false)
        }
        return res
    }

    const cleanDatas = () => {
        setEmail("")
        setErrorOnEmail(false)
        setFirstname("")
        setErrorOnFirstname(false)
        setLastname("")
        setErrorOnLastname(false)
    }

    const handleConfirm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (checkErrors()) {
            createUser({
                variables: {
                    email,
                    firstname,
                    lastname,
                }
            })
        }
    }

    const handleClose = () => {
        cleanDatas()
        props.handleClose()
    }

    return (
        <div className="ModalNewUser">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <Box
                    noValidate
                    component="form"
                    onSubmit={handleConfirm}
                >
                    <DialogTitle color="primary">
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <PersonOutlineOutlinedIcon color="desktop" sx={{ fontSize: 24, mr: "12px" }} />
                            <Typography sx={{ fontSize: 24 }}>
                                Nouvel utilisateur
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            inputRef={emailRef}
                            autoFocus
                            margin="dense"
                            label="Email"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setEmail(event.target.value) }}
                            value={email}
                            required
                            error={errorOnEmail}
                            helperText={errorOnEmail ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="dense"
                            label="Prénom"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setFirstname(event.target.value) }}
                            value={firstname}
                            required
                            error={errorOnFirstname}
                            helperText={errorOnFirstname ? "Ce champ est obligatoire." : ""}
                        />
                        <TextField
                            margin="dense"
                            label="Nom de famille"
                            fullWidth
                            variant="standard"
                            onChange={(event) => { setLastname(event.target.value) }}
                            value={lastname}
                            required
                            error={errorOnLastname}
                            helperText={errorOnLastname ? "Ce champ est obligatoire." : ""}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="cancel">Annuler</Button>
                        <Button type="submit" variant="contained" color="primary">
                            Inviter
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </div >
    )
}
