import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EnumMessageType, EnumShareLevel, EnumSize } from '../../../enums';
import { IAlbum, IUser } from '../../../interfaces';
import { useMutation } from '@apollo/client';
import { addMessage } from '../../messages/Message';
import { GQL_MUTATION_CREATE_PARTNERSHIP, GQL_MUTATION_SHARE_ALBUM } from '../../../graphql/Mutations';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { UserBadge } from '../../UserBadge';
import { useGetCurrentUser, setCurrentUserRefetchNeeded, useGetPartners } from '../../../redux/features/users/currentUserSlice';
import { setAllRefetchNeeded } from '../../../redux/store';
import { updateAlbumAction } from '../../../redux/features/albums/albumsSlice';
import { updateAlbumAction as updateAlbumDetailsAction } from '../../../redux/features/albums/albumSlice';
import { Flexbox } from '../../utils/Flexbox';

interface IProps {
    open: boolean;
    handleClose: () => void;
    album: IAlbum;
}

export const ModalShareAlbum: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const partners = useGetPartners()
    // const [triggerSetAllRefetchNeededOnClose, setTriggerSetAllRefetchNeededOnClose] = useState(false)
    const [shareAlbum, { data: shareAlbumData, loading: shareAlbumLoading, error: shareAlbumError }] = useMutation(GQL_MUTATION_SHARE_ALBUM)
    const dispatch = useDispatch()

    useEffect(() => {
        if (shareAlbumError) {
            addMessage({
                location: "ModalShareAlbum",
                type: EnumMessageType.Error,
                message: shareAlbumError.message,
            })
        } else if (shareAlbumData) {
            if (shareAlbumData.shareAlbum.statusCode === 200) {
                const updatedAlbum: IAlbum = shareAlbumData.shareAlbum.album
                dispatch(updateAlbumAction(updatedAlbum))
                dispatch(updateAlbumDetailsAction(updatedAlbum))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Partage effectué.",
                })
            } else {
                shareAlbumData.shareAlbum.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [shareAlbumData, shareAlbumError])

    const handleClose = () => {
        // if (triggerSetAllRefetchNeededOnClose) {
        //     setAllRefetchNeeded("ModalShareAlbum")
        // }
        props.handleClose()
    }

    const handleShareAlbum = (user: IUser, shareLevel: EnumShareLevel) => {
        if (currentUser) {
            shareAlbum({
                variables: {
                    albumId: props.album.id,
                    userId: user.id,
                    shareLevel,
                }
            })
        }
    }

    return (
        <div className="ModalShareAlbum">
            <Dialog open={props.open} onClose={handleClose} fullWidth>
                <DialogTitle color="primary">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <HandshakeOutlinedIcon color="primary" sx={{ fontSize: 24, mr: "12px" }} />
                        <Typography sx={{ fontSize: { xs: 18, md: 24 } }}>
                            Partage de l'album
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                        {
                            currentUser &&
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <UserBadge user={currentUser} badge={{ label: "propriétaire", color: "desktop" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button size='small' color='shared' onClick={() => handleShareAlbum(currentUser, EnumShareLevel.viewer)}>Partage</Button>
                                    <Button size='small' color='cancel' onClick={() => handleShareAlbum(currentUser, EnumShareLevel.none)}>Annuler</Button>
                                </Box>
                            </Box>
                        }
                        {
                            partners && partners.map(partner => {
                                return (
                                    <Flexbox sx={{ width: "100%" }} key={`partner-box-${partner.id}`}>
                                        {
                                            props.album.owners.map(owner => owner.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} badge={{ label: "propriétaire", color: "desktop" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='shared' onClick={() => handleShareAlbum(partner, EnumShareLevel.viewer)}>Partage</Button>
                                                    <Button size='small' color='cancel' onClick={() => handleShareAlbum(partner, EnumShareLevel.none)}>Annuler</Button>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            props.album.viewers.map(viewer => viewer.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} badge={{ label: "partagé", color: "shared" }} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='desktop' onClick={() => handleShareAlbum(partner, EnumShareLevel.owner)}>Propriétaire</Button>
                                                    <Button size='small' color='cancel' onClick={() => handleShareAlbum(partner, EnumShareLevel.none)}>Annuler</Button>
                                                </Box>
                                            </Box>
                                        }
                                        {
                                            !props.album.owners.map(owner => owner.id).includes(partner.id) &&
                                            !props.album.viewers.map(viewer => viewer.id).includes(partner.id) &&
                                            <Box key={partner.id} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <UserBadge user={partner} size={EnumSize.medium} sx={{ my: 2, ml: { xs: 0, md: 2 }, mr: { xs: 0, md: 8 } }} />
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Button size='small' color='desktop' onClick={() => handleShareAlbum(partner, EnumShareLevel.owner)}>Propriétaire</Button>
                                                    <Button size='small' color='shared' onClick={() => handleShareAlbum(partner, EnumShareLevel.viewer)}>Partage</Button>
                                                </Box>
                                            </Box>
                                        }
                                    </Flexbox>
                                )
                            })
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">Ok</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
