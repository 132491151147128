// import "../../css/components/documents/Document.css"
import "../../css/components/notes/Note.css"
import "../../css/components/notes/markdown.css"
import { Box, Divider, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react'
import { IBreadcrumb, IDocumentSearchResult, INoteSearchResult } from "../../interfaces";
import { EnumBasePathFor, EnumBreadcrumbMode, EnumMessageType, EnumSvgVariant } from "../../enums";
import { dateFormat, truncateString } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { DocumentIcon } from "../svg_icons/DocumentIcon";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { constructPath, getBasePath, getModePath } from "../../utils/Navigation";
import { Breadcrumbs } from "../Breadcrumbs";
import {
    setPocketRefetchNeeded,
    useGetNotes as useGetPocketNotes,
} from "../../redux/features/pocket/pocketSlice";
import { PocketIcon } from "../svg_icons/PocketIcon";
import { useMutation } from "@apollo/client";
import { GQL_MUTATION_ADD_IN_POCKET, GQL_MUTATION_REMOVE_FROM_POCKET } from "../../graphql/Mutations";
import { addMessage } from "../messages/Message";
import { useDispatch } from "react-redux";
import { NoteIcon } from "../svg_icons/NoteIcon";
import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import { toggleExpandNoteAction } from "../../redux/features/search/searchSlice";


interface IProps {
    note: INoteSearchResult,
}

export const NoteSearchResult: FC<IProps> = (props) => {
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | undefined>()
    const pocketNotes = useGetPocketNotes()
    const [addInPocket, { data: addInPocketData, loading: addInPocketLoading, error: addInPocketError }] = useMutation(GQL_MUTATION_ADD_IN_POCKET)
    const [removeFromPocket, { data: removeFromPocketData, loading: removeFromPocketLoading, error: removeFromPocketError }] = useMutation(GQL_MUTATION_REMOVE_FROM_POCKET)
    const [hasOverflow, setHasOverflow] = useState(false);
    const markdownBoxRef = useRef(null);


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const directoryPath = constructPath(getBasePath(EnumBasePathFor.directory, props.note.directory.mode), [
        { key: "directoryId", value: props.note.directory.id.toString() }
    ])

    useEffect(() => {
        if (markdownBoxRef.current) {
            const { scrollHeight, clientHeight } = markdownBoxRef.current;
            setHasOverflow(scrollHeight > clientHeight);
        }
        // }, [props.note.content, props.note.expanded]);
    }, []);

    const getAncestorsBreadcrumbs = () => {
        if (props.note === undefined) {
            return [{ title: "..." }]
        }
        let res = []
        // Le locker d'abord:
        res.push(
            {
                title: props.note.directory.locker.title,
                path: constructPath(getBasePath(EnumBasePathFor.locker, props.note.directory.mode), [
                    { key: "lockerId", value: props.note.directory.locker.id.toString() }
                ])
            }
        )
        // Puis les ancestors:
        res.push(
            ...props.note.directory.ancestors.map(ancestor => {
                return {
                    title: ancestor.name,
                    path: constructPath(getBasePath(EnumBasePathFor.directory, props.note.directory.mode), [
                        { key: "directoryId", value: ancestor.id.toString() }
                    ])
                }
            })
        )
        return res
    }

    const isInPocket = () => {
        if (pocketNotes) {
            return pocketNotes.filter(note => note.id === props.note.id).length > 0
        }
        return false
    }

    const handlePutInPocket = () => {
        addInPocket({
            variables: {
                noteIds: [props.note.id],
            }
        })
    }

    const handleRemoveFromPocket = () => {
        removeFromPocket({
            variables: {
                noteIds: [props.note.id],
            }
        })
    }

    useEffect(() => {
        if (addInPocketError) {
            addMessage({
                location: "NoteSearchResult",
                type: EnumMessageType.Error,
                message: addInPocketError.message,
            })
        } else if (addInPocketData) {
            if (addInPocketData.addInPocket.statusCode === 200) {
                dispatch(setPocketRefetchNeeded(true))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "C'est dans la pocket.",
                })
            } else {
                addInPocketData.addInPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [addInPocketData, addInPocketError])

    useEffect(() => {
        if (removeFromPocketError) {
            addMessage({
                location: "NoteSearchResult",
                type: EnumMessageType.Error,
                message: removeFromPocketError.message,
            })
        } else if (removeFromPocketData) {
            if (removeFromPocketData.removeFromPocket.statusCode === 200) {
                dispatch(setPocketRefetchNeeded(true))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Retiré de la pocket.",
                })
            } else {
                removeFromPocketData.removeFromPocket.errors.map((error: string) => {
                    addMessage({
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [removeFromPocketData, removeFromPocketError])

    useEffect(() => {
        setBreadcrumbs([
            getModePath(props.note.directory.mode),
            ...getAncestorsBreadcrumbs(),
            {
                title: props.note.directory.name || "...",
                path: constructPath(getBasePath(EnumBasePathFor.directory, props.note.directory.mode), [
                    { key: "directoryId", value: props.note.directory.id.toString() }
                ])
            },
            {
                title: props.note.title || "...",
            },
        ])
    }, [props.note.directory])

    const handleExpand = () => {
        dispatch(toggleExpandNoteAction(props.note.id))
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", mx: 2, width: "100%" }}>

            <Paper className={`Note ${props.note.selected ? "selected" : ""} `}>
                <Box className="Note-container">
                    <Box className="Note-main-section">
                        <Box className="Note-icon-section">
                            <NoteIcon color={props.note.mode} sx={{ fontSize: 24, mx: .5, mt: -0.6, ":hover": { cursor: "pointer" } }} />
                        </Box>
                        <Box className="Note-content-section">
                            <Box className="Note-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24, overflowWrap: "anywhere" }}>
                                    {props.note.title}
                                </Typography>
                                {
                                    hasOverflow &&
                                    <ExpandMore
                                        expand={props.note.expanded == undefined ? false : props.note.expanded}
                                        onClick={handleExpand} aria-expanded={props.note.expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                }
                            </Box>
                            <Box
                                ref={markdownBoxRef}
                                className="Note-markdown markdown-body"
                                sx={{
                                    maxHeight: !props.note.expanded ? "200px" : "none",
                                    overflowY: !props.note.expanded ? "hidden" : "visible",
                                }}
                            >
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]} >
                                    {props.note.content}
                                </ReactMarkdown>
                            </Box>
                        </Box>

                    </Box>
                    <Divider />
                    <Box className="Note-footer-section">
                        {
                            isInPocket() ?
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Retirer de la pocket">
                                    <IconButton onClick={handleRemoveFromPocket} color="secondary">
                                        <PocketIcon variant={EnumSvgVariant.secondary} />
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip disableInteractive enterDelay={1000} enterNextDelay={1000} title="Placer dans la pocket">
                                    <IconButton onClick={handlePutInPocket} color="primary">
                                        <PocketIcon variant={EnumSvgVariant.primary} />
                                    </IconButton>
                                </Tooltip>
                        }
                    </Box>
                </Box>
            </Paper>
            <Box sx={{ ml: 2 }}>
                <Breadcrumbs mode={EnumBreadcrumbMode.standard} breadcrumbs={breadcrumbs} sx={{ mt: -1, mb: 2 }} />
            </Box>
        </Box>
    )
}
