import { createSlice } from "@reduxjs/toolkit";
import { IAlbum } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_ALBUM } from "../../../graphql/Queries";


interface IAlbumSliceState {
    album: undefined | IAlbum;
    refetchNeeded: boolean
}

const initialState: IAlbumSliceState = {
    album: undefined,
    refetchNeeded: false,
}

export const albumSlice = createSlice({
    name: "albumSlice",
    initialState,
    reducers: {
        setAlbumAction: (state, action) => {
            state.album = action.payload
        },
        updateAlbumAction: (state, action) => {
            state.album = { ...state.album, ...action.payload }
        },
        setAlbumRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        setSizeInfosAlbumAction: (state, action) => {
            const payloadAlbums = action.payload.albums
            payloadAlbums.forEach((payloadAlbum: IAlbum) => {
                if (state.album && state.album.id === payloadAlbum.id) {
                    state.album = { ...state.album, sizeInfos: payloadAlbum.sizeInfos }
                }
            })
        },


    }
})

export const {
    setAlbumRefetchNeeded,
    setAlbumAction,
    updateAlbumAction,
    setSizeInfosAlbumAction,
} = albumSlice.actions


export const useGetAlbum = (albumId: string) => {
    const album: (undefined | IAlbum) = useSelector((state: IRootState) => state.albumReducer.album)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.albumReducer.refetchNeeded)
    if (refetchNeeded || album === undefined || album.id !== albumId) {
        apolloClient.query({
            query: GQL_ALBUM,
            fetchPolicy: 'network-only',
            variables: {
                albumId,
            }
        }
        ).then(response => {
            store.dispatch(setAlbumAction(response.data.album))
            store.dispatch(setAlbumRefetchNeeded(false))
            return response.data.album
        })
    } else {
        return album
    }
}

export default albumSlice.reducer