import "../../css/components/lockers/LockerDetails.css";
import { Box, Button, Collapse, Divider, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { ICurrentUser, ILocker } from "../../interfaces";
import { LockerIcon } from "../svg_icons/LockerIcon";
import { DeleteOutline, EditOutlined, IosShareOutlined, ShareOutlined } from '@mui/icons-material';

import { ExpandMore } from "../utils/ExpandMore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VaultIcon } from "../svg_icons/VaultIcon";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../messages/Message";
import { EnumBasePathFor, EnumListLockersMode, EnumLockerIconMode, EnumMessageType, EnumParentType, EnumSize, EnumSliceReference } from "../../enums";
import { GQL_MUTATION_DELETE_LOCKER, GQL_MUTATION_PUT_LOCKER_IN_VAULT, GQL_MUTATION_EXTRACT_LOCKER_FROM_VAULT, GQL_MUTATION_SET_LOCKER_PUBLIK, GQL_MUTATION_UNSET_LOCKER_PUBLIK } from "../../graphql/Mutations";
import { removeLockerFromVaultAction } from "../../redux/features/lockers/lockerSlice";
import { ModalEditLocker } from "./modals/ModalEditLocker";
import { SharedIcon } from "../svg_icons/SharedIcon";
import { IRootState, setAllRefetchNeeded } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { NAVIGATE_DESKTOP, NAVIGATE_VAULT, getBasePath } from "../../utils/Navigation";
import { UserBadge } from "../UserBadge";
import { SubDirectoriesInfos } from "../SubDirectoriesInfos";
import { useGetCurrentUser } from "../../redux/features/users/currentUserSlice";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { ModalShareLocker } from "./modals/ModalShareLocker";
import { Flexbox } from "../utils/Flexbox";
import { FloatingSpinner } from "../spinner/FloatingSpinner";

interface IProps {
    locker: ILocker,
    mode: EnumListLockersMode,
}

export const LockerDetails: FC<IProps> = (props) => {
    const currentUser = useGetCurrentUser()
    const [showDeleteLockerDialog, setShowDeleteLockerDialog] = useState(false)
    const [showModalEditLocker, setShowModalEditLocker] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [showModalShareLocker, setShowModalShareLocker] = useState(false)

    const [deleteLocker, { data: deleteLockerData, loading: deleteLockerLoading, error: deleteLockerError }] = useMutation(GQL_MUTATION_DELETE_LOCKER)
    const [putLockerInVault, { data: putLockerInVaultData, loading: putLockerInVaultLoading, error: putLockerInVaultError }] = useMutation(GQL_MUTATION_PUT_LOCKER_IN_VAULT)
    const [extractLockerFromVault, { data: extractLockerFromVaultData, loading: extractLockerFromVaultLoading, error: extractLockerFromVaultError }] = useMutation(GQL_MUTATION_EXTRACT_LOCKER_FROM_VAULT)
    const [setLockerPublik, { data: setLockerPublikData, loading: setLockerPublikLoading, error: setLockerPublikError }] = useMutation(GQL_MUTATION_SET_LOCKER_PUBLIK)
    const [unsetLockerPublik, { data: unsetLockerPublikData, loading: unsetLockerPublikLoading, error: unsetLockerPublikError }] = useMutation(GQL_MUTATION_UNSET_LOCKER_PUBLIK)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        if (deleteLockerError) {
            addMessage({
                location: "LockerDetails",
                type: EnumMessageType.Error,
                message: deleteLockerError.message,
            })
        } else if (deleteLockerData) {
            if (deleteLockerData.deleteLocker.statusCode === 200) {
                setAllRefetchNeeded("LockerDetails", [EnumSliceReference.locker])
                // Attention, ne pas removeLockerAction principal car çà reprovoque des refetch et le backend génère une erreur car il ne trouve plus le locker en question !
                // dispatch(removeLockerAction())
                navigate(getBasePath(EnumBasePathFor.mode, props.mode))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier supprimé.",
                })
            } else {
                deleteLockerData.deleteLocker.errors.map((error: string) => {
                    addMessage({
                        location: "LockerDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteLockerData, deleteLockerError])

    useEffect(() => {
        if (putLockerInVaultError) {
            addMessage({
                location: "LockerDetails",
                type: EnumMessageType.Error,
                message: putLockerInVaultError.message,
            })
        } else if (putLockerInVaultData) {
            if (putLockerInVaultData.putLockerInVault.statusCode === 200) {
                // dispatch(putLockerInVaultAction())
                // setLockersRefetchNeeded(true)
                setAllRefetchNeeded("LockerDetails")
                navigate(NAVIGATE_VAULT)
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier placé dans le coffre.",
                })
            } else {
                putLockerInVaultData.putLockerInVault.errors.map((error: string) => {
                    addMessage({
                        location: "LockerDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [putLockerInVaultData, putLockerInVaultError])

    useEffect(() => {
        if (extractLockerFromVaultError) {
            addMessage({
                location: "LockerDetails",
                type: EnumMessageType.Error,
                message: extractLockerFromVaultError.message,
            })
        } else if (extractLockerFromVaultData) {
            if (extractLockerFromVaultData.extractLockerFromVault.statusCode === 200) {
                dispatch(removeLockerFromVaultAction())
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier retiré du coffre.",
                })
                navigate(NAVIGATE_DESKTOP)
            } else {
                extractLockerFromVaultData.extractLockerFromVault.errors.map((error: string) => {
                    addMessage({
                        location: "LockerDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [extractLockerFromVaultData, extractLockerFromVaultError])

    useEffect(() => {
        if (setLockerPublikError) {
            addMessage({
                location: "LockerDetails",
                type: EnumMessageType.Error,
                message: setLockerPublikError.message,
            })
        } else if (setLockerPublikData) {
            if (setLockerPublikData.setLockerPublik.statusCode === 200) {
                setAllRefetchNeeded("LockerDetails")
                // dispatch(setPublikLockerAction(props.locker.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier rendu public.",
                })
            } else {
                setLockerPublikData.setLockerPublik.errors.map((error: string) => {
                    addMessage({
                        location: "LockerDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [setLockerPublikData, setLockerPublikError])

    useEffect(() => {
        if (unsetLockerPublikError) {
            addMessage({
                location: "LockerDetails",
                type: EnumMessageType.Error,
                message: unsetLockerPublikError.message,
            })
        } else if (unsetLockerPublikData) {
            if (unsetLockerPublikData.unsetLockerPublik.statusCode === 200) {
                setAllRefetchNeeded("LockerDetails")
                // dispatch(unsetPublikLockerAction(props.locker.id))
                addMessage({
                    type: EnumMessageType.Success,
                    message: "Casier rendu privé.",
                })
            } else {
                unsetLockerPublikData.unsetLockerPublik.errors.map((error: string) => {
                    addMessage({
                        location: "LockerDetails",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [unsetLockerPublikData, unsetLockerPublikError])

    const handleEdit = () => {
        setShowModalEditLocker(true)
    }

    const closeModalEditLocker = () => {
        setShowModalEditLocker(false)
    }

    const handleDelete = () => {
        setShowDeleteLockerDialog(true)
    }

    const closeDeleteLockerDialog = () => {
        setShowDeleteLockerDialog(false)
    }

    const confirmedDeleteLocker = () => {
        deleteLocker({
            variables: {
                lockerId: props.locker.id
            }
        })
        setShowDeleteLockerDialog(false)
    }

    const handleExpand = () => {
        // dispatch(toggleExpandLockerAction())
        setExpanded(!expanded)
    }

    const handlePutInVault = () => {
        putLockerInVault({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const handleExtractFromVault = () => {
        extractLockerFromVault({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const closeModalShareLocker = () => {
        setShowModalShareLocker(false)
    }

    const handleShareLocker = () => {
        setShowModalShareLocker(true)
    }

    const handleSetPublik = () => {
        setLockerPublik({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    const handleUnsetPublik = () => {
        unsetLockerPublik({
            variables: {
                lockerId: props.locker.id
            }
        })
    }

    return (
        <>
            <FloatingSpinner dependances={[deleteLockerLoading]} />
            <Paper className={`LockerDetails ${props.locker.selected ? "selected" : ""} `}>
                <Box className="LockerDetails-container">
                    <Box className="LockerDetails-main-section">
                        <Box className="LockerDetails-icon-section">
                            {
                                props.mode === EnumListLockersMode.vault &&
                                <VaultIcon color="vault" sx={{ fontSize: 24, mx: .5 }} />
                            }
                            {
                                props.mode === EnumListLockersMode.desktop &&
                                <LockerIcon mode={props.locker.delivery ? EnumLockerIconMode.delivery : EnumLockerIconMode.standard} color="desktop" sx={{ fontSize: 24, mx: .5 }} />
                            }
                            {
                                props.mode === EnumListLockersMode.shared &&
                                <SharedIcon color="shared" sx={{ fontSize: 24, mx: .5 }} />
                            }

                        </Box>
                        <Box className="LockerDetails-content-section">
                            <Box className="LockerDetails-title-section">
                                <Typography color="text.primary" sx={{ fontSize: 24 }}>
                                    {props.locker.title}
                                </Typography>
                                <Box className="LockerDetails-expand-section">
                                    <ExpandMore expand={expanded}
                                        onClick={handleExpand} aria-expanded={expanded} aria-label="show more"
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="LockerDetails-expanded-section">
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {
                                props.locker.description && <>
                                    {/* <Divider /> */}
                                    <Box className="LockerDetails-description-section">
                                        <Typography sx={{ fontSize: "16px", my: 3 }}>
                                            {props.locker.description}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            <Grid container className="LockerDetails-infos-section">
                                <Grid size={{ xs: 12, md: 3 }} className="LockerDetails-infos-content" sx={{ mb: 2 }}>
                                    <Divider flexItem sx={{ mb: 1 }}>Informations</Divider>
                                    <SubDirectoriesInfos mode={props.mode} detailsMode={true} parentType={EnumParentType.locker} locker={props.locker} />
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-infos-users" sx={{ mb: 2 }}>
                                    <Box className="LockerDetails-infos-owners">
                                        <Divider flexItem>Propriétaires</Divider>
                                        {
                                            props.locker.owners.map((owner) => {
                                                return (
                                                    <UserBadge key={owner.id} user={owner} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                )
                                            })
                                        }
                                    </Box>
                                    {
                                        props.locker.viewers.length > 0 && <>
                                            <Box className="LockerDetails-infos-viewers">
                                                <Divider flexItem>Partagés</Divider>
                                                {
                                                    props.locker.viewers.map((viewer) => {
                                                        return (
                                                            <UserBadge key={viewer.id} user={viewer} size={EnumSize.medium} sx={{ my: 1, mr: { xs: 0, md: 8 } }} />
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </>
                                    }
                                </Grid>
                                <Grid size={{ xs: 12, md: 4 }} className="LockerDetails-actions"
                                    sx={{ mb: 2 }}
                                >
                                    <Divider flexItem sx={{ mb: 1 }}>Actions</Divider>
                                    {
                                        props.mode !== EnumListLockersMode.shared &&
                                        <Flexbox sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                                            <Flexbox direction="column" sx={{ alignItems: "flex-start" }}>
                                                <Button startIcon={<EditOutlined />} color="primary" size="small" onClick={handleEdit}>Modifier</Button>
                                                {
                                                    !props.locker.delivery &&
                                                    !props.locker.vault &&
                                                    <Button startIcon={<HandshakeOutlinedIcon />} color="primary" size="small" onClick={handleShareLocker}>Partager</Button>
                                                }
                                                {
                                                    props.locker.publik &&
                                                    <Button startIcon={<ShareOutlined />} color="shared" size="small" onClick={handleUnsetPublik}>Rendre privé</Button>
                                                }
                                                {
                                                    !props.locker.delivery &&
                                                    !props.locker.vault &&
                                                    !props.locker.publik &&
                                                    <Button startIcon={<ShareOutlined />} color="primary" size="small" onClick={handleSetPublik}>Rendre public</Button>
                                                }
                                            </Flexbox>
                                            <Flexbox direction="column" sx={{ alignItems: "flex-start" }}>
                                                {
                                                    !props.locker.delivery &&
                                                    !props.locker.vault &&
                                                    props.locker.viewers.length === 0 &&
                                                    props.locker.owners.length === 1 &&
                                                    props.locker.owners[0].id === currentUser?.id &&
                                                    <Button startIcon={<VaultIcon />} color="primary" size="small" onClick={handlePutInVault}>Mettre au coffre</Button>
                                                }
                                                {
                                                    props.locker.vault &&
                                                    <Button startIcon={<LockerIcon mode={EnumLockerIconMode.standard} />} color="desktop" size="small" onClick={handleExtractFromVault}>Retirer du coffre</Button>
                                                }
                                                <Button startIcon={<DeleteOutline />} color="error" size="small" onClick={handleDelete}>Supprimer</Button>
                                            </Flexbox>
                                        </Flexbox>
                                    }
                                    {
                                        props.mode === EnumListLockersMode.shared &&
                                        <Typography>
                                            Aucune action disponible pour un casier partagé.
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </Collapse>
                    </Box>
                </Box>
            </Paper>
            <ModalEditLocker
                open={showModalEditLocker}
                handleClose={closeModalEditLocker}
                locker={props.locker}
            />
            <ModalDeleteConfirm
                open={showDeleteLockerDialog}
                title="Suppression du casier"
                content={`Confirmez-vous la suppression définitive du casier ${props.locker.title} ?`}
                handleClose={closeDeleteLockerDialog}
                handleConfirm={confirmedDeleteLocker}
            />
            <ModalShareLocker
                open={showModalShareLocker}
                handleClose={closeModalShareLocker}
                locker={props.locker}
            />
        </>
    )
}
