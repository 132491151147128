import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC } from 'react';
import { UserInfos } from '../components/profile/UserInfos';
import { Layout } from "./Layout";
import { Partners } from '../components/profile/Partners';
import { useGetCurrentUser } from '../redux/features/users/currentUserSlice';
import { UserPackInfos } from '../components/profile/UserPackInfos';
import { EnumUserInfosMode } from '../enums';

interface IProps {
}

export const ProfilePage: FC<IProps> = (props) => {
  const currentUser = useGetCurrentUser()

  return (
    <div className="ProfilePage">
      <Layout>
        <div className="page-body">
          <Box sx={{ mt: 4, px: { xs: 0, md: 8 } }}>
            {
              currentUser &&
              <Grid container spacing={0}>
                <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", px: 2, py: 2 }}>
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
                    <UserInfos user={currentUser} mode={EnumUserInfosMode.standard} />
                    <UserPackInfos user={currentUser} />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", px: 2, py: 2 }}>
                  <Partners user={currentUser} />
                </Grid>
              </Grid>
            }
          </Box>
        </div>
      </Layout>
    </div >
  )
}
