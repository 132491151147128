import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC } from 'react';
import { IDirectory } from "../../interfaces";
import { PublikDirectory } from "./PublikDirectory";
import { PublikNote } from "../notes/PublikNote";
import { PublikDocument } from "../documents/PublikDocument";

interface IProps {
    directory: IDirectory,
}

export const ListPublikSubDirectories: FC<IProps> = (props) => {
    return (
        <div className='ListPublikDirectories'>
            <Container className="ListPublikDirectories-container">
                <Grid container spacing={0} className="ListDirectories-grid-container">
                    {
                        props.directory.children?.map((child) => {
                            return (
                                <Grid key={`${child.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                    <PublikDirectory directory={child} />
                                </Grid>
                            )
                        })
                    }
                    {
                        props.directory.documents?.map((document) => {
                            return (
                                <Grid key={`${document.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                    <PublikDocument document={document} />
                                </Grid>
                            )
                        })
                    }
                    {
                        props.directory.notes?.map((note) => {
                            return (
                                <Grid key={`${note.id}`} size={{ xs: 12 }} display="flex" justifyContent="center" alignItems="flex-start">
                                    <PublikNote note={note} />
                                </Grid>
                            )
                        })
                    }
                    {
                        props.directory.children?.length === 0 &&
                        props.directory.documents?.length === 0 &&
                        props.directory.notes?.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun élément.
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Container>
        </div>
    );
}
