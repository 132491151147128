import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { FC, useEffect, useState } from 'react';
import { Spinner } from "../spinner/Spinner";
import { useGetBankAccounts } from "../../redux/features/cashflow/bankAccountsSlice";
import { ModalDeleteConfirm } from "../modals/ModalDeleteConfirm";
import { addMessage } from "../messages/Message";
import { EnumMessageType } from "../../enums";
import { useMutation } from "@apollo/client";
import { setAllRefetchNeeded } from "../../redux/store";
import { filterMatch, pluralize } from "../../utils/Utils";
import { IBankAccount } from '../../interfaces';
import { BankAccount } from './BankAccount';
import { GQL_MUTATION_DELETE_BANK_ACCOUNTS } from '../../graphql/Mutations';
import { BankAccountsFilter } from './BankAccountsFilter';


interface IProps {
}

export const ListBankAccounts: FC<IProps> = (props) => {
    const bankAccounts = useGetBankAccounts()
    const [showDeleteBankAccountsDialog, setShowDeleteBankAccountsDialog] = useState(false)
    const [filter, setFilter] = useState("")
    const [filteredBankAccounts, setFilteredBankAccounts] = useState<IBankAccount[]>([])

    const [deleteBankAccounts, { data: deleteBankAccountsData, loading: deleteBankAccountsLoading, error: deleteBankAccountsError }] = useMutation(GQL_MUTATION_DELETE_BANK_ACCOUNTS)

    useEffect(() => {
        if (bankAccounts) {
            if (filter.length === 0) {
                setFilteredBankAccounts(bankAccounts)
            } else {
                setFilteredBankAccounts(bankAccounts.filter(bankAccount => (
                    filterMatch(bankAccount.title, filter) ||
                    filterMatch(bankAccount.bankName, filter) ||
                    filterMatch(bankAccount.description, filter)
                )))
            }
        }
    }, [bankAccounts, filter])

    const handleDeleteSelection = () => {
        if (getSelectedBankAccountIds().length > 0) {
            setShowDeleteBankAccountsDialog(true)
        }
    }

    const closeDeleteBankAccountsDialog = () => {
        setShowDeleteBankAccountsDialog(false)
    }

    const confirmedDeleteBankAccounts = () => {
        deleteBankAccounts({
            variables: {
                bankAccountIds: getSelectedBankAccountIds()
            }
        })
        setShowDeleteBankAccountsDialog(false)
    }

    const getSelectedBankAccountIds = () => {
        if (filteredBankAccounts === undefined) {
            return []
        } else {
            return filteredBankAccounts.filter(filteredBankAccount => filteredBankAccount.selected).map(filteredBankAccount => filteredBankAccount.id)
        }
    }

    useEffect(() => {
        if (deleteBankAccountsError) {
            addMessage({
                location: "ListBankAccounts",
                type: EnumMessageType.Error,
                message: deleteBankAccountsError.message,
            })
        } else if (deleteBankAccountsData) {
            if (deleteBankAccountsData.deleteBankAccounts.statusCode === 200) {
                const selectedBankAccountIds = getSelectedBankAccountIds()
                setAllRefetchNeeded("ListBankAccounts")
                addMessage({
                    type: EnumMessageType.Success,
                    message: `${selectedBankAccountIds.length} ${pluralize("compte supprimé", selectedBankAccountIds.length, "comptes supprimés")}.`,
                })
            } else {
                deleteBankAccountsData.deleteBankAccounts.errors.map((error: string) => {
                    addMessage({
                        location: "ListBankAccounts",
                        type: EnumMessageType.Error,
                        message: error,
                    })
                })
            }
        }
    }, [deleteBankAccountsData, deleteBankAccountsError])

    return (
        <div className='ListBankAccounts'>
            <BankAccountsFilter filteredBankAccounts={filteredBankAccounts || []} setFilter={setFilter} selectedBankAccountIds={getSelectedBankAccountIds()} handleDeleteSelection={handleDeleteSelection} />
            <Container className="ListBankAccounts-container">
                <Grid container spacing={0} className="ListBankAccounts-grid-container">
                    {
                        bankAccounts === undefined ? (
                            <Spinner classes="big-spinner" />
                        ) : (
                            filteredBankAccounts?.map((bankAccount) => {
                                return (
                                    <Grid key={`${bankAccount.id}`} size={{ xs: 12, md: 4 }} display="flex" justifyContent="center" alignItems="flex-start">
                                        <BankAccount bankAccount={bankAccount} />
                                    </Grid>
                                )
                            })
                        )
                    }
                    {
                        bankAccounts !== undefined && bankAccounts.length === 0 &&
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography color="text.secondary" sx={{ fontSize: 24 }}>
                                Aucun compte.
                            </Typography>
                        </Box>
                    }
                </Grid>
            </Container>
            <ModalDeleteConfirm
                open={showDeleteBankAccountsDialog}
                title="Suppression des comptes"
                content={`Confirmez-vous la suppression définitive de ${pluralize("ce compte", getSelectedBankAccountIds().length, `ces ${getSelectedBankAccountIds().length} comptes`)} ?`}
                handleClose={closeDeleteBankAccountsDialog}
                handleConfirm={confirmedDeleteBankAccounts}
                danger={true}
            />
        </div>
    )
}
