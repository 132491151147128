import { createSlice } from "@reduxjs/toolkit";
import { IContact } from "../../../interfaces";
import { useSelector } from "react-redux";
import { IRootState, store } from "../../store";
import { apolloClient } from "../../../ApolloClient";
import { GQL_CONTACTS } from "../../../graphql/Queries";


interface IContactsSliceState {
    contacts: undefined | IContact[];
    refetchNeeded: boolean
}

const initialState: IContactsSliceState = {
    contacts: undefined,
    refetchNeeded: false,
}

export const contactsSlice = createSlice({
    name: "contactsSlice",
    initialState,
    reducers: {
        setContactsAction: (state, action) => {
            const newContacts = [...action.payload]
            // state.contacts = sortByTitle(newContacts) as IContact[]
            state.contacts = newContacts as IContact[]
        },
        unsetContactsAction: (state) => {
            state.contacts = initialState.contacts
        },
        setContactsRefetchNeeded: (state, action) => {
            state.refetchNeeded = action.payload
        },
        selectAllContactAction: (state) => {
            state.contacts = state.contacts?.map(
                contact => {
                    return { ...contact, selected: true }
                }
            )
        },
        unSelectAllContactAction: (state) => {
            state.contacts = state.contacts?.map(
                contact => {
                    return { ...contact, selected: false }
                }
            )
        },
        toggleSelectContactAction: (state, action) => {
            const contactId = action.payload
            state.contacts = state.contacts?.map(
                contact => {
                    return contact.id === contactId ? { ...contact, selected: !contact.selected } : contact
                }
            )
        },
    }
})

export const { setContactsAction, unsetContactsAction, setContactsRefetchNeeded,
    selectAllContactAction,
    unSelectAllContactAction,
    toggleSelectContactAction,
} = contactsSlice.actions


export const useGetContacts = () => {
    const contacts: (undefined | IContact[]) = useSelector((state: IRootState) => state.contactsReducer.contacts)
    const refetchNeeded: boolean = useSelector((state: IRootState) => state.contactsReducer.refetchNeeded)
    if (contacts === undefined || refetchNeeded) {
        apolloClient.query({
            query: GQL_CONTACTS,
            fetchPolicy: 'network-only',
            // variables: {
            //     mode: mode,
            //     filter: filterQuery,
            // }
        }
        ).then(response => {
            store.dispatch(setContactsAction(response.data.contacts))
            store.dispatch(setContactsRefetchNeeded(false))
            // console.log("return contacts by fetching..")
            return response.data.contacts
        })
    } else {
        // console.log("return contacts by useSeletor..")
        return contacts
    }
}

export default contactsSlice.reducer